import { defineStore } from "pinia";
import ServiceAPI from "@/services/ServiceAPI.js";

export const useBusinessOwnerBusinessesStore = defineStore("businessOwnerBusinessesStore", {
  state: () => ({
    myBusinesses: [],
    myBusinessesIds: [],
  }),

  getters: {},

  actions: {
    async getMyBusinesses() {
      if (this.myBusinesses.length > 0) {
        return this.myBusinesses;
      }

      const response = await ServiceAPI.getMyBusinesses();
      this.myBusinesses = response.data?.businesses;
      this.myBusinessesIds = this.myBusinesses.map((b) => b.id);

      return this.myBusinesses;
    },

    async getMyBusinessesIds() {
      if (this.myBusinessesIds.length > 0) {
        return this.myBusinessesIds;
      }

      await this.getMyBusinesses();
      return this.myBusinessesIds;
    },
  },
});
