import { event as gEvent } from "vue-gtag";

export default {
  logSearch(searchTerm, state, advancedSearch) {
    gEvent("search", {
      search_term: searchTerm,
      event_category: advancedSearch ? "advanced" : "normal",
      state: state,
    });
  },
  logOpenFinancials(businessId, businessName) {
    gEvent("financials", {
      event_category: "open-request",
      business_id: businessId,
      business_name: businessName,
    });
  },
  logRequestFinancials(businessId, businessName) {
    gEvent("financials", {
      event_category: "request",
      business_id: businessId,
      business_name: businessName,
    });
  },
  logRegisterNewBusiness(businessId, businessName) {
    gEvent("business", {
      event_category: "new",
      business_id: businessId,
      business_name: businessName,
    });
  },
  logViewBusiness(businessId, businessName, state) {
    gEvent("business", {
      event_category: "view",
      business_id: businessId,
      business_name: businessName,
      state,
    });
  },
  logClickTrackBusiness(businessId, track) {
    gEvent("sb-click", {
      event_category: track ? "track-business" : "untrack-business",
      business_id: businessId,
    });
  },
  logClickExportCsv(businessId) {
    gEvent("sb-click", {
      event_category: "export-csv",
      business_id: businessId,
    });
  },
  logClaimedBusinessConversion(businessId) {
    gEvent("claim-business", {
      business_id: businessId,
    });
  },
  logLoanApplicationConversion(businessId) {
    gEvent("loan-application", {
      business_id: businessId,
    });
  },
};
