<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="financial-invitation"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog">
        <div class="modal-content color-background-modal">
          <div class="modal-header">
            <h1 class="modal-title fs-16 fw-semi-bold" id="exampleModalLabel">{{ this.title }}</h1>
            <button
              type="button"
              class="btn-close hover fs-12"
              style="width: 20px; height: 20px"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeAndReset()"></button>
          </div>
          <div v-if="loading === true" class="modal-body">
            <div class="d-flex justify-content-center align-items-center py-3">
              <span class="me-2 color-text-primary">Loading</span>
              <div
                class="spinner-border color-text-primary"
                style="width: 1.4rem; height: 1.4rem"
                role="status"></div>
            </div>
          </div>
          <div v-else class="modal-body mx-2">
            <p class="fw-medium fs-14 ms-1 mb-2">Select the new status</p>
            <select
              required
              v-model="currentStatus"
              class="form-select fs-12 color-background-form border-2 color-border py-2 my-2"
              aria-label="Select funds use">
              <option
                v-for="status in manualTenantStatus"
                :key="status.statusId"
                class="text-muted"
                :value="status.statusId">
                {{ status.status }}
              </option>
            </select>
            <div class="form-group mb-3">
              <label class="fs-14 fw-medium">Insert your notes</label>
              <textarea class="form-control fs-12 color-border" v-model="note" rows="4"></textarea>
            </div>
            <div class="mb-3" v-if="isEmailAvailable">
              <div class="form-check fs-16">
                <input
                  id="sendEmailCheckBox"
                  class="form-check-input"
                  type="checkbox"
                  v-model="sendEmail" />
                <label class="form-check-label fs-14" for="sendEmailCheckBox">
                  Send an automatic email
                </label>
              </div>
            </div>
            <div class="d-flex justify-content-center py-3">
              <button
                @click="closeAndReset()"
                class="btn sb-btn-dark-secondary me-3 fs-14"
                style="width: 80px">
                Cancel
              </button>
              <button @click="changeStatus()" class="btn sb-btn-dark fs-14" style="width: 80px">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { computed, ref } from "vue";

export default {
  props: {
    visible: Boolean,
    title: String,
    status: Object,
    listOfObjects: Object,
    loading: Boolean,
    tenantStatus: Array,
    statusColor: Object,
  },

  setup(props, { emit }) {
    const currentStatus = ref(props.status.statusId);
    const note = ref(null);
    const sendEmail = ref(false);

    const manualTenantStatus = props.tenantStatus.filter((status) => status.manual === true) ?? [];

    const currentStatusObject = computed(() => {
      return props.tenantStatus.find((status) => status.statusId === currentStatus.value) ?? {};
    });

    const isEmailAvailable = computed(() => {
      if (!currentStatusObject?.value?.email) {
        return false;
      }
      return currentStatusObject.value.email;
    });

    const closeDialog = () => {
      emit("toggle", false);
    };

    const changeStatus = () => {
      if (isEmailAvailable.value) {
        emit("changeStatus", currentStatus.value, note.value, sendEmail.value);
      } else {
        emit("changeStatus", currentStatus.value, note.value, null);
      }
      note.value = "";
    };

    const closeAndReset = () => {
      currentStatus.value = props.status;
      closeDialog();
    };

    return {
      closeDialog,
      changeStatus,
      closeAndReset,
      currentStatus,
      note,

      manualTenantStatus,
      isEmailAvailable,
      sendEmail,
    };
  },
  data() {
    return {
      openClose: this.visible,
    };
  },

  watch: {
    visible: function (newVal) {
      this.openClose = newVal;
    },
  },
};
</script>

<style>
.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none !important;
}
</style>
