<template>
  <div class="card border color-border h-100 card-mh">
    <div class="card-body">
      <div v-if="loadSkeleton">
        <h6 class="card-subtitle mb-2 text-muted fs-10 fw-semi-bold font-spacing pt-1">
          SOCIAL MEDIA
        </h6>
        <content-loader
          class="d-lg-block d-none"
          viewBox="0 0 340 40"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <circle cx="320" cy="20" r="20" />
          <circle cx="260" cy="20" r="20" />
        </content-loader>
        <content-loader
          class="d-md-block d-lg-none d-none"
          viewBox="0 0 340 34"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <circle cx="320" cy="17" r="17" />
          <circle cx="270" cy="17" r="17" />
        </content-loader>
        <content-loader
          class="d-md-none d-block"
          viewBox="0 0 340 30"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <circle cx="320" cy="15" r="15" />
          <circle cx="275" cy="15" r="15" />
        </content-loader>
      </div>
      <div v-else class="position-relative">
        <div>
          <i
            class="bi bi-facebook circle-icon position-absolute top-0 end-0"
            :class="[
              this.business && this.business.facebook && this.business.facebook.url
                ? 'color-text-primary hover-2'
                : 'text-muted',
            ]"
            @click="
              this.business && this.business.facebook && this.business.facebook.url
                ? openWindow(this.business.facebook.url)
                : null
            ">
          </i>
          <div class="me-5 position-relative">
            <i
              class="bi bi-instagram circle-icon position-absolute top-0 end-0 me-2"
              :class="[
                this.business && this.business.instagram && this.business.instagram.url
                  ? 'color-text-primary hover-2'
                  : 'text-muted',
              ]"
              @click="
                this.business && this.business.instagram && this.business.instagram.url
                  ? openWindow(this.business.instagram.url)
                  : null
              "></i>
          </div>
        </div>
        <div class="me-4 pe-3 pt-1">
          <h6 class="card-subtitle mb-2 text-muted fs-10 fw-semi-bold font-spacing">
            SOCIAL MEDIA
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  props: ["business", "loading"],
  components: { ContentLoader },
  setup(props) {
    const loadSkeleton =
      props.loading && !(props.business?.facebook?.url || props.business?.instagram?.url);
    return { loadSkeleton };
  },

  methods: {
    openWindow(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style></style>
