<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="financial-invitation"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog">
        <div class="modal-content color-background-modal">
          <div class="modal-header">
            <h1 class="modal-title fs-16 fw-semi-bold" id="exampleModalLabel">
              {{ title }}
            </h1>
            <button
              type="button"
              class="btn-close hover fs-12"
              style="width: 20px; height: 20px"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeDialog()"></button>
          </div>
          <div v-if="loading === true" class="modal-body overflow-auto">
            <div class="d-flex justify-content-center align-items-center py-3">
              <span class="me-2 color-text-primary">Loading</span>
              <div
                class="spinner-border color-text-primary"
                style="width: 1.4rem; height: 1.4rem"
                role="status"></div>
            </div>
          </div>
          <div
            v-else
            class="modal-body mx-2 overflow-auto mb-3"
            style="height: fit-content; max-height: 85vh">
            <div v-for="status in history" :key="status">
              <SingleStatusChange
                class="mb-3 pb-1 pt-1"
                :status="status"
                :statusColors="statusColors" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SingleStatusChange from "@/components/loans_dashboard/SingleStatusChange.vue";
import ServiceAPI from "@/services/ServiceAPI";
import { ref, watch } from "vue";
import { useToast } from "vue-toastification";

export default {
  props: {
    visible: Boolean,
    title: String,
    statusColors: Object,
    loanApplicationId: String,
  },

  components: {
    SingleStatusChange,
  },

  setup(props, { emit }) {
    const history = ref();
    const loading = ref(false);
    const toast = useToast();

    const closeDialog = () => {
      emit("toggle", false);
    };

    const loadHistory = async () => {
      loading.value = true;
      try {
        const response = await ServiceAPI.getApplicationsStatusHistory(props.loanApplicationId);
        history.value = response.data;
      } catch (error) {
        console.log(error);
        loading.value = false;
        toast.error("Failed to load history, please try again later.");
        closeDialog();
      }
      loading.value = false;
    };

    watch(
      () => props.visible,
      async (newVal) => {
        if (newVal) {
          await loadHistory();
        }
      }
    );

    return {
      closeDialog,
      history,
      loading,
    };
  },

  data() {
    return {
      openClose: this.visible,
    };
  },

  watch: {
    visible: function (newVal) {
      this.openClose = newVal;
    },
  },
};
</script>

<style>
.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none !important;
}
</style>
