<template>
  <div class="px-3 bg-white border color-border bg-body" style="width: 180px">
    <div class="vh-100 sticky-top flex-column pt-3 m-0">
      <router-link to="/" class="me-md-auto color-text-primary d-xl-block d-none">
        <img class="mt-2" src="../assets/shubox_black.svg" />
      </router-link>
      <ul
        class="nav nav-pills flex-column mb-md-auto mb-0 justify-content-start mt-5 pt-xl-0 pt-4"
        id="menu">
        <li v-if="this.user?.role != 'business-owner' || hasSearch" class="nav-item mb-1">
          <router-link
            to="/search"
            class="nav-link align-middle px-0"
            :class="[
              page === 'Search' || page === 'ResultsSearch'
                ? 'color-text-primary'
                : 'text-dark sidebar-hover',
            ]">
            <div class="fs-14 d-flex">
              <i class="fs-6 bi bi-search ms-1"></i><span class="ms-2">Search</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            v-if="this.user.role != 'business-owner'"
            to="/followed"
            class="nav-link align-middle px-0"
            :class="[page === 'Followed' ? 'color-text-primary' : 'text-dark sidebar-hover']">
            <div class="fs-14 d-flex">
              <i class="fs-6 bi bi bi-bookmarks ms-1"></i><span class="ms-2">Businesses</span>
            </div></router-link
          >
          <router-link
            v-else
            to="/my-businesses"
            class="nav-link align-middle px-0"
            :class="[page === 'MyBusinesses' ? 'color-text-primary' : 'text-dark sidebar-hover']">
            <div class="fs-14 d-flex">
              <i class="fs-6 bi bi bi-bookmarks ms-1"></i><span class="ms-2">My Businesses</span>
            </div></router-link
          >
        </li>
        <li v-if="user.role === 'admin'" class="nav-item mb-1">
          <router-link
            to="/loan-applications"
            class="nav-link align-middle px-0"
            :class="[
              page === 'LoanApplications' ? 'color-text-primary' : 'text-dark sidebar-hover',
            ]">
            <div class="fs-14 d-flex">
              <i class="fs-6 bi bi-wallet ms-1"></i><span class="ms-2">Loan Applications</span>
            </div>
          </router-link>
        </li>
        <li v-if="user.role === 'admin' || user.role === 'loan-officer'" class="nav-item mb-1">
          <router-link
            to="/loans/dashboard"
            class="nav-link align-middle px-0"
            :class="[
              page === 'LoanApplications' ? 'color-text-primary' : 'text-dark sidebar-hover',
            ]">
            <div class="fs-14 d-flex">
              <i class="fs-6 bi bi-inboxes ms-1"></i><span class="ms-2">Applications</span>
            </div>
          </router-link>
        </li>
      </ul>
      <div
        v-if="this.user.role != 'loan-officer'"
        class="border-top mt-4 mx-0 dl-flex align-items-center">
        <p class="text-dark fs-14 fw-semi-bold mt-4 ms-1">Business Loan Application</p>
        <Popper
          :hover="this.page != 'Business' ? true : false"
          :arrow="true"
          class="fs-12"
          content="Go to the business page to start your loan application!">
          <router-link
            :to="!this.route.params.id ? '' : '/loan-application/' + this.route.params.id"
            target="_blank"
            :disabled="this.page != 'Business'"
            :style="!this.route.params.id ? 'pointer-events: none;' : ''"
            class="nav-link align-middle px-0 start-loan-application">
            <button
              type="button"
              class="btn fs-12 fw-semi-bold px-3 py-2 rounded sb-btn-primary"
              @click="this.loanApplicationStore.changeBusinessId(this.route.params.id)"
              :disabled="this.page != 'Business'">
              Start Application
            </button>
          </router-link>
        </Popper>
      </div>
      <div
        class="container-fluid dropdown pb-4 d-flex justify-content-start position-absolute p-0 bottom-0">
        <a
          href="#"
          class="align-items-center text-decoration-none text-dark dropdown-toggle sidebar-hover"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <table class="table table-borderless">
            <tbody>
              <tr class="align-middle">
                <td class="p-0">
                  <img
                    v-bind:src="user.picture"
                    width="36"
                    height="36"
                    class="rounded-circle align-left border border-white shadow-sm" />
                </td>
                <td class="ps-xl-1 pe-0">
                  <span
                    class="d-flex align-self-left align-left text-wrap fs-14 lh-sm ms-1 pe-2 text-break"
                    style="text-align: left">
                    {{ user.name }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </a>
        <ul class="dropdown-menu dropdown-menu-light text-mdall" aria-labelledby="dropdownUser1">
          <!--           <li><a class="dropdown-item" href="#">Settings</a></li>
          <li><a class="dropdown-item" href="#">Profile</a></li>
          <li>
            <hr class="dropdown-divider" />
          </li> -->
          <li><a class="dropdown-item" @click="logout()">Sign out</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import Keycloak from "../services/auth";
import { hasSearchCapability } from "@/services/auth";
import { useRoute } from "vue-router";

export default {
  props: ["user"],

  setup() {
    const route = useRoute();
    const loanApplicationStore = useLoanApplicationStore();

    return {
      route,
      loanApplicationStore,
    };
  },

  data() {
    return {
      page: this.getPage(),
    };
  },

  methods: {
    logout() {
      Keycloak.logout();
    },
    getPage() {
      return this.$route.name;
    },
  },

  computed: {
    hasSearch() {
      return hasSearchCapability();
    },
  },
};
</script>

<style></style>
