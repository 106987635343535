<template>
  <div class="card border color-border h-100 big-card-mh px-2">
    <div class="card-body pb-0">
      <div v-if="loadSkeleton">
        <div class="align-left">
          <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">History</h4>
        </div>
        <div class="d-none d-sm-block">
          <content-loader
            class="my-3"
            viewBox="0 0 500 250"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="500" height="250" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none">
          <content-loader
            class="my-3"
            viewBox="0 0 320 230"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="320" height="230" />
          </content-loader>
        </div>
      </div>
      <div v-if="!loadSkeleton" class="container-fluid d-flex justify-content-between p-0">
        <div class="align-left">
          <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">History</h4>
        </div>
        <div class="row d-none d-sm-block">
          <div class="col dropdown">
            <p
              class="btn dropdown-toggle disabled b-0 mb-0 pb-0 text-muted fs-12"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Revenue growth rate
              <i class="bi bi-chevron-down text-muted icon-bold-2 fs-10 ps-2"></i>
            </p>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button class="dropdown-item fs-10">Revenue growth rate</button>
              </li>
              <li>
                <button class="dropdown-item fs-10">Cash in</button>
              </li>
              <li>
                <button class="dropdown-item fs-10">Cash out</button>
              </li>
            </ul>
          </div>
          <div class="col dropdown">
            <p
              class="btn dropdown-toggle mb-0 pb-0 text-muted fs-12 dropdown-hover border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Last {{ getCurrentCut() }} scores
              <i class="bi bi-chevron-down text-muted icon-bold-2 fs-10 ps-2"></i>
            </p>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button class="dropdown-item fs-10" @click="setCutTo3()">Last 3 scores</button>
              </li>
              <li>
                <button class="dropdown-item fs-10" @click="setCutTo5()">Last 5 scores</button>
              </li>
              <li>
                <button class="dropdown-item fs-10" @click="setCutTo10()">Last 10 scores</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <canvas
        v-if="!loadSkeleton"
        class="mt-3 pt-1"
        id="historyChart"
        style="height: 190px; min-height: 190px; max-height: 190px"></canvas>
      <div v-if="!loadSkeleton" class="d-flex text-muted mt-3 ms-5 align-items-center">
        <div class="fs-11 d-flex align-items-center">
          <span>
            <i class="color-text-chart-purple fs-8 bi bi-circle-fill me-1"></i>
          </span>
          Shubox score
        </div>
        <div class="fs-11 ms-4 d-flex align-items-center">
          <span>
            <i class="color-text-chart-orange fs-8 bi bi-circle-fill me-1"></i>
          </span>
          Revenue growth rate
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { ContentLoader } from "vue-content-loader";

export default {
  props: ["scoreData", "otherData", "labels", "loading"],
  components: {
    ContentLoader,
  },

  setup(props) {
    const loadSkeleton = props.scoreData?.length == 0 && props.loading;
    return { loadSkeleton };
  },

  data() {
    return {
      cut: 3,
    };
  },

  methods: {
    getCurrentCut() {
      return this.cut;
    },

    setCutTo3() {
      if (this.cut != 3) {
        const chart = Chart.getChart("historyChart");
        chart.destroy();
        this.cut = 3;
      }
    },

    setCutTo5() {
      if (this.cut != 5) {
        const chart = Chart.getChart("historyChart");
        chart.destroy();
        this.cut = 5;
      }
    },

    setCutTo10() {
      if (this.cut != 10) {
        const chart = Chart.getChart("historyChart");
        chart.destroy();
        this.cut = 10;
      }
    },

    getScores() {
      return this.scoreData?.slice(-this.cut);
    },
    getDates() {
      return this.labels?.slice(-this.cut);
    },

    applyFilterScores(array) {
      return array.slice(-3);
    },

    drawChart() {
      const ctx = document.getElementById("historyChart");

      var data = {
        labels: this.getDates(),
        datasets: [
          {
            label: "Shubox Score",
            data: this.getScores(),
            fill: false,
            cubicInterpolationMode: "monotone",
            pointBackgroundColor: "#B16BF8",
            pointBorderColor: "#B16BF8",
            pointHoverBackgroundColor: "#B16BF8",
            pointHoverBorderColor: "#B16BF8",
            tension: 0.2,
            pointRadius: 2.6,
            pointHoverRadius: 6,
            borderColor: ["#B16BF8"],
            colors: ["#B16BF8"],
            borderWidth: 3,
            yAxisID: "y",
          },
          {
            label: "Revenue growth rate",
            data: this.otherData,
            fill: false,
            cubicInterpolationMode: "monotone",
            pointBackgroundColor: "#F09B1B",
            pointBorderColor: "#F09B1B",
            pointHoverBackgroundColor: "#F09B1B",
            pointHoverBorderColor: "#F09B1B",
            tension: 0.2,
            pointRadius: 2.6,
            pointHoverRadius: 6,
            borderColor: ["#F09B1B"],
            colors: ["#F09B1B"],
            borderWidth: 3,
            yAxisID: "y",
          },
        ],
      };

      var options = {
        maintainAspectRatio: false,
        responsive: true,

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 11,
                family: "Open Sans",
              },
            },
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 100,
            title: {
              display: true,
              text: "Shubox Score",
              color: "#B16BF8",
              position: "top",
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
            grid: {
              color: "#E6EAEE",
              borderDash: [5, 5],
              drawBorder: false,
              drawTicks: {
                display: false,
              },
            },
            pointLabels: {
              color: "#728095",
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
          y1: {
            suggestedMin: 0,
            suggestedMax: 100,
            position: "right",
            title: {
              display: true,
              text: "Revenue growth rate (%)",
              color: "#F09B1B",
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
            grid: {
              color: "#E6EAEE",
              borderDash: [5, 5],
              drawBorder: false,
              drawTicks: {
                display: false,
              },
            },
            pointLabels: {
              color: "#728095",
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },

  beforeUpdate() {
    if (this.loadSkeleton) return;
    this.drawChart();
  },

  mounted() {
    if (this.loadSkeleton) return;
    this.drawChart();
  },
};
</script>

<style></style>
