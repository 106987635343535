<template>
  <div class="card border shadow color-background sb-chatbot-container">
    <div
      class="sb-chatbot-header card-title py-2 mirror-rounded-top rounded-top d-flex align-items-center justify-content-center border-0 m-0 color-background-light-purple chat-hover"
      @click="this.shueyChatStore.toggleChat">
      <p class="m-0 color-text-dark fw-bold fs-16 py-1">Ask Shuey</p>
      <i class="bi bi-x fs-24"></i>
    </div>
    <div ref="scrollContainer" class="card-body p-2 overflow-auto scrollContainer">
      <ChatMessage
        :message="
          isAuthenticated
            ? 'Hi, I\'m Shuey, your business assistant. How can I help you?'
            : 'Hi, I\'m Shuey, your business assistant. \nI can help you answer questions about this business once you login.'
        "
        user="assistant"></ChatMessage>
      <li
        class="list-group-item border-0 py-0 mt-1 bg-transparent"
        :class="m.role === 'user' ? 'mt-3' : ''"
        v-for="m in this.shueyChatStore.chatSessions[this.businessId]?.messages
          ? this.shueyChatStore.chatSessions[this.businessId].messages
          : []"
        v-bind:key="m.content">
        <ChatMessage :message="m.content" :user="m.role"></ChatMessage>
      </li>
      <WaitMessage class="mt-1" v-if="this.shueyChatStore.loading"></WaitMessage>
    </div>
    <div class="card-footer border-0 bg-transparent px-3 m-2 pb-2">
      <div
        class="mb-4"
        v-if="
          !this.shueyChatStore.chatSessions[this.businessId] ||
          this.shueyChatStore.chatSessions[this.businessId].messages.length < 2
        ">
        <p class="p-0 fs-14 mb-2 fw-semi-bold color-text-dark">Popular questions</p>
        <div class="mx-3">
          <button
            type="button"
            @click="askPreDefinedQuestion('How is the business doing?')"
            class="btn w-100 card shadow-sm border-0 p-2 px-3 mirror-rounded color-text-dark fs-14 hover"
            :disabled="!isAuthenticated">
            How is the business doing?
          </button>
          <button
            type="button"
            @click="askPreDefinedQuestion('Summarize me the negative reviews.')"
            class="btn w-100 card shadow-sm border-0 p-2 px-3 my-2 mirror-rounded color-text-dark fs-14 hover"
            :disabled="!isAuthenticated">
            Summarize me the negative reviews.
          </button>
          <button
            type="button"
            @click="askPreDefinedQuestion('How are the business financials doing?')"
            class="btn w-100 card shadow-sm border-0 p-2 px-3 mirror-rounded color-text-dark fs-14 hover"
            :disabled="!isAuthenticated">
            How are the business financials doing?
          </button>
        </div>
      </div>
      <div class="mb-3 pt-2">
        <textarea
          class="border-0 shadow-sm fs-14 mirror-rounded-darker w-100 p-2 color-text-dark text-decoration-none"
          placeholder="Ask Shuey anything..."
          id="chatInput"
          v-model="chatInput"
          @keyup.enter="askQuestion()"
          style="resize: none"
          rows="3"
          :disabled="!isAuthenticated"></textarea>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button
            @click="askQuestion()"
            type="button"
            class="btn border-0 color-background-transparent color-text-primary fw-semi-bold fs-14 hover"
            :disabled="!isAuthenticated">
            <i class="bi bi-send"></i>
            Ask Shuey
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatMessage from "@/components/chat/ChatMessage.vue";
import WaitMessage from "@/components/chat/WaitMessage.vue";
import { useShueyChatStore } from "@/stores/ShueyChatStore";
import { useUserStore } from "@/stores/UserStore";

export default {
  setup() {
    const shueyChatStore = useShueyChatStore();
    const userStore = useUserStore();

    return {
      shueyChatStore,
      isAuthenticated: userStore.isAuthenticated,
    };
  },

  data() {
    return {
      chatIsCleared: true,
      chatInput: "",
      businessId: this.$route.params.id,
    };
  },

  methods: {
    async askQuestion() {
      const message = this.chatInput;
      if (this.chatInput.trim().length > 0) {
        this.shueyChatStore.addUserMessage(message, this.businessId);
        this.chatInput = "";
        try {
          await this.shueyChatStore.addAssitantMessage(message, this.businessId);
        } catch (error) {
          console.log(error);
        }
      }
    },

    async askPreDefinedQuestion(message) {
      this.shueyChatStore.addUserMessage(message, this.businessId);
      this.chatInput = "";

      try {
        await this.shueyChatStore.addAssitantMessage(message, this.businessId);
      } catch (error) {
        console.log(error);
      }
    },

    scrollToBottom() {
      const scrollContainer = this.$refs.scrollContainer;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    },
  },

  watch: {
    "shueyChatStore.chatSessions": {
      handler() {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      deep: true,
    },
  },

  components: {
    ChatMessage,
    WaitMessage,
  },

  mounted() {
    this.scrollToBottom();
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 0;
}

textarea {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  outline: none;
}
</style>
