<template>
  <MainLayout :role="role">
    <div class="favorites">
      <div class="row mx-1 h-100">
        <SearchTopBar :numberOfbusinesses="numberOfbusinesses" :title="title"></SearchTopBar>
      </div>
      <div class="row mt-sm-5 mt-2 mx-1">
        <SearchResults :businesses="businesses" :page="page"></SearchResults>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { computed } from "vue";
import SearchTopBar from "@/components/SearchTopBar.vue";
import SearchResults from "@/components/SearchResults.vue";
import MainLayout from "@/components/MainLayout.vue";
import { useFollowedBusinessesStore } from "@/stores/FollowedBusinessesStore";

export default {
  props: ["role"],

  setup() {
    const followedBusinessesStore = useFollowedBusinessesStore();
    followedBusinessesStore.getFollowedBusinesses();

    const numberOfbusinesses = computed(() => {
      return followedBusinessesStore.followed.length;
    });

    const businesses = computed(() => {
      return followedBusinessesStore.followed;
    });

    return {
      businesses,
      numberOfbusinesses,
    };
  },

  components: {
    SearchTopBar,
    SearchResults,
    MainLayout,
  },

  data() {
    return {
      page: "follow",
      title: "Businesses",
    };
  },
};
</script>

<style>
.empty {
  margin-top: 100px;
  text-align: center;
}

.empty i {
  font-size: 200px;
}
</style>
