<template>
  <div class="row pe-0">
    <div class="col-auto d-flex align-items-center">
      <h3 class="color-text-dark mt-2 fs-18 fw-semi-bold fit-content w-100" v-if="this.title">
        {{ this.title }}
      </h3>
    </div>
    <div v-if="showSearch" class="col d-flex align-items-center justify-content-end pe-0">
      <div class="col-auto p-0 bg-body rounded" style="min-width: 40%">
        <div v-if="title === 'Search'">
          <SearchBusinessBarMini></SearchBusinessBarMini>
        </div>
      </div>
      <div class="col-auto"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import SearchBusinessBarMini from "@/components/SearchBusinessBarMini.vue";
export default {
  props: ["numberOfbusinesses", "title"],

  setup() {
    const showSearch = ref(true);

    if (window.innerWidth < 600) {
      showSearch.value = false;
    }

    const handleResize = () => {
      if (window.innerWidth >= 600) {
        showSearch.value = true;
      } else {
        showSearch.value = false;
      }
    };

    const toggleSearch = () => {
      showSearch.value = !showSearch.value;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      showSearch,
      toggleSearch,
    };
  },

  data() {
    return {
      searchQuery: "",
      currentState: "New York",
    };
  },
  methods: {
    getCurrentState() {
      return this.currentState;
    },

    setCurrentState(state) {
      this.currentState = state;
    },

    getQuery() {
      return this.searchQuery;
    },

    getCurrentRoute() {
      return this.$route.path;
    },

    isQueryEmpty() {
      return this.searchQuery.trim().length < 3;
    },

    routerPush() {
      this.$router.push("/results/" + this.getCurrentState() + "/" + this.getQuery()).then(() => {
        this.$router.go();
      });
    },
  },

  components: {
    SearchBusinessBarMini,
  },
};
</script>

<style></style>
