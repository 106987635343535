import Keycloak from "keycloak-js";
import environment from "./environment";
import { query as gtagQuery } from "vue-gtag";

const baseUrl = environment.getAuthUrl();

const initOptions = {
  url: baseUrl,
  realm: "shubox",
  clientId: "shubox-backend",
  onLoad: "check-sso",
  // enableLogging: "true",
  // checkLoginIframe: false,
};

const keycloak = new Keycloak(initOptions);

export default keycloak;

export function init(initApp) {
  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
      if (!auth) {
        console.debug("guest");
      } else {
        console.debug("Authenticated");

        //Token Refresh
        setInterval(() => {
          keycloak
            .updateToken(11)
            .then((refreshed) => {
              if (refreshed) {
                console.debug("Token refreshed" + refreshed);

                // localStorage.setItem("keycloak-token", keycloak.token);
              } else {
                console.warn(
                  "Token not refreshed, valid for " +
                    Math.round(
                      keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
                    ) +
                    " seconds"
                );
              }
            })
            .catch(() => {
              console.error("Failed to refresh token");
            });
        }, 10000);
      }

      initApp();

      gtagQuery("set", "user_properties", {
        tenant: getTenant(),
      });
    })
    .catch((e) => {
      console.error("Authenticated Failed", e);
    });
}

function getRoles() {
  return keycloak.tokenParsed?.realm_access?.roles;
}

function getTenant() {
  return keycloak.tokenParsed?.tenant;
}

function getEmail() {
  return keycloak.tokenParsed?.email;
}

function getFirstName() {
  return keycloak.tokenParsed?.given_name;
}

function getLastName() {
  return keycloak.tokenParsed?.family_name;
}

/**
 * To be called for business owners only.
 * It checks if this business owner has the role that allows them to search.
 *
 * @returns True if it has the permission.
 */
function hasSearchCapability() {
  return getRoles()?.includes("feature:search");
}

export { getRoles, getTenant, hasSearchCapability, getEmail, getFirstName, getLastName };
