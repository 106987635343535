<template>
  <div class="container-fuild mb-4">
    <div class="col">
      <div v-if="business.address?.state === 'NY'">
        <div class="row">
          <div class="col-xl-6">
            <div class="row p-2">
              <ScoreCard
                v-if="business?.score !== undefined"
                :loading="loading"
                :business="business"
                :score="business.score"
                :hasFinancials="hasFinancials"></ScoreCard>
            </div>
            <div class="row p-2">
              <SimilarBusinessesCard
                :type="peersCardType"
                @changePage="changePage"
                @changePeersCardType="changePeersCardType"></SimilarBusinessesCard>
            </div>
          </div>
          <div class="col-xl-6 p-2">
            <MapCard
              :loading="loading"
              :aois="aois"
              :pois="pois"
              :business="business"
              v-if="isAuthenticated"></MapCard>
            <MapPlacehorderCard :loading="loading" :business="business" v-else></MapPlacehorderCard>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 p-2">
            <CategoriesCard
              :loading="loading"
              v-if="business.businessInfo?.description.length > 0"
              :business="business.businessInfo"
              :isMapLayout="true"></CategoriesCard>
          </div>
          <div class="col-xl-6 p-2">
            <AreaStatisticsCard
              :loading="loading"
              :aois="aois"
              :pois="pois"
              :authenticated="isAuthenticated"></AreaStatisticsCard>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-xl-6 p-2">
                <WebsiteCard :loading="loading" :website="this.business.website"></WebsiteCard>
              </div>
              <div class="col-xl-6 p-2">
                <SocialMediaCard
                  :loading="loading"
                  v-if="business"
                  :business="this.business"></SocialMediaCard>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.websiteUsers?.value ?? business.website?.users
                  "
                  :previousValue="business.businessSelfComparison?.websiteUsers?.previousValue"
                  title="WEBSITE USERS"></SocialMediaSmallCard>
              </div>
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.instagramFollowers?.value ??
                    business.instagram?.followers ??
                    null
                  "
                  :previousValue="
                    business.businessSelfComparison?.instagramFollowers?.previousValue
                  "
                  title="IG FOLLOWERS"></SocialMediaSmallCard>
              </div>
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.facebookFollowers?.value ??
                    business.facebook?.followers ??
                    null
                  "
                  :previousValue="business.businessSelfComparison?.facebookFollowers?.previousValue"
                  title="FB FOLLOWERS"></SocialMediaSmallCard>
              </div>
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.facebookLikes?.value ??
                    business.facebook?.likes ??
                    null
                  "
                  :previousValue="business.businessSelfComparison?.facebookLikes?.previousValue"
                  title="FB LIKES"></SocialMediaSmallCard>
              </div>
            </div>
          </div>
          <div class="col-xl-6 p-2">
            <ConsumerSentimentCard
              :loading="loading"
              v-if="
                business.sentiment &&
                business.sentiment.positiveReviews >= 22 &&
                business.sentiment.neutralReviews >= 20 &&
                business.sentiment.negativeReviews >= 25
              "
              :customerSentiment="business.sentiment"></ConsumerSentimentCard>
            <ConsumerSentimentCard2
              :loading="loading"
              v-else-if="business.sentiment"
              :customerSentiment="business.sentiment"></ConsumerSentimentCard2>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-sm-6 p-2">
                <AddressCard
                  :loading="loading"
                  v-if="business.address && business.google && business.google.url"
                  :businessAddress="business.address"
                  :googleWebsite="business.google.url"></AddressCard>
              </div>
              <div class="col-sm-6 p-2">
                <ScheduleCard
                  :loading="loading"
                  v-if="business.schedules && business.google && business.google.url"
                  :schedule="business.schedules"
                  :googleWebsite="business.google.url"></ScheduleCard>
              </div>
            </div>
            <div class="row" v-if="enableEnigmaData && isAuthenticated && isAdminOrLoanOfficer">
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="estimatedMonthlyRevenue.title"
                  :value="estimatedMonthlyRevenue.value"
                  :valueType="estimatedMonthlyRevenue.valueType"
                  :variance="estimatedMonthlyRevenue.variance"
                  :varianceType="estimatedMonthlyRevenue.varianceType"
                  :caption="estimatedMonthlyRevenue.caption"
                  :graphValues="estimatedMonthlyRevenue.graphValues"
                  :graphLabels="estimatedMonthlyRevenue.graphLabels"
                  :graphColor="estimatedMonthlyRevenue.graphColor"
                  :graphId="estimatedMonthlyRevenue.graphId"></GenericMediumCard>
              </div>
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="cardRevenueGrowthByYear.title"
                  :value="cardRevenueGrowthByYear.value"
                  :valueType="cardRevenueGrowthByYear.valueType"
                  :variance="cardRevenueGrowthByYear.variance"
                  :varianceType="cardRevenueGrowthByYear.varianceType"
                  :caption="cardRevenueGrowthByYear.caption"
                  :graphValues="cardRevenueGrowthByYear.graphValues"
                  :graphLabels="cardRevenueGrowthByYear.graphLabels"
                  :graphColor="cardRevenueGrowthByYear.graphColor"
                  :graphId="cardRevenueGrowthByYear.graphId"></GenericMediumCard>
              </div>
            </div>
            <div class="row" v-if="enableEnigmaData && isAuthenticated && isAdminOrLoanOfficer">
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="estimatedCostumersPerDay.title"
                  :value="estimatedCostumersPerDay.value"
                  :valueType="estimatedCostumersPerDay.valueType"
                  :variance="estimatedCostumersPerDay.variance"
                  :varianceType="estimatedCostumersPerDay.varianceType"
                  :caption="estimatedCostumersPerDay.caption"
                  :graphValues="estimatedCostumersPerDay.graphValues"
                  :graphLabels="estimatedCostumersPerDay.graphLabels"
                  :graphColor="estimatedCostumersPerDay.graphColor"
                  :graphId="estimatedCostumersPerDay.graphId"></GenericMediumCard>
              </div>
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="estimatedBasketSize.title"
                  :value="estimatedBasketSize.value"
                  :valueType="estimatedBasketSize.valueType"
                  :variance="estimatedBasketSize.variance"
                  :varianceType="estimatedBasketSize.varianceType"
                  :caption="estimatedBasketSize.caption"
                  :graphValues="estimatedBasketSize.graphValues"
                  :graphLabels="estimatedBasketSize.graphLabels"
                  :graphColor="estimatedBasketSize.graphColor"
                  :graphId="estimatedBasketSize.graphId"></GenericMediumCard>
              </div>
            </div>
            <div class="row p-2">
              <RadarCard
                :loading="loading"
                v-if="business.radarChart || loading"
                :customerInflux="business.radarChart"
                :customerInfluxComparison="business.businessSelfComparison?.footTraffic">
              </RadarCard>
            </div>
          </div>
          <div class="col-xl-6 p-2">
            <ReviewsCard
              :loading="loading"
              v-if="business && (business.google != null || business.yelp != null)"
              :service="google"
              :service2="yelp"
              :business="business"></ReviewsCard>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-xl-6 p-2">
            <ScoreCard
              :loading="loading"
              v-if="business?.score !== undefined || loading"
              :business="business"
              :score="business.score"
              :hasFinancials="hasFinancials"></ScoreCard>
          </div>
          <div class="col-xl-6 p-2">
            <HistoryCardSimple
              v-if="
                ((enviromnent === 'prod' || !isAuthenticated) &&
                  this.business.businessInfo?.description &&
                  this.business.lastScores) ||
                loading
              "
              :loading="loading"
              :scoreData="getScoreDataArray()"
              :labels="getScoreDataLabels()"></HistoryCardSimple>
            <HistoryCard
              v-else-if="
                (this.business.businessInfo?.description && this.business.lastScores) || loading
              "
              :loading="loading"
              :otherData="[20, 20, 30, 10, 20, 30, 10, 20, 30, 10, 20, 30]"
              :scoreData="getScoreDataArray()"
              :labels="getScoreDataLabels()"></HistoryCard>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="row p-2">
              <CategoriesCard
                v-if="business.businessInfo?.description?.length > 0 || loading"
                :loading="loading"
                :business="business.businessInfo"></CategoriesCard>
            </div>
            <div class="row">
              <div class="col-xl-6 p-2">
                <WebsiteCard :loading="loading" :website="this.business.website"></WebsiteCard>
              </div>
              <div class="col-xl-6 p-2">
                <SocialMediaCard
                  v-if="business"
                  :loading="loading"
                  :business="this.business"></SocialMediaCard>
              </div>
            </div>
          </div>
          <div class="col-xl-6 p-2">
            <ConsumerSentimentCard2
              v-if="
                (business.sentiment &&
                  business.sentiment.positiveReviews >= 22 &&
                  business.sentiment.neutralReviews >= 20 &&
                  business.sentiment.negativeReviews >= 25) ||
                loading
              "
              :loading="loading"
              :customerSentiment="business.sentiment"></ConsumerSentimentCard2>
            <ConsumerSentimentCard
              v-else-if="business.sentiment"
              :customerSentiment="business.sentiment"></ConsumerSentimentCard>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.websiteUsers?.value ?? business.website?.users
                  "
                  :previousValue="business.businessSelfComparison?.websiteUsers?.previousValue"
                  title="WEBSITE USERS"></SocialMediaSmallCard>
              </div>
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.instagramFollowers?.value ??
                    business.instagram?.followers ??
                    null
                  "
                  :previousValue="
                    business.businessSelfComparison?.instagramFollowers?.previousValue
                  "
                  title="IG FOLLOWERS"></SocialMediaSmallCard>
              </div>
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.facebookFollowers?.value ??
                    business.facebook?.followers ??
                    null
                  "
                  :previousValue="business.businessSelfComparison?.facebookFollowers?.previousValue"
                  title="FB FOLLOWERS"></SocialMediaSmallCard>
              </div>
              <div class="col-lg-3 col-6 p-0 p-2">
                <SocialMediaSmallCard
                  :loading="loading"
                  :value="
                    business.businessSelfComparison?.facebookLikes?.value ??
                    business.facebook?.likes ??
                    null
                  "
                  :previousValue="business.businessSelfComparison?.facebookLikes?.previousValue"
                  title="FB LIKES"></SocialMediaSmallCard>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 p-2">
                <AddressCard
                  :loading="loading"
                  v-if="(business.address && business.google && business.google.url) || loading"
                  :businessAddress="business.address"
                  :googleWebsite="business.google?.url"></AddressCard>
              </div>
              <div class="col-sm-6 p-2">
                <ScheduleCard
                  :loading="loading"
                  v-if="(business.schedules && business.google && business.google.url) || loading"
                  :schedule="business?.schedules"
                  :googleWebsite="business.google?.url"></ScheduleCard>
              </div>
            </div>
            <div class="row" v-if="enableEnigmaData && isAuthenticated">
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="estimatedMonthlyRevenue.title"
                  :value="estimatedMonthlyRevenue.value"
                  :valueType="estimatedMonthlyRevenue.valueType"
                  :variance="estimatedMonthlyRevenue.variance"
                  :varianceType="estimatedMonthlyRevenue.varianceType"
                  :caption="estimatedMonthlyRevenue.caption"
                  :graphValues="estimatedMonthlyRevenue.graphValues"
                  :graphLabels="estimatedMonthlyRevenue.graphLabels"
                  :graphColor="estimatedMonthlyRevenue.graphColor"
                  :graphId="estimatedMonthlyRevenue.graphId"></GenericMediumCard>
              </div>
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="cardRevenueGrowthByYear.title"
                  :value="cardRevenueGrowthByYear.value"
                  :valueType="cardRevenueGrowthByYear.valueType"
                  :variance="cardRevenueGrowthByYear.variance"
                  :varianceType="cardRevenueGrowthByYear.varianceType"
                  :caption="cardRevenueGrowthByYear.caption"
                  :graphValues="cardRevenueGrowthByYear.graphValues"
                  :graphLabels="cardRevenueGrowthByYear.graphLabels"
                  :graphColor="cardRevenueGrowthByYear.graphColor"
                  :graphId="cardRevenueGrowthByYear.graphId"></GenericMediumCard>
              </div>
            </div>
            <div class="row" v-if="enableEnigmaData && isAuthenticated">
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="estimatedCostumersPerDay.title"
                  :value="estimatedCostumersPerDay.value"
                  :valueType="estimatedCostumersPerDay.valueType"
                  :variance="estimatedCostumersPerDay.variance"
                  :varianceType="estimatedCostumersPerDay.varianceType"
                  :caption="estimatedCostumersPerDay.caption"
                  :graphValues="estimatedCostumersPerDay.graphValues"
                  :graphLabels="estimatedCostumersPerDay.graphLabels"
                  :graphColor="estimatedCostumersPerDay.graphColor"
                  :graphId="estimatedCostumersPerDay.graphId"></GenericMediumCard>
              </div>
              <div class="col-sm-6 p-2">
                <GenericMediumCard
                  :title="estimatedBasketSize.title"
                  :value="estimatedBasketSize.value"
                  :valueType="estimatedBasketSize.valueType"
                  :variance="estimatedBasketSize.variance"
                  :varianceType="estimatedBasketSize.varianceType"
                  :caption="estimatedBasketSize.caption"
                  :graphValues="estimatedBasketSize.graphValues"
                  :graphLabels="estimatedBasketSize.graphLabels"
                  :graphColor="estimatedBasketSize.graphColor"
                  :graphId="estimatedBasketSize.graphId"></GenericMediumCard>
              </div>
            </div>
            <div class="row p-2">
              <RadarCard
                :loading="loading"
                v-if="business.radarChart || loading"
                :customerInflux="business.radarChart"
                :customerInfluxComparison="business.businessSelfComparison?.footTraffic">
              </RadarCard>
            </div>
          </div>
          <div class="col-xl-6 p-2">
            <ReviewsCard
              :loading="loading"
              v-if="(business && (business.google != null || business.yelp != null)) || loading"
              :service="google"
              :service2="yelp"
              :business="business"></ReviewsCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressCard from "./AddressCard.vue";
import ScheduleCard from "./ScheduleCard.vue";
import WebsiteCard from "./WebsiteCard.vue";
import SocialMediaSmallCard from "./SocialMediaSmallCard.vue";
import RadarCard from "./RadarCard.vue";
import ReviewsCard from "./ReviewsCard.vue";
import ConsumerSentimentCard from "./ConsumerSentimentCard.vue";
import ConsumerSentimentCard2 from "./ConsumerSentimentCard2.vue";
import HistoryCard from "./HistoryCard.vue";
import HistoryCardSimple from "./HistoryCardSimple.vue";
import ScoreCard from "./ScoreCard.vue";
import CategoriesCard from "./CategoriesCard.vue";
import SocialMediaCard from "./SocialMediaCard.vue";
import MapCard from "./MapCard.vue";
import MapPlacehorderCard from "./MapPlaceholderCard.vue";
import AreaStatisticsCard from "./AreaStatisticsCard.vue";
import GenericMediumCard from "./GenericMediumCard.vue";
import SimilarBusinessesCard from "./SimilarBusinessesCard.vue";
import environment from "@/services/environment";
import { useUserStore } from "@/stores/UserStore";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  props: {
    google: { type: Object, default: () => {} },
    yelp: { type: Object, default: () => {} },
    business: { type: Object, default: () => {} },
    favorites: { type: Array, default: () => [] },
    hasFinancials: { type: Boolean, default: false },
    aois: { type: Array, default: () => [] },
    pois: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    externalTransactionalInfo: { type: Object, default: () => {} },
    peersComparison: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const userStore = useUserStore();
    const isAuthenticated = userStore.isAuthenticated;
    const isAdminOrLoanOfficer = userStore.role === "admin" || userStore.role === "loan-officer";

    const peersCardType = ref(
      props.peersComparison && Object.keys(props.peersComparison).length > 1 ? "populated" : "empty"
    );

    const changePeersCardType = () => {
      peersCardType.value = peersCardType.value === "populated" ? "empty" : "populated";
    };

    const enableEnigmaData =
      environment.isEnigmaDataEnabled() &&
      Object.keys(props.externalTransactionalInfo ?? {}).length > 0;

    const { d } = useI18n();

    const externalCardRevenue = computed(() => {
      let result = props.externalTransactionalInfo?.cardRevenueBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardRevenueGrowth = computed(() => {
      let result = props.externalTransactionalInfo?.cardRevenueGrowthBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardTransactions = computed(() => {
      let result = props.externalTransactionalInfo?.cardTransactionsBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardRefunds = computed(() => {
      let result = props.externalTransactionalInfo?.refundsBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardCostumers = computed(() => {
      let result = props.externalTransactionalInfo?.cardCostumersBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const estimatedMonthlyRevenue = computed(() => {
      const lastCardRevenueObject =
        externalCardRevenue.value?.[externalCardRevenue.value?.length - 1];

      const secondLastCardRevenueObject =
        externalCardRevenue.value?.[externalCardRevenue.value?.length - 2];

      const value = lastCardRevenueObject?.monthlyCardRevenueLastMonth;
      const variance =
        lastCardRevenueObject && secondLastCardRevenueObject
          ? (lastCardRevenueObject.monthlyCardRevenueLastMonth -
              secondLastCardRevenueObject?.monthlyCardRevenueLastMonth) /
            secondLastCardRevenueObject?.monthlyCardRevenueLastMonth
          : null;

      const caption = lastCardRevenueObject
        ? d(lastCardRevenueObject?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const graphValues = externalCardRevenue.value?.map((cardRevenueObject) => {
        return cardRevenueObject.monthlyCardRevenueLastMonth;
      });

      const graphLabels = externalCardRevenue.value?.map((cardRevenueObject) => {
        return d(cardRevenueObject?.endDate, "monthAndYear");
      });

      return {
        title: "Est. Monthly Card Revenues",
        value,
        valueType: "currencyNoCents",
        variance,
        varianceType: "percentWithOneFractionDigit",
        caption,
        graphValues,
        graphLabels,
        graphColor: "#85E0A3",
        graphId: "EstimatedRevenueChart",
      };
    });

    const estimatedMonthlyTransactions = computed(() => {
      const lastCardTransactionsObject =
        externalCardTransactions.value?.[externalCardTransactions.value?.length - 1];

      const secondLastCardTransactionsObject =
        externalCardTransactions.value?.[externalCardTransactions.value?.length - 2];

      const value = lastCardTransactionsObject?.monthlyCardTransactionsLastMonth;
      const variance =
        lastCardTransactionsObject && secondLastCardTransactionsObject
          ? (lastCardTransactionsObject.monthlyCardTransactionsLastMonth -
              secondLastCardTransactionsObject?.monthlyCardTransactionsLastMonth) /
            secondLastCardTransactionsObject?.monthlyCardTransactionsLastMonth
          : null;

      const caption = lastCardTransactionsObject
        ? d(lastCardTransactionsObject?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const graphValues = externalCardTransactions.value?.map((cardTransactionsObject) => {
        return cardTransactionsObject.monthlyCardTransactionsLastMonth;
      });

      const graphLabels = externalCardTransactions.value?.map((cardTransactionsObject) => {
        return d(cardTransactionsObject?.endDate, "monthAndYear");
      });

      return {
        title: "Est. Monthly Card Transactions",
        value,
        valueType: "integer",
        variance,
        varianceType: "percentWithOneFractionDigit",
        caption,
        graphValues,
        graphLabels,
        graphColor: "#1F8AD8",
        graphId: "EstimatedTransactionsChart",
      };
    });

    const estimatedBasketSize = computed(() => {
      const lastCardTransactionsObject =
        externalCardTransactions.value?.[externalCardTransactions.value?.length - 1];

      const lastCardRevenueObject =
        externalCardRevenue.value?.[externalCardRevenue.value?.length - 1];

      const secondLastCardTransactionsObject =
        externalCardTransactions.value?.[externalCardTransactions.value?.length - 2];

      const secondLastCardRevenueObject =
        externalCardRevenue.value?.[externalCardRevenue.value?.length - 2];

      const lastBasketSize =
        lastCardRevenueObject?.monthlyCardRevenueLastMonth /
        lastCardTransactionsObject?.monthlyCardTransactionsLastMonth;

      const secondLastBasketSize =
        secondLastCardRevenueObject?.monthlyCardRevenueLastMonth /
        secondLastCardTransactionsObject?.monthlyCardTransactionsLastMonth;

      const variance =
        lastBasketSize && secondLastBasketSize
          ? (lastBasketSize - secondLastBasketSize) / secondLastBasketSize
          : null;

      const caption = lastCardTransactionsObject
        ? d(lastCardTransactionsObject?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      return {
        title: "Est. Avg Basket Size",
        value: lastBasketSize,
        valueType: "currency",
        variance,
        varianceType: "percentWithOneFractionDigit",
        caption,
        graphId: "EstimatedTransactionsChart",
      };
    });

    const estimatedCostumersPerDay = computed(() => {
      const lastCardCostumersObject =
        externalCardCostumers.value?.[externalCardCostumers.value?.length - 1];

      const secondLastCardCostumersObject =
        externalCardCostumers.value?.[externalCardCostumers.value?.length - 2];

      const value = lastCardCostumersObject?.averageDailyCardCostumersLastMonth;

      const variance =
        lastCardCostumersObject && secondLastCardCostumersObject
          ? (lastCardCostumersObject.averageDailyCardCostumersLastMonth -
              secondLastCardCostumersObject?.averageDailyCardCostumersLastMonth) /
            secondLastCardCostumersObject?.averageDailyCardCostumersLastMonth
          : null;

      const caption = lastCardCostumersObject
        ? d(lastCardCostumersObject?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const graphValues = externalCardCostumers.value?.map((cardCostumersObject) => {
        return cardCostumersObject.averageDailyCardCostumersLastMonth;
      });

      const graphLabels = externalCardCostumers.value?.map((cardCostumersObject) => {
        return d(cardCostumersObject?.endDate, "monthAndYear");
      });

      return {
        title: "Est. Avg Customers per Day",
        value,
        valueType: "integer",
        variance,
        varianceType: "percentWithOneFractionDigit",

        caption,
        graphValues,
        graphLabels,
        graphColor: "#FF98F5",
        graphId: "EstimatedCostumersPerDayChart",
      };
    });

    const cardRevenueGrowthByYear = computed(() => {
      const lastCardRevenueObject =
        externalCardRevenueGrowth.value?.[externalCardRevenueGrowth.value?.length - 1];

      let previousYearDate = null;
      let caption = null;
      if (lastCardRevenueObject) {
        previousYearDate = new Date(lastCardRevenueObject?.endDate);
        previousYearDate.setFullYear(previousYearDate.getFullYear() - 1);

        caption =
          d(previousYearDate, "monthAndYear").toUpperCase() +
          " - " +
          d(lastCardRevenueObject?.endDate, "monthAndYear").toUpperCase() +
          " VS PRIOR YEAR";
      }

      const value = lastCardRevenueObject?.cardRevenueGrowthLastYear;

      return {
        title: "Est. Card Revenue Growth by Year",
        value,
        valueType: "percentWithOneFractionDigit",
        caption,
        graphId: "EstimatedyYearlyCardRevenueGrowthChart",
      };
    });

    const changePage = () => {
      if (props.page === "BenchmarkCards") {
        emit("changePage", "InsightsCards");
      } else {
        emit("changePage", "BenchmarkCards");
      }
    };

    return {
      isAuthenticated,
      isAdminOrLoanOfficer,
      enableEnigmaData,
      externalCardRevenue,
      externalCardRevenueGrowth,
      externalCardTransactions,
      externalCardRefunds,
      externalCardCostumers,
      estimatedMonthlyRevenue,
      estimatedMonthlyTransactions,
      estimatedCostumersPerDay,
      cardRevenueGrowthByYear,
      estimatedBasketSize,
      changePage,
      peersCardType,
      changePeersCardType,
    };
  },

  data() {
    return {
      init: false,
      showFinancialRequestModal: false,
      enviromnent: environment.getEnvironment(),
    };
  },

  methods: {
    getScoreDataArray() {
      return this.business?.lastScores?.map((score) => score.score);
    },

    getScoreDataLabels() {
      return this.business?.lastScores?.map((score) => this.parseTimestamp(score.timestamp));
    },

    parseTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString("en-US", {
        day: "numeric",
        month: "short",
      });
    },
  },

  components: {
    AddressCard,
    ScheduleCard,
    WebsiteCard,
    SocialMediaSmallCard,
    RadarCard,
    ReviewsCard,
    ConsumerSentimentCard,
    ConsumerSentimentCard2,
    ScoreCard,
    CategoriesCard,
    SocialMediaCard,
    MapCard,
    MapPlacehorderCard,
    AreaStatisticsCard,
    HistoryCard,
    HistoryCardSimple,
    GenericMediumCard,
    SimilarBusinessesCard,
  },
};
</script>

<style>
#a {
  color: #fff;
}
</style>
