<template>
  <div class="mt-3"></div>
  <img
    v-if="tenant.imageUrl"
    :src="tenant.imageUrl"
    alt=""
    style="height: 80px; display: block; margin: auto"
    class="mb-4" />
  <LoanApplicationFormContainer type="interest-form" :tenant="tenant">
    <div v-if="!submitted">
      <p class="fs-20 fw-semi-bold text-center my-4 color-text-dark">Loan Interest Form</p>
      <div class="card-body mt-2 pb-0 mx-sm-5 mx-2">
        <form @submit.prevent="submitForm()">
          <div>
            <p class="fw-bold fs-14 ms-1 mb-2 color-text-dark">Owner's information</p>
            <div class="row">
              <div class="col-6 mt-1">
                <label for="firstName" class="form-label fs-14 text-muted ms-1">First name</label>
                <div class="input-box p-0">
                  <input
                    v-model="firstName"
                    type="text"
                    required
                    id="firstName"
                    placeholder="Insert first name"
                    autocomplete="given-name"
                    autofocus="autofocus"
                    class="form-control color-background-form border-2 color-border py-2 ps-2 fs-14 color-text-dark input-height"
                    aria-label="First name" />
                </div>
              </div>
              <div class="col-6 mt-1">
                <label for="lastName" class="form-label fs-14 text-muted ms-1">Last name</label>
                <div class="input-box p-0">
                  <input
                    v-model="lastName"
                    type="text"
                    required
                    id="lastName"
                    autocomplete="family-name"
                    placeholder="Insert last name"
                    class="form-control color-background-form border-2 color-border py-2 ps-2 color-text-dark input-height"
                    aria-label="Last name" />
                </div>
              </div>
              <div class="mt-2">
                <label for="email" class="form-label fs-14 text-muted ms-1">Email</label>
                <div class="input-box p-0">
                  <input
                    v-model="email"
                    type="email"
                    required
                    id="email"
                    placeholder="Insert email"
                    autocomplete="email"
                    class="form-control color-background-form border-2 color-border py-2 ps-2 fs-14 color-text-dark input-height"
                    aria-label="Email" />
                </div>
              </div>
              <div class="mt-2">
                <label for="phoneNumber" class="form-label fs-14 text-muted ms-1"
                  >Phone number</label
                >
                <div class="input-box p-0">
                  <input
                    v-model="phoneNumber"
                    v-on:input="formatPhoneNumber()"
                    type="text"
                    required
                    id="phoneNumber"
                    placeholder="Insert phone number (000) 000-0000"
                    autocomplete="tel"
                    class="form-control fs-14 color-background-form border-2 color-border py-2 ps-2 color-text-dark input-height"
                    aria-label="Amount (to the nearest dollar)" />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2 color-text-dark">Business Name</p>
            <input
              required
              v-model="businessName"
              class="form-control fs-14 color-background-form border-2 color-border py-2 ps-2 color-text-dark input-height"
              placeholder="Insert business name"
              aria-label="Business Name" />
          </div>
          <div class="mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2 color-text-dark">
              Business Address <i class="text-muted fw-normal"> - Optional</i>
            </p>
            <input
              v-model="businessAddress"
              class="form-control fs-14 color-background-form border-2 color-border py-2 ps-2 color-text-dark input-height"
              placeholder="Insert business address"
              aria-label="Business Address" />
          </div>
          <div class="mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">Annual Revenue</p>
            <div class="input-box">
              <span
                class="prefix fs-14"
                :class="
                  annualRevenue != '' && annualRevenue != null ? 'fw-semi-bold' : 'text-muted'
                "
                >USD</span
              >
              <input
                v-model="annualRevenue"
                v-on:input="addCommasToAmount()"
                type="text"
                required
                id="annualRevenue"
                class="form-control fs-14 color-background-form border-2 color-border py-2 ps-2 color-text-dark input-height"
                aria-label="Annual Revenue" />
            </div>
          </div>
          <div class="d-flex justify-content-center my-5">
            <button type="submit" class="btn sb-btn-primary btn-lg px-3 fs-14">Submit form</button>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <p class="fs-20 fw-bold text-center mt-4 color-text-dark mb-4 mx-5">
        Thank you for choosing {{ tenant.name }}!
      </p>
      <div class="px-5 pb-5 fs-14">
        <p>
          Thank you for submitting the interest form to
          <span class="fw-semi-bold"> {{ tenant.name }}</span
          >. It was successfully received.
        </p>
        <p>
          Our team will review your submission and get back to you shortly. We will send you an
          email confirmation that includes a link to kick-start the detailed application process, so
          remember to check your inbox, including spam.
        </p>
        <p>We look forward to assisting you with your financial needs.</p>
      </div>
    </div>
  </LoanApplicationFormContainer>
</template>

<script>
import LoanApplicationFormContainer from "@/components/loan_application/LoanApplicationFormContainer.vue";
import { useToast } from "vue-toastification";
import { ref, onMounted } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import ServiceAPI from "@/services/ServiceAPI";

export default {
  components: {
    LoanApplicationFormContainer,
  },

  props: {
    tenant: {
      type: Object,
    },
    tenantExternalId: {
      type: String,
    },
  },

  setup(props) {
    const toast = useToast();
    const submitted = ref(false);
    const submissionSuccsess = ref(true);

    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const phoneNumber = ref("");
    const businessName = ref("");
    const annualRevenue = ref("");
    const businessAddress = ref();

    const { executeRecaptcha, recaptchaLoaded, instance } = useReCaptcha();

    const formatPhoneNumber = () => {
      var cleaned = ("" + phoneNumber.value).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        phoneNumber.value = "(" + match[1] + ") " + match[2] + "-" + match[3];
      } else {
        phoneNumber.value = cleaned;
      }
    };

    const addCommasToAmount = () => {
      annualRevenue.value = annualRevenue.value.replace(/\D/g, "");
      annualRevenue.value = annualRevenue.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const removeNumberCommas = (number) => {
      return parseInt(number.replace(/,/g, ""));
    };

    const submitForm = async () => {
      try {
        const token = await executeRecaptcha("short_form");

        const shortForm = {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phoneNumber: phoneNumber.value,
          businessName: businessName.value,
          annualRevenue: removeNumberCommas(annualRevenue.value),
          businessAddress: businessAddress.value,
        };

        await ServiceAPI.submitLoanInterestForm(props.tenantExternalId, shortForm, token);
      } catch (error) {
        submissionError();
        return;
      }
      submitted.value = true;
    };

    const submissionError = () => {
      submissionSuccsess.value = false;
      toast.error("Oops! Something went wrong. Please try again later or contact support.", {
        timeout: 5000,
      });
    };

    onMounted(async () => {
      await recaptchaLoaded();
      instance.value.showBadge();
    });

    return {
      firstName,
      lastName,
      email,
      phoneNumber,
      businessName,
      annualRevenue,
      businessAddress,
      formatPhoneNumber,
      addCommasToAmount,
      submitForm,
      submitted,
      submissionSuccsess,
      submissionError,
    };
  },
};
</script>

<style>
.input-height {
  height: 36px;
}
</style>
