<template>
  <div class="card border color-border h-100 mb-3 px-2 parent">
    <div class="card-body pb-0">
      <div class="container m-0 p-0 align-left d-flex justify-content-between align-items-end">
        <p class="card-title m-0 p-0 mb-1 color-text-dark fs-20">{{ title }}</p>
        <div
          v-if="tooltipLabel && tooltipContent"
          class="card-title m-0 p-0 fs-12 d-flex align-items-center">
          <span class="d-none d-sm-block">{{ tooltipLabel }}</span>
          <span class="m-0 ms-1 p-0 bg-transparent">
            <Popper :hover="true" :arrow="true" :content="tooltipContent">
              <i class="bi bi-info-circle color-text-primary hover text-break fs-16"></i>
            </Popper>
          </span>
        </div>
      </div>
      <div v-if="loading">
        <div class="d-none d-sm-block">
          <content-loader
            class="my-3"
            viewBox="0 0 500 200"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="500" height="200" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none">
          <content-loader
            class="my-3"
            viewBox="0 0 320 250"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="320" height="250" />
          </content-loader>
        </div>
      </div>
      <div v-else>
        <p v-if="subtitle" class="fs-14 text-muted" :class="graphType === 'line' ? ' m-0' : ''">
          {{ subtitle }}
        </p>
        <p
          v-if="yAxisLabel && dataAvailable"
          class="fs-12 text-muted mb-0"
          :class="subtitle ? 'mt-0' : 'mt-3 pt-4'"
          :style="{ color: yAxisLabelColor + ' !important' }">
          {{ yAxisLabel }}
        </p>
        <canvas
          v-if="graphId && dataAvailable"
          class="pt-3 pe-4"
          :id="graphIdValue"
          :style="{
            height: graphHeight,
            'min-height': graphHeight,
            'max-height': graphHeight,
          }"></canvas>
        <div v-else class="container-fluid">
          <div
            :style="{ height: graphHeight, 'min-height': graphHeight, 'max-height': graphHeight }">
            <p class="fs-14 text-muted child">No data available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUpdated } from "vue";
import Chart from "chart.js/auto";

import { ContentLoader } from "vue-content-loader";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    graphType: {
      // options: line, bar
      type: String,
      default: "line",
    },
    graphId: {
      type: String,
    },
    graphDatasets: {
      // example: [{ label: 'Dataset 1', data: [65, 59, 23, 81, 56, 55, 40], borderColor: '#C190F3', backgroundColor: '#C190F3', }]
      type: Array,
    },
    graphHeight: {
      type: String,
      default: "220px",
    },
    graphLabels: {
      type: Array,
    },
    graphLabelsNeeded: {
      type: Boolean,
      default: true,
    },
    tooltipLabel: {
      type: String,
    },
    tooltipContent: {
      type: String,
    },
    yAxisLabel: {
      type: String,
    },
    yAxisLabelColor: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ContentLoader,
  },

  setup(props) {
    const graphIdValue = props.graphId
      ? props.graphId
      : "graph" + Math.floor(Math.random() * 1000000);

    const chartValues = computed(() => {
      return props.graphDatasets ? props.graphDatasets : [];
    });

    const chartLabels = computed(() => {
      return props.graphLabels ? props.graphLabels : [];
    });

    const dataAvailable = computed(() => {
      return (
        chartValues?.value?.length > 0 &&
        (chartLabels?.value?.length > 0 || !props.graphLabelsNeeded)
      );
    });

    let theChart = null;

    const createGraph = () => {
      const data = {
        labels: chartLabels.value,
        datasets: chartValues.value,
      };

      const options = {
        maintainAspectRatio: false,
        pointHitRadius: 20,
        pointRadius: 0,
        responsive: true,
        tension: 0.4,
        indexAxis: "y",
        borderRadius: 4,

        scales: {
          x: {
            alignToPixels: true,
            position: "top",
            grid: {
              color: "#E6EAEE",
              borderDash: [5, 5],
              drawBorder: false,
              drawTicks: {
                display: false,
              },
            },
            ticks: {
              color: "#728095",
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
          y: {
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            pointLabels: {
              color: "#728095",
            },
            ticks: {
              color: "#728095",
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      if (theChart != null) {
        theChart.destroy();
      }

      const ctx = document.getElementById(graphIdValue);
      theChart = new Chart(ctx, {
        type: props.graphType,
        data: data,
        options: options,
      });
    };

    onMounted(() => {
      if (props.graphId && props.graphDatasets && props.graphLabels && !props.loading) {
        createGraph();
      }
    });

    onUpdated(() => {
      if (props.graphId && props.graphDatasets && props.graphLabels && !props.loading) {
        createGraph();
      }
    });

    return {
      graphIdValue,
      dataAvailable,
    };
  },
};
</script>

<style>
.parent {
  position: relative;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
</style>
