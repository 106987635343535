<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="business-edit"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog">
        <div class="modal-content color-background-modal">
          <div class="modal-header">
            <h1 class="modal-title fs-16 fw-semi-bold">{{ this.title }}</h1>
            <button
              type="button"
              class="btn-close hover fs-12"
              style="width: 20px; height: 20px"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeAndReset()"></button>
          </div>
          <div v-if="loading === true" class="modal-body">
            <div class="d-flex justify-content-center align-items-center py-3">
              <span class="me-2 color-text-primary">Loading</span>
              <div
                class="spinner-border color-text-primary"
                style="width: 1.4rem; height: 1.4rem"
                role="status"></div>
            </div>
          </div>
          <div v-else class="modal-body mx-2 mt-3">
            <div class="row">
              <div class="col-4">
                <label class="form-check-label fs-14 fw-semi-bold" for="instagram">
                  <span> <i class="bi bi-instagram circle-icon me-2"></i> Instagram </span>
                </label>
              </div>
              <div class="col">
                <input
                  id="instagram"
                  class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                  type="text"
                  v-model="newInstagram" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-4">
                <label class="form-check-label fs-14 fw-semi-bold" for="facebook">
                  <span> <i class="bi bi-facebook circle-icon me-2"></i> Facebook </span>
                </label>
              </div>
              <div class="col">
                <input
                  id="facebook"
                  class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                  type="text"
                  v-model="newFacebook" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-4">
                <span> <i class="bi bi-globe circle-icon me-2"></i> Website </span>
              </div>
              <div class="col">
                <input
                  id="website"
                  class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                  type="text"
                  v-model="newWebsite" />
              </div>
            </div>

            <div class="mb-3 mt-4">
              <div class="form-check fs-16">
                <input
                  id="confirmationCheckBox"
                  class="form-check-input"
                  type="checkbox"
                  v-model="confirmation" />
                <label class="form-check-label fs-14" for="confirmationCheckBox">
                  I confirm that the information I am providing is accurate.
                </label>
              </div>
            </div>
            <div class="d-flex justify-content-center py-3">
              <button
                @click="closeAndReset()"
                class="btn sb-btn-dark-secondary me-3 fs-14"
                style="width: 80px">
                Cancel
              </button>
              <button
                @click="save()"
                class="btn sb-btn-dark fs-14"
                style="width: 80px"
                :disabled="!confirmation">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { ref, watch } from "vue";

import ServiceAPI from "@/services/ServiceAPI";
import { useToast } from "vue-toastification";

export default {
  props: {
    visible: Boolean,
    title: String,
    loading: Boolean,
    businessId: Number,
    facebook: String,
    instagram: String,
    website: String,
  },
  setup(props, { emit }) {
    const toast = useToast();

    const newFacebook = ref(props.facebook);
    const newInstagram = ref(props.instagram);
    const newWebsite = ref(props.website);
    const openClose = ref(props.visible);
    const confirmation = ref(false);

    const closeDialog = (reloadPage) => {
      emit("toggle", reloadPage);
    };

    const validateNotUrl = (val) => {
      return !val.startsWith("http");
    };

    const save = async () => {
      if (
        (newFacebook.value && !validateNotUrl(newFacebook.value)) ||
        (newInstagram.value && !validateNotUrl(newInstagram.value))
      ) {
        toast.error("Please enter the username instead of the URL");
        return;
      }

      try {
        await ServiceAPI.updateBusiness(
          props.businessId,
          newFacebook.value,
          newInstagram.value,
          newWebsite.value
        );

        toast.success("Updated with success!");
        closeDialog(true);
      } catch (error) {
        toast.error("Couldn't update now, try later.");
        closeAndReset(false);
      }
    };

    const closeAndReset = (reloadPage) => {
      newFacebook.value = props.facebook;
      newInstagram.value = props.instagram;
      newWebsite.value = props.website;
      confirmation.value = false;

      closeDialog(reloadPage);
    };

    watch(
      () => props.visible,
      async (newVal) => {
        openClose.value = newVal;
      }
    );

    return {
      closeDialog,
      save,
      closeAndReset,
      newFacebook,
      newInstagram,
      newWebsite,
      openClose,
      confirmation,
    };
  },
};
</script>

<style>
.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none !important;
}
</style>
