import { defineStore } from "pinia";
import ServiceAPI from "@/services/ServiceAPI.js";
import Analytics from "@/services/analytics.js";

export const useFollowedBusinessesStore = defineStore("followedBusinessesStore", {
  state: () => ({
    followed: [],
    followedIds: [],
    firstFetch: false,
  }),

  getters: {
    isFollowed() {
      return (id) => this.followedIds.includes(id);
    },
  },

  actions: {
    async getFollowedBusinesses() {
      const response = await ServiceAPI.getFavorites();
      const object = response.data;
      this.followed = object.businesses;
      this.followedIds = this.followed.map((b) => b.id);
    },

    async getIds() {
      if (this.firstFetch) return;
      this.firstFetch = true;
      const response = await ServiceAPI.getFavorites();
      const object = response.data;
      this.followed = object.businesses;
      this.followedIds = this.followed.map((b) => b.id);
    },

    async follow(id) {
      await ServiceAPI.addFavorite(id);
      Analytics.logClickTrackBusiness(id, true);
      this.followedIds.push(id);
    },

    async unfollow(id) {
      await ServiceAPI.removeFavorite(id);
      Analytics.logClickTrackBusiness(id, false);
      this.followedIds = this.followedIds.filter((b) => b !== id);
    },
  },
});
