<template>
  <LoanApplicationFormContainer
    :tenant="programDetails ? programDetails.tenant : null"
    :tenantId="tenantId"
    :grant="grant">
    <div class="row mx-md-5 mx-sm-4 mx-2">
      <p class="fs-18 fw-bold text-center mt-4 pt-2">Your Business Application</p>
      <div>
        <span
          v-if="programDetails && programDetails.descriptionHtml"
          v-html="programDetails.descriptionHtml"></span>
      </div>
      <div
        v-if="programDetails && programDetails.status && programDetails.status.isOpen == false"
        class="mb-5 mt-4">
        <span v-html="programDetails.status.message"></span>
      </div>
      <div v-else class="row mt-5 mb-5">
        <div class="d-flex justify-content-center mt-5 mb-5">
          <button
            type="button"
            :disabled="!programDetails"
            class="btn sb-btn-primary fs-14 fw-semi-bold p-2 px-4"
            style="width: fit-content"
            @click="startApplicationV2">
            Start Application
          </button>
        </div>
      </div>
    </div>
  </LoanApplicationFormContainer>
</template>

<script setup>
import LoanApplicationFormContainer from "@/components/loan_application/LoanApplicationFormContainer.vue";
import { defineProps, defineEmits } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useToast } from "vue-toastification";

import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";

const props = defineProps([
  "businessId",
  "authenticated",
  "tenantId",
  "tenant",
  "programId",
  "programDetails",
  "grant",
  "codeIsValid",
]);
const emits = defineEmits(["changeTab", "resetApplication"]);

const { executeRecaptcha } = useReCaptcha();

const loanApplicationStore = useLoanApplicationStore();

const resetApplication = () => {
  emits("resetApplicationNoConfirm");
};

/*const startApplication = async () => {
  const token = !props.authenticated ? await executeRecaptcha("start_application") : undefined;
  const id = await loanApplicationStore.createOrLoadApplication(
    props.businessId,
    token,
    props.tenantId,
    props.grant
  );

  if (id) {
    emits("changeTab", "FormTab1");
  } else {
    useToast().error(
      props.grant
        ? "Cannot start grant application now. Try again later or contact support@shubox.ai"
        : "Cannot start loan application now. Try again later or contact support@shubox.ai"
    );
    resetApplication();
  }
};*/

const startApplicationV2 = async () => {
  const token = !props.authenticated ? await executeRecaptcha("start_application") : undefined;
  const id = await loanApplicationStore.createOrLoadApplicationV2(
    token,
    props.programId,
    props.businessId
  );
  if (id) {
    emits("changeTab", "FormTab1");
  } else {
    useToast().error("Cannot start application now. Try again later or contact support@shubox.ai");
    resetApplication();
  }
};
</script>

<style></style>
