<template>
  <div v-if="status">
    <p class="fs-12 fw-semi-bold color-text-dark mb-0">
      <i class="bi bi-circle-fill fs-12 me-1" :style="{ color: color + '!important' }"></i>
      {{ status.status.status }}
    </p>
    <p class="fs-11 color-text-dark ms-4 mb-0">
      <span class="fw-medium"> {{ status?.user?.name ? status.user.name : "" }}</span>
      {{
        status?.user?.name ? " - " + dateAndTime(status.timestamp) : dateAndTime(status.timestamp)
      }}
    </p>
    <p
      v-if="status.sendEmail"
      class="fs-11 color-text-dark ms-4 mb-0 color-text-dark-grey d-flex align-items-center">
      <i class="bi bi-envelope-at color-text-dark-grey fs-12 me-1"></i> Email sent
    </p>
    <div v-if="status.note" class="card ms-3 me-4 border-0 mt-1 py-1">
      <div class="card-body px-2 py-1">
        <p class="fs-11 color-text-dark mb-0 px-1">{{ status.note }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

export default {
  props: {
    status: Object,
    statusColors: Object,
  },

  setup(props) {
    const { d } = useI18n();
    const color = props.statusColors[props.status?.status?.type] ?? "#000000";
    const dateAndTime = (date) => {
      return d(date, "dateAndTime");
    };

    return {
      color,
      dateAndTime,
    };
  },
};
</script>
<style></style>
