<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="financial-invitation"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-16 fw-semi-bold" id="exampleModalLabel">{{ this.title }}</h1>
            <div class="spinner-border" style="width: 1.4rem; height: 1.4rem" role="status"></div>
          </div>
          <div class="modal-body fs-14 mx-2">
            <p>{{ this.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    title: String,
    message: String,
  },
  data() {
    return {
      openClose: this.visible,
    };
  },

  watch: {
    visible: function (newVal) {
      this.openClose = newVal;
    },
  },
  emits: ["toggle"],
};
</script>

<style></style>
