<template>
  <div class="loanApplicationPage px-sm-5 px-4 color-background" style="overflow: hidden">
    <div class="d-flex justify-content-center my-4 pe-4 pb-2">
      <img v-if="imageUrl" :src="imageUrl" style="max-width: 145px; width: 90%" />
    </div>
    <div v-if="tab === 'SummaryPage'" class="d-flex justify-content-center mb-4">
      <button @click="resetApplication()" class="btn sb-btn-secondary fs-12 fw-semi-bold">
        Restart application
      </button>
    </div>
    <FormNavbar
      @changeTab="changeTab"
      :businessId="this.loanApplicationStore.applicationBusiness.businessId"
      :tab="tab"></FormNavbar>
    <component
      :is="tab"
      @changeTab="changeTab"
      @resetApplication="resetApplication"
      @resetApplicationNoConfirm="resetApplicationNoConfirm"
      :authenticated="isAuthenticatedUser()"
      :businessId="loanApplicationStore.applicationBusiness.businessId"
      :tenantId="newTenantId"
      :programId="programId"
      :programDetails="programDetails"
      :grant="grant"
      :codeIsValid="codeIsValid"
      :questions="programQuestions"
      :documents="programDocuments"
      :tenant="tenant"></component>
  </div>
</template>

<script>
import FormNavbar from "@/components/loan_application/LoanApplicationNavbar.vue";
import FormTab1 from "@/components/loan_application/LoanApplicationTab1.vue";
import FormTab2 from "@/components/loan_application/LoanApplicationTab2.vue";
import FormTab3 from "@/components/loan_application/LoanApplicationTab3.vue";
import FormTab4 from "@/components/loan_application/LoanApplicationTab4.vue";
import StartPage from "@/components/loan_application/LoanApplicationStart.vue";
import ContinuePage from "@/components/loan_application/LoanApplicationContinue.vue";
import FinishedPage from "@/components/loan_application/LoanApplicationFinished.vue";
import SummaryPage from "@/components/loan_application/LoanApplicationSummaryTab.vue";
import { onMounted, onUnmounted, ref } from "vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useToast } from "vue-toastification";
import Analytics from "@/services/analytics";
import { useUserStore } from "@/stores/UserStore";
import router from "@/router";

const UNDEFINED_BUSINESS_ID_KEY = "new";

export default {
  props: {
    businessId: {
      type: String,
    },
    tenantId: {
      type: String,
    },
    grant: {
      type: Boolean,
    },
    invitationCode: {
      type: String,
    },
    programId: {
      type: String,
    },
  },

  setup(props) {
    const business = ref({});
    const { executeRecaptcha, recaptchaLoaded, instance } = useReCaptcha();
    const loanApplicationStore = useLoanApplicationStore();
    const shuboxImageSrc = require("@/assets/shubox_black_48.png");
    const userStore = useUserStore();
    const tenant = ref();
    const codeIsValid = ref();
    const programDetails = ref();
    const programQuestions = ref();
    const programDocuments = ref();
    const imageUrl = ref();

    const newTenantId = ref(props.tenantId);

    const isAuthenticatedUser = () => {
      return userStore.isAuthenticated;
    };

    const loadBusiness = async (id) => {
      try {
        const response = await ServiceAPI.getBusiness(id);
        business.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadProgram = async (programId) => {
      try {
        const recaptchaToken = await getRecaptchaToken("load_program");
        const response = await ServiceAPI.getProgramDetails(programId, recaptchaToken);
        programDetails.value = response.data;
        tenant.value = programDetails.value.tenant;
        newTenantId.value = programDetails.value.tenant.externalId;
        imageUrl.value = programDetails.value.tenant.imageUrl;
      } catch (e) {
        console.error("Could not get program details", e);
        router.replace({ name: "PageNotFound" });
      }
      try {
        const recaptchaToken = await getRecaptchaToken("load_program");
        const response = await ServiceAPI.getProgramQuestions(programId, recaptchaToken);
        programQuestions.value = response.data.questions;
        programDocuments.value = response.data.documents;
      } catch (e) {
        console.error("Could not get program questions", e);
        router.replace({ name: "PageNotFound" });
      }
    };

    const getRecaptchaToken = async (action) => {
      if (!userStore.isAuthenticated) {
        let retry = 0;
        while (retry < 3) {
          try {
            return await executeRecaptcha(action);
          } catch (e) {
            console.error("Could not get recaptcha token", e);
            retry++;
          }
        }
      }
    };

    const resetApplicationNoConfirm = () => {
      loanApplicationStore.clearApplication(props.businessId);
      localStorage.removeItem("applicationBusiness");
      localStorage.removeItem("userLoanApplications");
    };

    const resetApplication = () => {
      const reset = confirm(
        "Are you sure you want to restart the application and start from scratch?"
      );
      if (!reset) {
        return;
      }
      loanApplicationStore.clearApplication(props.businessId);
      localStorage.removeItem("applicationBusiness");
      localStorage.removeItem("userLoanApplications");
      router.go();
    };

    const validateAuthenticationCode = async () => {
      if (!props.invitationCode) {
        return false;
      }

      const captcha = await getRecaptchaToken("application_invitation");

      try {
        const response = await ServiceAPI.validateInvitationCode(
          props.tenantId,
          props.invitationCode,
          captcha
        );
        return response.data?.isValid;
      } catch (error) {
        console.log("Could not validate invitationCode", error);
        return false;
      }
    };

    onMounted(async () => {
      if (!isAuthenticatedUser()) {
        await recaptchaLoaded();
        instance.value.showBadge();
      }

      if (props.businessId) {
        loanApplicationStore.changeBusinessId(props.businessId);
        loadBusiness(loanApplicationStore.applicationBusiness.businessId);
      } else {
        loanApplicationStore.changeBusinessId(UNDEFINED_BUSINESS_ID_KEY);
      }

      codeIsValid.value = await validateAuthenticationCode();

      await loadProgram(props.programId);
    });

    onUnmounted(async () => {
      instance.value.hideBadge();
    });

    return {
      business,
      loanApplicationStore,
      isAuthenticatedUser,
      getRecaptchaToken,
      tenant,
      shuboxImageSrc,
      resetApplication,
      resetApplicationNoConfirm,
      codeIsValid,
      programDetails,
      programQuestions,
      programDocuments,
      imageUrl,
      newTenantId,
    };
  },

  components: {
    FormNavbar,
    StartPage,
    ContinuePage,
    FormTab1,
    FormTab2,
    FormTab3,
    FormTab4,
    FinishedPage,
    SummaryPage,
  },

  computed: {},

  methods: {
    goingBack(currentTab, nextTab) {
      return this.tabs.indexOf(currentTab) > this.tabs.indexOf(nextTab);
    },

    async changeTab(nextTab, data, submitApplication) {
      if (this.goingBack(this.tab, nextTab)) {
        this.tab = nextTab;
        return;
      }
      try {
        if (this.tab === "FormTab1") {
          try {
            const response = await this.saveBasicDetailsV2();
            if (response === false) {
              useToast().error(
                "Could not perform action right now. Try again later or contact support@shubox.ai",
                {
                  timeout: 5000,
                }
              );
              return;
            }
          } catch (error) {
            console.log(error);
            return;
          }
        }
        if (this.tab === "FormTab2") {
          try {
            const response = await this.saveOwnerDetailsV2();
            if (response === false) {
              useToast().error(
                "Could not perform action right now. Try again later or contact support@shubox.ai",
                {
                  timeout: 5000,
                }
              );
              return;
            }
          } catch (error) {
            console.log(error);
            return;
          }
        }
        if (this.tab === "FormTab3") {
          try {
            const response = await this.saveBusinessDetailsV2();
            if (response === false) {
              useToast().error(
                "Could not perform action right now. Try again later or contact support@shubox.ai",
                {
                  timeout: 5000,
                }
              );
              return;
            }
          } catch (error) {
            console.log(error);
            return;
          }
        }
        if (this.tab === "FormTab4") {
          try {
            const response = await this.saveFinancialDetailsV2(data);
            if (response === false) {
              useToast().error(
                "Could not perform action right now. Try again later or contact support@shubox.ai",
                {
                  timeout: 5000,
                }
              );
              return;
            }
          } catch (error) {
            console.log(error);
            return;
          }
        }
        if (this.tab === "SummaryPage") {
          try {
            const response = await this.submitApplicationV2(submitApplication);
            if (response === false) {
              useToast().error(
                "Could not perform action right now. Try again later or contact support@shubox.ai",
                {
                  timeout: 5000,
                }
              );
              return;
            }
            Analytics.logLoanApplicationConversion(this.businessId);
          } catch (error) {
            console.log(error);
            return;
          }
        }
      } catch (error) {
        console.log(error);
        return;
      }

      this.tab = nextTab;

      setTimeout(() => {
        window.scroll({ top: 0, behavior: "smooth" });
      }, 2);
    },

    async saveBasicDetails() {
      const newRequest =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.newRequest;
      const whereHearAboutUs =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.whereHearAboutUs;
      const referralSource =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.referralSource;
      const loanAmount = this.getNumber(
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.amount
      );
      const loanReason =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.fundsPurpose;
      const businessLegalName =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.businessLegalName;
      const businessDba =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.businessDbaName;
      const businessAddress = `${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.businessAddress
      }, ${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.businessCity
      }, ${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.businessState
      }, ${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.businessZip
      }`;
      const businessIndustry =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted.industry;
      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");
        await ServiceAPI.saveBasicDetails(
          this.isAuthenticatedUser(),
          loanApplicationId,
          newRequest,
          whereHearAboutUs,
          referralSource,
          loanAmount,
          loanReason,
          businessLegalName,
          businessDba,
          businessAddress,
          businessIndustry,
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature ?? null,
          recaptchaToken
        );
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async saveOwnerDetails() {
      const firstName =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.firstName;
      const lastName =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.lastName;
      const email =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.email;
      const phoneNumber = this.getNumber(
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.phoneNumber
      );
      const gender =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.gender;
      const isVeteran =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.isVeteran;
      const title =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownersTitle;
      const ancestry =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ethnicity;
      const countryOfOrigin =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.countryOfOrigin;
      const ownershipPercentage =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownershipPercentage;
      const role =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownership;
      const dateOfBirth =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.dateOfBirth;
      const address = `${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownerAddressLine1
      }, ${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownerCity
      }, ${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownerState
      }, ${
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.ownerZip
      }`;
      const creditScore =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.creditScore;
      const personalDebt = this.getNumber(
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.personalDebt
      );
      const filledApplication = false; // what is this for?
      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");
        await ServiceAPI.saveOwnerDetails(
          this.isAuthenticatedUser(),
          loanApplicationId,
          firstName,
          lastName,
          email,
          phoneNumber,
          gender,
          isVeteran,
          title,
          ancestry,
          countryOfOrigin,
          ownershipPercentage,
          role,
          dateOfBirth,
          address,
          creditScore,
          personalDebt,
          filledApplication,
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature ?? null,
          recaptchaToken
        );

        if (!this.isAuthenticatedUser()) {
          const response = this.setUser();
          if (!response) {
            return false;
          }
        }
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async setUser() {
      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");
        const signature =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature;
        const loanApplicationId =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.id;
        const email =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].tab2OwnerInformation.email;
        const phoneNumber =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].tab2OwnerInformation.phoneNumber;
        const firstName =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].tab2OwnerInformation.firstName;
        const lastName =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].tab2OwnerInformation.lastName;
        const password = Math.random().toString(36).substring(0, 12);
        await ServiceAPI.setUser(
          recaptchaToken,
          signature,
          loanApplicationId,
          email,
          phoneNumber,
          firstName,
          lastName,
          password
        );
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async saveBusinessDetails() {
      const businessEmail =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.businessEmail;
      const businessPhone = this.getNumber(
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.businessPhone
      );
      const yearsOpen =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.yearsInBusiness;
      const incorporationDate =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.dateOfFormation;
      const numberOfEmployees =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.numberOfEmployees;
      const businessEntity =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.businessEntity;
      const isForProfit =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.isForProfit;
      const isStartup =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.isStartup;
      const isOperating =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.isOperating;
      const employerIdentificationNumber =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.employerIdentificationNumber;
      const businessDescription =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation.businessDescription;

      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");
        await ServiceAPI.saveBusinessDetails(
          this.isAuthenticatedUser(),
          loanApplicationId,
          businessEmail,
          businessPhone,
          yearsOpen,
          numberOfEmployees,
          incorporationDate,
          businessEntity,
          employerIdentificationNumber,
          isForProfit,
          isStartup,
          isOperating,
          businessDescription,
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature ?? null,
          recaptchaToken
        );
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async saveFinancialDetails(files) {
      const averageRevenueLast3Months = 0; // still not in form
      const existingDebtAmount = this.getNumber(
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation.personalDebt
      );
      const existingCreditIssues =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData.seriousCreditIssuesOrOweBackTaxes;
      const inAnInstallmentPlan =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData.approvedInstallmentPlan;
      const everFilledForBankruptcy =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData.filledForBankruptcy;
      const bankruptcyStillActive =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData.activeBankruptcy;
      const planDescription =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData.fundingUseDescription;
      const grossReceiptsPastYear = this.getNumber(
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData.grossReceiptsPastYear
      );
      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");
        const response = await ServiceAPI.saveFinancialDetails(
          this.isAuthenticatedUser(),
          loanApplicationId,
          averageRevenueLast3Months,
          existingDebtAmount,
          existingCreditIssues,
          inAnInstallmentPlan,
          everFilledForBankruptcy,
          bankruptcyStillActive,
          planDescription,
          grossReceiptsPastYear,
          files,
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature ?? null,
          recaptchaToken
        );
        console.debug(response);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async submitApplication(submitApplication) {
      if (submitApplication) {
        const loanApplicationId =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.id;
        const loanApplicationIdSignature = this.authenticated
          ? null
          : this.loanApplicationStore.userLoanApplications[
              this.loanApplicationStore.applicationBusiness.businessId
            ].loanApplicationId.signature;
        try {
          const recaptchaToken = await this.getRecaptchaToken("application_submit");
          const response = await ServiceAPI.submitLoanApplication(
            this.isAuthenticatedUser(),
            loanApplicationId,
            loanApplicationIdSignature,
            recaptchaToken,
            this.invitationCode
          );
          console.debug(response);
          this.loanApplicationStore.clearApplication(
            this.loanApplicationStore.applicationBusiness.businessId
          );
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
    },

    async saveBasicDetailsV2() {
      const loanApplication =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab1GetStarted;

      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      const loanApplicationIdSignature = this.authenticated
        ? null
        : this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");

        console.debug(loanApplication);

        const loanApplicationResponses = {
          section: "BASIC",
          responses: {},
        };

        if (loanApplication["businessAddress"]) {
          const businessAddress = `${loanApplication.businessAddress}, ${loanApplication.businessCity}, ${loanApplication.businessState}, ${loanApplication.businessZip}`;
          loanApplicationResponses.responses["businessAddress"] = businessAddress;
        }

        for (const [key, value] of Object.entries(loanApplication)) {
          let k = key;
          let v = value;

          if (k == "amount") {
            k = "loanAmount";
            v = this.getNumber(v);
          }

          if (k == "fundsPurpose") {
            k = "loanReason";
          }

          if (k == "businessDbaName") {
            k = "businessDba";
          }

          if (k == "industry") {
            k = "businessIndustry";
          }

          if (k == "businessAddress") {
            k = "businessAddressLine";
          }

          if (k == "businessZip") {
            k = "businessZipCode";
          }

          loanApplicationResponses.responses[k] = v;
        }

        await ServiceAPI.saveBasicDetailsV2(
          recaptchaToken,
          loanApplicationIdSignature,
          loanApplicationId,
          loanApplicationResponses
        );

        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async setUserV2() {
      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");

        const signature =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature;

        const loanApplicationId =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.id;

        const bean = {
          email:
            this.loanApplicationStore.userLoanApplications[
              this.loanApplicationStore.applicationBusiness.businessId
            ].tab2OwnerInformation.email,
          firstName:
            this.loanApplicationStore.userLoanApplications[
              this.loanApplicationStore.applicationBusiness.businessId
            ].tab2OwnerInformation.firstName,
          lastName:
            this.loanApplicationStore.userLoanApplications[
              this.loanApplicationStore.applicationBusiness.businessId
            ].tab2OwnerInformation.lastName,
          phoneNumber:
            this.loanApplicationStore.userLoanApplications[
              this.loanApplicationStore.applicationBusiness.businessId
            ].tab2OwnerInformation.phoneNumber,
          password: Math.random().toString(36).substring(0, 12),
        };

        await ServiceAPI.setUserV2(recaptchaToken, signature, loanApplicationId, bean);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async saveOwnerDetailsV2() {
      const loanApplication =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab2OwnerInformation;

      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      const loanApplicationIdSignature = this.authenticated
        ? null
        : this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");

        const loanApplicationResponses = {
          section: "OWNER",
          responses: {},
        };

        if (loanApplication["ownerAddressLine1"]) {
          const address = `${loanApplication.ownerAddressLine1}, ${loanApplication.ownerCity}, ${loanApplication.ownerState}, ${loanApplication.ownerZip}`;
          loanApplicationResponses.responses["ownerAddress"] = address;
        }

        const addressVariables = ["ownerAddressLine1", "ownerCity", "ownerState", "ownerZip"];

        for (const [key, value] of Object.entries(loanApplication)) {
          if (!addressVariables.includes(key)) {
            let k = key;
            let v = value;

            if (k == "personalDebt" || k == "phoneNumber" || k == "ownerHomePhoneNumber") {
              v = this.getNumber(v);
            }

            if (k == "firstName") {
              k = "ownerFirstName";
            }

            if (k == "lastName") {
              k = "ownerLastName";
            }

            if (k == "email") {
              k = "ownerEmail";
            }

            if (k == "phoneNumber") {
              k = "ownerPhoneNumber";
            }

            if (k == "gender") {
              k = "ownerGender";
            }

            if (k == "isVeteran") {
              k = "ownerIsVeteran";
            }

            if (k == "ethnicity") {
              k = "ownerEthnicity";
            }

            if (k == "countryOfOrigin") {
              k = "ownerCountryOfOrigin";
            }

            if (k == "ownership") {
              k = "ownerRole";
            }

            if (k == "ownersTitle") {
              k = "ownerTitle";
            }

            if (k == "ownershipPercentage") {
              k = "ownerPercentage";
            }

            if (k == "dateOfBirth") {
              k = "ownerDateOfBirth";
            }

            if (k == "creditScore") {
              k = "ownerCreditScore";
            }

            loanApplicationResponses.responses[k] = v;
          }
        }

        await ServiceAPI.saveOwnerDetailsV2(
          recaptchaToken,
          loanApplicationIdSignature,
          loanApplicationId,
          loanApplicationResponses
        );

        if (!this.isAuthenticatedUser()) {
          const response = this.setUser();
          if (!response) {
            return false;
          }
        }
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async saveBusinessDetailsV2() {
      const loanApplication =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab3BusinessInformation;

      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      const loanApplicationIdSignature = this.authenticated
        ? null
        : this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");

        const loanApplicationResponses = {
          section: "BUSINESS",
          responses: {},
        };

        for (const [key, value] of Object.entries(loanApplication)) {
          let k = key;
          let v = value;

          if (k == "businessPhone") {
            v = this.getNumber(v);
          }

          if (k == "yearsInBusiness") {
            k = "businessYearsOpen";
          }

          if (k == "dateOfFormation") {
            k = "businessIncorporationDate";
          }

          if (k == "numberOfEmployees") {
            k = "businessNumberOfEmployees";
          }

          if (k == "employerIdentificationNumber") {
            k = "ein";
          }

          if (k == "isForProfit") {
            k = "businessIsForProfit";
          }

          if (k == "isStartup") {
            k = "businessIsStartup";
          }

          if (k == "isOperating") {
            k = "businessIsOperating";
          }

          loanApplicationResponses.responses[k] = v;
        }

        await ServiceAPI.saveBusinessDetailsV2(
          recaptchaToken,
          loanApplicationIdSignature,
          loanApplicationId,
          loanApplicationResponses
        );
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async saveFinancialDetailsV2(files) {
      const loanApplication =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].tab4FinancialData;

      const loanApplicationId =
        this.loanApplicationStore.userLoanApplications[
          this.loanApplicationStore.applicationBusiness.businessId
        ].loanApplicationId.id;

      const loanApplicationIdSignature = this.authenticated
        ? null
        : this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.signature;

      try {
        const recaptchaToken = await this.getRecaptchaToken("application_save");

        const loanApplicationResponses = {
          section: "FINANCIALS",
          responses: {},
        };

        for (const [key, value] of Object.entries(loanApplication)) {
          let k = key;
          let v = value;

          if (k == "grossReceiptsPastYear") {
            v = this.getNumber(v);
          }

          loanApplicationResponses.responses[k] = v;
        }

        console.debug("FILES:", files);
        console.debug("RESPONSES:", loanApplicationResponses);

        const response = await ServiceAPI.saveFinancialDetailsV2(
          recaptchaToken,
          loanApplicationIdSignature,
          loanApplicationId,
          files,
          loanApplicationResponses
        );
        console.debug(response);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    async submitApplicationV2(submitApplication) {
      if (submitApplication) {
        const loanApplicationId =
          this.loanApplicationStore.userLoanApplications[
            this.loanApplicationStore.applicationBusiness.businessId
          ].loanApplicationId.id;

        const loanApplicationIdSignature = this.authenticated
          ? null
          : this.loanApplicationStore.userLoanApplications[
              this.loanApplicationStore.applicationBusiness.businessId
            ].loanApplicationId.signature;

        try {
          const recaptchaToken = await this.getRecaptchaToken("application_submit");
          const response = await ServiceAPI.submitApplicationV2(
            recaptchaToken,
            loanApplicationIdSignature,
            loanApplicationId
          );
          console.debug(response);
          this.loanApplicationStore.clearApplication(
            this.loanApplicationStore.applicationBusiness.businessId
          );
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
    },

    getNumber(n) {
      if (n === undefined || n === null) {
        return null;
      }
      return Number(n.replace(/[^0-9.]/g, ""));
    },
  },

  data() {
    return {
      tabs: [
        "StartPage",
        "ContinuePage",
        "FormTab1",
        "FormTab2",
        "FormTab3",
        "FormTab4",
        "SummaryPage",
        "FinishedPage",
      ],
      tab: this.loanApplicationStore.isFormStarted(
        this.loanApplicationStore.applicationBusiness.businessId
      )
        ? "ContinuePage"
        : "StartPage",

      loanApplicationBean: {},
    };
  },
};
</script>

<style></style>
