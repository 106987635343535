<template>
  <MainLayout>
    <div class="mt-3"></div>
    <LoanApplicationSummary
      class="mt-5"
      v-if="loanApplication.documents && tenantStatus.length > 0"
      :summary="loanApplication"
      :grant="loanApplication?.isGrant"
      :loanApplicationId="loanApplicationId"
      :tenantStatus="tenantStatus"
      :tenantList="tenantList">
    </LoanApplicationSummary>
  </MainLayout>
</template>

<script>
import { onMounted, ref } from "vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import MainLayout from "@/components/MainLayout.vue";
import LoanApplicationSummary from "@/components/loan_application/LoanApplicationSummary.vue";
import { useUserStore } from "@/stores/UserStore";

export default {
  props: ["loanApplicationId"],
  setup(props) {
    const loanApplication = ref({});
    const tenantStatus = ref();
    const tenantList = ref([]);
    const userStore = useUserStore();

    const isLoanOfficerOrAdmin = () => {
      return (
        userStore.isAuthenticated &&
        (userStore.role === "loan-officer" || userStore.role === "admin")
      );
    };

    const getLoanApplication = async () => {
      const response = await ServiceAPI.getLoanApplication(props.loanApplicationId);
      loanApplication.value = response.data;
    };

    const loadTenantStatus = async () => {
      console.log(loanApplication?.value?.loanProgramId);
      try {
        const response = await ServiceAPI.getTenantAvailableStatuses(
          loanApplication?.value?.loanProgramId
        );
        tenantStatus.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadTenantList = async () => {
      try {
        const response = await ServiceAPI.getTenantList();
        tenantList.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      try {
        await getLoanApplication();
        if (isLoanOfficerOrAdmin()) {
          Promise.all([loadTenantStatus(), loadTenantList()]);
        }
      } catch (error) {
        console.log(error);
      }
    });

    return {
      loanApplication,
      tenantStatus,
      tenantList,
    };
  },

  components: {
    MainLayout,
    LoanApplicationSummary,
  },
};
</script>
