<template>
  <div class="border-top pt-3 color-border-light">
    <p class="mb-2 p-0 fs-14">
      {{ review.comment }}
    </p>
    <div class="d-flex align-items-center mb-1">
      <div class="align-left">
        <img
          v-if="image"
          @error="changeImage()"
          v-bind:src="image"
          style="display: inline"
          width="25"
          height="25"
          class="rounded-circle" />
      </div>
      <div class="align-left">
        <p class="ps-2 m-0 fs-14">{{ review.name }}</p>
      </div>
      <div class="align-left">
        <p class="ms-3 text-muted m-0 fs-14 d-none d-sm-block">
          {{ $d(review.timestamp, "dateOnly") }}
        </p>
        <p class="ms-3 text-muted m-0 fs-14 d-block d-sm-none">
          {{ $d(review.timestamp, "dateNumeric") }}
        </p>
      </div>
      <div class="align-left d-none d-sm-block">
        <div v-if="review.rating < 2.5">
          <p class="fs-12 rounded-pill px-2 ms-3 color-background-negative m-0">Negative</p>
        </div>
        <div v-else-if="review.rating > 3.5">
          <p class="fs-12 rounded-pill px-2 ms-3 color-background-positive m-0">Positive</p>
        </div>
        <div v-else>
          <p class="fs-12 rounded-pill px-2 ms-3 color-background-neutral m-0">Neutral</p>
        </div>
      </div>
      <div class="align-left" v-if="!reviewIsEmtpy() && showShuey">
        <button
          @click="this.postUserMessage"
          class="d-flex align-items-center bg-transparent ms-2 fs-14 border-0 color-text-primary">
          <div class="hover d-flex align-items-center">
            <i class="bi bi-stars fs-20 pe-1 fw-light"></i>
            <p class="d-none d-sm-block m-0 p-0">Answer</p>
          </div>
        </button>
      </div>
    </div>
    <div class="color-star">
      <div class="align-left">
        <div v-if="review.rating < 1 && review.rating >= 0.5">
          <i class="bi bi-star-half fs-14"></i>
        </div>
        <div v-else-if="review.rating < 0.5">
          <i class="bi bi-star fs-14"></i>
        </div>
        <div v-else>
          <i class="bi bi-star-fill fs-14"></i>
        </div>
      </div>

      <div class="align-left">
        <div v-if="review.rating < 2 && review.rating >= 1.5">
          <i class="bi bi-star-half fs-14"></i>
        </div>
        <div v-else-if="review.rating < 1.5">
          <i class="bi bi-star fs-14"></i>
        </div>
        <div v-else>
          <i class="bi bi-star-fill fs-14"></i>
        </div>
      </div>

      <div class="align-left">
        <div v-if="review.rating < 3 && review.rating >= 2.5">
          <i class="bi bi-star-half fs-14"></i>
        </div>
        <div v-else-if="review.rating < 2.5">
          <i class="bi bi-star fs-14"></i>
        </div>
        <div v-else>
          <i class="bi bi-star-fill fs-14"></i>
        </div>
      </div>

      <div class="align-left">
        <div v-if="review.rating < 4 && review.rating >= 3.5">
          <i class="bi bi-star-half fs-14"></i>
        </div>
        <div v-else-if="review.rating < 3.5">
          <i class="bi bi-star fs-14"></i>
        </div>
        <div v-else>
          <i class="bi bi-star-fill fs-14"></i>
        </div>
      </div>

      <div class="align-left">
        <div v-if="review.rating < 5 && review.rating >= 4.5">
          <i class="bi bi-star-half fs-14"></i>
        </div>
        <div v-else-if="review.rating < 4.5">
          <i class="bi bi-star fs-14"></i>
        </div>
        <div v-else>
          <i class="bi bi-star-fill fs-14"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useShueyChatStore } from "@/stores/ShueyChatStore";

export default {
  setup() {
    const shueyChatStore = useShueyChatStore();

    return {
      shueyChatStore,
    };
  },

  props: ["review", "businessId", "showShuey"],

  data() {
    return {
      image: this.review.imageUrl,
    };
  },
  methods: {
    reviewIsEmtpy() {
      return this.review.comment.trim() === "";
    },

    changeImage() {
      this.image = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
    },

    postUserMessage() {
      this.shueyChatStore.showChat = true;
      this.shueyChatStore.answerToReview(this.review, this.businessId);
    },
  },
};
</script>

<style></style>
