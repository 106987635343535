<template>
  <div class="card border color-border h-100 px-2">
    <div class="card-body pb-0">
      <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">{{ title }}</h4>
      <div class="container-fluid my-4 d-flex align-items-center fs-14 text-muted ps-0">
        <p class="">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
  },
};
</script>

<style></style>
