<template>
  <div class="card border color-border bg-body rounded" style="width: 270px">
    <div class="card-body position-relative pointer">
      <div class="position-relative mb-3 pb-3">
        <p
          class="position-absolute color-text-negative top-0 end-0 fs-24 fw-bold"
          v-if="loanApplication.business && loanApplication.business.score <= 24">
          {{ loanApplication.business.score }}
        </p>
        <p
          class="position-absolute color-text-neutral top-0 end-0 fs-24 fw-bold"
          v-if="
            loanApplication.business &&
            loanApplication.business.score > 24 &&
            loanApplication.business.score <= 37
          ">
          {{ loanApplication.business.score }}
        </p>
        <p
          class="position-absolute color-text-positive top-0 end-0 fs-24 fw-bold"
          v-if="loanApplication.business && loanApplication.business.score > 37">
          {{ loanApplication.business.score }}
        </p>

        <div class="me-4 pe-3">
          <p
            class="card-subtitle mb-2 text-muted text-uppercase fs-10 fw-semi-bold"
            v-if="loanApplication.business.info">
            {{ loanApplication.business.info.category }}
          </p>
          <p class="card-text m-0 fw-bold fs-14" v-if="loanApplication.business.info">
            <router-link
              class="hover color-text-dark text-decoration-none"
              :to="{
                name: 'Business',
                params: { id: loanApplication.business.id },
              }"
              target="_blank">
              {{ loanApplication.business.info.name }}
            </router-link>
          </p>
          <p class="card-text m-0 fs-14" v-if="loanApplication.business.address">
            {{ loanApplication.business.address.city }},
            {{ loanApplication.business.address.state }}
            {{ loanApplication.business.address.zipCode }}
          </p>
          <p class="card-text m-0 fs-14" v-if="loanApplication.business.address">
            {{ loanApplication.business.address.country }}
          </p>

          <p
            class="p-2 me-1 mt-3 badge rounded-pill wrap p-0 m-0 fs-12 fw-normal color-background-success">
            {{ loanApplication.status.toLowerCase() }}
          </p>
        </div>
      </div>

      <router-link
        v-if="loanApplication.business.info"
        class="card-link color-text-primary text-decoration-none position-absolute mb-3 bottom-0 fs-14 hover text-center"
        :to="{
          name: 'LoanApplicationSummary',
          params: { loanApplicationId: loanApplication.loanApplicationId },
        }"
        target="_blank">
        View Application
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },

  props: ["loanApplication"],
};
</script>

<style></style>
