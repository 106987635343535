<template>
  <MainLayout :role="role">
    <div class="results">
      <div class="row mx-1">
        <SearchTopBar :numberOfbusinesses="numberOfbusinesses" :title="title"></SearchTopBar>
      </div>
      <div class="row mt-sm-5 mt-2 mx-1">
        <SearchResults
          v-if="!loading"
          :businesses="search.businesses"
          :page="page"
          :role="role"
          :searchSignature="searchSignature"></SearchResults>
        <div v-else-if="loading" class="col-12">
          <div class="d-flex justify-content-center">
            <p class="fs-14 color-text-primary">
              Loading businesses
              <span
                class="align-middle spinner-border color-text-primary ms-2 mb-1"
                style="width: 1.2rem; height: 1.2rem"
                role="status"
                aria-hidden="true"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <SearchExceededPopover v-if="showPopup" @closeSearchExceededPopup="showPopup = false">
    </SearchExceededPopover>
  </MainLayout>
</template>

<script>
import { onMounted, ref, defineAsyncComponent } from "vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import SearchTopBar from "@/components/SearchTopBar.vue";
import SearchResults from "@/components/SearchResults.vue";
import MainLayout from "@/components/MainLayout.vue";
import { useRoute } from "vue-router";
import { useFollowedBusinessesStore } from "@/stores/FollowedBusinessesStore";
import { useUserStore } from "@/stores/UserStore";
import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  props: ["role"],

  setup() {
    const search = ref({});
    const route = useRoute();
    const numberOfbusinesses = ref(0);
    const { executeRecaptcha, recaptchaLoaded, instance } = useReCaptcha();
    const searchSignature = ref(null);
    const loading = ref(false);

    const followedBusinessesStore = useFollowedBusinessesStore();
    const userStore = useUserStore();
    const showPopup = ref(userStore.exceedsSearches);

    if (userStore.isAuthenticated) {
      followedBusinessesStore.getFollowedBusinesses();
    }

    const loadSearch = async (region, name) => {
      try {
        loading.value = true;
        if (userStore.isAuthenticated) {
          const response = await ServiceAPI.getSearchedBusinesses(region, name);
          search.value = response.data;
          numberOfbusinesses.value = search.value.businesses.length;
          loading.value = false;
        } else {
          const token = await executeRecaptcha("business_search");
          const response = await ServiceAPI.searchBusinessWithoutSession(name, region, token);

          search.value = response.data;
          searchSignature.value = search.value.signature;
          numberOfbusinesses.value = search.value.businesses.length;
          userStore.incrementSearches();
          loading.value = false;
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
      loading.value = false;
    };

    onMounted(async () => {
      if (!userStore.isAuthenticated) {
        await recaptchaLoaded();
        instance.value.showBadge();
      }
      const region = route.params.region;
      const name = route.params.name;
      await loadSearch(region, name);
    });

    return {
      search,
      followedBusinessesStore,
      numberOfbusinesses,
      searchSignature,
      showPopup,
      loading,
    };
  },

  components: {
    SearchResults,
    SearchTopBar,
    MainLayout,
    SearchExceededPopover: defineAsyncComponent(() =>
      import("@/components/popup/SearchExceededPopover.vue")
    ),
  },

  data() {
    return {
      page: "search",
      title: "Search",
      google: {
        name: "Google",
        imageUrl: "https://freesvg.org/img/1534129544.png",
      },

      yelp: {
        name: "Yelp",
        imageUrl: "https://cdn-icons-png.flaticon.com/512/174/174882.png",
      },
    };
  },
};
</script>

<style></style>
