<template>
  <div class="card border color-border h-100 big-card-mh px-2">
    <div class="card-body">
      <h4 class="card-title mb-2 color-text-dark fs-20">Foot Traffic Overview</h4>
      <div v-if="loadSkeleton">
        <content-loader
          class="d-none d-sm-block my-2"
          viewBox="0 0 340 140"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <circle cx="260" cy="70" r="70" />
          <rect x="20" y="20" rx="3" ry="3" width="120" height="10" />
          <rect x="20" y="35" rx="3" ry="3" width="120" height="10" />
          <rect x="20" y="50" rx="3" ry="3" width="90" height="10" />
          <rect x="20" y="105" rx="3" ry="3" width="60" height="10" />
          <rect x="20" y="120" rx="3" ry="3" width="64" height="10" />
        </content-loader>
        <content-loader
          class="d-block d-sm-none"
          viewBox="0 0 200 140"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <circle cx="100" cy="60" r="50" />
          <rect x="180" y="180" rx="3" ry="3" width="120" height="10" />
          <rect x="0" y="115" rx="3" ry="3" width="200" height="10" />
          <rect x="0" y="130" rx="3" ry="3" width="200" height="10" />
        </content-loader>
      </div>
      <div v-else class="container-fluid">
        <div class="align-left mt-5">
          <p class="text-break m-0 fs-14 color-text-dark">
            On average
            <span v-if="this.customerInflux" class="fw-semi-bold">{{
              getDay(indexOfBiggestInflux())
            }}</span>
            is
          </p>
          <p v-if="this.customerInflux" class="text-break m-0 fs-14 color-text-dark">
            the busiest day, <span class="fw-semi-bold">{{ getBiggestInflux() }}% of</span>
          </p>
          <p class="text-break m-0 mb-5 fs-14 color-text-dark">
            <span class="fw-semi-bold"> customers</span> check in.
          </p>
          <!--<div class="py-1 d-flex align-content-center">
            <i class="bi bi-circle-fill fs-12 color-text-1st-trimester"></i>
            <span class="fs-12 color-text-dark ms-2"> January - April</span>
          </div>
          <div class="py-1 d-flex align-content-center">
            <i class="bi bi-circle-fill fs-12 color-text-2nd-trimester"></i>
            <span class="fs-12 color-text-dark ms-2"> May - August</span>
          </div>

          <div class="py-1 d-flex align-content-center">
            <i class="bi bi-circle-fill fs-12 color-text-3rd-trimester"></i>
            <span class="fs-12 color-text-dark ms-2">
              September - December</span
            >
          </div> -->
        </div>
        <div class="align-right">
          <canvas id="foot-traffic-chart" style="height: 260px; width: 260px"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { ContentLoader } from "vue-content-loader";

export default {
  props: { customerInflux: Object, customerInfluxComparison: String, loading: Boolean },

  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = props.loading && !props.customerInflux?.data;
    return { loadSkeleton };
  },

  methods: {
    indexOfBiggestInflux() {
      let arr = this.customerInflux?.data;
      let max = arr[0];
      let maxIndex = 0;

      for (let i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
          maxIndex = i;
          max = arr[i];
        }
      }
      return maxIndex;
    },

    getDay(index) {
      let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      return days[index];
    },

    getBiggestInflux() {
      let arr = this.customerInflux.data;
      let max = arr[0];

      for (let i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
          max = arr[i];
        }
      }
      return max;
    },
  },

  mounted() {
    if (!this.customerInflux) return;
    const ctx = document.getElementById("foot-traffic-chart");

    const datasets = [
      {
        label: "Current %",
        data: this.customerInflux?.data,
        backgroundColor: ["rgba(247, 101, 163, 0.2)"],
        borderColor: ["rgba(247, 101, 163, 1)"],
        borderWidth: 1,
      },
    ];

    let equal = true;

    if (this.customerInfluxComparison) {
      if (
        this.customerInfluxComparison.value?.data?.length === 7 &&
        this.customerInfluxComparison.previousValue?.data?.length === 7
      ) {
        for (let i = 0; i < 7; i++) {
          if (
            this.customerInfluxComparison.value?.data[i] !=
            this.customerInfluxComparison.previousValue?.data[i]
          ) {
            equal = false;
            break;
          }
        }
      }

      if (!equal) {
        datasets.push({
          label: "Previous %",
          data: this.customerInfluxComparison.previousValue?.data,
          backgroundColor: ["rgba(22, 191, 214, 0.2)"],
          borderColor: ["rgba(22, 191, 214, 1)"],
          borderWidth: 1,
        });
      }
    }

    const data = {
      labels: this.customerInflux?.labels,
      datasets: datasets,
    };

    const options = {
      scales: {
        r: {
          suggestedMin: 0,
          suggestedMax: 100,

          angleLines: {
            color: "rgba(215, 215, 215, 1)",
          },
          grid: {
            color: "rgba(215, 215, 215, 1)",
          },
          pointLabels: {
            color: "rgba(155, 155, 155, 1)",
          },
          ticks: {
            color: "rgba(215, 215, 215, 1)",
          },
        },
      },
      plugins: {
        legend: {
          display: !equal,
          position: "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            boxHeight: 6,
            maxWidth: 300,
          },
        },
      },
      maintainAspectRatio: true,
      aspectRatio: 1,
      scale: {
        ticks: {
          beginAtZero: true,
          max: 5,
        },
      },
    };

    new Chart(ctx, {
      type: "radar",
      data: data,
      options: options,
    });
  },
};
</script>

<style>
.color-text-1st-trimester {
  color: #16bfd6;
  font-size: 15px;
}

.color-text-2nd-trimester {
  color: #f765a3;
  font-size: 15px;
}

.color-text-3rd-trimester {
  color: #a155b9;
  font-size: 15px;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-15px {
  font-size: 13px;
}
</style>
