const URLS = {
  api: {
    dev: "http://localhost:8080",
    nightly: "https://nightly.shubox.ai/api",
    sandbox: "https://demo-api.shubox.ai",
    prod: "https://api.shubox.ai",
  },
  auth: {
    dev: "http://localhost:8543/",
    nightly: "https://nightly-auth.shubox.ai/",
    sandbox: "https://login.shubox.ai/",
    prod: "https://auth.shubox.ai/",
  },
};

const getEnvironment = () => {
  if (process.env.NODE_ENV !== "production") {
    return "dev";
  }

  if (window.location.href?.includes("dashboard.shubox.ai")) {
    return "prod";
  }

  if (window.location.href?.includes("demo.shubox.ai")) {
    return "sandbox";
  }

  if (window.location.href?.includes("nightly.shubox.ai")) {
    return "nightly";
  }

  return "dev"; //should not happen
};

const enableEnigmaData = true;
const areGrantsEnabled = false;
const enablePlaidAssets = false;

export default {
  getEnvironment,

  getApiUrl() {
    const environment = getEnvironment();
    return URLS.api[environment];
  },

  getAuthUrl() {
    const environment = getEnvironment();
    return URLS.auth[environment];
  },

  isEnigmaDataEnabled() {
    return enableEnigmaData;
  },
  areGrantsEnabled() {
    return areGrantsEnabled;
  },

  isPlaidAssetsEnabled() {
    return enablePlaidAssets || getEnvironment() !== "prod";
  },
};
