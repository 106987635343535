<template>
  <nav class="navbar navbar-expand-lg bg-white border-bottom sticky-top shadow-sm">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'OpenSearch' }">
        <img style="vertical-align: sub; height: 24px" src="../assets/shubox_purple_48.png" />
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarShubox"
        aria-controls="navbarShubox"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarShubox">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <div class="d-flex" style="align-items: center">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link
                class="nav-link color-text-dark fs-14 hover-2"
                :to="{ name: 'ClaimBusiness' }">
                Claim Business
              </router-link>
            </li>
            <li class="nav-item ms-lg-3">
              <router-link
                class="nav-link color-text-dark fs-14 hover-2"
                :to="{ name: 'LoanApplicationBeforeYouStart' }">
                Apply for a loan
              </router-link>
            </li>
          </ul>

          <div class="pe-2 ms-5 align-middle">
            <router-link
              :to="{ name: 'Home' }"
              class="align-items-center text-decoration-none color-text-dark badge rounded-pill fw-semi-bold color-background hover-2 px-3 py-2">
              <span class="fs-14 lh-sm" style="text-align: left"> Login </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
