<template>
  <div class="card border color-border h-100 big-card-mh px-2">
    <div class="card-body pb-0">
      <div v-if="loadSkeleton">
        <div class="align-left">
          <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">History</h4>
        </div>
        <div class="d-none d-sm-block">
          <content-loader
            class="my-3"
            viewBox="0 0 500 250"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="500" height="250" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none">
          <content-loader
            class="my-3"
            viewBox="0 0 320 230"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="320" height="230" />
          </content-loader>
        </div>
      </div>
      <div v-if="!loadSkeleton" class="container-fluid d-flex justify-content-between p-0">
        <div class="align-left">
          <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">History</h4>
        </div>
        <div class="align-right d-none d-sm-block">
          <div class="dropdown">
            <p
              class="btn dropdown-toggle mb-0 pb-0 text-muted fs-12"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Last {{ getCurrentCut() }} scores
              <i class="bi bi-chevron-down text-muted icon-bold-2 fs-10 ps-2"></i>
            </p>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button class="dropdown-item fs-10" @click="setCutTo3()">Last 3 scores</button>
              </li>
              <li>
                <button class="dropdown-item fs-10" @click="setCutTo5()">Last 5 scores</button>
              </li>
              <li>
                <button class="dropdown-item fs-10" @click="setCutTo10()">Last 10 scores</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p v-if="!loadSkeleton" class="text-muted mt-0 mb-2 fs-14">Shubox score over time</p>
      <p v-if="!loadSkeleton" class="mb-1 color-text-dark fs-12">Score</p>
      <canvas
        v-if="!loadSkeleton"
        class="mx-1 px-1"
        id="myChart2"
        style="height: 190px; min-height: 190px; max-height: 190px"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { ContentLoader } from "vue-content-loader";

export default {
  props: ["scoreData", "labels", "loading", "selectedCut"],
  components: {
    ContentLoader,
  },

  setup(props) {
    const loadSkeleton = props.loading && !props.scoreData?.length;
    return {
      loadSkeleton,
    };
  },

  data() {
    return {
      cut: this.selectedCut ?? 3,
    };
  },

  methods: {
    getCurrentCut() {
      return this.cut;
    },

    setCutTo3() {
      if (this.cut != 3) {
        const chart = Chart.getChart("myChart2");
        chart.destroy();
        this.cut = 3;
      }
    },

    setCutTo5() {
      if (this.cut != 5) {
        const chart = Chart.getChart("myChart2");
        chart.destroy();
        this.cut = 5;
      }
    },

    setCutTo10() {
      if (this.cut != 10) {
        const chart = Chart.getChart("myChart2");
        chart.destroy();
        this.cut = 10;
      }
    },

    getScores() {
      return this.scoreData?.slice(-this.cut);
    },

    getDates() {
      return this.labels?.slice(-this.cut);
    },

    applyFilterScores(array) {
      return array.slice(-3);
    },

    drawGraph() {
      const ctx = document.getElementById("myChart2");

      var data = {
        labels: this.getDates(),
        datasets: [
          {
            label: "Shubox Score",
            data: this.getScores(),
            fill: false,
            cubicInterpolationMode: "monotone",
            pointBackgroundColor: "#fff",
            pointBorderColor: "rgba(240, 155, 27, 1)",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#0f2744",
            tension: 0.4,
            borderColor: ["rgba(240, 155, 27, 1)"],
            colors: ["rgba(240, 155, 27, 1)"],
            borderWidth: 3,
          },
        ],
      };

      var options = {
        maintainAspectRatio: false,
        responsive: true,

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 100,
            grid: {
              color: "#E6EAEE",
              borderDash: [5, 5],
              drawBorder: false,
              drawTicks: {
                display: false,
              },
            },
            pointLabels: {
              color: "#728095",
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },

  beforeUpdate() {
    if (this.loadSkeleton) return;
    this.drawGraph();
  },

  mounted() {
    if (this.loadSkeleton) return;
    this.drawGraph();
  },
};
</script>

<style></style>
