<template>
  <div class="card border color-border h-100" style="min-height: 180px">
    <div class="card-title fs-20 m-3 mb-0">Current Balance</div>
    <div class="card-body position-relative">
      <div class="d-flex align-items-center mb-1">
        <span class="fs-48 fw-semi-bold">
          {{ $n(balance, "currency", { notation: "compact" }) }}
        </span>
        <!-- <span
          class="fs-18 color-text-positive ms-3"
          :class="{
            'color-text-positive': balanceVariance > 0,
            'color-text-neutral': balanceVariance < 0,
          }">
          <i
            class="bi"
            :class="{
              'bi-arrow-up-circle': balanceVariance > 0,
              'bi-arrow-down-circle': balanceVariance < 0,
            }"></i>
          {{ $n(balanceVariance, "percent", { minimumFractionDigits: 1 }) }}
        </span> -->
      </div>
      <!-- <p class="fs-10 fw-semi-bold text-muted font-spacing">LAST 30 DAYS</p> -->
    </div>
  </div>
</template>

<script>
import ServiceAPI from "@/services/ServiceAPI.js";
import { useBusinessesStore } from "@/stores/BusinessStore";
import { watch } from "vue";

export default {
  props: ["businessId"],
  data() {
    return {
      balance: 0,
      balanceVariance: 0,
    };
  },
  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      const accountId = useBusinessesStore().currentBankAccount.id;
      let cashIn = {};
      if (!accountId || accountId === "main") {
        cashIn = (await ServiceAPI.getBusinessCashBalance(this.businessId)).data;
      } else {
        cashIn = (await ServiceAPI.getBusinessCashBalanceByAccount(this.businessId, accountId))
          .data;
      }

      this.balance = cashIn.balance;
      this.balanceVariance = cashIn.variance;
    },
  },

  created() {
    const businessStore = useBusinessesStore();

    watch(
      () => businessStore.currentBankAccount,
      () => {
        this.loadData();
      }
    );
  },
};
</script>
<style></style>
