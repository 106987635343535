import { createRouter, createWebHistory } from "vue-router";

import BusinessPage from "../views/BusinessPage.vue";
import BusinessNewPage from "../views/BusinessNewPage.vue";
import SearchPage from "../views/SearchPage.vue";
import HomePage from "../views/HomePage.vue";
import ResultsPage from "../views/ResultsPage.vue";
import FavoritesPage from "../views/FavoritesPage.vue";
import ConnectBankAccountPage from "../views/plaid/ConnectBankAccountPage.vue";
import RegisterBusinessOwner from "../views/user/RegisterBusinessOwner.vue";
import RegisterTenantUser from "../views/user/RegisterTenantUser.vue";
import MyBusinessesPage from "../views/business-owner/MyBusinessesPage.vue";
import LoanApplicationPage from "../views/loan-application/LoanApplicationPage.vue";
import LoanApplicationsPage from "@/views/loan-application/LoanApplicationsPage.vue";
import LoanApplicationSummaryPage from "@/views/loan-application/LoanApplicationSummaryPage.vue";
import LoanApplicationBeforeYouStartPage from "../views/LoanApplicationBeforeYouStartPage.vue";
import InterestFormPage from "../views/loan-application/InterestFormPage.vue";
import LoansDashboardPage from "../views/LoansDashboardPage.vue";
import keycloak from "../services/auth";
import PlaidConnectingSuccessful from "../views/plaid/PlaidConnectingSuccessful.vue";
import PageNotFound from "../views/PageNotFound.vue";

const ClaimBusinessPage = () => import("@/views/business-owner/ClaimBusinessPage.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/business/:id",
    name: "Business",
    component: BusinessPage,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/new-business/:id",
    name: "BusinessNewPage",
    component: BusinessNewPage,
    props: (route) => ({ ...route.query, ...route.params }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/connect-bank-account/:invitationCode",
    name: "ConnectBankAccount",
    props: true,
    component: ConnectBankAccountPage,
  },
  {
    path: "/self-connect-bank-account/:invitationCode",
    name: "SelfConnectBankAccountPage",
    props: (route) => ({ invitationCode: route.params.invitationCode, type: "self" }),
    component: ConnectBankAccountPage,
  },
  {
    path: "/register-business-owner/:invitationCode",
    name: "RegisterBusinessOwner",
    props: true,
    component: RegisterBusinessOwner,
  },
  {
    path: "/register-tenant-user/:invitationCode",
    name: "RegisterTenantUser",
    props: true,
    component: RegisterTenantUser,
  },
  {
    path: "/connect-bank-account-result/",
    name: "ConnectBankAccountResult",
    props: true,
    component: PlaidConnectingSuccessful,
  },
  {
    path: "/search",
    name: "Search",
    component: SearchPage,
    props: () => ({ openPage: false }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/open-search",
    name: "OpenSearch",
    component: SearchPage,
    props: () => ({ openPage: true }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/results",
    name: "Results",
    component: ResultsPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/results/:region/:name",
    name: "ResultsSearch",
    component: ResultsPage,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/results/:region",
    name: "ResultsSearch2",
    component: ResultsPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/followed",
    name: "Followed",
    component: FavoritesPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-businesses",
    name: "MyBusinesses",
    component: MyBusinessesPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/loan-application/:businessId",
    name: "LoanApplication",
    component: LoanApplicationPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/loan-applications/:loanApplicationId",
    name: "LoanApplicationSummary",
    component: LoanApplicationSummaryPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/loan-applications",
    name: "LoanApplications",
    component: LoanApplicationsPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/claim-business",
    name: "ClaimBusiness",
    component: ClaimBusinessPage,
  },

  {
    path: "/public-loan-application",
    name: "LoanApplicationNoAuth",
    component: LoanApplicationPage,
    props: true,
  },

  {
    path: "/grant-application/:tenantName/:tenantId",
    name: "GrantNoAuthWithTenantId",
    component: LoanApplicationPage,
    props: (route) => ({ ...route.query, ...route.params, grant: true }),
  },

  /* {
    path: "/public-loan-application/:tenantName/:tenantId",
    name: "LoanApplicationNoAuthWithTenantId",
    component: LoanApplicationPage,
    props: true,
  },*/

  {
    path: "/program/:programName/:programId",
    name: "LoanApplicationNoAuthWithProgram",
    component: LoanApplicationPage,
    props: true,
  },

  {
    path: "/loan-application/before-you-start",
    name: "LoanApplicationBeforeYouStart",
    component: LoanApplicationBeforeYouStartPage,
    props: true,
  },

  {
    path: "/:tenantName/interest-form/:tenantId",
    name: "LoanInterestForm",
    component: InterestFormPage,
    props: true,
  },

  {
    path: "/loans/dashboard/:tab",
    name: "LoansDashboardPage",
    component: LoansDashboardPage,
    props: true,
    alias: "/loans/dashboard",
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/oauthredirect",
    component: () => import("../views/plaid/OAuthRedirect.vue"),
    name: "oauthredirect",
  },
  {
    path: "/notFound",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound2",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = keycloak.authenticated;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.debug("Is authenticated: ", isAuthenticated);

    if (isAuthenticated) {
      next();
      return;
    }

    keycloak.login();
  } else {
    next();
  }
});

export default router;
