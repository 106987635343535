<template>
  <div class="container-fluid mb-4 p-0">
    <div class="row">
      <div class="col-lg-6 p-2">
        <HistoryCardSimple
          :scoreData="getScoreDataArray()"
          selectedCut="5"
          :labels="getScoreDataLabels()"></HistoryCardSimple>
      </div>
      <div class="col-lg-6 p-2">
        <ReviewsComparisonCard :businessId="business.businessId"></ReviewsComparisonCard>
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <ReviewsOvertimeStarsCard :businessId="business.businessId"></ReviewsOvertimeStarsCard>
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <SocialMediaComparisonCard
          :businessId="business.businessId"
          title="Website visitors"
          id="social1"
          cardType="website"
          color="#FFD966"></SocialMediaComparisonCard>
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <SocialMediaComparisonCard
          :businessId="business.businessId"
          title="Instagram Followers"
          id="social2"
          cardType="instagram"
          color="#B771FC"></SocialMediaComparisonCard>
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <SocialMediaComparisonCard
          :businessId="business.businessId"
          title="Facebook Likes"
          id="social3"
          cardType="facebook"
          color="#1F8AD8"></SocialMediaComparisonCard>
      </div>
    </div>
  </div>
  <InsightsEstimatedCardSection
    v-if="externalTransactionalInfo && Object.keys(externalTransactionalInfo).length > 0"
    :externalTransactionalInfo="externalTransactionalInfo"></InsightsEstimatedCardSection>
</template>

<script>
import HistoryCardSimple from "@/components/profile_cards/HistoryCardSimple.vue";
import SocialMediaComparisonCard from "./SocialMediaComparisonCard.vue";
import ReviewsComparisonCard from "./ReviewsComparisonCard.vue";
import ReviewsOvertimeStarsCard from "./ReviewsOvertimeStarsCard.vue";
import InsightsEstimatedCardSection from "./InsightsEstimatedCardSection.vue";

export default {
  props: {
    business: {
      type: Object,
    },
    google: {
      type: Object,
    },
    yelp: {
      type: Object,
    },
    externalTransactionalInfo: {
      type: Object,
    },
  },

  components: {
    ReviewsComparisonCard,
    ReviewsOvertimeStarsCard,
    SocialMediaComparisonCard,
    HistoryCardSimple,
    InsightsEstimatedCardSection,
  },
  methods: {
    getScoreDataArray() {
      return this.business?.lastScores?.map((score) => score.score);
    },
    getScoreDataLabels() {
      return this.business?.lastScores?.map((score) => this.parseTimestamp(score.timestamp));
    },
    parseTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString("en-US", {
        day: "numeric",
        month: "short",
      });
    },
  },
};
</script>

<style></style>
