<template>
  <div class="row pt-2">
    <div class="col-lg-4 p-2">
      <GenericMediumCard
        :title="applicationsSubmittedData?.title"
        :titleSize="18"
        titleWeight="normal"
        :valueType="applicationsSubmittedData?.valueType"
        :varianceType="applicationsSubmittedData?.varianceType"
        :varianceCaption="applicationsSubmittedData?.varianceCaption"
        graphId="applicationsSubmittedDataChart"
        :graphColor="applicationsSubmittedData?.graphColor"
        :colorInverted="applicationsSubmittedData?.colorInverted"
        :iconStyle="applicationsSubmittedData?.iconStyle"
        :value="applicationsSubmittedData?.value"
        :caption="applicationsSubmittedData?.caption"
        :graphValues="applicationsSubmittedData?.graphValues"
        :graphLabels="applicationsSubmittedData?.graphLabels"
        :variance="applicationsSubmittedData?.variance"
        :loading="loading.simpleCardsLoading">
      </GenericMediumCard>
    </div>
    <div class="col-lg-4 p-2">
      <GenericMediumCard
        :title="loansFundedData?.title"
        :titleSize="18"
        titleWeight="normal"
        :valueType="loansFundedData?.valueType"
        :varianceType="loansFundedData?.varianceType"
        :varianceCaption="loansFundedData?.varianceCaption"
        graphId="loansFundedDataChart"
        :graphColor="loansFundedData?.graphColor"
        :iconStyle="loansFundedData?.iconStyle"
        :value="loansFundedData?.value"
        :caption="loansFundedData?.caption"
        :colorInverted="loansFundedData?.colorInverted"
        :graphValues="loansFundedData?.graphValues"
        :graphLabels="loansFundedData?.graphLabels"
        :variance="loansFundedData?.variance"
        :loading="loading.simpleCardsLoading"></GenericMediumCard>
    </div>
    <div class="col-lg-4 p-2">
      <GenericMediumCard
        :title="applicationsDeniedData?.title"
        :titleSize="18"
        titleWeight="normal"
        :valueType="applicationsDeniedData?.valueType"
        :varianceType="applicationsDeniedData?.varianceType"
        :varianceCaption="applicationsDeniedData?.varianceCaption"
        graphId="applicationsDeniedDataChart"
        :graphColor="applicationsDeniedData?.graphColor"
        :iconStyle="applicationsDeniedData?.iconStyle"
        :value="applicationsDeniedData?.value"
        :caption="applicationsDeniedData?.caption"
        :colorInverted="applicationsDeniedData?.colorInverted"
        :graphValues="applicationsDeniedData?.graphValues"
        :graphLabels="applicationsDeniedData?.graphLabels"
        :variance="applicationsDeniedData?.variance"
        :loading="loading.simpleCardsLoading"></GenericMediumCard>
    </div>
  </div>
  <div class="row p-2">
    <GenericHorizontalChartCard
      :title="applicationsInEachStageData.title"
      :loading="loading.applicationsInEachStageLoading"
      :graphDatasets="applicationsInEachStageData.graphDatasets"
      :graphLabelsNeeded="applicationsInEachStageData.graphLabelsNeeded"
      :graphHeight="graphHeight"
      :graphLabels="applicationsInEachStageData.graphLabels"
      :graphId="applicationsInEachStageData.graphId + '2'"
      :graphType="applicationsInEachStageData.graphType"></GenericHorizontalChartCard>
  </div>
</template>

<script>
import GenericMediumCard from "@/components/profile_cards/GenericMediumCard.vue";
import GenericHorizontalChartCard from "@/components/loans_dashboard/GenericHorizontalChartCard.vue";
import { computed } from "@vue/reactivity";
import { useI18n } from "vue-i18n";

export default {
  props: {
    overviewSmallCards: { type: Object, default: () => {} },

    loading: {
      type: Boolean,
    },

    portfolioPerformance: { type: Object, default: () => {} },

    applicationsInEachStage: { type: Object, default: () => {} },

    tenantStatus: { type: Array, default: () => [] },

    periodFilter: {
      type: String,
      default: "Last month",
    },

    valueTypeFilter: {
      type: String,
      default: "Total count",
    },
  },

  components: {
    GenericMediumCard,
    GenericHorizontalChartCard,
  },

  setup(props) {
    const { d } = useI18n();

    const interestFormsFilled = computed(() => {
      let result = props.overviewSmallCards.interestFormsFilled;
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const applicationsSubmitted = computed(() => {
      let result = props.overviewSmallCards.applicationsSubmitted;
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const loansFunded = computed(() => {
      let result = props.overviewSmallCards.loansFunded;
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const applicationsDenied = computed(() => {
      let result = props.overviewSmallCards.applicationsDenied;
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const genericMediumCardDataFeeder = (
      title,
      varianceCaption,
      graphId,
      graphColor,
      iconStyle,
      colorInverted,
      listOfObjects
    ) => {
      if (!listOfObjects)
        return {
          title: title,
          varianceCaption: varianceCaption,
          graphId: graphId,
          graphColor: graphColor,
        };

      const lastDateObject = listOfObjects.slice(-1)[0];
      const secondLastDateObject = listOfObjects.slice(-2)[0];

      const value =
        props.valueTypeFilter === "Total count"
          ? lastDateObject?.count
          : props.valueTypeFilter === "Total amount"
          ? lastDateObject?.amount
          : null;

      const graphValues =
        props.valueTypeFilter === "Total count"
          ? listOfObjects?.map((item) => item.count)
          : props.valueTypeFilter === "Total amount"
          ? listOfObjects?.map((item) => item.amount)
          : null;

      const variance =
        props.valueTypeFilter === "Total count"
          ? lastDateObject?.count - secondLastDateObject?.count
          : props.valueTypeFilter === "Total amount"
          ? lastDateObject?.amount - secondLastDateObject?.amount
          : null;

      const graphLabels = listOfObjects.map((item) => {
        return "Period end date: " + d(item?.endDate, "monthAndYear");
      });

      const caption = props.periodFilter;

      const valueType =
        props.valueTypeFilter === "Total count"
          ? "integer"
          : props.valueTypeFilter === "Total amount"
          ? "currencyNoCents"
          : null;

      const varianceType =
        props.valueTypeFilter === "Total count"
          ? "integer"
          : props.valueTypeFilter === "Total amount"
          ? "currencyNoCents"
          : null;

      return {
        title: title,
        valueType,
        varianceType,
        varianceCaption: varianceCaption,
        graphId: graphId,
        graphColor: graphColor,
        iconStyle: iconStyle,
        colorInverted: colorInverted,
        value,
        graphValues,
        graphLabels,
        caption,
        variance,
      };
    };

    const insterestFormData = computed(() => {
      const value = genericMediumCardDataFeeder(
        "Interest forms filled",
        "than last period",
        "interestFormsFilledGraph",
        "#6082FB",
        "plusMinus",
        false,
        interestFormsFilled.value
      );
      return value;
    });

    const applicationsSubmittedData = computed(() => {
      const value = genericMediumCardDataFeeder(
        "Applications submitted",
        "than last period",
        "applicationsSubmittedGraph",
        "#B16BF8",
        "plusMinus",
        false,
        applicationsSubmitted.value
      );
      return value;
    });

    const loansFundedData = computed(() => {
      const value = genericMediumCardDataFeeder(
        "Loans funded",
        "than last period",
        "loansFundedGraph",
        "#16C79A",
        "plusMinus",
        false,
        loansFunded.value
      );
      return value;
    });

    const applicationsDeniedData = computed(() => {
      const value = genericMediumCardDataFeeder(
        "Applications denied",
        "than last period",
        "applicationsDeniedGraph",
        "#FFB61D",
        "plusMinus",
        true,
        applicationsDenied.value
      );
      return value;
    });

    const formsInEachStageData = computed(() => {
      const title = "Interest forms";
      const graphLabelsNeeded = false;
      const graphId = "formsInEachStageGraph";
      const interestForms = props.applicationsInEachStage?.shortForms ?? [];
      const value = props.valueTypeFilter === "Total count" ? "count" : "amount";

      const graphType = "bar";
      const formSubmitted = interestForms.find((item) => item.status === "SUBMITTED")
        ? interestForms.find((item) => item.status === "SUBMITTED")[value]
        : 0;

      const formCotacted = interestForms.find((item) => item.status === "CONTACTED")
        ? interestForms.find((item) => item.status === "CONTACTED")[value]
        : 0;

      const applicationCreatedFromForm = interestForms.find(
        (item) => item.status === "LOAN_APPLICATION_CREATED"
      )
        ? interestForms.find((item) => item.status === "LOAN_APPLICATION_CREATED")[value]
        : 0;

      const lostInterest = interestForms.find((item) => item.status === "NOT_INTERESTED")
        ? interestForms.find((item) => item.status === "NOT_INTERESTED")[value]
        : 0;

      const formClosed = interestForms.find((item) => item.status === "CLOSED")
        ? interestForms.find((item) => item.status === "CLOSED")[value]
        : 0;

      const graphDatasets = [
        {
          label: props.valueTypeFilter === "Total count" ? "Count" : "Amount ($)",
          data: [
            { x: formSubmitted, y: "Submitted" },
            { x: formCotacted, y: "Contacted" },
            { x: applicationCreatedFromForm, y: "Application created" },
            { x: lostInterest, y: "Lost interest" },
            { x: formClosed, y: "Closed" },
          ],
          borderColor: ["#6082FB", "#6082FB", "#16C79A", "#FFB61D", "#FFB61D"],
          backgroundColor: ["#6082FB", "#6082FB", "#16C79A", "#FFB61D", "#FFB61D"],
        },
      ];

      return {
        title,
        graphLabelsNeeded,
        graphId,
        graphLabels: [],
        graphType,
        graphDatasets,
      };
    });

    const applicationsInEachStageData = computed(() => {
      const title = "Applications";
      const graphLabelsNeeded = false;
      const graphId = "applicationsInEachStageGraph";
      const loanApplications = props.applicationsInEachStage?.loanApplications ?? [];
      const value = props.valueTypeFilter === "Total count" ? "count" : "amount";

      const graphType = "bar";

      const data = [];

      for (const item of props.tenantStatus) {
        const graphValue = loanApplications.find((object) => object.status === item.statusCode)
          ? loanApplications.find((object) => object.status === item.statusCode)[value]
          : 0;

        data.push({ x: graphValue, y: item.status });
      }

      const graphDatasets = [
        {
          label: props.valueTypeFilter === "Total count" ? "Count" : "Amount ($)",
          data: data,
          borderColor: ["#16C79A"],
          backgroundColor: ["#16C79A"],
        },
      ];

      return {
        title,
        graphLabelsNeeded,
        graphId,
        graphLabels: [],
        graphType,
        graphDatasets,
      };
    });

    const graphHeight = computed(() => {
      return 30 * props.tenantStatus.length + "px";
    });

    return {
      interestFormsFilled,
      applicationsSubmitted,
      loansFunded,
      applicationsDenied,
      genericMediumCardDataFeeder,
      insterestFormData,
      applicationsSubmittedData,
      loansFundedData,
      applicationsDeniedData,
      applicationsInEachStageData,
      formsInEachStageData,
      graphHeight,
    };
  },
};
</script>

<style></style>
