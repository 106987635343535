<template>
  <div v-if="isAuthenticated && enableEnigmaData" class="container-fuild">
    <div class="wrapper">
      <div class="fw-semi-bold fs-14 me-2 color-text-dark">Estimated Card Transaction Data</div>
      <hr class="divider" />
      <div class="dropdown">
        <p
          class="btn dropdown-toggle mb-0 py-0 text-muted fs-12 color-text-dark"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {{ currentSelection }}
          <i class="bi bi-chevron-down text-muted icon-bold-2 fs-10 ps-2"></i>
        </p>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button class="dropdown-item fs-10" @click="changeCurrentSelection('Last month')">
              Last month
            </button>
          </li>
          <li>
            <button class="dropdown-item fs-10" @click="changeCurrentSelection('Last 3 months')">
              Last 3 months
            </button>
          </li>
          <li>
            <button class="dropdown-item fs-10" @click="changeCurrentSelection('Last 12 months')">
              Last 12 months
            </button>
          </li>
        </ul>
      </div>
    </div>
    <p v-if="lastDate" class="fs-14 m-0" style="color: rgba(0, 0, 0, 0.6)">
      Last updated at {{ lastDate }}
    </p>
    <div class="col mt-3">
      <div class="row">
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="avgMonthlyRevenue.title"
            :valueType="avgMonthlyRevenue.valueType"
            :varianceType="avgMonthlyRevenue.varianceType"
            :varianceCaption="avgMonthlyRevenue.varianceCaption"
            :graphId="avgMonthlyRevenue.graphId"
            :graphColor="avgMonthlyRevenue.graphColor"
            :iconStyle="avgMonthlyRevenue.iconStyle"
            :value="avgMonthlyRevenue.value"
            :caption="avgMonthlyRevenue.caption"
            :graphValues="avgMonthlyRevenue.graphValues"
            :graphLabels="avgMonthlyRevenue.graphLabels"
            :variance="avgMonthlyRevenue.variance" />
        </div>
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="avgMonthlyTransactions.title"
            :valueType="avgMonthlyTransactions.valueType"
            :varianceType="avgMonthlyTransactions.varianceType"
            :varianceCaption="avgMonthlyTransactions.varianceCaption"
            :graphId="avgMonthlyTransactions.graphId"
            :graphColor="avgMonthlyTransactions.graphColor"
            :iconStyle="avgMonthlyTransactions.iconStyle"
            :value="avgMonthlyTransactions.value"
            :caption="avgMonthlyTransactions.caption"
            :graphValues="avgMonthlyTransactions.graphValues"
            :graphLabels="avgMonthlyTransactions.graphLabels"
            :variance="avgMonthlyTransactions.variance" />
        </div>
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="avgMonthlyBasketSize.title"
            :valueType="avgMonthlyBasketSize.valueType"
            :varianceType="avgMonthlyBasketSize.varianceType"
            :varianceCaption="avgMonthlyBasketSize.varianceCaption"
            :graphId="avgMonthlyBasketSize.graphId"
            :graphColor="avgMonthlyBasketSize.graphColor"
            :iconStyle="avgMonthlyBasketSize.iconStyle"
            :value="avgMonthlyBasketSize.value"
            :caption="avgMonthlyBasketSize.caption"
            :graphValues="avgMonthlyBasketSize.graphValues"
            :graphLabels="avgMonthlyBasketSize.graphLabels"
            :variance="avgMonthlyBasketSize.variance" />
        </div>
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="avgDailyCostumers.title"
            :valueType="avgDailyCostumers.valueType"
            :varianceType="avgDailyCostumers.varianceType"
            :varianceCaption="avgDailyCostumers.varianceCaption"
            :graphId="avgDailyCostumers.graphId"
            :graphColor="avgDailyCostumers.graphColor"
            :iconStyle="avgDailyCostumers.iconStyle"
            :value="avgDailyCostumers.value"
            :caption="avgDailyCostumers.caption"
            :graphValues="avgDailyCostumers.graphValues"
            :graphLabels="avgDailyCostumers.graphLabels"
            :variance="avgDailyCostumers.variance" />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="avgMonthlyRevenueGrowth.title"
            :valueType="avgMonthlyRevenueGrowth.valueType"
            :varianceType="avgMonthlyRevenueGrowth.varianceType"
            :varianceCaption="avgMonthlyRevenueGrowth.varianceCaption"
            :graphId="avgMonthlyRevenueGrowth.graphId"
            :graphColor="avgMonthlyRevenueGrowth.graphColor"
            :iconStyle="avgMonthlyRevenueGrowth.iconStyle"
            :value="avgMonthlyRevenueGrowth.value"
            :caption="avgMonthlyRevenueGrowth.caption"
            :variance="avgMonthlyRevenueGrowth.variance" />
        </div>
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="avgMonthlyRevenueGrowthSa.title"
            :valueType="avgMonthlyRevenueGrowthSa.valueType"
            :varianceType="avgMonthlyRevenueGrowthSa.varianceType"
            :varianceCaption="avgMonthlyRevenueGrowthSa.varianceCaption"
            :graphId="avgMonthlyRevenueGrowthSa.graphId"
            :graphColor="avgMonthlyRevenueGrowthSa.graphColor"
            :iconStyle="avgMonthlyRevenueGrowthSa.iconStyle"
            :value="avgMonthlyRevenueGrowthSa.value"
            :caption="avgMonthlyRevenueGrowthSa.caption"
            :variance="avgMonthlyRevenueGrowthSa.variance" />
        </div>
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="monthlyRefundsAmount.title"
            :valueType="monthlyRefundsAmount.valueType"
            :varianceType="monthlyRefundsAmount.varianceType"
            :varianceCaption="monthlyRefundsAmount.varianceCaption"
            :graphId="monthlyRefundsAmount.graphId"
            :graphColor="monthlyRefundsAmount.graphColor"
            :iconStyle="monthlyRefundsAmount.iconStyle"
            :value="monthlyRefundsAmount.value"
            :caption="monthlyRefundsAmount.caption"
            :graphValues="monthlyRefundsAmount.graphValues"
            :graphLabels="monthlyRefundsAmount.graphLabels"
            :variance="monthlyRefundsAmount.variance"
            :colorInverted="monthlyRefundsAmount.colorInverted" />
        </div>
        <div class="col-xl-3 col-lg-6 p-2">
          <GenericMediumCard
            :title="monthlyRefundsAvgSize.title"
            :valueType="monthlyRefundsAvgSize.valueType"
            :varianceType="monthlyRefundsAvgSize.varianceType"
            :varianceCaption="monthlyRefundsAvgSize.varianceCaption"
            :graphId="monthlyRefundsAvgSize.graphId"
            :graphColor="monthlyRefundsAvgSize.graphColor"
            :iconStyle="monthlyRefundsAvgSize.iconStyle"
            :value="monthlyRefundsAvgSize.value"
            :caption="monthlyRefundsAvgSize.caption"
            :graphValues="monthlyRefundsAvgSize.graphValues"
            :graphLabels="monthlyRefundsAvgSize.graphLabels"
            :variance="monthlyRefundsAvgSize.variance"
            :colorInverted="monthlyRefundsAvgSize.colorInverted" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericMediumCard from "../profile_cards/GenericMediumCard.vue";
import { useUserStore } from "@/stores/UserStore";
import { computed } from "vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import environment from "@/services/environment";

export default {
  components: {
    GenericMediumCard,
  },

  props: {
    externalTransactionalInfo: { type: Object, default: () => {} },
  },

  setup(props) {
    const userStore = useUserStore();
    const isAuthenticated = userStore.isAuthenticated;
    const enableEnigmaData = environment.isEnigmaDataEnabled();

    const { d } = useI18n();

    const currentSelection = ref("Last month");

    const changeCurrentSelection = (selection) => {
      currentSelection.value = selection;
    };

    const externalCardRevenue = computed(() => {
      let result = props.externalTransactionalInfo?.cardRevenueBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardRevenueGrowth = computed(() => {
      let result = props.externalTransactionalInfo?.cardRevenueGrowthBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardTransactions = computed(() => {
      let result = props.externalTransactionalInfo?.cardTransactionsBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardRefunds = computed(() => {
      let result = props.externalTransactionalInfo?.refundsBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const externalCardCostumers = computed(() => {
      let result = props.externalTransactionalInfo?.cardCostumersBean;
      // sort by endDate ascending
      result = result?.sort((a, b) => {
        return new Date(a.endDate) - new Date(b.endDate);
      });
      return result;
    });

    const lastCardRevenueObject = computed(() => {
      return externalCardRevenue.value?.slice(-1)[0];
    });

    const secondLastCardRevenueObject = computed(() => {
      return externalCardRevenue.value?.slice(-2)[0];
    });

    const lastCardRevenueGrowthObject = computed(() => {
      return externalCardRevenueGrowth.value?.slice(-1)[0];
    });

    const lastCardTransactionsObject = computed(() => {
      return externalCardTransactions.value?.slice(-1)[0];
    });

    const secondLastCardTransactionsObject = computed(() => {
      return externalCardTransactions.value?.slice(-2)[0];
    });

    const lastCardRefundsObject = computed(() => {
      return externalCardRefunds.value?.slice(-1)[0];
    });

    const secondLastCardRefundsObject = computed(() => {
      return externalCardRefunds.value?.slice(-2)[0];
    });

    const lastCardCostumersObject = computed(() => {
      return externalCardCostumers.value?.slice(-1)[0];
    });

    const secondLastCardCostumersObject = computed(() => {
      return externalCardCostumers.value?.slice(-2)[0];
    });

    const lastDate = computed(() => {
      return lastCardCostumersObject?.value
        ? d(lastCardRevenueObject?.value?.endDate, "fullMonthAndYear")
        : null;
    });

    const avgMonthlyRevenue = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? lastCardRevenueObject.value?.monthlyCardRevenueLastMonth
          : currentSelection.value === "Last 3 months"
          ? lastCardRevenueObject.value?.monthlyCardRevenueLastThreeMonths
          : currentSelection.value === "Last 12 months"
          ? lastCardRevenueObject.value?.monthlyCardRevenueLastYear
          : null;

      const graphValues =
        currentSelection.value === "Last month"
          ? externalCardRevenue.value?.map((item) => item.monthlyCardRevenueLastMonth)
          : currentSelection.value === "Last 3 months"
          ? externalCardRevenue.value?.map((item) => item.monthlyCardRevenueLastThreeMonths)
          : currentSelection.value === "Last 12 months"
          ? externalCardRevenue.value?.map((item) => item.monthlyCardRevenueLastYear)
          : null;

      const graphLabels = externalCardRevenue.value?.map((cardRevenueObject) => {
        return "Period End Date: " + d(cardRevenueObject?.endDate, "monthAndYear");
      });

      const caption = lastCardRevenueObject.value
        ? d(lastCardRevenueObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const variance =
        lastCardCostumersObject?.value && secondLastCardRevenueObject?.value
          ? currentSelection.value === "Last month"
            ? lastCardRevenueObject.value?.monthlyCardRevenueLastMonth -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastMonth
            : currentSelection.value === "Last 3 months"
            ? lastCardRevenueObject.value?.monthlyCardRevenueLastThreeMonths -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastThreeMonths
            : currentSelection.value === "Last 12 months"
            ? lastCardRevenueObject.value?.monthlyCardRevenueLastYear -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastYear
            : null
          : null;

      return {
        title: "Avg Monthly Revenue",
        valueType: "currencyNoCents",
        varianceType: "currencyNoCents",
        varianceCaption: "than last month",
        graphId: "avgMonthlyRevenue",
        graphColor: "#85E0A3",
        value,
        graphValues,
        graphLabels,
        caption,
        variance,
      };
    });

    const avgMonthlyTransactions = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? lastCardTransactionsObject.value?.monthlyCardTransactionsLastMonth
          : currentSelection.value === "Last 3 months"
          ? lastCardTransactionsObject.value?.monthlyCardTransactionsLastThreeMonths
          : currentSelection.value === "Last 12 months"
          ? lastCardTransactionsObject.value?.monthlyCardTransactionsLastYear
          : null;

      const graphValues =
        currentSelection.value === "Last month"
          ? externalCardTransactions.value?.map((item) => item.monthlyCardTransactionsLastMonth)
          : currentSelection.value === "Last 3 months"
          ? externalCardTransactions.value?.map(
              (item) => item.monthlyCardTransactionsLastThreeMonths
            )
          : currentSelection.value === "Last 12 months"
          ? externalCardTransactions.value?.map((item) => item.monthlyCardTransactionsLastYear)
          : null;

      const graphLabels = externalCardTransactions.value?.map((cardTransactionsObject) => {
        return "Period End Date: " + d(cardTransactionsObject?.endDate, "monthAndYear");
      });

      const caption = lastCardTransactionsObject.value
        ? d(lastCardTransactionsObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const variance =
        lastCardTransactionsObject?.value && secondLastCardTransactionsObject?.value
          ? currentSelection.value === "Last month"
            ? lastCardTransactionsObject.value?.monthlyCardTransactionsLastMonth -
              secondLastCardTransactionsObject?.value?.monthlyCardTransactionsLastMonth
            : currentSelection.value === "Last 3 months"
            ? lastCardTransactionsObject.value?.monthlyCardTransactionsLastThreeMonths -
              secondLastCardTransactionsObject?.value?.monthlyCardTransactionsLastThreeMonths
            : currentSelection.value === "Last 12 months"
            ? lastCardTransactionsObject.value?.monthlyCardTransactionsLastYear -
              secondLastCardTransactionsObject?.value?.monthlyCardTransactionsLastYear
            : null
          : null;

      return {
        title: "Avg Monthly Transactions",
        valueType: "integer",
        varianceType: "integer",
        varianceCaption: "than last month",
        graphId: "avgMonthlyTransactions",
        graphColor: "#FF98F5",
        value,
        graphValues,
        graphLabels,
        caption,
        variance,
      };
    });

    const avgMonthlyBasketSize = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? lastCardRevenueObject.value?.monthlyCardRevenueLastMonth /
            lastCardTransactionsObject.value?.monthlyCardTransactionsLastMonth
          : currentSelection.value === "Last 3 months"
          ? lastCardRevenueObject.value?.monthlyCardRevenueLastThreeMonths /
            lastCardTransactionsObject.value?.monthlyCardTransactionsLastThreeMonths
          : currentSelection.value === "Last 12 months"
          ? lastCardRevenueObject.value?.monthlyCardRevenueLastYear /
            lastCardTransactionsObject.value?.monthlyCardTransactionsLastYear
          : null;

      const graphValues = [];
      let i = 0;
      while (i < externalCardRevenue.value?.length) {
        graphValues.push(
          externalCardRevenue.value[i]?.monthlyCardRevenueLastMonth /
            externalCardTransactions.value[i]?.monthlyCardTransactionsLastMonth
        );
        i++;
      }

      const graphLabels = externalCardRevenue.value?.map((cardRevenueObject) => {
        return "Period End Date: " + d(cardRevenueObject?.endDate, "monthAndYear");
      });

      const caption = lastCardTransactionsObject.value
        ? d(lastCardTransactionsObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const variance =
        lastCardTransactionsObject?.value &&
        secondLastCardTransactionsObject?.value &&
        lastCardRevenueObject?.value &&
        secondLastCardRevenueObject?.value
          ? currentSelection.value === "Last month"
            ? lastCardRevenueObject.value?.monthlyCardRevenueLastMonth /
                lastCardTransactionsObject.value?.monthlyCardTransactionsLastMonth -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastMonth /
                secondLastCardTransactionsObject?.value?.monthlyCardTransactionsLastMonth
            : currentSelection.value === "Last 3 months"
            ? lastCardRevenueObject.value?.monthlyCardRevenueLastThreeMonths /
                lastCardTransactionsObject.value?.monthlyCardTransactionsLastThreeMonths -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastThreeMonths /
                secondLastCardTransactionsObject?.value?.monthlyCardTransactionsLastThreeMonths
            : currentSelection.value === "Last 12 months"
            ? lastCardRevenueObject.value?.monthlyCardRevenueLastYear /
                lastCardTransactionsObject.value?.monthlyCardTransactionsLastYear -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastYear /
                secondLastCardTransactionsObject?.value?.monthlyCardTransactionsLastYear
            : null
          : null;

      return {
        title: "Avg Monthly Basket Size",
        valueType: "currency",
        varianceType: "currency",
        varianceCaption: "than last month",
        graphId: "avgMonthlyBasketSize",
        graphColor: "#1F8AD8",
        value,
        graphValues,
        graphLabels,
        caption,
        variance,
      };
    });

    const avgDailyCostumers = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? lastCardCostumersObject.value?.averageDailyCardCostumersLastMonth
          : currentSelection.value === "Last 3 months"
          ? lastCardCostumersObject.value?.averageDailyCardCostumersLastThreeMonths
          : currentSelection.value === "Last 12 months"
          ? lastCardCostumersObject.value?.averageDailyCardCostumersLastYear
          : null;

      const graphValues =
        currentSelection.value === "Last month"
          ? externalCardCostumers.value?.map((item) => item.averageDailyCardCostumersLastMonth)
          : currentSelection.value === "Last 3 months"
          ? externalCardCostumers.value?.map(
              (item) => item.averageDailyCardCostumersLastThreeMonths
            )
          : currentSelection.value === "Last 12 months"
          ? externalCardCostumers.value?.map((item) => item.averageDailyCardCostumersLastYear)
          : null;

      const graphLabels = externalCardCostumers.value?.map((cardCostumersObject) => {
        return "Period End Date: " + d(cardCostumersObject?.endDate, "monthAndYear");
      });

      const caption = lastCardCostumersObject.value
        ? d(lastCardCostumersObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const variance =
        lastCardCostumersObject?.value && secondLastCardCostumersObject?.value
          ? currentSelection.value === "Last month"
            ? lastCardCostumersObject.value?.averageDailyCardCostumersLastMonth -
              secondLastCardCostumersObject?.value?.averageDailyCardCostumersLastMonth
            : currentSelection.value === "Last 3 months"
            ? lastCardCostumersObject.value?.averageDailyCardCostumersLastThreeMonths -
              secondLastCardCostumersObject?.value?.averageDailyCardCostumersLastThreeMonths
            : currentSelection.value === "Last 12 months"
            ? lastCardCostumersObject.value?.averageDailyCardCostumersLastYear -
              secondLastCardCostumersObject?.value?.averageDailyCardCostumersLastYear
            : null
          : null;

      return {
        title: "Avg Daily Customers",
        valueType: "integer",
        varianceType: "integer",
        varianceCaption: "than last month",
        graphId: "avgDailyCostumers",
        graphColor: "#B771FC",
        value,
        caption,
        graphValues,
        graphLabels,
        variance,
      };
    });

    const avgMonthlyRevenueGrowth = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? (lastCardRevenueObject.value?.monthlyCardRevenueLastMonth -
              secondLastCardRevenueObject?.value?.monthlyCardRevenueLastMonth) /
            secondLastCardRevenueObject?.value?.monthlyCardRevenueLastMonth
          : currentSelection.value === "Last 3 months"
          ? lastCardRevenueGrowthObject.value?.cardRevenueGrowthLastThreeMonths
          : currentSelection.value === "Last 12 months"
          ? lastCardRevenueGrowthObject.value?.cardRevenueGrowthLastYear
          : null;

      const caption = lastCardRevenueGrowthObject.value
        ? d(lastCardRevenueGrowthObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      return {
        title: "Avg Revenue Growth",
        valueType: "percentWithOneFractionDigit",
        value,
        caption,
      };
    });

    const avgMonthlyRevenueGrowthSa = computed(() => {
      const value =
        currentSelection.value === "Last 3 months"
          ? lastCardRevenueGrowthObject.value?.cardRevenueGrowthLastThreeMonthsSeasonallyAdjusted
          : currentSelection.value === "Last 12 months"
          ? lastCardRevenueGrowthObject.value?.cardRevenueGrowthLastYear
          : null;

      const caption = lastCardRevenueGrowthObject.value
        ? d(lastCardRevenueGrowthObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      return {
        title: "Avg Revenue Growth SA",
        valueType: "percentWithOneFractionDigit",
        value,
        caption,
      };
    });

    const monthlyRefundsAmount = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? Math.abs(lastCardRefundsObject.value?.lastMonthRefundsAmount)
          : currentSelection.value === "Last 3 months"
          ? Math.abs(lastCardRefundsObject.value?.lastThreeMonthsRefundsAmount)
          : currentSelection.value === "Last 12 months"
          ? Math.abs(lastCardRefundsObject.value?.lastYearRefundsAmount)
          : null;

      const graphValues =
        currentSelection.value === "Last month"
          ? externalCardRefunds.value?.map((item) => Math.abs(item.lastMonthRefundsAmount))
          : currentSelection.value === "Last 3 months"
          ? externalCardRefunds.value?.map((item) => Math.abs(item.lastThreeMonthsRefundsAmount))
          : currentSelection.value === "Last 12 months"
          ? externalCardRefunds.value?.map((item) => Math.abs(item.lastYearRefundsAmount))
          : null;

      if (graphValues) {
        graphValues.forEach((item, index) => {
          if (item === null) {
            graphValues[index] = 0;
          }
        });
      }

      const graphLabels = externalCardRefunds.value?.map((cardRefundsObject) => {
        return "Period End Date: " + d(cardRefundsObject?.endDate, "monthAndYear");
      });

      const caption = lastCardRefundsObject.value
        ? d(lastCardRefundsObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const variance =
        lastCardRefundsObject?.value && secondLastCardRefundsObject?.value
          ? currentSelection.value === "Last month"
            ? Math.abs(lastCardRefundsObject.value?.lastMonthRefundsAmount) -
              Math.abs(secondLastCardRefundsObject?.value?.lastMonthRefundsAmount)
            : currentSelection.value === "Last 3 months"
            ? Math.abs(lastCardRefundsObject.value?.lastThreeMonthsRefundsAmount) -
              Math.abs(secondLastCardRefundsObject?.value?.lastThreeMonthsRefundsAmount)
            : currentSelection.value === "Last 12 months"
            ? Math.abs(lastCardRefundsObject.value?.lastYearRefundsAmount) -
              Math.abs(secondLastCardRefundsObject?.value?.lastYearRefundsAmount)
            : null
          : null;

      return {
        title: "Refunds Amount",
        valueType: "currency",
        varianceType: "currency",
        varianceCaption: "than last month",
        graphId: "monthlyRefundsAmount",
        graphColor: "#E87856",
        value,
        caption,
        graphValues,
        graphLabels,
        variance,
        colorInverted: true,
      };
    });

    const monthlyRefundsAvgSize = computed(() => {
      const value =
        currentSelection.value === "Last month"
          ? Math.abs(lastCardRefundsObject.value?.lastMonthRefundAverageSize)
          : currentSelection.value === "Last 3 months"
          ? Math.abs(lastCardRefundsObject.value?.lastThreeMonthsRefundAverageSize)
          : currentSelection.value === "Last 12 months"
          ? Math.abs(lastCardRefundsObject.value?.lastYearRefundAverageSize)
          : null;

      const graphValues =
        currentSelection.value === "Last month"
          ? externalCardRefunds.value?.map((item) => Math.abs(item.lastMonthRefundAverageSize))
          : currentSelection.value === "Last 3 months"
          ? externalCardRefunds.value?.map((item) =>
              Math.abs(item.lastThreeMonthsRefundAverageSize)
            )
          : currentSelection.value === "Last 12 months"
          ? externalCardRefunds.value?.map((item) => Math.abs(item.lastYearRefundAverageSize))
          : null;

      if (graphValues) {
        graphValues.forEach((item, index) => {
          if (item === null) {
            graphValues[index] = 0;
          }
        });
      }

      const graphLabels = externalCardRefunds.value?.map((cardRefundsObject) => {
        return "Period End Date: " + d(cardRefundsObject?.endDate, "monthAndYear");
      });

      const caption = lastCardRefundsObject.value
        ? d(lastCardRefundsObject.value?.endDate, "fullMonthAndYear").toUpperCase()
        : null;

      const variance =
        lastCardRefundsObject?.value && secondLastCardRefundsObject?.value
          ? currentSelection.value === "Last month"
            ? Math.abs(lastCardRefundsObject.value?.lastMonthRefundAverageSize) -
              Math.abs(secondLastCardRefundsObject?.value?.lastMonthRefundAverageSize)
            : currentSelection.value === "Last 3 months"
            ? Math.abs(lastCardRefundsObject.value?.lastThreeMonthsRefundAverageSize) -
              Math.abs(secondLastCardRefundsObject?.value?.lastThreeMonthsRefundAverageSize)
            : currentSelection.value === "Last 12 months"
            ? Math.abs(lastCardRefundsObject.value?.lastYearRefundAverageSize) -
              Math.abs(secondLastCardRefundsObject?.value?.lastYearRefundAverageSize)
            : null
          : null;

      return {
        title: "Refunds Avg Size",
        valueType: "currency",
        varianceType: "currency",
        varianceCaption: "than last month",
        graphId: "monthlyRefundsAvgSize",
        graphColor: "#F09B1B",
        value,
        caption,
        graphValues,
        graphLabels,
        variance,
        colorInverted: true,
      };
    });

    return {
      currentSelection,
      changeCurrentSelection,
      avgMonthlyRevenue,
      avgMonthlyTransactions,
      avgMonthlyBasketSize,
      avgDailyCostumers,
      avgMonthlyRevenueGrowth,
      avgMonthlyRevenueGrowthSa,
      monthlyRefundsAmount,
      monthlyRefundsAvgSize,
      isAuthenticated,
      enableEnigmaData,
      lastDate,
      lastCardRevenueObject,
      secondLastCardRevenueObject,
    };
  },
};
</script>

<style>
.wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.divider {
  flex-grow: 1;
  border-color: #6c757d;
}
</style>
