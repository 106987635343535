import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import Popper from "vue3-popper";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { init as initAuth } from "./services/auth";
import Toast from "vue-toastification";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import VueGtag from "vue-gtag";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueGoogleMaps from "@fawmi/vue-google-maps";

import environment from "./services/environment.js";
import "./styles/style.css";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "shepherd.js/dist/css/shepherd.css";

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
      notation: "standard",
    },
    currencyNoCents: {
      style: "currency",
      currency: "USD",
      notation: "standard",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    integer: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
    percentWithOneFractionDigit: {
      style: "percent",
      useGrouping: false,
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
  },
};

const datetimeFormats = {
  en: {
    monthOnly: {
      month: "long",
    },
    monthAndYear: {
      year: "numeric",
      month: "short",
    },
    fullMonthAndYear: {
      year: "numeric",
      month: "long",
    },
    dateOnly: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    dateNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    dateAndTime: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
  },
};

const i18n = createI18n({
  legacy: false,
  fallbackLocale: "en",
  numberFormats,
  datetimeFormats,
});

const initApp = () => {
  const app = createApp(App)
    .component("Popper", Popper)
    .use(router)
    .use(createPinia())
    .use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: "places",
      },
    })
    .use(Toast, {
      position: "top-center",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 1,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: false,
      rtl: false,
      transition: "Vue-Toastification__bounce",
    })
    .use(i18n)
    .use(VueReCaptcha, {
      siteKey: "6Lcr5y4nAAAAABFf77WCflFjy1AKYQZBoYcAMRIr",
      loaderOptions: { autoHideBadge: true },
    });

  app.use(
    VueGtag,
    {
      disableScriptLoad: environment.getEnvironment() !== "prod",
      appName: "shubox",
      includes: [
        {
          id: "AW-11296379334",
        },
      ],
      config: {
        id: "G-DRRN12R9LN",
      },
    },
    router
  );

  app.mount("#app");
};

const authEnabled = true;

if (authEnabled) {
  initAuth(initApp);
} else {
  initApp();
}
