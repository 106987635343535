<template>
  <MainLayout :role="role">
    <div class="row mx-1">
      <SearchTopBar :numberOfbusinesses="this.myBusinesses.length" :title="title"></SearchTopBar>
    </div>
    <div class="row mt-sm-5 mt-2 mx-1">
      <SearchResults :businesses="myBusinesses" :page="page"></SearchResults>
    </div>
  </MainLayout>
</template>

<script>
import SearchTopBar from "@/components/SearchTopBar.vue";
import SearchResults from "@/components/SearchResults.vue";
import MainLayout from "@/components/MainLayout.vue";
import { useBusinessOwnerBusinessesStore } from "@/stores/BusinessOwnerBusinessesStore";
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import { mapActions, mapState } from "pinia";
import { useToast } from "vue-toastification";

export default {
  props: ["role"],

  components: {
    SearchTopBar,
    SearchResults,
    MainLayout,
  },

  computed: {
    ...mapState(useBusinessOwnerBusinessesStore, ["myBusinesses"]),
    ...mapState(useLoanApplicationStore, ["getWantsLoanApplication"]),
  },
  methods: {
    ...mapActions(useBusinessOwnerBusinessesStore, ["getMyBusinesses"]),
    ...mapActions(useLoanApplicationStore, ["clearWantsLoanApplication"]),
  },
  mounted() {
    this.getMyBusinesses();

    if (this.getWantsLoanApplication) {
      this.clearWantsLoanApplication();

      const that = this;
      useToast().info("Redirecting to loan application", {
        timeout: 4000,
        closeOnClick: false,
        hideProgressBar: false,
        closeButton: false,
        toastClassName: "shubox-toast",
        icon: true,
        rtl: false,
        onClose: () => {
          const businessId = that.myBusinesses[0]?.id;
          that.$router.push({ name: "LoanApplication", params: { businessId } });
        },
      });
    }
  },

  data() {
    return {
      page: "MyBusinesses",
      title: "My Businesses",
    };
  },
};
</script>

<style>
.empty {
  margin-top: 100px;
  text-align: center;
}

.empty i {
  font-size: 200px;
}
</style>
