<template>
  <MainLayout class="position-relative">
    <div v-if="business?.businessInfo" class="business">
      <BusinessNavbar
        :business="business"
        :role="userStore.role"
        :hasFinancials="hasFinancials"
        :loading="true"
        @changePage="changePage" />
      <div
        v-if="!showLoading"
        class="alert alert-light"
        role="alert"
        style="box-shadow: 0px 4px 12px 0px #1515160d">
        <span
          class="align-middle spinner-border text-warning me-3"
          style="width: 1.4rem; height: 1.4rem"
          role="status"
          aria-hidden="true"></span>
        <span class="fs-14 fw-semi-bold color-text-dark">
          The business page is loading, wait while we gather the final data points on the business.
        </span>
      </div>

      <ProfileCards
        :is="page"
        :businessId="businessId"
        :google="google"
        :yelp="yelp"
        :business="business"
        :hasFinancials="hasFinancials"
        :loading="true"
        class="tab"></ProfileCards>
    </div>
  </MainLayout>

  <LoadingPopover
    v-if="showLoading"
    :visible="showLoading"
    title="Loading Business"
    message="Please wait while we load the data.">
  </LoadingPopover>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";
import ProfileCards from "@/components/profile_cards/ProfileCards.vue";
import LoadingPopover from "@/components/LoadingPopover.vue";
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import BusinessNavbar from "@/components/BusinessNavbar.vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import { useUserStore } from "@/stores/UserStore";
import { useToast } from "vue-toastification";
import environment from "@/services/environment";
import Analytics from "@/services/analytics.js";
import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  props: {
    id: Number,
    signature: String,
    name: String,
    imageUrl: String,
    isInternalId: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { executeRecaptcha, recaptchaLoaded, instance } = useReCaptcha();
    const userStore = useUserStore();
    const router = useRouter();

    const user = ref({});
    const business = ref({
      businessInfo: {
        name: props.name,
        img: props.imageUrl,
      },
    });
    const hasFinancials = ref(false);
    const showLoading = ref(true);

    const useWebsocket = false;

    const getRecaptchaToken = async (action) => {
      if (!userStore.isAuthenticated) {
        let retry = 0;
        while (retry < 3) {
          try {
            return await executeRecaptcha(action);
          } catch (e) {
            console.error("Could not get recaptcha token", e);
            retry++;
          }
        }
      }
    };

    const loadBusiness = async (id) => {
      if (userStore.isAuthenticated) {
        const response = await ServiceAPI.getBusiness(id);
        business.value = response.data;
      } else {
        try {
          const businessDetailsToken = await getRecaptchaToken("business_details");
          const response = await ServiceAPI.getBusinessPublic(id, businessDetailsToken);
          business.value = response.data;
        } catch (error) {
          console.log(error);
        }
      }
    };

    const onloadPartialBusiness = (businessId) => {
      loadBusiness(businessId);
      Analytics.logRegisterNewBusiness(businessId, props.name);
      showLoading.value = false;
    };

    const onBusinessFinished = (businessId) => {
      router.replace({
        name: "Business",
        params: { id: businessId },
      });
    };

    if (useWebsocket) {
      const protocol = environment.getEnvironment() !== "dev" ? "wss://" : "ws://";
      const baseUrl = environment.getApiUrl().replace(/http(s)?:\/\//, protocol);

      const url = encodeURI(`${baseUrl}/business/${props.id}/${props.signature}`);
      const websocket = new WebSocket(url);

      websocket.onopen = (event) => {
        console.debug("onopen", event);
      };

      websocket.onmessage = (event) => {
        const { status, businessId } = JSON.parse(event.data);
        console.debug("registering", businessId, status);

        if (status == "DOING") {
          onloadPartialBusiness(businessId);
        }
        if (status == "DONE") {
          websocket.close();
          onBusinessFinished(businessId);
        }
      };

      websocket.onerror = (event) => {
        console.error("Error in websocket", event);
        showLoading.value = false;
        useToast().error("Something went wrong");
        router.push({ name: "Home" });
      };

      websocket.onclose = (event) => {
        console.debug("onclose", event);
      };
    }

    const registerBusiness = async () => {
      let businessId = null;
      if (userStore.isAuthenticated) {
        const response = await ServiceAPI.registerBusiness(
          props.id,
          props.signature,
          props.isInternalId
        );
        businessId = response.data;
      } else {
        const businessRegisterToken = await getRecaptchaToken("business_register");
        const response = await ServiceAPI.registerBusinessWithoutSession(
          props.id,
          props.signature,
          businessRegisterToken,
          props.isInternalId
        );
        businessId = response.data?.businessId;
      }

      Analytics.logRegisterNewBusiness(businessId, props.name);
      onloadPartialBusiness(businessId);

      let done = false;
      while (!done) {
        const response = await ServiceAPI.checkBusinessRegistration(props.signature);
        done = response.data;
        await new Promise((r) => setTimeout(r, 1_000));
      }

      onBusinessFinished(businessId);
    };

    onMounted(async () => {
      if (!userStore.isAuthenticated) {
        await recaptchaLoaded();
        instance.value.showBadge();
      }
      if (!useWebsocket) {
        registerBusiness();
      }
    });

    onUnmounted(async () => {
      if (!userStore.isAuthenticated) {
        instance.value.hideBadge();
      }
    });

    return {
      user,
      business,
      hasFinancials,
      userStore,
      showLoading,
    };
  },

  components: {
    ProfileCards,
    BusinessNavbar,
    MainLayout,
    LoadingPopover,
  },

  data() {
    return {
      google: {
        name: "Google",
        imageUrl: "https://freesvg.org/img/1534129544.png",
      },

      yelp: {
        name: "Yelp",
        imageUrl: "https://cdn-icons-png.flaticon.com/512/174/174882.png",
      },
    };
  },
};
</script>

<style></style>
