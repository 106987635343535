<template>
  <div class="card border color-border h-100 px-2">
    <div class="card-body pb-0">
      <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">{{ title }}</h4>
      <div class="table-responsive mt-3">
        <table class="table table-borderless">
          <tr v-for="row in rows" :key="row">
            <th scope="col">
              <p class="fs-12 fw-semi-bold text-dark ps-0">{{ row.label }}</p>
            </th>
            <th scope="col">
              <p
                class="p-2 px-3 badge rounded-pill text-dark fs-12"
                style="min-width: 100%"
                :class="
                  row.type === 'currency'
                    ? 'color-text-primary bg-white fw-semi-bold'
                    : 'color-background-grey fw-normal'
                "
                :style="{ 'background-color': row?.color + ' !important' }">
                {{ row.value }}
              </p>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
