<template>
  <div class="card border color-border h-100 card-mh px-2">
    <div class="card-body">
      <h4 class="card-title mb-3 color-text-dark fs-20">Customer Sentiment</h4>
      <div v-if="loadSkeleton">
        <content-loader
          viewBox="0 0 560 100"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="3" ry="3" width="40" height="15" />
          <rect x="330" y="0" rx="3" ry="3" width="40" height="15" />
          <rect x="460" y="0" rx="3" ry="3" width="40" height="15" />

          <rect x="0" y="20" rx="3" ry="3" width="300" height="20" />
          <rect x="305" y="20" rx="3" ry="3" width="90" height="20" />
          <rect x="400" y="20" rx="3" ry="3" width="160" height="20" />

          <rect x="0" y="45" rx="3" ry="3" width="60" height="15" />
          <rect x="305" y="45" rx="3" ry="3" width="57" height="16" />
          <rect x="400" y="45" rx="3" ry="3" width="56" height="19" />

          <rect x="430" y="75" rx="3" ry="3" width="140" height="19" />
        </content-loader>
      </div>
      <div v-else>
        <table class="table mb-1">
          <tbody>
            <tr>
              <td
                class="p-0 fs-14 fw-semi-bold border-end border-white border-3 color-text-dark font-spacing-sm"
                :style="{
                  width: customerSentiment.positivereviews + '%',
                }">
                {{ getPositiveReviews() }}%
              </td>
              <td
                class="p-0 fs-14 fw-semi-bold border-end border-white border-3 color-text-dark font-spacing-sm"
                :style="{
                  width: customerSentiment.neutralReviews + '%',
                }">
                {{ getNeutralReviews() }}%
              </td>
              <td
                class="p-0 border-0 fs-14 fw-semi-bold color-text-dark font-spacing-sm"
                :style="{
                  width: customerSentiment.negativeReviews + '%',
                }">
                {{ getNegativeReviews() }}%
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table mb-4">
          <tbody>
            <tr>
              <td
                class="color-bar-positive border-end border-white border-3 rounded-bottom-left-1 rounded-top-left-1 color-text-dark"
                :style="{
                  width: customerSentiment.positiveReviews + '%',
                }"></td>
              <td
                class="color-bar-neutral"
                :style="{
                  width: customerSentiment.neutralReviews + '%',
                }"></td>
              <td
                class="color-bar-negative border-start border-white border-3 rounded-bottom-right-1 rounded-top-right-1"
                :style="{
                  width: customerSentiment.negativeReviews + '%',
                }"></td>
            </tr>
          </tbody>
        </table>
        <i class="fs-12 bi bi-circle-fill color-bar-label-positive"></i>
        <span class="fs-12"> Positive</span>
        <i class="fs-12 bi bi-circle-fill color-bar-label-neutral ms-3"></i>
        <span class="fs-12"> Neutral</span>
        <i class="fs-12 bi bi-circle-fill color-bar-label-negative ms-3"></i>
        <span class="fs-12"> Negative</span>
        <div class="align-right fs-12 color-text-dark ms-2 mt-2 d-none d-sm-block">
          Total responses
        </div>
        <div class="align-right fs-12 mt-2 color-text-dark fw-semi-bold d-none d-sm-block">
          {{ customerSentiment.totalReviews }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { computed } from "vue";

export default {
  props: ["customerSentiment", "loading"],

  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = computed(
      () => props.loading && (props.customerSentiment?.totalReviews ?? 0) <= 8
    );
    return { loadSkeleton };
  },
  methods: {
    roundNumber: function (number) {
      return Math.round(number);
    },

    getPositiveReviews: function () {
      return this.roundNumber(this.customerSentiment.positiveReviews);
    },

    getNeutralReviews: function () {
      return 100 - this.getPositiveReviews() - this.getNegativeReviews();
    },

    getNegativeReviews: function () {
      return this.roundNumber(this.customerSentiment.negativeReviews);
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
