<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="financial-invitation"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog">
        <div class="modal-content color-background-modal">
          <div class="modal-header">
            <h1 class="modal-title fs-16 fw-semi-bold" id="exampleModalLabel">
              Assign Application
            </h1>
            <button
              type="button"
              class="btn-close hover fs-12"
              style="width: 20px; height: 20px"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeDialog()"></button>
          </div>
          <div v-if="loading === true" class="modal-body">
            <div class="d-flex justify-content-center align-items-center py-3">
              <span class="me-2 color-text-primary">Loading</span>
              <div
                class="spinner-border color-text-primary"
                style="width: 1.4rem; height: 1.4rem"
                role="status"></div>
            </div>
          </div>
          <div v-else class="modal-body mx-2">
            <p class="fw-medium fs-14 ms-1 mb-2">Assign application to:</p>
            <select
              required
              v-model="currentAssignee"
              class="form-select fs-12 color-background-form border-2 color-border py-2 my-2"
              aria-label="Select funds use">
              <option v-for="user in tenantList" :key="user.id" class="text-muted" :value="user.id">
                {{ user.name }}
              </option>
            </select>
            <div class="d-flex justify-content-center py-4">
              <button
                @click="closeDialog()"
                class="btn sb-btn-dark-secondary me-3 fs-14"
                style="width: 80px">
                Cancel
              </button>
              <button @click="assign()" class="btn sb-btn-dark fs-14" style="width: 80px">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    visible: Boolean,
    title: String,
    loading: Boolean,
    currentUserId: String,
    assignee: Object,
    tenantList: Array,
  },

  setup(props, { emit }) {
    const closeDialog = () => {
      emit("toggle", false);
    };

    const currentAssignee = ref(props.assignee?.id ? props.assignee.id : {});

    const assign = () => {
      emit("assign", currentAssignee.value);
    };

    return {
      closeDialog,
      currentAssignee,
      assign,
    };
  },
  data() {
    return {
      openClose: this.visible,
    };
  },

  watch: {
    visible: function (newVal) {
      this.openClose = newVal;
    },
  },
};
</script>

<style>
.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none !important;
}
</style>
