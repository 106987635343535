<template>
  <div id="shubox-score" class="card border color-border h-100 big-card-mh px-2">
    <div class="card-body pb-0">
      <div class="container-fluid d-flex justify-content-between p-0">
        <div class="container m-0 p-0 align-left d-flex justify-content-between align-items-end">
          <h4 class="card-title m-0 p-0 fs-20">
            {{ userStore.isAuthenticated ? "Shubox Score" : "Shubox Social Score" }}
          </h4>
          <div class="card-title m-0 p-0 fs-12 d-flex align-items-center">
            <span class="d-none d-sm-block">{{
              userStore.isAuthenticated ? "Your score explained" : "Score explanation"
            }}</span>
            <span class="m-0 ms-1 p-0 bg-transparent border-0">
              <Popper hover="true" :arrow="true" :content="scoreExplanation">
                <i class="bi bi-info-circle color-text-primary hover text-break fs-16"></i>
              </Popper>
            </span>
          </div>
        </div>
      </div>
      <p class="text-muted mb-2 mt-2 pb-1 fs-14 d-none d-sm-block">
        Measuring business performance
      </p>
      <div v-if="loadSkeleton">
        <div class="d-none d-sm-block mb-md-5">
          <content-loader
            viewBox="0 0 320 90"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <circle cx="80" cy="80" r="70" />
            <rect x="152" y="25" rx="3" ry="3" width="144" height="10" />
            <rect x="180" y="50" rx="3" ry="3" width="80" height="10" />
            <rect x="180" y="65" rx="3" ry="3" width="120" height="10" />
            <rect x="180" y="80" rx="3" ry="3" width="90" height="10" />
          </content-loader>
          <content-loader
            viewBox="0 0 340 15"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="50" y="5" rx="3" ry="3" width="70" height="10" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none mt-3 mb-5">
          <content-loader
            viewBox="0 0 180 90"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <circle cx="90" cy="80" r="70" />
          </content-loader>
          <content-loader
            viewBox="0 0 180 15"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="50" y="5" rx="0" ry="0" width="70" height="10" />
          </content-loader>
        </div>
      </div>
      <div v-else class="container d-flex justify-content-around row m-0">
        <div style="max-height: 168px; min-width: 274px; width: 274px" class="col-auto mb-4">
          <canvas id="shubox-score-canva" style="width: 250px"> </canvas>
          <div
            v-if="this.business.lastScores && difference !== 0"
            class="fs-18 color-text-positive d-flex justify-content-center"
            :class="
              difference > 0
                ? 'color-text-positive'
                : difference < 0
                ? 'color-text-negative'
                : 'color-text-neutral'
            ">
            <i
              class="bi me-1"
              :class="
                difference > 0
                  ? 'bi-arrow-up-circle'
                  : difference < 0
                  ? 'bi-arrow-down-circle'
                  : 'bi-dash-circle'
              "></i>
            {{ scoreProgress }}%
          </div>
          <div class="fs-11 d-flex justify-content-center mt-2">
            <p>
              {{ userStore.isAuthenticated ? "Your score is" : "Shubox score is" }}
              <span class="fw-bold">
                {{ this.business.score }} out of
                {{ hasFinancials || !userStore.isAuthenticated ? "100" : "50" }}.</span
              >
            </p>
          </div>
        </div>
        <div v-if="userStore.isAuthenticated" class="mt-5 fs-13 pt-2 col-md">
          <div>
            Your shubox score is under
            <span class="fw-bold">
              {{ this.business.score < 30 ? "30" : this.business.score < 40 ? "40" : "100" }}
            </span>
          </div>
          <div class="fw-bold fs-12 ms-md-3 mt-3">Action</div>
          <div class="fs-12 ms-md-3 mt-1">
            {{
              this.business.score < 30
                ? "Understand and improve your customer experience & pain points"
                : this.business.score < 40
                ? "Focus on increasing positive user engagement"
                : "Connect your financials to get a full picture of your business performance"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { useUserStore } from "@/stores/UserStore";
import { useToast } from "vue-toastification";
import { ContentLoader } from "vue-content-loader";
import { ref } from "vue";

export default {
  props: {
    business: {
      type: Object,
    },
    hasFinancials: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = ref(props.loading);
    const userStore = useUserStore();
    const toast = useToast();

    const scoreExplanation = userStore.isAuthenticated
      ? "Shubox score characterizes a business based on intagram followers, google rating, web traffic, yelp reviews and financials."
      : "Shubox score characterizes a business based on social presence, web traffic and more.";

    return {
      userStore,
      toast,
      scoreExplanation,
      loadSkeleton,
    };
  },

  data() {
    return {
      pointValue: 57,
      redColor: "#F58080",
      greenColor: "#90E6A8",
      yellowColor: "#F3B455",
      muteColor: "#D3D3D3",
      pointerColor: "#d0d0d0",
    };
  },

  computed: {
    scoreProgress() {
      const currentScore = this.business.score;
      const size = this.business.lastScores.length;
      const lastScore =
        this.business.lastScores[size - 2] != null
          ? this.business.lastScores[size - 2].score
          : currentScore;
      const difference = Math.abs(currentScore - lastScore);
      const result = (difference / lastScore) * 100;
      return result.toFixed(1);
    },

    difference() {
      const currentScore = this.business.score;
      const size = this.business.lastScores.length;
      const lastScore =
        this.business.lastScores[size - 2] != null
          ? this.business.lastScores[size - 2].score
          : currentScore;
      return currentScore - lastScore;
    },
  },

  methods: {
    connectFinancialData() {
      this.toast.error("This feature is not available yet!");
    },

    drawChart() {
      const ctx = document.getElementById("shubox-score-canva");

      if (!ctx) {
        return;
      }

      const gradientSegment = ctx.getContext("2d").createLinearGradient(0, 0, 214, 0);
      gradientSegment.addColorStop(0, this.redColor);
      gradientSegment.addColorStop(0.1, this.redColor);
      gradientSegment.addColorStop(0.8, this.yellowColor);
      gradientSegment.addColorStop(1, this.greenColor);

      const gradientSegment50Cap = ctx.getContext("2d").createLinearGradient(0, 0, 100, 0);
      gradientSegment50Cap.addColorStop(0, this.redColor);
      gradientSegment50Cap.addColorStop(0.1, this.redColor);
      gradientSegment50Cap.addColorStop(0.6, this.yellowColor);
      gradientSegment50Cap.addColorStop(1, this.greenColor);
      const that = this;

      var data = {
        labels: ["Bad", "Average", "Great"],
        datasets: [
          {
            label: "Shubox Score",
            data: this.hasFinancials || !this.userStore.isAuthenticated ? [100] : [50, 50],
            fill: false,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            backgroundColor:
              this.hasFinancials || !this.userStore.isAuthenticated
                ? [gradientSegment]
                : [gradientSegment50Cap, this.muteColor],
            pointValue: this.business.score,
            borderWidth: 5,
            cutout: "85%",
            circumference: 180,
            rotation: 270,
            borderRadius: 0,
          },
        ],
      };

      var options = {
        responsive: true,
        maintainAspectRatio: false,
        events: [],
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      const gaugeEnd = {
        id: "gaugeEnd",
        afterRender(chart) {
          const {
            ctx,
            config,
            data,
            chartArea: { width },
          } = chart;
          ctx.textAlign = "center";
          ctx.save();
          const originalPointValue = data.datasets[0].pointValue;
          const pointValue = Math.max(originalPointValue, 0);
          const dataTotal = config.data.datasets[0].data.reduce((a, b) => a + b, 0);
          const angle = Math.PI + (1 / dataTotal) * pointValue * Math.PI;

          const cx = width / 2;
          const cy = chart._metasets[0].data[0].y;

          ctx.beginPath();
          ctx.arc(cx + 113.1, cy - 1, 6.7, 0, 9);
          ctx.fillStyle =
            that.hasFinancials || !that.userStore.isAuthenticated
              ? that.greenColor
              : that.muteColor;
          ctx.fill();

          ctx.restore();

          ctx.beginPath();
          ctx.arc(cx + 113 * Math.cos(angle), cy + 113 * Math.sin(angle), 12, 0, 10);
          let color = that.pointerColor;
          ctx.fillStyle = color;
          ctx.fill();
          ctx.restore();

          ctx.beginPath();
          ctx.arc(cx + 113 * Math.cos(angle), cy + 113 * Math.sin(angle), 8, 0, 10);
          ctx.fillStyle = "white";
          ctx.fill();
          ctx.restore();

          ctx.font = "bold 60px Open Sans";
          if (pointValue <= 24 && !that.hasFinancials && that.userStore.isAuthenticated) {
            color = "#f36f52";
          } else if (pointValue <= 37 && !that.hasFinancials && that.userStore.isAuthenticated) {
            color = "#e8a12e";
          } else if (pointValue > 37 && !that.hasFinancials && that.userStore.isAuthenticated) {
            color = "#4dc59b";
          } else if (pointValue < 50) {
            color = "#f36f52";
          } else if (pointValue < 70) {
            color = "#e8a12e";
          } else if (pointValue >= 70) {
            color = "#4dc59b";
          } else {
            color = that.muteColor;
          }
          ctx.fillStyle = color;
          ctx.fillText(originalPointValue ? pointValue : "N/A", cx, cy);
          ctx.restore();
        },
      };

      const gaugePoint = {
        id: "gaugePoint",
        afterDraw(chart) {
          const {
            ctx,
            chartArea: { width },
          } = chart;
          ctx.textAlign = "center";
          ctx.save();

          const cx = width / 2;
          const cy = chart._metasets[0].data[0].y;

          ctx.beginPath();
          ctx.arc(cx - 113.1, cy - 1, 6.7, 0, 9);
          ctx.fillStyle = that.redColor;
          ctx.fill();
          ctx.restore();

          ctx.font = "12px Open Sans";
          ctx.fillStyle = "rgba(0, 0, 0, 0.49)";
          ctx.fillText("0", cx - 114, cy + 20);
          ctx.restore();

          ctx.font = "12px Open Sans";
          ctx.fillStyle = "rgba(0, 0, 0, 0.49)";
          ctx.fillText("100", cx + 113.4, cy + 20);
          ctx.restore();
        },
      };

      new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: options,
        plugins: [gaugePoint, gaugeEnd],
      });
    },
  },

  mounted() {
    this.drawChart();
  },
};
</script>

<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}
</style>
