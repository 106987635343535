<template>
  <div class="card border color-border h-100" style="min-height: 180px">
    <div class="card-title fs-20 m-3 mb-0">Cash {{ flowString }}</div>
    <div class="card-body position-relative">
      <div class="d-flex align-items-center mb-1">
        <span class="fs-32 fw-semi-bold">
          {{ $n(cashInTotal, "currency", { notation: "compact" }) }}
        </span>
        <span
          class="fs-18 ms-3"
          :class="{
            'color-text-positive': isPositive,
            'color-text-neutral': !isPositive,
          }"
          v-if="cashInVariance != 0.0 && isFinite(cashInVariance)">
          <i
            class="bi"
            :class="{
              'bi-arrow-up-circle': cashInVariance > 0,
              'bi-arrow-down-circle': cashInVariance < 0,
            }"></i>
          {{ $n(cashInVariance, "percent", { minimumFractionDigits: 1 }) }}
        </span>
      </div>
      <div v-if="graphId">
        <p class="fs-10 fw-semi-bold text-muted my-0 font-spacing">LAST {{ period }} DAYS</p>
        <canvas :id="graphId" style="max-height: 40px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

import { mapActions, mapState } from "pinia";
import { watch } from "vue";
import { useBusinessesStore } from "@/stores/BusinessStore";

export default {
  // flow: in, out
  props: ["flow", "businessId"],
  data() {
    return {
      cashInTotal: 0,
      cashInVariance: 0,
      theChart: null,
    };
  },
  computed: {
    ...mapState(useBusinessesStore, ["period", "currentBankAccount"]),
    isCashIn() {
      return this.flow === "in";
    },
    isPositive() {
      return (
        (this.isCashIn && this.cashInVariance > 0) || (!this.isCashIn && this.cashInVariance < 0)
      );
    },
    graphId() {
      return "financialChart" + this.flow;
    },
    flowString() {
      return this.flow.charAt(0).toUpperCase() + this.flow.slice(1);
    },
  },
  methods: {
    ...mapActions(useBusinessesStore, ["getCashIn", "getCashOut"]),
    async loadData() {
      const cash = this.isCashIn
        ? await this.getCashIn(this.businessId)
        : await this.getCashOut(this.businessId);

      this.cashInTotal = Math.abs(cash.total);
      this.cashInVariance = cash.variance;

      const labels = cash.transactions.map((x) => x.date);
      const values = cash.transactions.map((x) => Math.abs(x.value));

      const colour = this.isCashIn ? "rgba(77, 197, 155, 1)" : "rgba(232, 161, 46, 1)";

      var data = {
        labels: labels,
        datasets: [
          {
            data: values,
            fill: false,
            cubicInterpolationMode: "monotone",

            borderColor: [colour],
            borderWidth: 2,
          },
        ],
      };

      var options = {
        responsive: true,
        maintainAspectRatio: false,
        pointHitRadius: 40,

        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 100,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      if (this.theChart != null) {
        this.theChart.destroy();
      }

      const ctx = document.getElementById(this.graphId);
      this.theChart = new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },
  created() {
    const businessStore = useBusinessesStore();

    watch(
      () => businessStore.period,
      () => {
        this.loadData();
      }
    );

    watch(
      () => businessStore.currentBankAccount,
      () => {
        this.loadData();
      }
    );
  },

  mounted() {
    this.loadData();
  },
};
</script>
