<template>
  <span :class="colorClass">{{ finalScore }}</span>
</template>
<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  score: Number,
  outOf100: { type: Boolean, default: false },
  duplicate: { type: Boolean, default: false },
});

const finalScore = computed(() => (props.duplicate ? props.score * 2 : props.score));

const calculateColor = (score, outOf100) => {
  if (!outOf100) {
    if (score <= 24) {
      return "color-text-negative";
    }
    if (score <= 37) {
      return "color-text-neutral";
    }
    return "color-text-positive";
  }

  if (score < 50) {
    return "color-text-negative";
  }
  if (score < 70) {
    return "color-text-neutral";
  }

  return "color-text-positive";
};

const colorClass = calculateColor(finalScore.value, props.outOf100 || props.duplicate);
</script>
