<template>
  <div>
    <div class="fs-14 text-center m-0 mt-3">
      Need help? Don't hesitate to get in touch with us
      <span v-if="tenantPhoneNumber && tenantName != 'Greater Jamaica Development Corporation'">
        at

        <a :href="'tel:' + tenantPhoneNumber" class="hover color-text-primary text-decoration-none">
          {{ tenantPhoneNumber }}
        </a>
      </span>
      <span v-else-if="grant">
        <a
          href="https://calendly.com/jambizserv/southeast-queens-entrepreneur-grant-program"
          class="hover color-text-primary text-decoration-none"
          target="_blank">
          here
        </a>
      </span>
      <p class="mt-1">
        <span
          v-if="
            tenantEmail &&
            tenantPhoneNumber &&
            tenantName != 'Greater Jamaica Development Corporation'
          ">
          or
        </span>
        <span v-if="tenantEmail">
          via email at
          <a :href="'mailto:' + tenantEmail" class="hover color-text-primary text-decoration-none">
            {{ tenantEmail }}
          </a>
        </span>
      </p>
      <p>
        <span class="fw-semi-bold"
          >Powered by
          <a
            target="_blank"
            href="https://shubox.ai/"
            class="hover color-text-primary text-decoration-none"
            >Shubox</a
          >
        </span>
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-center mb-4 pb-2 mt-4">
    <img src="@/assets/bottom_icons_1_no_bg.png" style="max-height: 40px" />
  </div>
</template>

<script>
export default {
  props: {
    tenantPhoneNumber: {
      type: String,
    },
    tenantEmail: {
      type: String,
    },
    tenantName: {
      type: String,
    },
    tenantWebsite: {
      type: String,
    },
    grant: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
