<template>
  <LoanApplicationFormContainer :tenant="tenant" :tenantId="tenantId" :grant="grant">
    <div class="row mx-sm-5 mx-2 d-flex align-items-center mt-5 mb-2">
      <div class="col-md-8">
        <div class="card-title fw-bold fs-18 mb-0">1. Get Started</div>
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button @click="resetApplication()" class="btn sb-btn-secondary fs-12 fw-semi-bold">
          Restart application
        </button>
      </div>
    </div>
    <div class="card-body mt-2 pb-0 mx-sm-5 mx-2">
      <form @submit.prevent="$emit('changeTab', 'FormTab2')">
        <div v-if="(question = getQuestionObject('newRequest'))">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Is this a new or follow up request?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            placeholder="Select option"
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.newRequest
            "
            aria-label="Select option"
            autofocus>
            <option value="" disabled selected hidden muted>Select option</option>
            <option value="new">New</option>
            <option value="followup">Follow up</option>
            <option value="na">Not sure</option>
          </select>
        </div>
        <div
          v-if="
            !(
              programDetails.name === 'Marketing Loan Program' &&
              programDetails.tenant.name === 'Greater Jamaica Development Corporation'
            )
          ">
          <div v-if="(question = getQuestionObject('whereHearAboutUs'))" class="mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              How did you hear about us?
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <select
              class="form-select fs-12 color-background-form border-2 color-border py-2"
              placeholder="Where did you hear about us?"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .whereHearAboutUs
              "
              aria-label="Where did you hear about us?">
              <option value="" disabled selected hidden muted>Where did you hear about us?</option>
              <option>Email</option>
              <option>Referral</option>
              <option value="Previous">Previous Client</option>
              <option>Website</option>
              <option>Google</option>
              <option>Webinar</option>
              <option>Event</option>
              <option>Meeting</option>
              <option v-if="grant" value="Pix11">Pix 11</option>
              <option v-if="grant" value="QueensBusinessSolutionsCenter">
                Queens Business Solutions Center
              </option>
              <option>Other</option>
            </select>
          </div>
          <div
            class="mt-4"
            v-if="
              (question = getQuestionObject('referralSource')) &&
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                .whereHearAboutUs === 'Referral'
            ">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Who referred you to us?
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .referralSource
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
              placeholder="Insert who referred you"
              aria-label="Insert who referred you" />
          </div>
        </div>
        <div v-else>
          <div v-if="(question = getQuestionObject('whereHearAboutUs'))" class="mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              How did you hear about us?
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <select
              class="form-select fs-12 color-background-form border-2 color-border py-2"
              placeholder="Where did you hear about us?"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .whereHearAboutUs
              "
              aria-label="Where did you hear about us?">
              <option value="" disabled selected hidden muted>Where did you hear about us?</option>
              <option>Referral</option>
              <option>Website search</option>
              <option>Event</option>
              <option>Previous Client</option>
              <option>Flyer</option>
              <option>Other</option>
            </select>
          </div>
          <div
            class="mt-4"
            v-if="
              (question = getQuestionObject('referralSource')) &&
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                .whereHearAboutUs === 'Referral'
            ">
            <p class="fw-bold fs-14 ms-1 mb-2">Who referred you to us?</p>
            <select
              class="form-select fs-12 color-background-form border-2 color-border py-2"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .referralSource2
              "
              required
              @change="handleReferredToUs($event.target.value)">
              <option value="Queens Business Solutions Center">
                Queens Business Solutions Center
              </option>
              <option value="Queens Chamber of Commerce">Queens Chamber of Commerce</option>
              <option value="Queens Economic Development Corporation">
                Queens Economic Development Corporation
              </option>
              <option value="Other">Other</option>
            </select>
            <div
              v-if="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .referralSource2 === 'Other'
              ">
              <p class="fw-bold fs-14 ms-1 m-2 mt-4">Please specify who</p>
              <input
                required
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                    .referralSource
                "
                class="form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                placeholder="Insert who referred you"
                aria-label="Insert who referred you" />
            </div>
          </div>
        </div>
        <div
          v-if="
            !(
              programDetails.name === 'Marketing Loan Program' &&
              programDetails.tenant.name === 'Greater Jamaica Development Corporation'
            ) && (question = getQuestionObject('loanAmount'))
          "
          class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            How much are you looking for?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="input-box">
            <span
              class="prefix fs-12"
              :class="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount !=
                  '' &&
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount !=
                  null
                  ? 'fw-bold'
                  : 'text-muted'
              "
              >USD</span
            >
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount
              "
              v-on:input="this.addCommasToAmount()"
              type="text"
              :required="question.required"
              id="amount"
              style="height: 36px"
              min="0"
              class="form-control fs-12 color-background-form border-2 color-border py-2 ps-1"
              aria-label="Amount (to the nearest dollar)" />
          </div>
        </div>
        <div
          v-if="
            programDetails.name === 'Marketing Loan Program' &&
            programDetails.tenant.name === 'Greater Jamaica Development Corporation' &&
            (question = getQuestionObject('loanAmount'))
          "
          class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Loan amount requested
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Amount (to the nearest dollar)">
            <option class="text-muted" value="2,500">$2,500</option>
            <option class="text-muted" value="5,000">$5,000</option>
            <option class="text-muted" value="7,500">$7,500</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('onlineSalesOver10k'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Have your online sales exceeded $10,000 in the past 12 months?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                .onlineSalesOver10k
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Have your online sales exceeded $10,000 in the past 12 months?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('detailedPlan'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Do you have a detailed marketing plan?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.detailedPlan
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Do you have a detailed marketing plan?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('loanReason'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            What would you use your funds for?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            placeholder="Select funds use"
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.fundsPurpose
            "
            aria-label="Select funds use">
            <option value="" disabled selected hidden muted>Select funds use</option>
            <option value="ROUTINE_EXPENSES">Routine expenses other than payroll</option>
            <option value="PAYROLL">Payroll</option>
            <option value="INVENTORY">Inventory</option>
            <option value="HIRING">Hiring employees</option>
            <option value="MARKETING">Marketing or advertising</option>
            <option value="PURCHASE_EQUIPMENT">Purchasing equipment or vehicles</option>
            <option value="NEW_SPACE_REMODELING">New space or remodeling</option>
            <option value="RAINY_DAY">Rainy day fund</option>
            <option value="UNEXPECTED_EXPENSE">Covering unexpected expenses</option>
            <option value="REFINANCING_DEBT">Refinancing debt</option>
            <option value="OTHER">Other</option>
          </select>
        </div>
        <div class="row mt-4">
          <div v-if="(question = getQuestionObject('businessLegalName'))" class="col-md-6">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Legal business name
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .businessLegalName
              "
              :required="question.required"
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert legal business name"
              aria-label="Insert business address line 1" />
          </div>
          <div v-if="(question = getQuestionObject('businessDba'))" class="col-md-6 mt-md-0 mt-3">
            <p class="fw-bold fs-14 ms-1 mb-2">Doing business as (DBA name)</p>
            <input
              required
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .businessDbaName
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert business name"
              aria-label="Insert business name" />
          </div>
          <p class="text-muted fs-11 mt-2 ms-1 me-1 mb-2">
            If your doing business as (DBA name) is the same as your legal business name, please
            enter the same name in the DBA name field.
          </p>
        </div>
        <div v-if="(question = getQuestionObject('businessAddress'))">
          <div class="mt-4">
            <div class="row">
              <div class="fw-bold fs-14 ms-1 mb-2 pe-0 col-auto">Business address line</div>
              <a
                class="hover color-text-primary fs-14 col-auto fw-semi-bold text-decoration-none"
                style="width: fit-content"
                @click="this.toggleAddressLine2">
                <p v-if="!this.addressLine2" class="mb-0">
                  <i class="bi bi-plus-circle me-1"></i>apt/suite/floor
                </p>
              </a>
            </div>
            <input
              required
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .businessAddress
              "
              :class="addressLine2 ? 'mt-0' : 'mt-2'"
              class="form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
              placeholder="Insert business address line 1"
              aria-label="Insert business address line 1" />
          </div>
          <div class="mt-4" v-if="addressLine2">
            <div class="row">
              <div class="fw-bold fs-14 ms-1 mb-2 pe-0 col-auto">Business address line 2</div>
              <a
                class="hover color-text-primary fs-14 col-auto fw-semi-bold text-decoration-none"
                style="width: fit-content"
                @click="this.toggleAddressLine2">
                <p v-if="addressLine2" class="mb-0">
                  <i class="bi bi-x-circle me-1"></i>apt/suite/floor
                </p>
              </a>
            </div>
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                  .businessAddressLine2
              "
              :class="addressLine2 ? 'mt-2' : 'mt-0'"
              class="form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
              :required="addressLine2"
              placeholder="Insert business address line 2"
              aria-label="Insert business address line 2" />
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <label for="inputCity" class="fw-bold fs-14 ms-1 mb-2">City</label>
              <input
                required
                type="text"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                    .businessCity
                "
                class="form-control fs-12 color-background-form border-2 color-border py-2"
                placeholder="Insert business city"
                aria-label="Insert city"
                id="inputCity" />
            </div>
            <div class="col-md-4 col-7 mt-md-0 mt-3">
              <label for="inputState" class="fw-bold fs-14 ms-1 mb-2">State</label>
              <select
                required
                id="inputState"
                aria-label="Select state"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                    .businessState
                "
                :class="{
                  'text-muted':
                    loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                      .businessState === '',
                }"
                class="form-select fs-12 color-background-form border-2 color-border py-2">
                <option value="">Select state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="col-md-2 col-5 mt-md-0 mt-3">
              <label for="inputZip" class="fw-bold fs-14 ms-1 mb-2">Zip</label>
              <input
                id="zip"
                name="zip"
                type="text"
                required
                inputmode="numeric"
                pattern="[0-9]{5}"
                title="Insert a valid 5 digits zip code"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
                    .businessZip
                "
                placeholder="Zip"
                aria-label="Insert zip"
                class="form-control fs-12 color-background-form border-2 color-border py-2 text-deccoration-none" />
            </div>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('businessIndustry'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Primary business activity
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            placeholder="Select industry type"
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.industry
            "
            aria-label="Select funds use">
            <option value="" disabled selected hidden muted>Select industry</option>
            <option value="Accommodation and Food Services">Accommodation and Food Services</option>
            <option value="Administrative and Support Services">
              Administrative and Support Services
            </option>
            <option value="Agriculture, Forestry, Hunting and Fishing">
              Agriculture, Forestry, Hunting and Fishing
            </option>
            <option value="Arts, Entertainment and Recreation">
              Arts, Entertainment and Recreation
            </option>
            <option value="Construction">Construction</option>
            <option value="Data Processing Services">Data Processing Services</option>
            <option value="Educational Services">Educational Services</option>
            <option value="Finance and Insurance">Finance and Insurance</option>
            <option value="Healthcare and Social Assistance">
              Healthcare and Social Assistance
            </option>
            <option value="Information/Technology">Information/Technology</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Personal Care Business">Personal Care Business</option>
            <option value="Professional, Scientific, and Technical Services">
              Professional, Scientific, and Technical Services
            </option>
            <option value="Retail Trade">Retail Trade</option>
            <option value="Transportation and Warehousing">Transportation and Warehousing</option>
            <option value="Waste Management and Remediation Services">
              Waste Management and Remediation Services
            </option>
            <option value="Wholesale Trade">Wholesale Trade</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div v-if="!authenticated" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">Connect business</p>
          <div v-if="this.loanApplicationStore.applicationBusiness?.business?.info">
            <div class="card border-1 color-border">
              <div
                class="d-flex align-items-center justify-content-between position-relative"
                style="height: 48px">
                <div class="ms-2 d-flex align-items-center">
                  <span>
                    <img
                      :src="this.loanApplicationStore.applicationBusiness.business.info.img"
                      width="30"
                      height="30"
                      class="rounded-circle border color-border-grey" />
                  </span>
                  <span class="fs-12 fw-semi-bold ms-2 text-break">{{
                    this.loanApplicationStore.applicationBusiness.business.info.name
                  }}</span>
                  <span class="fs-12 ms-4 d-none d-md-block">{{
                    this.loanApplicationStore.applicationBusiness.business.info.category
                  }}</span>
                </div>
                <div class="me-2">
                  <a
                    class="hover color-text-primary fs-12 fw-semi-bold text-decoration-none"
                    @click="searchBusiness()"
                    style="width: fit-content">
                    Change
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card-body py-2 color-background-plaid rounded px-sm-4">
            <div class="row justify-content-between align-items-center py-1">
              <div
                class="col-md-6 d-flex justify-content-md-start justify-content-center mb-md-0 mb-2">
                <img src="@/assets/shubox_black_48.png" style="max-height: 22px; max-width: 100%" />
              </div>
              <div class="col-md-6 d-flex justify-content-md-end justify-content-center">
                <button
                  class="btn sb-btn-dark fs-12 fw-semi-bold"
                  :disabled="connectBusinessButtonDisabled"
                  @click.prevent="searchBusiness()">
                  Connect business
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-5 mb-5">
          <button
            type="button"
            class="btn shadow-sm sb-btn-secondary rounded mt-4 mx-2"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-bold"> Previous </span>
          </button>
          <button
            class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2"
            :disabled="
              !this.authenticated && !this.loanApplicationStore.applicationBusiness?.business
            "
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-bold"> Next </span>
          </button>
        </div>
      </form>
    </div>
  </LoanApplicationFormContainer>
  <LoadingPopover
    :visible="showLoadingBusinesses"
    title="Loading Businesses"
    message="Please wait while we load the data.">
  </LoadingPopover>
  <LoadingPopover
    :visible="showLoadingCreatingBusiness"
    title="Connecting Business"
    message="Please wait while we connect the business.">
  </LoadingPopover>
  <BusinessesResultsPopover
    :visible="showBusinessesResults"
    @selectBusiness="selectBusiness"
    @closeBusinessesResultsPopover="closeBusinessesResultsPopover"
    @addManualBusiness="registerBusiness"
    :businesses="businesses">
  </BusinessesResultsPopover>
</template>

<script>
import ServiceAPI from "@/services/ServiceAPI";
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import { useReCaptcha } from "vue-recaptcha-v3";
import { onMounted, ref } from "vue";
import LoanApplicationFormContainer from "./LoanApplicationFormContainer.vue";
import LoadingPopover from "../LoadingPopover.vue";
import BusinessesResultsPopover from "./BusinessesResultsPopover.vue";
import { useToast } from "vue-toastification";

export default {
  props: [
    "businessId",
    "authenticated",
    "tenant",
    "tenantId",
    "grant",
    "questions",
    "programDetails",
  ],

  setup(props, { emit }) {
    const loanApplicationStore = useLoanApplicationStore();
    const { executeRecaptcha } = useReCaptcha();
    const business = ref({});
    const businesses = ref([]);
    const showLoadingBusinesses = ref(false);
    const showBusinessesResults = ref(false);
    const showLoadingCreatingBusiness = ref(false);

    const getRecaptchaToken = async (action) => {
      if (!props.authenticated) {
        let retry = 0;
        while (retry < 3) {
          try {
            return await executeRecaptcha(action);
          } catch (e) {
            console.error("Could not get recaptcha token", e);
            retry++;
          }
        }
      }
    };

    const handleReferredToUs = (option) => {
      if (
        !loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.referralSource
      ) {
        loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.referralSource =
          "";
      }
      if (option != "Other") {
        loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.referralSource =
          option;
      } else {
        loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.referralSource =
          "";
      }
    };

    const getQuestionObject = (name) => {
      const loanApplicationDetails = props.questions.LoanApplicationDetails;
      const loanApplicationOwnerDetails = props.questions.LoanApplicationOwnerDetails;
      const loanApplicationBusinessDetails = props.questions.LoanApplicationBusinessDetails;
      const loanApplicationFinancialDetails = props.questions.LoanApplicationFinancialDetails;

      return (
        loanApplicationDetails.find((item) => item.name === name) ||
        loanApplicationOwnerDetails.find((item) => item.name === name) ||
        loanApplicationBusinessDetails.find((item) => item.name === name) ||
        loanApplicationFinancialDetails.find((item) => item.name === name)
      );
    };

    const loadBusiness = async (id) => {
      if (!loanApplicationStore.userLoanApplications[props.businessId].businessInfo) {
        try {
          const response = await ServiceAPI.getBusiness(id);
          business.value = response.data;
          loanApplicationStore.userLoanApplications[
            props.businessId
          ].tab1GetStarted.businessDbaName = business.value.businessInfo.name;
          loanApplicationStore.userLoanApplications[
            props.businessId
          ].tab1GetStarted.businessAddress = business.value.address.address.split(",")[0].trim();
          loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.businessCity =
            business.value.address.address.split(",")[1].trim();
          loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.businessState =
            business.value.address.state;
          loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.businessZip =
            business.value.address.zipCode;
        } catch (error) {
          console.log(error);
        }
        loanApplicationStore.userLoanApplications[props.businessId].businessInfo = true;
      }
    };

    const selectBusiness = async (businessSelected) => {
      const registerBusinessToken = await getRecaptchaToken("business_register");
      showBusinessesResults.value = false;
      showLoadingCreatingBusiness.value = true;
      const googlePlaceId = businessSelected.info.googlePlaceId;
      try {
        const response = await ServiceAPI.registerBusinessWithoutSession(
          googlePlaceId,
          businessSelected.signature,
          registerBusinessToken
        );
        const businessId = response.data?.businessId;
        const signature = response.data?.signature;
        loanApplicationStore.userLoanApplications[
          loanApplicationStore.defaultBusinessId
        ].businessId = businessId;
        loanApplicationStore.userLoanApplications[
          loanApplicationStore.defaultBusinessId
        ].businessIdSignature = signature;

        loanApplicationStore.applicationBusiness.business = businessSelected;
        showLoadingCreatingBusiness.value = false;

        setApplicationBusiness();
      } catch (error) {
        console.log("Problem searching", error);
        showLoadingCreatingBusiness.value = false;
        useToast().error(
          "Could not perform search right now. Try again later or contact support@shubox.ai",
          {
            timeout: 5000,
          }
        );
      }
    };

    const setManualConnectedBusiness = (response) => {
      loanApplicationStore.applicationBusiness.business = {};
      loanApplicationStore.applicationBusiness.business.info = {};
      setApplicationBusiness();
      loanApplicationStore.applicationBusiness.business.info.name =
        loanApplicationStore.userLoanApplications[
          loanApplicationStore.defaultBusinessId
        ].tab1GetStarted.businessDbaName;
      loanApplicationStore.applicationBusiness.business.info.img =
        "https://cdn.discordapp.com/attachments/990253498283204649/1141021061807341599/store_icon.png";

      loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId].businessId =
        response.data?.businessId;
      loanApplicationStore.userLoanApplications[
        loanApplicationStore.defaultBusinessId
      ].businessIdSignature = response.data?.signature;
    };

    const registerBusiness = async () => {
      const businessName =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .tab1GetStarted.businessDbaName;

      const address =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .tab1GetStarted.businessAddress;

      const city =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .tab1GetStarted.businessCity;

      const state =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .tab1GetStarted.businessState;

      const zipCode =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .tab1GetStarted.businessZip;

      const industry =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .tab1GetStarted.industry;

      const facebook =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId].social
          .facebook;

      const instagram =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId].social
          .instagram;

      let website =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId].social
          .website;

      if (website === "https://") {
        website = "";
      }

      showBusinessesResults.value = false;
      showLoadingCreatingBusiness.value = true;
      const token = await getRecaptchaToken("business_register");

      try {
        const response = await ServiceAPI.registerBusinessManual(
          businessName,
          address,
          city,
          state,
          zipCode,
          industry,
          facebook,
          instagram,
          website,
          token
        );

        setManualConnectedBusiness(response);
        showLoadingCreatingBusiness.value = false;
      } catch (e) {
        showLoadingCreatingBusiness.value = false;
        console.log(e);

        useToast().error(
          "Could not register business right now. Try again later or contact support@shubox.ai",
          {
            timeout: 5000,
          }
        );
      }
    };

    const searchBusiness = async () => {
      const searchBusinessToken = await getRecaptchaToken("business_search");
      const searchQuery =
        loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.businessDbaName;
      const currentState =
        loanApplicationStore.userLoanApplications[props.businessId].tab1GetStarted.businessState;

      showLoadingBusinesses.value = true;
      try {
        const response = await ServiceAPI.advancedSearchBusinessWithoutSession(
          searchQuery,
          currentState,
          searchBusinessToken
        );

        businesses.value = response.data?.businesses;

        showLoadingBusinesses.value = false;
        showBusinessesResults.value = true;
      } catch (error) {
        console.log("Problem searching", error);
        showLoadingBusinesses.value = false;
        useToast().error(
          "Could not perform search right now. Try again later or contact support@shubox.ai",
          {
            timeout: 5000,
          }
        );
      }
    };

    const setApplicationBusiness = async () => {
      const applicationSaveToken = await getRecaptchaToken("application_save");
      const signature =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .loanApplicationId.signature;
      const applicationId =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .loanApplicationId.id;
      const businessId =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .businessId;
      const businessIdSignature =
        loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
          .businessIdSignature;
      try {
        /*await ServiceAPI.setBusiness(
          signature.toString(),
          applicationId,
          businessId,
          businessIdSignature,
          applicationSaveToken
        );*/
        await ServiceAPI.setBusinessV2(
          signature.toString(),
          applicationId,
          businessId,
          businessIdSignature,
          applicationSaveToken
        );
      } catch (error) {
        console.log(error);
      }
    };

    const resetApplication = () => {
      emit("resetApplication");
    };

    onMounted(async () => {
      if (props.authenticated) {
        loadBusiness(props.businessId);
      }
    });

    return {
      loanApplicationStore,
      business,
      showLoadingBusinesses,
      showBusinessesResults,
      showLoadingCreatingBusiness,
      businesses,
      getQuestionObject,
      selectBusiness,
      searchBusiness,
      setApplicationBusiness,
      registerBusiness,
      resetApplication,
      handleReferredToUs,
    };
  },

  computed: {
    connectBusinessButtonDisabled() {
      return (
        this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
          .businessState === "" ||
        !this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
          .businessState ||
        this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
          .businessDbaName === "" ||
        !this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
          .businessDbaName
      );
    },
  },

  methods: {
    removeEverytingButNumbersOrDots(x) {
      if (x == null) {
        return "";
      }
      return x.toString().replace(/[^0-9.]/g, "");
    },

    addCommasToNumber(x) {
      let input = this.removeEverytingButNumbersOrDots(x);
      let decimal = input.split(".")[1];
      let integer = input.split(".")[0];
      let res = "";

      for (let i = integer.length - 1; i >= 0; i--) {
        res = integer[i] + res;
        if ((integer.length - i) % 3 == 0 && i != 0) {
          res = "," + res;
        }
      }

      if (input.includes(".")) {
        res += "." + decimal;
      }

      return res;
    },

    addCommasToAmount() {
      this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount =
        this.addCommasToNumber(
          this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount
        );
    },

    toggleAddressLine2() {
      this.addressLine2 = !this.addressLine2;
      if (this.addressLine2 == false) {
        this.loanApplicationStore.userLoanApplications[
          this.businessId
        ].tab1GetStarted.businessAddressLine2 = "";
      }
    },

    closeBusinessesResultsPopover() {
      this.showBusinessesResults = false;
    },
  },

  data() {
    return {
      addressLine2:
        this.loanApplicationStore.userLoanApplications[this.businessId]?.tab1GetStarted
          ?.businessAddressLine2 != null &&
        this.loanApplicationStore.userLoanApplications[this.businessId]?.tab1GetStarted
          ?.businessAddressLine2 != "",
      businessMock: {
        info: {
          name: "Bronx Native",
          img: "https://static.wixstatic.com/media/0eddf3_55bb8a8783864b5a89d0d4e64779c405~mv2.jpg/v1/fit/w_2500,h_1330,al_c/0eddf3_55bb8a8783864b5a89d0d4e64779c405~mv2.jpg",
          category: "Clothing",
        },
        address: { address: "", state: "", zipCode: "" },
      },
    };
  },

  mounted() {
    this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount =
      this.addCommasToNumber(
        this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount
      );

    if (
      this.grant &&
      !this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.businessState
    ) {
      this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.businessState =
        "NY";
    }
  },

  components: {
    LoanApplicationFormContainer,
    LoadingPopover,
    BusinessesResultsPopover,
  },
  emits: ["changeTab"],
};
</script>

<style>
.input-box {
  display: flex;
  background-color: #f7f8fd;
  align-items: center;
  border: 1px solid #e6eaee;
  border-radius: 0.375rem;
  padding-left: 12px;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  color: #0f2744;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border-color: #7200e4;
}
</style>
