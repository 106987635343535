<template>
  <div class="card border color-border h-100 p-0" style="min-height: 87px">
    <div class="card-body">
      <div class="position-relative">
        <div>
          <h6 class="card-subtitle text-muted fs-10 fw-semi-bold font-spacing mb-1 pt-1">
            DESCRIPTION
          </h6>
          <div v-if="loadSkeleton">
            <content-loader
              class="mt-2"
              viewBox="0 0 340 40"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="3" ry="3" width="40" height="10" />
              <rect x="45" y="0" rx="3" ry="3" width="60" height="10" />
              <rect x="110" y="0" rx="3" ry="3" width="80" height="10" />
              <rect x="195" y="0" rx="3" ry="3" width="30" height="10" />
              <rect x="230" y="0" rx="3" ry="3" width="80" height="10" />
              <rect x="0" y="15" rx="3" ry="3" width="80" height="10" />
              <rect x="85" y="15" rx="3" ry="3" width="40" height="10" />
            </content-loader>
          </div>
          <template v-else v-for="item in this.business.description" :key="item">
            <span
              class="p-2 me-1 my-1 badge rounded-pill color-background-grey card-text p-0 m-0 fs-12 fw-normal color-text-dark"
              :class="{ 'mt-2': isMapLayout }">
              {{ item }}
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  props: ["business", "isMapLayout", "loading"],
  components: { ContentLoader },
  setup(props) {
    const loadSkeleton = props.loading && !props.business?.description;
    return { loadSkeleton };
  },
};
</script>

<style></style>
