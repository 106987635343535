<template>
  <LoanApplicationSummary
    v-if="summary.businessDetails"
    :summary="summary"
    :loanApplicationId="loanApplicationId"
    :grant="grant"
    :tenantId="tenantId"
    :tenant="tenant">
    <p v-if="!tenantId">
      Once you <span class="fw-semi-bold">Submit</span> the application it will be forwarded to
      {{ tenant.name }}. You will be contacted regarding the status of your application.
    </p>
    <p v-if="tenant.name === 'Shubox'" class="fs-12">
      If you have any questions about this interest form, please contact us at
      <a class="color-text-primary text-decoration-none" href="mailto:support@shubox.ai">
        support@shubox.ai
      </a>
    </p>

    <form @submit.prevent="validateAndNextTab">
      <div class="form-check fs-14 mt-4">
        <input
          class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
          type="checkbox"
          value=""
          id="submit-confirmation"
          required />
        <label class="form-check-label" for="submit-confirmation">
          {{
            tenantId
              ? "I consent to the secure electronic transmission of my application information " +
                tenantNameText()
              : "I agree that Shubox can share my information with their lending network as part of my interest in getting a loan."
          }}
        </label>
      </div>

      <div class="d-flex align-items-center justify-content-center mt-5 mb-5">
        <button
          type="button"
          class="btn shadow-sm sb-btn-secondary rounded mt-4 mx-2"
          @click="$emit('changeTab', 'FormTab4')"
          style="height: 42px; width: 90px">
          <span class="fs-14 fw-semi-bold">Previous</span>
        </button>
        <button
          type="submit"
          class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2"
          style="height: 42px; width: 90px">
          <span class="fs-14 fw-semi-bold">Submit</span>
        </button>
      </div>
    </form>
  </LoanApplicationSummary>
</template>

<script>
import LoanApplicationSummary from "@/components/loan_application/LoanApplicationSummary.vue";
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import ServiceAPI from "@/services/ServiceAPI";
import { onMounted, ref } from "vue";

import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  props: ["businessId", "authenticated", "tenant", "tenantId", "grant"],

  setup(props) {
    const { executeRecaptcha } = useReCaptcha();
    const loanApplicationStore = useLoanApplicationStore();
    const summary = ref({});
    const loanApplicationId = ref();

    const getRecaptchaToken = async (action) => {
      if (!props.authenticated) {
        let retry = 0;
        while (retry < 3) {
          try {
            return await executeRecaptcha(action);
          } catch (e) {
            console.error("Could not get recaptcha token", e);
            retry++;
          }
        }
      }
    };

    const tenantNameText = () => {
      if (props.tenant && props.tenant.name) {
        return "to " + props.tenant.name + ".";
      } else {
        return ".";
      }
    };

    const loadSummary = async (loanApplicationId, loanApplicationIdSignature) => {
      const recaptchaToken = await getRecaptchaToken("application_submit");
      const response = await ServiceAPI.getLoanApplicationSummaryV2(
        recaptchaToken,
        loanApplicationIdSignature,
        loanApplicationId
      );
      summary.value = response.data;
    };

    onMounted(async () => {
      const businessId = props.businessId;
      loanApplicationId.value =
        loanApplicationStore.userLoanApplications[businessId].loanApplicationId.id;
      const loanApplicationIdSignature =
        loanApplicationStore.userLoanApplications[businessId].loanApplicationId.signature ?? null;
      await loadSummary(loanApplicationId.value, loanApplicationIdSignature);
    });

    return {
      summary,
      loanApplicationId,
      tenantNameText,
    };
  },

  methods: {
    validateAndNextTab() {
      const submitApplication = confirm("Submit application?");
      this.$emit(
        "changeTab",
        submitApplication ? "FinishedPage" : "SummaryPage",
        null,
        submitApplication
      );
    },

    async submitForm() {},
  },

  components: {
    LoanApplicationSummary,
  },
};
</script>

<style></style>
