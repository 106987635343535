<template>
  <div class="card border color-border h-100 p-0" style="min-height: 87px">
    <div class="card-body">
      <div class="position-relative">
        <div>
          <h6 class="card-subtitle text-muted fs-10 fw-semi-bold font-spacing mb-1 pt-1">
            NEIGHBORHOOD INSIGHTS
          </h6>
          <content-loader
            v-if="loadSkeleton"
            class="mt-2"
            viewBox="0 0 340 40"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="3" ry="3" width="107" height="10" />
            <rect x="174" y="0" rx="3" ry="3" width="140" height="10" />
            <rect x="0" y="20" rx="3" ry="3" width="120" height="10" />
            <rect x="174" y="20" rx="3" ry="3" width="130" height="10" />
          </content-loader>
          <div v-else class="row">
            <div class="col-sm-6 my-1 fs-11" v-for="item in statistics" :key="item">
              <span class="my-3">
                {{ item.name }}
                <span
                  class="p-2 ms-2 badge rounded-pill card-text fs-11 fw-normal color-text-dark"
                  :class="[
                    item.value === 'Yes'
                      ? 'color-background-positive'
                      : item.value === 'No'
                      ? 'color-background-neutral'
                      : 'color-background-grey',
                    authenticated ? '' : 'pill-blur',
                  ]">
                  {{ item.value }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { computed, ref } from "vue";

export default {
  props: {
    aois: { type: Array, default: () => [] },
    pois: { type: Array, default: () => [] },
    authenticated: Boolean,
    loading: Boolean,
  },

  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = ref(props.loading);

    const communityDistrictAoiValues = computed(() => {
      return props.authenticated && props.aois.length
        ? props.aois.find((aoi) => aoi.type === "COMMUNITY_DISTRICT")?.values ?? []
        : [];
    });

    const listOfBIDs = computed(() => {
      return props.authenticated && props.aois.length
        ? props.aois.filter((aoi) => aoi.type === "BUSINESS_IMPROVEMENT_DISTRICT")
        : [];
    });

    const areaMedianIncome = computed(() => {
      return props.authenticated && communityDistrictAoiValues.value.length
        ? communityDistrictAoiValues.value.find(
            (value) => value.type === "MEDIAN_INCOME_ALL_HOUSEHOLDS"
          )?.value ?? null
        : null;
    });

    const numberOfHouseholds = computed(() => {
      return props.authenticated && communityDistrictAoiValues.value.length
        ? communityDistrictAoiValues.value.find(
            (value) => value.type === "NUMBER_OF_ALL_HOUSEHOLDS"
          )?.value ?? null
        : null;
    });

    const transitHubs = computed(() => {
      return props.authenticated && props.pois.length
        ? props.pois.filter((poi) => poi.type === "METRO_STATION" || poi.type === "BUS_STOP")
        : [];
    });

    const distanceToClosestTransitHub = computed(() => {
      return props.authenticated
        ? transitHubs.value.length
          ? transitHubs.value.reduce((prev, current) =>
              prev.distance < current.distance ? prev : current
            ).distance
          : "N/A"
        : null;
    });

    const distanceToClosestTransitHubInFeet = computed(() => {
      return props.authenticated
        ? transitHubs.value.length
          ? Math.round(distanceToClosestTransitHub.value * 3.28084) + " ft"
          : "N/A"
        : null;
    });

    // method to add commas to numbers
    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const isInBID = computed(() => {
      return props.authenticated ? listOfBIDs.value.some((bid) => bid.contains === true) : null;
    });

    const statistics = computed(() => {
      return [
        {
          name: "In Commercial Hub",
          value: props.authenticated ? (isInBID.value ? "Yes" : "No") : "N/A",
        },
        {
          name: "Household Median Income",
          value: props.authenticated
            ? areaMedianIncome.value
              ? `$${numberWithCommas(areaMedianIncome.value)}`
              : "N/A"
            : "$73,539",
        },
        {
          name: "Number of Households",
          value: props.authenticated
            ? numberOfHouseholds.value
              ? numberWithCommas(numberOfHouseholds.value)
              : "N/A"
            : "72,880",
        },
        {
          name: "Closest Mass Transit",
          value: props.authenticated
            ? numberWithCommas(distanceToClosestTransitHubInFeet.value)
            : "340 ft",
        },
      ];
    });

    return { statistics, communityDistrictAoiValues, loadSkeleton };
  },
};
</script>

<style></style>
