import { defineStore } from "pinia";
import ServiceAPI from "@/services/ServiceAPI.js";
import { useStorage } from "@vueuse/core";

export const useShueyChatStore = defineStore("shueyChatStore", {
  state: () => ({
    chatSessions: useStorage("chatSessions", {}),
    loading: false,
    showChat: false,
  }),

  getters: {
    getShueyChat(businessId) {
      return this.chatSessions[businessId].messages ?? [];
    },
  },

  actions: {
    toggleChat() {
      this.showChat = !this.showChat;
    },

    answerToReview(review, businessId) {
      const message = `Answer to this comment on my business "${review.comment}" from ${review.name} with ${review.rating} stars rating.`;
      const userMessage = { role: "user", content: message };
      this.chatSessions[businessId] = this.chatSessions[businessId] ?? {
        messages: [],
        sessionId: null,
      };
      this.chatSessions[businessId].messages.push(userMessage);
      this.loading = true;

      this.addAssitantMessage(message, businessId);
    },

    addUserMessage(message, businessId) {
      const userMessage = { role: "user", content: message };
      this.chatSessions[businessId] = this.chatSessions[businessId] ?? {
        messages: [],
        sessionId: null,
      };
      this.chatSessions[businessId].messages.push(userMessage);
      this.loading = true;
    },

    resetSessionId(businessId) {
      this.chatSessions[businessId].sessionId = null;
    },

    async addAssitantMessage(message, businessId) {
      try {
        const response = await ServiceAPI.sendChatMessage(
          businessId,
          message,
          this.chatSessions[businessId].sessionId
        );

        this.chatSessions[businessId].sessionId = response.data?.sessionId;

        this.loading = false;

        this.chatSessions[businessId].messages.push({
          role: "assistant",
          content: response.data?.message,
        });
      } catch (e) {
        this.loading = false;
        this.resetSessionId(businessId);
        this.chatSessions[businessId].messages.push({
          role: "assistant",
          content: "Sorry, something went wrong. Please try again later.",
        });
      }
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
});
