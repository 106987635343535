import { defineStore } from "pinia";
import Keycloak from "@/services/auth.js";
import { useStorage } from "@vueuse/core";

const SEARCH_EXPIRE_DAYS_MS = 10 * 24 * 3600_000;
const SEARCH_COUNT_LIMIT = 5;

export const useUserStore = () => {
  const innerStore = defineStore("userStore", {
    state: () => ({
      role: "",
      name: "",
      id: "",
      picture: "",
      searchCount: useStorage("unauthorized-search-count", {
        date: new Date().getTime(),
        count: 0,
      }),
    }),

    getters: {
      isAuthenticated() {
        return this.role !== "guest";
      },
      exceedsSearches() {
        if (this.isAuthenticated) {
          return false;
        }

        if (new Date().getTime() - this.searchCount.date > SEARCH_EXPIRE_DAYS_MS) {
          return false;
        }

        return this.searchCount.count >= SEARCH_COUNT_LIMIT;
      },
    },

    actions: {
      getUser() {
        const roles = Keycloak.tokenParsed?.realm_access?.roles ?? [];
        this.name = Keycloak.idTokenParsed?.name ?? "John Doe";
        this.id = Keycloak.idTokenParsed?.sub ?? "0";
        this.picture = `https://ui-avatars.com/api/?name=${this.name}&background=random&format=png`;

        if (roles.includes("admin")) {
          this.role = "admin";
        } else if (roles.includes("loan-officer")) {
          this.role = "loan-officer";
        } else if (roles.includes("business-owner")) {
          this.role = "business-owner";
        } else {
          this.role = "guest";
        }
      },
      incrementSearches() {
        if (this.isAuthenticated) {
          return;
        }

        const date = new Date().getTime();
        const count =
          date - this.searchCount.date > SEARCH_EXPIRE_DAYS_MS ? 1 : this.searchCount.count + 1;

        this.searchCount = { date, count };
      },
    },
  });

  const s = innerStore();
  if (s.role === "") {
    s.getUser();
  }
  return s;
};
