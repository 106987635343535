<template>
  <div
    class="sb-chatbot"
    :class="this.shueyChatStore.showChat ? 'sb-chatbot-open' : 'sb-chatbot-closed'">
    <Transition>
      <ShueyChat v-if="this.shueyChatStore.showChat" class="mb-3"></ShueyChat>
    </Transition>

    <button
      class="sb-chatbot-button mirror-rounded-purple-lighter color-text-primary fw-semi-bold fs-14 rounded d-flex align-items-center px-3 py-1 border border-2 color-border-primary align-right"
      @click="this.shueyChatStore.toggleChat">
      <i
        class="bi fs-24 color-text-primary me-1"
        :class="this.shueyChatStore.showChat ? 'bi-x' : 'bi-stars'">
      </i>
      Ask Shuey
    </button>
  </div>
</template>

<script>
import ShueyChat from "@/components/chat/ShueyChat.vue";
import { useShueyChatStore } from "@/stores/ShueyChatStore";

export default {
  setup() {
    const shueyChatStore = useShueyChatStore();

    return {
      shueyChatStore,
    };
  },

  components: {
    ShueyChat,
  },
};
</script>

<style></style>
