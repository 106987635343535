<template>
  <LoanApplicationFormContainer :tenant="programDetails ? programDetails.tenant : null">
    <div class="row mx-md-5 mx-sm-4 mx-2">
      <p class="fs-18 fw-bold text-center mt-4 pt-2">
        Continue your Business Application. You are almost there.
      </p>
      <p>
        <span
          v-if="programDetails && programDetails.descriptionHtml"
          v-html="programDetails.descriptionHtml"></span>
      </p>
      <div class="row mt-5 mb-5 pb-5">
        <div class="col-sm-6 col d-flex justify-content-sm-end justify-content-center">
          <button
            @click="resetApplication()"
            class="btn sb-btn-secondary fs-14 fw-semi-bold p-2 px-3"
            style="width: 176px">
            Restart application
          </button>
        </div>
        <div class="col-sm-6 col d-flex justify-content-sm-start justify-content-center">
          <button
            type="button"
            class="btn sb-btn-primary fs-14 fw-semi-bold p-2 px-3"
            :disabled="!programDetails"
            style="width: 176px"
            @click="emits('changeTab', 'FormTab1')">
            Continue Application
          </button>
        </div>
      </div>
    </div></LoanApplicationFormContainer
  >
</template>

<script setup>
import LoanApplicationFormContainer from "@/components/loan_application/LoanApplicationFormContainer.vue";

import { defineProps, defineEmits } from "vue";

defineProps(["businessId", "authenticated", "tenantId", "tenant", "grant", "programDetails"]);
const emits = defineEmits(["changeTab", "resetApplication"]);

const resetApplication = () => {
  emits("resetApplication");
};
</script>
