<template>
  <div class="card border color-border">
    <div class="card-body position-relative">
      <div class="d-flex align-items-center justify-content-between">
        <div class="fs-14">
          <i class="bi bi-info-circle fs-16 color-text-primary me-2"></i>
          {{ message }}
        </div>
        <button
          type="button"
          class="btn sb-btn-primary fs-12 fw-semi-bold d-flex align-items-center justify-content-between"
          @click="buttonPress"
          :disabled="loading">
          {{ loading ? "Loading" : buttonMessage }}
          <span
            v-if="loading"
            class="align-middle spinner-border text-white ms-2"
            style="width: 1.2rem; height: 1.2rem"
            role="status"
            aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useToast } from "vue-toastification";
import { useUserStore } from "@/stores/UserStore";
export default {
  props: {
    type: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false);
    const toast = useToast();
    const userStore = useUserStore();

    const isAdminOrLoanOfficer = computed(() => {
      return userStore.role === "admin" || userStore.role === "loan-officer";
    });

    const message = computed(() => {
      return "Compare now with similar businesses!";
    });

    const buttonMessage = computed(() => {
      return "View Comparison";
    });

    const toggleType = () => {
      emit("changePeersCardType");
    };

    const buttonPress = async () => {
      if (isAdminOrLoanOfficer.value) {
        emit("changePage", "BenchmarkCards");
      } else {
        openCommingSoonPopup();
      }
    };

    const openCommingSoonPopup = () => {
      const businessOwnerMessage = "Business comparison is not yet available for business owners!";
      const notLoggedInUserMessage = "Business comparison is only available for logged in users!";
      console.log(userStore.role);
      toast.info(
        userStore.role === "business-owner" ? businessOwnerMessage : notLoggedInUserMessage
      );
    };

    return {
      buttonPress,
      buttonMessage,
      message,
      loading,
      toggleType,
      openCommingSoonPopup,
    };
  },
};
</script>

<style></style>
