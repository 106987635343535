import { defineStore } from "pinia";
import ServiceAPI from "@/services/ServiceAPI.js";

export const useBenchmarkStore = defineStore("benchmarkStore", {
  state: () => ({
    errorReloading: false,
    transactionsError: false,
    loading: false,
    peers: {},
    peersComparison: {},
    peersScoreComparison: {},
    peersReviewsComparison: {},
    peersExternalTransactionsComparison: {},
  }),

  actions: {
    async getPeers(businessId) {
      if (this.peers[businessId]) {
        return this.peers[businessId];
      }

      const response = await ServiceAPI.getPeers(businessId);
      this.peers[businessId] = response.data ?? {};

      return this.peers[businessId];
    },

    async registerPeers(businessId) {
      if (this.peers[businessId]?.allRegistered) {
        return true;
      }
      const response = await ServiceAPI.registerPeers(businessId);
      return response?.data?.success;
    },

    async getPeersComparison(businessId) {
      if (this.peersComparison[businessId]) {
        return this.peersComparison[businessId];
      }

      const response = await ServiceAPI.getPeerComparison(businessId);
      this.peersComparison[businessId] = response ? response.data : {};

      return this.peersComparison[businessId];
    },

    async getPeersScoreComparison(businessId) {
      if (this.peersScoreComparison[businessId]) {
        return this.peersScoreComparison[businessId];
      }

      const response = await ServiceAPI.getBusinessPeersScoreOverTime(businessId);
      this.peersScoreComparison[businessId] = response ? response.data : {};

      return this.peersScoreComparison[businessId];
    },

    async getPeersReviewsComparison(businessId) {
      if (this.peersReviewsComparison[businessId]) {
        return this.peersReviewsComparison[businessId];
      }

      const response = await ServiceAPI.getPeerReviewsCountComparison(businessId);
      this.peersReviewsComparison[businessId] = response ? response.data : {};

      return this.peersReviewsComparison[businessId];
    },

    async getPeersExternalTransactionsComparison(businessId) {
      if (this.peersExternalTransactionsComparison[businessId] || this.transactionsError) {
        return this.peersExternalTransactionsComparison[businessId];
      }

      const response = await ServiceAPI.getPeerGroupTransactionalDetails(businessId);
      this.peersExternalTransactionsComparison[businessId] = response ? response.data : {};

      return this.peersExternalTransactionsComparison[businessId];
    },

    async getPeersData(businessId, hasExternalTransactions) {
      this.loading = true;
      const allBusinessesRegistered = this.peers[businessId].allRegistered;

      if (!allBusinessesRegistered) {
        await this.registerPeers(businessId);
      }

      try {
        await Promise.all([
          this.getPeers(businessId),
          this.getPeersComparison(businessId),
          this.getPeersScoreComparison(businessId),
          this.getPeersReviewsComparison(businessId),
        ]);
      } catch (e) {
        this.errorReloading = true;
        console.log(e);
      }

      try {
        if (hasExternalTransactions) {
          await this.getPeersExternalTransactionsComparison(businessId);
        } else {
          this.transactionsError = true;
        }
      } catch (e) {
        this.transactionsError = true;
        console.log(e);
      }

      this.loading = false;
    },
  },
});
