<template>
  <div
    v-if="
      this.tab != 'StartPage' &&
      this.tab != 'ContinuePage' &&
      this.tab != 'FinishedPage' &&
      this.tab != 'SummaryPage'
    "
    class="container-fluid d-flex justify-content-center align-items-center mt-md-3 mb-md-4 mb-3">
    <nav
      class="mx-md-2 mx-2 fs-16 d-flex align-items-center px-1"
      :class="this.tab === 'FormTab1' ? 'fw-semi-bold' : 'hover'"
      :disabled="
        this.loanApplicationStore.userLoanApplications[this.businessId]?.tab1
          ? !this.loanApplicationStore?.isFormComplete(this.businessId, 1)
          : false
      "
      @click="
        this.tab === 'FormTab1' || !this.loanApplicationStore.isFormComplete(this.businessId, 1)
          ? ''
          : $emit('changeTab', 'FormTab1')
      ">
      <div
        class="circle-icon fw-semi-bold"
        :class="this.tab === 'FormTab1' ? 'color-background-dark text-white' : 'bg-muted'">
        1
      </div>
      <p class="m-0 d-none d-md-block ms-2">Get started</p>
    </nav>
    <nav
      class="mx-md-2 mx-2 fs-16 d-flex align-items-center px-1"
      :class="this.tab === 'FormTab2' ? 'fw-semi-bold' : 'hover'"
      :disabled="!this.loanApplicationStore?.isFormComplete(this.businessId, 2)"
      @click="
        this.tab === 'FormTab2' || !this.loanApplicationStore.isFormComplete(this.businessId, 2)
          ? ''
          : $emit('changeTab', 'FormTab2')
      ">
      <div
        class="circle-icon fw-semi-bold"
        :class="this.tab === 'FormTab2' ? 'color-background-dark text-white' : 'bg-muted hover'">
        2
      </div>
      <p class="m-0 d-none d-md-block ms-2">Owner information</p>
    </nav>
    <nav
      class="mx-md-2 mx-2 fs-16 d-flex align-items-center px-1"
      :class="this.tab === 'FormTab3' ? 'fw-semi-bold' : 'hover'"
      :disabled="!this.loanApplicationStore?.isFormComplete(this.businessId, 3)"
      @click="
        this.tab === 'FormTab3' || !this.loanApplicationStore.isFormComplete(this.businessId, 3)
          ? ''
          : $emit('changeTab', 'FormTab3')
      ">
      <div
        class="circle-icon fw-semi-bold"
        :class="this.tab === 'FormTab3' ? 'color-background-dark text-white' : 'bg-muted hover'">
        3
      </div>
      <p class="m-0 d-none d-md-block ms-2">Business information</p>
    </nav>
    <nav
      class="mx-md-2 mx-2 fs-16 d-flex align-items-center px-1"
      :class="this.tab === 'FormTab4' ? 'fw-semi-bold' : 'hover'"
      :disabled="!this.loanApplicationStore?.isFormComplete(this.businessId, 4)"
      @click="
        this.tab === 'FormTab4' || !this.loanApplicationStore.isFormComplete(this.businessId, 4)
          ? ''
          : $emit('changeTab', 'FormTab4')
      ">
      <div
        class="circle-icon fw-semi-bold"
        :class="this.tab === 'FormTab4' ? 'color-background-dark text-white' : 'bg-muted hover'">
        4
      </div>
      <p class="m-0 d-none d-md-block ms-2">Financial data</p>
    </nav>
  </div>
</template>

<script>
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";

export default {
  props: ["tab", "businessId"],

  setup() {
    const loanApplicationStore = useLoanApplicationStore();

    return {
      loanApplicationStore,
    };
  },

  data() {
    return {
      activateTab: "FormTab1",
    };
  },

  methods: {
    toggleActive(tab) {
      this.activateTab = tab;
    },
  },
};
</script>

<style></style>
