<template>
  <div class="card border color-border h-100 card-mh">
    <div class="card-body">
      <div class="position-relative">
        <div>
          <h6 class="card-subtitle text-muted fs-10 fw-semi-bold font-spacing pt-1">
            {{ this.title }}
          </h6>
          <div v-if="loadSkeleton">
            <content-loader
              class="d-lg-block d-none"
              viewBox="0 0 180 60"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="10" rx="6" ry="6" width="110" height="40" />
            </content-loader>
            <content-loader
              class="d-lg-none d-block"
              viewBox="0 0 340 40"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="10" rx="6" ry="6" width="120" height="40" />
            </content-loader>
          </div>
          <div v-else>
            <p
              class="card-text p-0 m-0 fs-20 fw-semi-bold color-text-dark font-spacing-sm"
              v-if="this.value">
              <span v-if="isValueNumeric">
                {{ $n(Number(value), { notation: "compact" }) }}
              </span>
              <span v-else>
                {{ removeValue() }}
              </span>
            </p>
            <p class="card-text p-0 m-0 fs-20 fw-semi-bold color-text-dark font-spacing-sm" v-else>
              -
            </p>
          </div>
        </div>
      </div>
      <div v-if="isValueNumeric && !isNaN(difference)" style="margin-top: -4px">
        <span
          class="fs-10"
          :class="{
            'color-text-negative': difference < 0,
            'color-text-positive': difference >= 0,
          }">
          {{ difference >= 0 ? "+" : "" }}{{ $n(Number(difference), { notation: "compact" }) }}
        </span>
        <span class="fs-8 color-text-light-grey"> &nbsp;than previous</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  props: { value: Number, previousValue: Number, title: String, loading: Boolean },
  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = props.loading && !props.value;
    return { loadSkeleton };
  },

  methods: {
    removeValue() {
      let arr = this.value.split(" ");
      return arr[0];
    },
  },
  computed: {
    isValueNumeric() {
      return this.value != null && !isNaN(this.value);
    },
    difference() {
      if (
        this.value == null ||
        this.previousValue == null ||
        isNaN(this.value) ||
        isNaN(this.previousValue)
      ) {
        return undefined;
      }

      return this.value - this.previousValue;
    },
  },
};
</script>

<style></style>
