<template>
  <div
    class="card border color-border px-2"
    :class="totalReviews() > 0 || loading ? 'xl-card-mh' : 'card-maxh-2'">
    <div class="card-body">
      <h4 class="card-title m-0 p-0 color-text-dark fs-20">Rating</h4>
      <div v-if="loadSkeleton" class="text-muted fs-16 mt-4">
        <content-loader
          viewBox="0 0 340 380"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <rect x="20" y="0" rx="3" ry="3" width="40" height="10" />
          <rect x="70" y="0" rx="3" ry="3" width="40" height="10" />
          <rect x="20" y="20" rx="3" ry="3" width="70" height="10" />
          <circle cx="220" cy="25" r="7" />
          <rect x="232" y="20" rx="3" ry="3" width="18" height="10" />
          <rect x="260" y="20" rx="3" ry="3" width="54" height="10" />
          <rect x="270" y="35" rx="3" ry="3" width="44" height="10" />

          <rect x="20" y="70" rx="3" ry="3" width="294" height="10" />
          <rect x="20" y="85" rx="3" ry="3" width="294" height="10" />
          <rect x="20" y="100" rx="3" ry="3" width="180" height="10" />
          <circle cx="28" cy="125" r="8" />
          <rect x="45" y="120" rx="3" ry="3" width="56" height="10" />
          <rect x="110" y="120" rx="3" ry="3" width="45" height="10" />
          <rect x="164" y="120" rx="3" ry="3" width="36" height="10" />
          <rect x="20" y="138" rx="3" ry="3" width="58" height="8" />

          <rect x="20" y="170" rx="3" ry="3" width="294" height="10" />
          <rect x="20" y="185" rx="3" ry="3" width="294" height="10" />
          <rect x="20" y="200" rx="3" ry="3" width="180" height="10" />
          <circle cx="28" cy="225" r="8" />
          <rect x="45" y="220" rx="3" ry="3" width="56" height="10" />
          <rect x="110" y="220" rx="3" ry="3" width="45" height="10" />
          <rect x="164" y="220" rx="3" ry="3" width="36" height="10" />
          <rect x="20" y="238" rx="3" ry="3" width="58" height="8" />

          <rect x="20" y="270" rx="3" ry="3" width="294" height="10" />
          <rect x="20" y="285" rx="3" ry="3" width="294" height="10" />
          <rect x="20" y="300" rx="3" ry="3" width="180" height="10" />
          <circle cx="28" cy="325" r="8" />
          <rect x="45" y="320" rx="3" ry="3" width="56" height="10" />
          <rect x="110" y="320" rx="3" ry="3" width="45" height="10" />
          <rect x="164" y="320" rx="3" ry="3" width="36" height="10" />
          <rect x="20" y="338" rx="3" ry="3" width="58" height="8" />
        </content-loader>
      </div>
      <div v-else>
        <div class="container-fluid pb-4">
          <ul class="nav mt-3">
            <li class="nav-item active" v-if="business.google && googleHasReviews">
              <div
                @click="toggleGoogle"
                v-if="google"
                class="nav-link text-dark border-bottom border-warning border-1 pb-1 color-text-dark fs-14"
                style="border-color: #b16bf8 !important"
                aria-current="page"
                href="#"
                :disabled="business.google === null">
                Google
              </div>
              <div
                @click="toggleGoogle"
                v-else
                class="nav-link fs-14 text-dark lighter-hover"
                aria-current="page"
                href="#"
                :disabled="business.google === null">
                Google
              </div>
            </li>
            <li class="nav-item" v-if="business.yelp && yelpHasReviews">
              <div
                v-if="google"
                @click="toggleYelp"
                class="nav-link fs-14 text-dark lighter-hover"
                href="#"
                tabindex="-1"
                aria-disabled="true">
                Yelp
              </div>
              <div
                v-else
                @click="toggleYelp"
                class="nav-link text-dark border-bottom border-warning border-1 pb-1 color-text-dark fs-14"
                style="border-color: #b16bf8 !important"
                href="#"
                tabindex="-1"
                aria-disabled="true">
                Yelp
              </div>
            </li>
          </ul>

          <nav class="navbar navbar-expand-sm navbar-light bg-white border-bottom-0 p-0 mt-1">
            <div class="d-flex align-items-center m-0 p-0">
              <div class="align-left d-none d-sm-block">
                <a
                  v-if="google && business.google && googleHasReviews"
                  target="_blank"
                  :href="business.google.url"
                  class="card-link color-text-primary mt-3 fs-14 text-decoration-none hover"
                  >View in {{ service.name }}</a
                >
                <a
                  v-if="!google && business.yelp && yelpHasReviews"
                  target="_blank"
                  :href="business.yelp.url"
                  class="card-link color-text-primary mt-3 fs-14 text-decoration-none hover"
                  >View in {{ service2.name }}</a
                >
              </div>
            </div>

            <ul class="navbar-nav justify-content-end ms-auto align-items-center mb-0">
              <li class="nav-item">
                <a href="" class="nav-link">
                  <img
                    v-if="google && business.google && googleHasReviews"
                    v-bind:src="service.imageUrl"
                    class="rounded-circle mx-auto d-block pb-1"
                    style="height: 20px" />
                  <img
                    v-else-if="business.yelp && yelpHasReviews"
                    v-bind:src="service2.imageUrl"
                    class="rounded-circle mx-auto d-block pb-1"
                    style="height: 20px" />
                </a>
              </li>
            </ul>
            <div v-if="google && business.google && googleHasReviews">
              <h6 class="fw-bold fs-16 m-0 pb-1">{{ rating }}</h6>
            </div>
            <div v-else-if="business.yelp && yelpHasReviews">
              <h6 class="fw-bold fs-16 m-0 pb-1">{{ rating2 }}</h6>
            </div>
            <div
              v-if="google && business.google && googleHasReviews"
              class="color-star ps-3 pe-3 pb-1">
              <i v-if="rating < 1 && rating >= 0.5" class="bi bi-star-half"></i>
              <i v-else-if="rating < 0.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating < 2 && rating >= 1.5" class="bi bi-star-half"></i>
              <i v-else-if="rating < 1.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating < 3 && rating >= 2.5" class="bi bi-star-half"></i>
              <i v-else-if="rating < 1.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating < 4 && rating >= 3.5" class="bi bi-star-half"></i>
              <i v-else-if="rating < 3.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating < 5 && rating >= 4.5" class="bi bi-star-half"></i>
              <i v-else-if="rating < 4.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>
            </div>
            <div v-else-if="business.yelp && yelpHasReviews" class="color-star ps-3 pe-3 pb-1">
              <i v-if="rating2 < 1 && rating2 >= 0.5" class="bi bi-star-half"></i>
              <i v-else-if="rating2 < 0.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating2 < 2 && rating2 >= 1.5" class="bi bi-star-half"></i>
              <i v-else-if="rating2 < 1.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating2 < 3 && rating2 >= 2.5" class="bi bi-star-half"></i>
              <i v-else-if="rating2 < 1.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating2 < 4 && rating2 >= 3.5" class="bi bi-star-half"></i>
              <i v-else-if="rating2 < 3.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>

              <i v-if="rating2 < 5 && rating2 >= 4.5" class="bi bi-star-half"></i>
              <i v-else-if="rating2 < 4.5" class="bi bi-star"></i>
              <i v-else class="bi bi-star-fill"></i>
            </div>
          </nav>
          <div class="align-right">
            <p
              v-if="google && business.google && googleHasReviews"
              class="text-muted p-0 m-0 pe-3 fs-14">
              <span v-if="!isNaN(differenceGoogle)" class="me-3">
                <span
                  class="fs-12"
                  :class="{
                    'color-text-negative': differenceGoogle < 0,
                    'color-text-positive': differenceGoogle >= 0,
                  }">
                  {{ differenceGoogle >= 0 ? "+" : ""
                  }}{{
                    $n(Number(differenceGoogle), {
                      notation: "compact",
                      maximumFractionDigits: 1,
                      style: "decimal",
                    })
                  }}
                </span>
                <span class="fs-10 color-text-light-grey"> &nbsp;than previous</span>
              </span>

              <span> {{ business.google.reviews.length }} reviews </span>
            </p>
            <p
              v-if="!google && business.yelp && yelpHasReviews"
              class="text-muted p-0 m-0 pe-3 fs-14">
              <span v-if="!isNaN(differenceYelp)" class="me-3">
                <span
                  class="fs-12"
                  :class="{
                    'color-text-negative': differenceYelp < 0,
                    'color-text-positive': differenceYelp >= 0,
                  }">
                  {{ differenceYelp >= 0 ? "+" : ""
                  }}{{
                    $n(Number(differenceYelp), {
                      notation: "compact",
                      maximumFractionDigits: 1,
                      style: "decimal",
                    })
                  }}
                </span>
                <span class="fs-10 color-text-light-grey"> &nbsp;than previous</span>
              </span>

              <span> {{ business.yelp.reviews.length }} reviews </span>
            </p>
          </div>
        </div>

        <div class="container-fluid mt-3 p-0">
          <ul
            v-if="google && business.google"
            class="list-group overflow-auto"
            :class="enableEnigmaData && isAuthenticated ? 'list-group-xl' : ''">
            <li
              class="list-group-item border-0 py-0 mb-3"
              v-for="review in business.google.reviews"
              v-bind:key="review.id">
              <SingleReview
                :review="review"
                :businessId="business?.businessId"
                :showShuey="showShuey"></SingleReview>
            </li>
          </ul>
          <ul
            v-if="!google && business.yelp && business.yelp != null"
            class="list-group overflow-auto">
            <li
              class="list-group-item border-0 py-0 mb-3"
              v-for="review in business.yelp.reviews"
              v-bind:key="review.id">
              <SingleReview
                :review="review"
                :businessId="business?.businessId"
                :showShuey="showShuey"></SingleReview>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleReview from "./SingleReview.vue";
import { useUserStore } from "@/stores/UserStore";
import { ContentLoader } from "vue-content-loader";
import { computed } from "vue";
import environment from "@/services/environment";

export default {
  setup(props) {
    const loadSkeleton = computed(
      () =>
        props.loading &&
        ((props.business?.google?.reviews?.length ?? 0) <= 5 ||
          (props.business?.yelp?.reviews?.length ?? 0) == 0)
    );

    const enableEnigmaData = environment.isEnigmaDataEnabled();

    const userStore = useUserStore();
    const isAuthenticated = userStore.isAuthenticated;

    userStore.getUser();

    const showShuey = userStore.role === "admin" || userStore.role === "business-owner";

    const calculateDifference = (val, avg) => {
      if (avg == undefined || val == undefined || avg == null || val == null) {
        return undefined;
      }

      return val - avg;
    };

    const differenceGoogle = computed(() =>
      calculateDifference(
        props.business?.businessSelfComparison?.googleRating?.value,
        props.business?.businessSelfComparison?.googleRating?.previousValue
      )
    );
    const differenceYelp = computed(() =>
      calculateDifference(
        props.business?.businessSelfComparison?.yelpRating?.value,
        props.business?.businessSelfComparison?.yelpRating?.previousValue
      )
    );

    return {
      showShuey,
      loadSkeleton,
      enableEnigmaData,
      isAuthenticated,
      differenceGoogle,
      differenceYelp,
    };
  },

  props: ["business", "service", "service2", "loading"],
  components: {
    SingleReview,
    ContentLoader,
  },

  mounted() {
    this.calculateRating();
    this.calculateRating2();
    this.checkIfGoogleHasReviews();
    this.checkIfYelpHasReviews();
  },

  data() {
    return {
      google: true,
      rating: 0.0,
      rating2: 0.0,
      googleHasReviews: false,
      yelpHasReviews: false,
    };
  },

  methods: {
    toggleGoogle() {
      if (!this.google) {
        this.google = true;
      }
    },

    totalReviews() {
      let total = 0;
      if (this.business.google != null) {
        total += this.business.google.reviews.length;
      }
      if (this.business.yelp != null) {
        total += this.business.yelp.reviews.length;
      }
      return total;
    },

    checkIfGoogleHasReviews() {
      if (this.business.google == null) {
        this.googleHasReviews = false;
        this.google = false;
      }
      this.googleHasReviews = this.business?.google?.reviews?.length > 0;
      this.google = this.business?.google?.reviews?.length > 0;
    },

    checkIfYelpHasReviews() {
      if (this.business.yelp == null) {
        this.yelpHasReviews = false;
      }
      this.yelpHasReviews = this.business?.yelp?.reviews?.length > 0;
    },

    calculateRating() {
      if (this.business.google == null) {
        this.google = false;
        return;
      }
      let total = 0;
      this.business.google.reviews.forEach((review) => {
        total += review.rating;
      });
      var number = total / this.business.google.reviews.length;
      this.rating = Math.round(number * 10) / 10;
    },

    calculateRating2() {
      if (this.business.yelp == null) {
        return;
      }
      let total = 0;
      this.business.yelp.reviews.forEach((review) => {
        total += review.rating;
      });
      var number = total / this.business.yelp.reviews.length;
      this.rating2 = Math.round(number * 10) / 10;
    },

    toggleYelp() {
      if (this.google) {
        this.google = false;
      }
    },
  },
};
</script>

<style>
.list-group {
  height: 412px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.list-group-xl {
  height: 712px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7eced;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c8c6c6;
}
</style>
