<template>
  <div class="card border color-border h-100 card-mh-2">
    <div class="card-body position-relative">
      <div class="position-relative mb-5">
        <i
          v-if="!loadSkeleton"
          class="bi bi-geo circle-icon position-absolute color-text-primary top-0 end-0"
          :class="[this.googleWebsite ? 'color-text-primary hover-2' : 'text-muted']"
          @click="
            {
              this.googleWebsite ? openWindow(googleWebsite) : null;
            }
          "></i>
        <div class="me-4 pe-3 color-text-dark">
          <h6 class="card-subtitle mb-2 text-muted fs-10 fw-semi-bold font-spacing pt-1">
            ADDRESS
          </h6>
          <div v-if="loadSkeleton">
            <content-loader
              class="d-sm-block d-none pt-2"
              viewBox="0 0 170 40"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="10" />
              <rect x="0" y="15" rx="3" ry="3" width="120" height="10" />
              <rect x="0" y="30" rx="3" ry="3" width="60" height="10" />
            </content-loader>
            <content-loader
              class="d-sm-none d-block pt-2"
              viewBox="0 0 340 40"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="3" ry="3" width="200" height="10" />
              <rect x="0" y="15" rx="3" ry="3" width="240" height="10" />
              <rect x="0" y="30" rx="3" ry="3" width="120" height="10" />
            </content-loader>
          </div>
          <p v-else class="card-text m-0 fs-14 color-text-dark" style="white-space: pre">
            {{ addressFormatted }}
          </p>
        </div>
      </div>

      <a
        v-if="!loadSkeleton"
        :href="'https://www.google.com/maps/place/' + businessAddress.address"
        target="_blank"
        class="card-link color-text-primary text-decoration-none position-absolute mb-3 bottom-0 fs-14 hover"
        >View in Google Maps</a
      >
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  props: ["businessAddress", "googleWebsite", "loading"],
  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = props.loading && (!props.businessAddress?.address || !props.googleWebsite);
    return { loadSkeleton };
  },

  methods: {
    openWindow(url) {
      if (this.googleWebsite) window.open(url, "_blank");
    },
  },
  computed: {
    addressFormatted() {
      if (!this.businessAddress) {
        return this.businessAddress;
      }
      const numCommas = (this.businessAddress?.address.match(/,/g) || []).length;
      if (numCommas >= 3) {
        const divided = this.businessAddress.address.split(",");
        const address1 = divided[0].trim();

        let midAddress = divided[1].trim();
        for (let i = 2; i < divided.length - 1; i++) {
          midAddress += ", " + divided[i].trim();
        }
        const addressLast = divided[divided.length - 1].trim();

        return address1 + "\n" + midAddress + "\n" + addressLast;
      }
      return this.businessAddress;
    },
  },
};
</script>
<style></style>
