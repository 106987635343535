<template>
  <div v-if="isVisible" @click.stop="toggleFollow()">
    <div class="d-flex" :class="[disabled ? '' : 'hover']">
      <i
        :class="[
          followed ? 'bi-bookmark-check-fill' : 'bi-bookmark-check',
          additionalIconClasses,
          disabled ? 'text-muted' : 'color-text-primary',
        ]"
        class="bi align-items-center d-flex pe-1 fw-light"></i>

      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from "vue";
import { useFollowedBusinessesStore } from "@/stores/FollowedBusinessesStore.js";
import { useUserStore } from "@/stores/UserStore";

const props = defineProps(["businessId", "disabled", "additionalIconClasses"]);

const followedBusinessesStore = useFollowedBusinessesStore();
const userStore = useUserStore();

const isVisible = ref(userStore.isAuthenticated && userStore.role !== "business-owner");
const followed = ref(followedBusinessesStore.isFollowed(props.businessId));

const toggleFollow = () => {
  if (props.disabled) {
    return;
  }

  followed.value = !followed.value;
  if (followed.value) {
    followedBusinessesStore.follow(props.businessId);
  } else {
    followedBusinessesStore.unfollow(props.businessId);
  }
};
</script>
