import axios from "axios";
import keycloak from "./auth";
import router from "@/router";
import environment from "./environment";

const baseUrl = environment.getApiUrl();

export default (url = baseUrl) => {
  const http = axios.create({
    baseURL: url,
  });

  const token = keycloak.token;

  if (token) {
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 429) {
        console.log("Exceeded credits");
        router.push({ name: "Home" });
      } else {
        return Promise.reject(error);
      }
    }
  );

  return http;
};
