<template>
  <div class="card border color-border h-100 px-2">
    <div class="card-body pb-0">
      <h4
        class="card-title m-0 p-0 mb-1 color-text-dark fs-20"
        :class="
          titleWeight
            ? titleWeight == 'normal'
              ? 'fw-normal'
              : titleWeight == 'bold'
              ? 'fw-bold'
              : titleWeight == 'semi-bold'
              ? 'fw-semi-bold'
              : titleWeight == 'medium'
              ? 'fw-medium'
              : ''
            : ''
        ">
        {{ title }}
      </h4>
      <p v-if="subtitle" class="fs-14 text-muted">{{ subtitle }}</p>
      <div
        v-if="loading && (height || minHeight || maxHeight)"
        class="d-flex align-items-center justify-content-center"
        :style="[
          maxHeight ? { 'max-height': maxHeight + '!important' } : '',
          height ? { height: height + '!important' } : '',
          minHeight ? { 'min-height': minHeight + '!important' } : '',
        ]">
        <p class="fs-14 color-text-primary pb-5 mb-5">
          Loading applications
          <span
            class="align-middle spinner-border color-text-primary ms-2 mb-1"
            style="width: 1.2rem; height: 1.2rem"
            role="status"
            aria-hidden="true"></span>
        </p>
      </div>
      <div v-else-if="loading">
        <div class="d-none d-sm-block mb-3">
          <content-loader
            class="my-3"
            viewBox="0 0 1000 320"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="1000" height="320" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none">
          <content-loader
            class="my-3"
            viewBox="0 0 320 250"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="320" height="250" />
          </content-loader>
        </div>
      </div>
      <div
        v-else
        class="table-responsive mt-3 overflow-auto"
        :class="maxHeight ? 'mb-3' : 'pb-3'"
        :style="[
          maxHeight ? { 'max-height': maxHeight + '!important' } : '',
          height ? { height: height + '!important' } : '',
          minHeight ? { 'min-height': minHeight + '!important' } : '',
        ]">
        <div
          v-if="!tableData.length"
          class="d-flex justify-content-center align-items-center"
          :style="[height ? { height: '90% !important' } : '']">
          <p class="fs-14 text-muted">No data available</p>
        </div>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th
                class="fs-12 fw-normal text-muted pb-0"
                v-for="column in headerRow.header"
                scope="col"
                :key="column">
                <p
                  class="m-0"
                  :class="
                    column.align
                      ? {
                          'text-start': column.align === 'left',
                          'text-end': column.align === 'right',
                          'text-center': column.align === 'center',
                        }
                      : ''
                  ">
                  {{ column.value }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tableRows" :key="row">
              <th
                style="vertical-align: middle"
                class="fs-12"
                :class="[
                  column.align
                    ? {
                        'text-start': column.align === 'left',
                        'text-end': column.align === 'right',
                        'text-center': column.align === 'center',
                      }
                    : '',
                  column.url ? 'cursor-pointer hover' : '',
                  column.fontWeight
                    ? {
                        'fw-bold': column.fontWeight === 'bold',
                        'fw-semi-bold': column.fontWeight === 'semi-bold',
                        'fw-normal': column.fontWeight === 'normal',
                        'fw-medium': column.fontWeight === 'medium',
                      }
                    : 'fw-normal',
                ]"
                :style="[
                  column.textColor ? { color: column.textColor + ' !important' } : '',
                  column.rowsMargin
                    ? {
                        'padding-bottom': column.rowsMargin,
                      }
                    : 'py-0',
                ]"
                @click="column.url ? openPage(column.url) : ''"
                v-for="column in row.data"
                :key="column">
                <div v-if="column.valueType === 'icon'">
                  <div style="float: right">
                    <i class="bi bi-circle-fill fs-30 position-relative" style="color: #f7f8fd">
                      <i
                        class="bi bi-circle-fill fs-16 position-absolute top-50 start-50 translate-middle"
                        :style="{ color: column.color + ' !important' }"></i
                    ></i>
                  </div>
                </div>
                <div v-else-if="column.valueType === 'rating'">
                  <div v-if="column.value && typeof column.value === 'number'">
                    <div class="color-star fs-16 d-lg-block d-none" style="padding-bottom: 1px">
                      <i v-if="column.value < 1 && column.value >= 0.5" class="bi bi-star-half"></i>
                      <i v-else-if="column.value < 0.5" class="bi bi-star"></i>
                      <i v-else class="bi bi-star-fill"></i>

                      <i v-if="column.value < 2 && column.value >= 1.5" class="bi bi-star-half"></i>
                      <i v-else-if="column.value < 1.5" class="bi bi-star"></i>
                      <i v-else class="bi bi-star-fill"></i>

                      <i v-if="column.value < 3 && column.value >= 2.5" class="bi bi-star-half"></i>
                      <i v-else-if="column.value < 1.5" class="bi bi-star"></i>
                      <i v-else class="bi bi-star-fill"></i>

                      <i v-if="column.value < 4 && column.value >= 3.5" class="bi bi-star-half"></i>
                      <i v-else-if="column.value < 3.5" class="bi bi-star"></i>
                      <i v-else class="bi bi-star-fill"></i>

                      <i v-if="column.value < 5 && column.value >= 4.5" class="bi bi-star-half"></i>
                      <i v-else-if="column.value < 4.5" class="bi bi-star"></i>
                      <i v-else class="bi bi-star-fill"></i>
                    </div>
                    <div class="d-md-block d-lg-none">
                      <span>
                        {{ Math.round(column.value * 10) / 10 }}
                        <i class="bi bi-star-fill color-star"></i
                      ></span>
                    </div>
                  </div>
                  <div v-else>N/A</div>
                </div>

                <div v-else-if="column.valueType === 'pill'">
                  <span
                    class="py-2 my-1 badge rounded-pill color-background-grey card-text p-0 m-0 fs-12 fw-medium text-wrap"
                    :class="[
                      column.fontWeight
                        ? {
                            'fw-bold': column.fontWeight === 'bold',
                            'fw-semi-bold': column.fontWeight === 'semi-bold',
                            'fw-normal': column.fontWeight === 'normal',
                            'fw-medium': column.fontWeight === 'medium',
                          }
                        : 'fw-normal',
                      column.xPadding
                        ? {
                            'px-1': column.xPadding === 1,
                            'px-2': column.xPadding === 2,
                            'px-3': column.xPadding === 3,
                            'px-4': column.xPadding === 4,
                            'px-5': column.xPadding === 5,
                          }
                        : 'px-2',
                    ]"
                    :style="[
                      column.color ? { backgroundColor: column.color + ' !important' } : '',
                      column.textColor
                        ? { color: column.textColor + ' !important' }
                        : { color: '#0f2744 !important' },
                      column.width ? { width: column.width + ' !important' } : '',
                    ]">
                    {{ column.value }}
                  </span>
                </div>

                <div v-else-if="column.valueType === 'currency'">
                  <p class="my-2 color-text-primary fw-semi-bold">
                    {{ column.value }}
                  </p>
                </div>
                <div v-else-if="column.valueType === 'email'">
                  <a
                    v-if="column.value && column.value.length > 0"
                    :href="'mailto:' + column.value"
                    class="my-2 color-text-primary fw-semi-bold text-decoration-none text-break">
                    {{ column.value }}
                  </a>
                  <p v-else class="my-2">-</p>
                </div>
                <div v-else>
                  <p class="my-2">{{ column.value }}</p>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
        <nav v-if="pagination && tableData.length" class="center" aria-label="Page navigation">
          <ul class="pagination justify-content-center mt-3">
            <li class="page-item">
              <button
                type="button"
                class="page-link border-0"
                @click="previousPage()"
                href="#"
                aria-label="Previous">
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-left icon-bold rounded text-muted"></i
                ></span>
              </button>
            </li>
            <li v-for="n in totalPages" class="page-item ms-1" :key="n">
              <a
                class="page-link border-0 fs-14 rounded"
                :class="
                  currentPage === n ? 'color-background-primary text-white' : 'color-text-primary'
                "
                @click="goToPage(n)"
                href="#"
                >{{ n }}</a
              >
            </li>

            <li class="page-item ms-1">
              <button
                type="button"
                class="page-link border-0 ms-1"
                style=""
                @click="nextPage()"
                href="#"
                aria-label="Next">
                <span aria-hidden="true"
                  ><i class="bi bi-chevron-right icon-bold text-muted rounded"></i
                ></span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { ContentLoader } from "vue-content-loader";

export default {
  props: {
    title: {
      type: String,
      default: "Generic Comparison Table",
    },
    titleWeight: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    tableData: {
      // example: [{header: [{ value: '' },{ value: 'Name', align: 'left' },{ value: 'Avg Rating' },{ value: 'Website Users' },{ value: 'IG Followers' },{ value: 'Customer Sentiment' },{ value: 'FB Followers' },{ value: 'FB Likes' },],},{data: [{ valueType: 'icon', color: '#C190F3' },{ value: 'Business 1', valueType: 'basic', align: 'left' },{ value: 4.5, valueType: 'rating' },{ value: 3198, valueType: 'pill' },{ value: 1321, valueType: 'pill' },{ value: '56% Positive ', valueType: 'pill', color: '#E1FFEB' },{ value: 1423, valueType: 'pill' },{ value: 2313, valueType: 'pill' },],},]
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: String,
    },
    height: {
      type: String,
    },
    minHeight: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },

  components: {
    ContentLoader,
  },

  setup(props, { emit }) {
    const headerRow = computed(() => {
      return props.tableData[0] ? props.tableData[0] : [];
    });

    const tableRows = computed(() => {
      return props.tableData?.slice(1);
    });

    const openPage = (url) => {
      window.open(url, "_blank");
    };

    const goToPage = (page) => {
      emit("goToPage", page);
    };

    const previousPage = () => {
      emit("previousPage");
    };

    const nextPage = () => {
      emit("nextPage");
    };

    return { headerRow, tableRows, openPage, goToPage, previousPage, nextPage };
  },
};
</script>

<style></style>
