<template>
  <div class="card border color-border h-100 card-mh px-2">
    <div class="card-body">
      <h4 class="card-title mb-3 color-text-dark fs-20">Customer Sentiment</h4>
      <div v-if="loadSkeleton">
        <content-loader
          viewBox="0 0 560 100"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="3" ry="3" width="40" height="15" />
          <rect x="330" y="0" rx="3" ry="3" width="40" height="15" />
          <rect x="460" y="0" rx="3" ry="3" width="40" height="15" />

          <rect x="0" y="20" rx="3" ry="3" width="300" height="20" />
          <rect x="305" y="20" rx="3" ry="3" width="90" height="20" />
          <rect x="400" y="20" rx="3" ry="3" width="160" height="20" />

          <rect x="0" y="45" rx="3" ry="3" width="60" height="15" />
          <rect x="305" y="45" rx="3" ry="3" width="57" height="16" />
          <rect x="400" y="45" rx="3" ry="3" width="56" height="19" />

          <rect x="430" y="75" rx="3" ry="3" width="140" height="19" />
        </content-loader>
      </div>
      <div v-else>
        <table class="table mb-1">
          <tbody>
            <tr>
              <td
                class="p-0 fs-14 fw-half-bold border-end border-white border-3 color-text-dark font-spacing-sm"
                :style="{
                  width: customerSentiment.positiveReviews + '%',
                }">
                {{ roundedDecimal(customerSentiment.positiveReviews) }}%
              </td>
              <td
                class="p-0 fs-14 fw-half-bold border-end border-white border-3 color-text-dark font-spacing-sm"
                :style="{
                  width: customerSentiment.neutralReviews + '%',
                }">
                {{ roundedDecimal(customerSentiment.neutralReviews) }}%
              </td>
              <td
                class="p-0 border-0 fs-14 fw-half-bold color-text-darkfont-spacing-sm"
                :style="{
                  width: customerSentiment.negativeReviews + '%',
                }">
                {{ roundedDecimal(customerSentiment.negativeReviews) }}%
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table m-0 mb-2">
          <tbody>
            <tr>
              <td
                class="color-bar-positive border-end border-white border-3 rounded-bottom-left-1 rounded-top-left-1"
                :style="{
                  width: customerSentiment.positiveReviews + '%',
                }"></td>
              <td
                class="color-bar-neutral"
                :style="{
                  width: customerSentiment.neutralReviews + '%',
                }"></td>
              <td
                class="color-bar-negative border-start border-white border-3 rounded-bottom-right-1 rounded-top-right-1"
                :style="{
                  width: customerSentiment.negativeReviews + '%',
                }"></td>
            </tr>
          </tbody>
        </table>
        <table class="table mt-1">
          <tbody>
            <tr>
              <td
                class="p-0 fs-12 color-text-dark border-end border-white border-3"
                :style="{
                  width: customerSentiment.positiveReviews + '%',
                }">
                Positive
              </td>
              <td
                class="p-0 fs-12 color-text-dark border-end border-white border-3"
                :style="{
                  width: customerSentiment.neutralReviews + '%',
                }">
                Neutral
              </td>
              <td
                class="p-0 fs-12 border-0 color-text-dark"
                :style="{
                  width: customerSentiment.negativeReviews + '%',
                }">
                Negative
              </td>
            </tr>
          </tbody>
        </table>
        <div class="align-right ms-2 color-text-dark fs-12 d-none d-sm-block">Total responses</div>
        <div
          class="align-right color-text-dark fw-bold fs-12 d-none d-sm-block"
          v-if="customerSentiment">
          {{ customerSentiment.totalReviews }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { computed } from "vue";

export default {
  props: ["customerSentiment", "loading"],

  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = computed(
      () => props.loading && (props.customerSentiment?.totalReviews ?? 0) <= 8
    );
    return { loadSkeleton };
  },

  methods: {
    roundedDecimal(value) {
      return Math.round(value * 10) / 10;
    },
  },

  data() {
    return {};
  },
};
</script>

<style></style>
