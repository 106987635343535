<template>
  <div class="mx-3">
    <div class="d-flex align-items-center">
      <span
        v-if="user === 'assistant'"
        class="rounded-circle p-2 mirror-rounded-purple badge border-0 w-0 fs-20">
        <i class="bi bi-robot color-text-dark"></i>
      </span>
      <span v-if="user === 'assistant'" class="text-white fw-semi-bold ms-2 color-text-dark fs-14"
        >Shuey</span
      >
    </div>
    <div class="d-flex" :class="user === 'user' ? 'justify-content-end' : ''">
      <div
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        class="border-0 p-2 mt-1 fs-14 px-2 text-break position-relative"
        @click="copy(user)"
        :class="user === 'user' ? 'ms-5 mirror-rounded' : 'me-5 mirror-rounded-purple chat-hover'"
        style="max-width: fit-content; white-space: pre-wrap">
        {{ this.message }}
        <div
          v-if="user === 'assistant' && hover"
          class="position-absolute top-0 end-0 m-2"
          style="right: 0; bottom: 0">
          <span
            class="badge badge-pill p-2 fs-14 text-light"
            style="background-color: rgba(40, 40, 40, 0.7); border-radius: 33%"
            ><i class="bi bi-clipboard"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  props: ["message", "user"],

  data() {
    return {
      hover: false,
    };
  },

  methods: {
    copy(user) {
      if (user === "user") {
        return;
      }
      navigator.clipboard.writeText(this.message);
      useToast()("Copied to clipboard!", {
        timeout: 1500,
        toastClassName: "shubox-toast",
      });
    },
  },
};
</script>

<style></style>
