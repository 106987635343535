<template>
  <LoanApplicationFormContainer>
    <div class="row mx-md-5 mx-sm-4 mx-2">
      <p class="fs-18 fw-bold mt-4 pt-2 mb-3">Before you start</p>
      <p class="fs-14 mb-4" style="opacity: 0.8">
        Choose if you want to claim your business in shubox and create an account, or to proceed
        without an account
      </p>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <button
              type="button"
              class="btn fs-14 fw-semi-bold py-2"
              :class="selected === 'claim' ? 'sb-select-enabled' : 'sb-select-disabled'"
              @click="selected = 'claim'"
              style="width: 100%">
              Claim your business
            </button>
          </div>
          <div class="col-md-6 mt-md-0 mt-3">
            <button
              type="button"
              class="btn fs-14 fw-semi-bold py-2"
              :class="selected != 'claim' ? 'sb-select-enabled' : 'sb-select-disabled'"
              @click="selected = 'no-account'"
              style="width: 100%">
              Continue without account
            </button>
          </div>
        </div>
        <p class="fs-16 fw-bold mt-4 mb-2">What you get</p>
        <div class="ms-2">
          <div v-if="selected === 'claim'">
            <div class="d-flex align-items-center my-md-0 my-2">
              <i class="bi bi-check2-circle fs-22 color-text-primary"></i>
              <p class="fs-14 fw-semi-bold mb-0 ms-2">Streamline your loan application process</p>
            </div>
            <div class="d-flex align-items-center my-md-0 my-2">
              <i class="bi bi-check2-circle fs-22 color-text-primary"></i>
              <p class="fs-14 fw-semi-bold mb-0 ms-2">
                Monitor your business performance & Insights
              </p>
            </div>
            <div class="d-flex align-items-center my-md-0 my-2">
              <i class="bi bi-check2-circle fs-22 color-text-primary"></i>
              <p class="fs-14 fw-semi-bold mb-0 ms-2">Leverage Shuey, your business AI co-pilot</p>
            </div>
            <div class="d-flex align-items-center my-md-0 my-2">
              <i class="bi bi-check2-circle fs-22 color-text-primary"></i>
              <p class="fs-14 fw-semi-bold mb-0 ms-2">
                Use AI to generate responses to customer reviews
              </p>
            </div>
          </div>
          <div v-else class="d-flex align-items-center my-md-0 my-2">
            <i class="bi bi-check2-circle fs-22 color-text-primary"></i>
            <p class="fs-14 fw-semi-bold mb-0 ms-2">
              Submit your loan application to alternative lenders
            </p>
          </div>
        </div>
        <p class="fs-12 mt-3 mb-0">
          Already have an account?
          <a
            href="/"
            class="card-link text-decoration-none text-break hover fw-semi-bold color-text-primary">
            Login here</a
          >
        </p>
        <div class="d-flex justify-content-center mt-5 mb-5">
          <a
            @click="clickContinue"
            class="nav-link align-middle px-0 btn fs-14 fw-semi-bold px-3 py-2 rounded sb-btn-primary">
            Continue
          </a>
        </div>
      </div>
    </div>
  </LoanApplicationFormContainer>
</template>

<script>
import LoanApplicationFormContainer from "@/components/loan_application/LoanApplicationFormContainer.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";

export default {
  setup() {
    const router = useRouter();
    const loanApplicationStore = useLoanApplicationStore();

    const selected = ref("claim");

    const clickContinue = () => {
      if (selected.value !== "claim") {
        router.push({ name: "LoanApplicationNoAuth" });
        loanApplicationStore.clearWantsLoanApplication();
        return;
      }

      loanApplicationStore.setWantsLoanApplication();
      router.push({ name: "ClaimBusiness" });
    };

    return {
      selected,
      clickContinue,
    };
  },

  components: {
    LoanApplicationFormContainer,
  },
};
</script>
<style></style>
