<template>
  <div class="d-flex color-background col-12">
    <div class="input-group my-0" style="height: 36px">
      <span
        class="input-group-text fs-12 color-background-form border-2 py-2 ps-1 border-end-0 color-border"
        id="addon-wrapping-start">
        <i class="bi bi-search text-muted ms-2 ps-1 fs-12"></i>
      </span>
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search your business"
        class="form-control form-select-no-focus fs-12 color-background-form border-2 color-border py-2 ps-1 border-start-0 border-end-0"
        aria-label="Text input with segmented dropdown button"
        @focus="searchFocused = true"
        @focusout="searchFocused = false"
        @keyup.enter="routerPush()" />
      <select
        required
        id="inputState"
        aria-label="Select state"
        style="max-width: fit-content"
        v-model="currentState"
        class="form-select form-select-no-focus fs-12 color-background-form border-2 py-2 rounded-end border-start-0 color-border">
        <option value="">Select state</option>
        <option value="Alabama">Alabama</option>
        <option value="Alaska">Alaska</option>
        <option value="Arizona">Arizona</option>
        <option value="Arkansas">Arkansas</option>
        <option value="California">California</option>
        <option value="Colorado">Colorado</option>
        <option value="Connecticut">Connecticut</option>
        <option value="Delaware">Delaware</option>
        <option value="Florida">Florida</option>
        <option value="Georgia">Georgia</option>
        <option value="Hawaii">Hawaii</option>
        <option value="Idaho">Idaho</option>
        <option value="Illinois">Illinois</option>
        <option value="Indiana">Indiana</option>
        <option value="Iowa">Iowa</option>
        <option value="Kansas">Kansas</option>
        <option value="Kentucky">Kentucky</option>
        <option value="Louisiana">Louisiana</option>
        <option value="Maine">Maine</option>
        <option value="Maryland">Maryland</option>
        <option value="Massachusetts">Massachusetts</option>
        <option value="Michigan">Michigan</option>
        <option value="Minnesota">Minnesota</option>
        <option value="Mississippi">Mississippi</option>
        <option value="Missouri">Missouri</option>
        <option value="Montana">Montana</option>
        <option value="Nebraska">Nebraska</option>
        <option value="Nevada">Nevada</option>
        <option value="New Hampshire">New Hampshire</option>
        <option value="New Jersey">New Jersey</option>
        <option value="New Mexico">New Mexico</option>
        <option value="New York">New York</option>
        <option value="North Carolina">North Carolina</option>
        <option value="North Dakota">North Dakota</option>
        <option value="Ohio">Ohio</option>
        <option value="Oklahoma">Oklahoma</option>
        <option value="Oregon">Oregon</option>
        <option value="Pennsylvania">Pennsylvania</option>
        <option value="Rhode Island">Rhode Island</option>
        <option value="South Carolina">South Carolina</option>
        <option value="South Dakota">South Dakota</option>
        <option value="Tennessee">Tennessee</option>
        <option value="Texas">Texas</option>
        <option value="Utah">Utah</option>
        <option value="Vermont">Vermont</option>
        <option value="Virginia">Virginia</option>
        <option value="Washington">Washington</option>
        <option value="West Virginia">West Virginia</option>
        <option value="Wisconsin">Wisconsin</option>
        <option value="Wyoming">Wyoming</option>
      </select>
    </div>
    <span class="ms-3">
      <button
        @click="routerPush()"
        type="button"
        class="btn shadow-sm sb-btn-dark rounded px-3 fs-12"
        style="width: match-parent; height: 36px"
        :disabled="isQueryEmpty() || currentState === ''">
        Search
      </button>
    </span>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const currentState = ref("");
    const searchQuery = ref("");

    return {
      currentState,
      searchQuery,
    };
  },

  data() {
    return {
      searchFocused: false,
    };
  },

  methods: {
    setCurrentState(state) {
      this.currentState = state;
    },
    isQueryEmpty() {
      return this.searchQuery.trim().length < 3;
    },

    routerPush() {
      if (this.isQueryEmpty() || this.currentState === "") {
        return;
      }

      this.$router.push("/results/" + this.currentState + "/" + this.searchQuery).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>

<style></style>
