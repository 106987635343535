<template>
  <LoanApplicationFormContainer :tenant="tenant" :tenantId="tenantId" :grant="grant">
    <div class="row mx-sm-5 mx-2 d-flex align-items-center mt-5 mb-2">
      <div class="col-md-8">
        <div class="card-title fw-bold fs-18 mb-0">3. Business information</div>
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button @click="resetApplication()" class="btn sb-btn-secondary fs-12 fw-semi-bold">
          Restart application
        </button>
      </div>
    </div>
    <div class="card-body mt-2 pb-0 mx-sm-5 mx-2">
      <form @submit.prevent="$emit('changeTab', 'FormTab4')">
        <div class="row">
          <div v-if="(question = getQuestionObject('businessEmail'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Business email
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              type="email"
              autocomplete="email"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                  .businessEmail
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert your email"
              aria-label="Insert your email"
              autofocus />
          </div>
          <div v-if="(question = getQuestionObject('businessPhone'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Business phone number
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <div class="input-box p-0">
              <input
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                    .businessPhone
                "
                v-on:input="formatPhoneNumber()"
                type="text"
                :required="question.required"
                id="phoneNumber"
                placeholder="Insert phone number (000) 000-0000"
                autocomplete="tel"
                class="form-control fs-12 color-background-form border-2 color-border py-2"
                aria-label="Phone number" />
            </div>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('businessWebsite'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Business online shop
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            type="text"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .businessWebsite
            "
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            placeholder="https://www.example.com"
            aria-label="Business website" />
        </div>
        <div
          v-if="getQuestionObject('businessInstagram') || getQuestionObject('businessFacebook')"
          class="row">
          <div v-if="(question = getQuestionObject('businessFacebook'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Business facebook
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              type="text"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                  .businessFacebook
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert facebook id"
              aria-label="Business facebook" />
          </div>
          <div v-if="(question = getQuestionObject('businessInstagram'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Business instagram
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              type="text"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                  .businessInstagram
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert instagram id"
              aria-label="Business instagram" />
          </div>
        </div>
        <div
          v-if="
            getQuestionObject('businessTiktok') || getQuestionObject('businessOtherSocialMedia')
          "
          class="row">
          <div v-if="(question = getQuestionObject('businessTiktok'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Business tiktok
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              type="text"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                  .businessTiktok
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert tikTok id"
              aria-label="Business tiktok" />
          </div>
          <div
            v-if="(question = getQuestionObject('businessOtherSocialMedia'))"
            class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Business other social media
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              type="text"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                  .businessOtherSocialMedia
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="https://www.socialmedia.com/example"
              aria-label="Business other social media" />
          </div>
        </div>
        <div v-if="(question = getQuestionObject('businessYearsOpen'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            How many years has your business been open?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .yearsInBusiness
            "
            type="number"
            min="0"
            max="300"
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            placeholder="Insert the number of years"
            aria-label="Insert the number of years" />
        </div>
        <div v-if="(question = getQuestionObject('businessIncorporationDate'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            What is the date of incorporation or formation?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            type="date"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .dateOfFormation
            "
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            aria-label="Insert your date of birth (mm/dd/yyyy)" />
        </div>
        <div v-if="(question = getQuestionObject('businessNumberOfEmployees'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            How many employees does your organization have?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .numberOfEmployees
            "
            type="number"
            min="0"
            max="300"
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            placeholder="Insert the number of employees"
            aria-label="Insert the number of employees" />
        </div>
        <div v-if="(question = getQuestionObject('ein'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Employer Identification Number (EIN)
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .employerIdentificationNumber
            "
            type="text"
            v-on:input="this.formatEIN()"
            pattern=".{10}"
            title="Insert a valid EIN e.g. 12-3456789"
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            placeholder="Insert your business name"
            aria-label="Insert your business name" />
        </div>
        <div v-if="(question = getQuestionObject('businessIsForProfit'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Is this business a For-Profit business?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .isForProfit
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Is this business a For-Profit business?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('businessIsStartup'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Is this a startup?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .isStartup
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Is this a startup?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('businessIsOperating'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Is your business currently open and operating?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .isOperating
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Is your business currently open and operating?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('memberQueensChamberCommerce'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Is this business a member of the Queens Chamber of Commerce?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .memberQueensChamberCommerce
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Is your business currently open and operating?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('businessEntity'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Business entity
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                .businessEntity
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Select funds use">
            <option class="text-muted" value="SOLE_PROPRIETORSHIP">Sole proprietorship</option>
            <option class="text-muted" value="GENERAL_PARTNERSHIP">General partnership</option>
            <option class="text-muted" value="LIMITED_PARTNERSHIP">Limited partnership</option>
            <option class="text-muted" value="S_CORP">S-Corp</option>
            <option class="text-muted" value="C_CORP">C-Corp</option>
            <option class="text-muted" value="LLC">Limited liability company (LLC)</option>
            <option class="text-muted" value="LLP">Limite liability partnership (LLP)</option>
            <option class="text-muted" value="NON_PROFIT">Non profit</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('businessDescription'))" class="mt-4">
          <div class="shueyTextArea">
            <p class="fw-bold fs-14 ms-1 mb-2">
              {{ this.title }}
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <div class="textarea-container">
              <textarea
                :required="question.required"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                    .businessDescription
                "
                class="form-control fs-12 color-background-form border-2 color-border py-3"
                :disabled="loanApplicationStore.userLoanApplications[this.businessId].loading"
                rows="10"
                :placeholder="
                  loanApplicationStore.userLoanApplications[this.businessId].loading
                    ? ''
                    : 'Describe your business'
                "
                aria-label="Describe your business" />
              <a
                id="shuey-button"
                v-if="
                  this.authenticated &&
                  loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
                    .businessDescription === ''
                "
                @click="this.postUserMessage"
                class="d-flex align-items-center bg-transparent border-0 fs-14 color-text-primary fw-semi-bold mb-2">
                <div class="hover d-flex align-items-center mt-2 me-3">
                  <i class="bi bi-stars fs-20 pe-1 fw-light"></i>
                  <p class="d-none d-sm-block m-0 p-0">Fill with Shuey</p>
                </div>
              </a>
              <a
                class="mx-3 mt-2"
                id="wait-message"
                v-if="loanApplicationStore.userLoanApplications[this.businessId].loading">
                <div class="d-flex align-items-center">
                  <span class="rounded-circle p-2 mirror-rounded-purple badge border-0 w-0 fs-20">
                    <i class="bi bi-robot color-text-dark"></i>
                  </span>
                  <span class="text-white fw-semi-bold ms-2 color-text-dark fs-14">Shuey</span>
                </div>
                <div
                  class="border-0 p-2 px-3 mt-1 fs-14 px-2 text-break me-5 mirror-rounded-purple"
                  style="max-width: fit-content">
                  <div class="snippet" data-title="dot-typing">
                    <div class="stage">
                      <div class="dot-typing"></div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-5 mb-5">
          <button
            type="button"
            class="btn shadow-sm sb-btn-secondary rounded mt-4 mx-2"
            @click="$emit('changeTab', 'FormTab2')"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-semi-bold"> Previous </span></button
          ><button
            class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-semi-bold"> Next </span>
          </button>
        </div>
      </form>
    </div>
  </LoanApplicationFormContainer>
</template>

<script>
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import LoanApplicationFormContainer from "./LoanApplicationFormContainer.vue";

export default {
  props: ["businessId", "authenticated", "tenant", "tenantId", "grant", "questions"],

  setup(props, { emit }) {
    const loanApplicationStore = useLoanApplicationStore();

    const formatPhoneNumber = () => {
      var cleaned = (
        "" +
        loanApplicationStore.userLoanApplications[props.businessId].tab3BusinessInformation
          .businessPhone
      ).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        loanApplicationStore.userLoanApplications[
          props.businessId
        ].tab3BusinessInformation.businessPhone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      } else {
        loanApplicationStore.userLoanApplications[
          props.businessId
        ].tab3BusinessInformation.businessPhone = cleaned;
      }
    };

    const getQuestionObject = (name) => {
      const loanApplicationDetails = props.questions.LoanApplicationDetails;
      const loanApplicationOwnerDetails = props.questions.LoanApplicationOwnerDetails;
      const loanApplicationBusinessDetails = props.questions.LoanApplicationBusinessDetails;
      const loanApplicationFinancialDetails = props.questions.LoanApplicationFinancialDetails;

      return (
        loanApplicationDetails.find((item) => item.name === name) ||
        loanApplicationOwnerDetails.find((item) => item.name === name) ||
        loanApplicationBusinessDetails.find((item) => item.name === name) ||
        loanApplicationFinancialDetails.find((item) => item.name === name)
      );
    };

    const resetApplication = () => {
      emit("resetApplication");
    };

    return {
      loanApplicationStore,
      formatPhoneNumber,
      resetApplication,
      getQuestionObject,
    };
  },

  data() {
    return {
      title:
        "Describe your business. What products or services do you offer? What makes your business unique?",
      type: "businessDescription",
    };
  },

  mounted() {
    this.loanApplicationStore.userLoanApplications[this.businessId].loading = false;
  },

  methods: {
    postUserMessage() {
      const data = {
        industry:
          this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.industry,
        yearsInBusiness:
          this.loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
            .yearsInBusiness,
        address:
          this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
            .businessAddress,
        city: this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
          .businessCity,
      };
      return this.loanApplicationStore.answerToFormQuestion(
        data,
        this.title,
        this.type,
        this.businessId
      );
    },

    removeEverythingButNumbers(x) {
      return x.replace(/[^0-9]/g, "");
    },

    addDashToIndex(index, x) {
      if (x.length <= index) {
        return x;
      }
      return x.slice(0, index) + "-" + x.slice(index);
    },

    formatEIN() {
      this.loanApplicationStore.userLoanApplications[
        this.businessId
      ].tab3BusinessInformation.employerIdentificationNumber = this.addDashToIndex(
        2,
        this.removeEverythingButNumbers(
          this.loanApplicationStore.userLoanApplications[this.businessId].tab3BusinessInformation
            .employerIdentificationNumber
        )
      );
    },
  },

  components: {
    LoanApplicationFormContainer,
  },
  emits: ["changeTab"],
};
</script>

<style></style>
