<template>
  <div class="card border color-border bg-body rounded" style="width: 270px">
    <div @click="pushRoute(false)" class="card-body position-relative pointer">
      <div class="position-relative mb-5 pb-3">
        <p
          class="position-absolute color-text-negative top-0 end-0 fs-24 fw-bold"
          v-if="business && business.score">
          <score-text :score="business.score" :duplicate="!isAuthenticated"></score-text>
        </p>
        <p class="position-absolute text-muted top-0 end-0 fs-24 fw-bold" v-if="business.isNew">
          ?
        </p>

        <div class="me-4 pe-3">
          <p
            class="card-subtitle mb-2 text-muted text-uppercase fs-10 fw-semi-bold"
            v-if="business.info">
            {{ business.info.category }}
          </p>
          <p class="card-text m-0 fw-bold fs-14" v-if="business.info">
            {{ business.info.name }}
          </p>
          <p class="card-text m-0 fs-14" v-if="business.address">
            {{ business.address.city }}, {{ business.address.state }}
            {{ business.address.zipCode }}
          </p>
          <p class="card-text m-0 fs-14" v-if="business.address">
            {{ business.address.country }}
          </p>
        </div>
      </div>

      <span
        v-if="business.info"
        class="card-link color-text-primary text-decoration-none position-absolute mb-3 bottom-0 fs-14 hover">
        View more
      </span>
      <button class="icon-button position-absolute bottom-0 end-0" role="button">
        <track-business-button
          :businessId="business.id"
          :disabled="business.isNew"
          additionalIconClasses="position-absolute bottom-0 end-0 pe-3 mb-3 fs-24">
        </track-business-button>
      </button>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import TrackBusinessButton from "./TrackBusinessButton.vue";
import ScoreText from "./ScoreText.vue";
import { useFollowedBusinessesStore } from "@/stores/FollowedBusinessesStore";
import { useUserStore } from "@/stores/UserStore";

export default {
  setup() {
    const toast = useToast();

    const followedBusinessesStore = useFollowedBusinessesStore();
    const userStore = useUserStore();

    if (userStore.isAuthenticated) {
      followedBusinessesStore.getIds();
    }

    return {
      toast,
      isAuthenticated: userStore.isAuthenticated,
    };
  },

  props: ["business", "businessesSearchedIds", "signature"],

  data() {
    return {
      showFinancialRequestModal: false,
      filter: "Last 30 days",
    };
  },
  methods: {
    getFilter() {
      return this.filter;
    },
    setFilter(filter) {
      this.filter = filter;
    },
    parseDateFromLastSnapshot() {
      if (this.business.timestamp) {
        const date = new Date(this.business.timestamp);
        const month = date.toLocaleString("en-US", { month: "long" });
        const day = date.getDate();
        let hours = date.getHours();
        const amPm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minutes = date.getMinutes();
        minutes = minutes.toString().padStart(2, "0");
        return "Last updated at " + month + " " + day + ", " + hours + ":" + minutes + " " + amPm;
      }
      return "N/A";
    },
    splitAddress() {
      let address = this.business.address.address;
      let addressArray = address.split(",");
      let addressString = addressArray[1];
      for (let i = 2; i < addressArray.length; i++) {
        addressString += "," + addressArray[i];
      }
      return addressString;
    },
    async pushRoute(newTab) {
      this.showLoading = true;
      let id = this.business.id;
      if (this.business.isNew) {
        this.$router.push({
          name: "BusinessNewPage",
          params: {
            id: id,
          },
          query: {
            signature: this.business.signature,
            name: this.business.info.name,
            imageUrl: this.business.info.img,
            isInternalId: this.business.isInternalId ?? false,
          },
        });
        return;
      }
      if (newTab) {
        const routeData = this.$router.resolve({ name: "Business", params: { id } });
        this.showLoading = false;
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({ name: "Business", params: { id } });
      }
    },
  },
  components: { TrackBusinessButton, ScoreText },
};
</script>

<style></style>
