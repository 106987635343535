<template>
  <div class="mb-2 ms-2">
    <button
      @click="toggleFilters()"
      class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
      <div class="hover d-flex">
        <i class="bi bi-filter align-items-center d-flex fs-18 pe-1"></i>
        <span class="text-start">{{ showFilters ? "Hide filters" : "Show filters" }}</span>
      </div>
    </button>
  </div>
  <div class="p-2" :class="showFilters ? 'd-block' : 'd-none'">
    <ApplicationsFilterCard
      title="Applications Filter"
      :maxAmount="1000000"
      :tenantStatus="tenantStatus"
      :listOfObjects="dashboardLoanApplications?.items"
      :tenantList="tenantList"
      :programList="programs"
      @applicationsFiltersChanged="applicationsFiltersChanged"></ApplicationsFilterCard>
  </div>
  <div class="p-2">
    <GenericComparisonTable
      title="Applications"
      titleWeight="normal"
      height="75dvh"
      maxHeight="75dvh"
      minHeight="75dvh"
      :tableData="tableData"
      :loading="loading.applicationsTableLoading"
      :pagination="true"
      :currentPage="applicationsCurrentPage"
      :totalPages="applicationsTotalPages"
      @goToPage="goToPage"
      @previousPage="previousPage"
      @nextPage="nextPage"></GenericComparisonTable>
  </div>
</template>

<script>
import GenericComparisonTable from "@/components/benchmark_cards/GenericComparisonTable.vue";
import ApplicationsFilterCard from "@/components/loans_dashboard/ApplicationsFilterCard.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  props: {
    dashboardLoanApplications: { type: Object, default: () => {} },
    itemsPerPage: { type: Number, default: 25 },
    applicationsCurrentPage: { type: Number, default: 1 },
    applicationsTotalPages: { type: Number, default: 1 },
    loading: { type: Object, default: () => {} },
    tenantStatus: { type: Array, default: () => [] },
    tenantList: { type: Array, default: () => [] },
    programs: { type: Array },
  },

  setup(props, { emit }) {
    const { n, d } = useI18n();

    const statusColors = {
      START: "#BFE1FF",
      IN_PROGRESS: "#FFE7C2",
      UNSUCCESS: "#FFB4B4",
      SUCCESS: "#E1FFEB",
    };

    const maxAmount = computed(() => {
      if (!props.dashboardLoanApplications?.items?.length) {
        return 1000000;
      }

      return props.dashboardLoanApplications?.items.forEach((loan) => {
        return loan.loanAmount > maxAmount.value ? loan.loanAmount : maxAmount.value;
      });
    });

    const goToPage = (page) => {
      emit("goToPage", page);
    };

    const previousPage = () => {
      emit("previousPage");
    };

    const nextPage = () => {
      emit("nextPage");
    };

    const showFilters = ref(true);

    const toggleFilters = () => {
      showFilters.value = !showFilters.value;
    };

    const loans = computed(() => {
      return props.dashboardLoanApplications?.items ? props.dashboardLoanApplications.items : [];
    });

    const tableData = computed(() => {
      const tableData = [];

      if (!loans.value.length) {
        return tableData;
      }

      const header = {
        header: [
          { value: "Business", align: "left" },
          { value: "Shubox score" },
          { value: "Address" },
          { value: "Owner Name" },
          { value: "Submission" },
          { value: "Amount" },
          { value: "Assignee" },
          { value: "Status" },
        ],
      };
      tableData.push(header);
      loans.value.forEach((loan) => {
        const name = loan.businessName ? loan.businessName : "N/A";
        const shuboxScore = loan.shuboxScore ? loan.shuboxScore : "N/A";
        const shuboxScoreColor = loan.shuboxScore
          ? shuboxScore <= 24
            ? "#FF634E"
            : shuboxScore <= 37
            ? "#F09B1B"
            : "#16C79A"
          : "#0f2744";
        let address = loan.businessAddress ? loan.businessAddress : "N/A";
        let addressParts = address.split(",");
        addressParts.splice(-1);
        addressParts.splice(-2, 1);
        address = addressParts.join(",");
        const date = loan.submissionDate ? d(loan.submissionDate, "dateOnly") : "N/A";
        const amount = loan.loanAmount ? n(loan.loanAmount, "currencyNoCents") : "N/A";
        const status = loan.applicationStatus ? loan.applicationStatus : null;
        const ownerName = loan.ownerName ?? "N/A";
        const loanStatus = status
          ? props.tenantStatus.find((object) => object.statusCode === status) ?? null
          : null;
        const statusValue = loanStatus ? loanStatus.status : "N/A";
        const statusColor = loanStatus ? statusColors[loanStatus.type] : "#EDEDED";
        const businessId = loan.businessId ? loan.businessId : null;
        const businessUrl = businessId ? `/business/${businessId}` : null;
        const loanApplicationId = loan.loanApplicationId ? loan.loanApplicationId : null;
        const rowsMargin = "10px";
        const applicationUrl = loan.loanApplicationId
          ? `/loan-applications/${loanApplicationId}`
          : null;

        const assignee = loan.assignee ? loan.assignee.name : "-";
        const data = {
          data: [
            {
              value: name,
              valueType: "basic",
              fontWeight: "medium",
              rowsMargin: rowsMargin,
              url: applicationUrl,
            },
            {
              value: shuboxScore,
              valueType: "pill",
              textColor: shuboxScoreColor,
              fontWeight: "semi-bold",
              rowsMargin: rowsMargin,
              width: "36px",
              xPadding: 2,
              url: businessUrl,
            },
            {
              value: address,
              valueType: "basic",
              rowsMargin: rowsMargin,
              align: "left",
            },
            {
              value: ownerName,
              valueType: "basic",
              rowsMargin: rowsMargin,
              align: "center",
            },
            { value: date, valueType: "pill", xPadding: 2, rowsMargin: rowsMargin },
            { value: amount, valueType: "currency", rowsMargin: rowsMargin },
            {
              value: assignee,
              valueType: "basic",
              fontWeight: "medium",
              rowsMargin: rowsMargin,
            },
            {
              value: statusValue,
              valueType: "pill",
              color: statusColor,
              fontWeight: "semi-bold",
              width: "145px",
              rowsMargin: rowsMargin,
              xPadding: 3,
            },
          ],
        };
        tableData.push(data);
      });
      return tableData;
    });

    const applicationsFiltersChanged = (filters) => {
      emit("applicationsFiltersChanged", filters);
    };

    return {
      loans,
      tableData,
      goToPage,
      previousPage,
      nextPage,
      toggleFilters,
      showFilters,
      maxAmount,
      applicationsFiltersChanged,
    };
  },

  components: { GenericComparisonTable, ApplicationsFilterCard },
};
</script>

<style></style>
