<template>
  <div class="card border color-border h-100 px-2 parent">
    <div class="card-body pb-3">
      <div class="container m-0 p-0">
        <div class="d-flex justify-content-between">
          <p class="card-title m-0 p-0 mb-1 color-text-dark fs-20">{{ title }}</p>
          <button
            @click="applicationsFiltersChanged()"
            class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary bg-white">
            <div class="hover d-flex align-items-center">
              <i class="bi bi-arrow-clockwise d-flex fs-18 pe-1"></i>
              <span class="text-start d-sm-block d-none">Refresh applications</span>
            </div>
          </button>
        </div>
        <div class="row">
          <div class="mt-3 col-md-4">
            <label for="name" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Business name</label
            >
            <div class="input-group p-0">
              <span
                class="input-group-text fs-14 color-background-filter color-border-filter px-1 border-end-0 color-border"
                id="addon-wrapping-start">
                <i class="bi bi-search text-muted ms-2 fs-14"></i>
              </span>
              <input
                v-model="name"
                type="text"
                @keyup.enter="applicationsFiltersChanged()"
                @change="name.trim().length == 0 ? applicationsFiltersChanged() : null"
                placeholder="Search"
                class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
                aria-label="Name" />
            </div>
          </div>
          <div class="mt-3 col-md-4">
            <label for="startDate" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Start date</label
            >
            <div class="input-group p-0">
              <input
                v-model="startDate"
                @change="checkValidDate(startDate) ? applicationsFiltersChanged() : null"
                type="date"
                class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
                aria-label="startDate" />
            </div>
          </div>

          <div class="mt-3 col-md-4">
            <label for="endDate" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >End date</label
            >
            <div class="input-group p-0">
              <input
                v-model="endDate"
                type="date"
                @change="checkValidDate(endDate) ? applicationsFiltersChanged() : null"
                placeholder=""
                class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
                aria-label="endDate" />
            </div>
          </div>
          <div class="mt-3 col-md-4">
            <label for="status" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Application status</label
            >
            <div class="input-group p-0">
              <select
                class="form-select color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
                @change="applicationsFiltersChanged()"
                v-model="status"
                aria-label="Default select example">
                <option value="" selected>All status</option>
                <option
                  v-for="status in tenantStatus"
                  :key="status.statusCode"
                  :value="status.statusCode">
                  {{ status.status }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-md-4">
            <label for="status" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Assignee</label
            >
            <div class="input-group p-0">
              <select
                class="form-select color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
                @change="applicationsFiltersChanged()"
                v-model="assignedUserId"
                aria-label="Default select example">
                <option value="" selected>Any</option>
                <option v-for="user in tenantList" :key="user.id" :value="user.id">
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3 col-md-4">
            <label for="order" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Order by</label
            >
            <select
              class="form-select color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              v-model="order"
              @change="applicationsFiltersChanged()"
              aria-label="Default select example">
              <option class="fs-12 color-background-filter" value="1">Submission old to new</option>
              <option class="fs-12 color-background-filter" value="2">Submission new to old</option>
              <option class="fs-12 color-background-filter" value="3">Amount low to high</option>
              <option class="fs-12 color-background-filter" value="4">Amount high to low</option>
              <option class="fs-12 color-background-filter" value="5">Status A to Z</option>
              <option class="fs-12 color-background-filter" value="6">Status Z to A</option>
              <option class="fs-12 color-background-filter" value="7">
                Shubox score low to high
              </option>
              <option class="fs-12 color-background-filter" value="8">
                Shubox score high to low
              </option>
            </select>
          </div>
          <div class="mt-3 col-md-4">
            <label for="order" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Select program</label
            >
            <select
              class="form-select color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              v-model="programId"
              @change="applicationsFiltersChanged()"
              aria-label="Default select example">
              <option class="fs-12 color-background-filter" :value="0">All programs</option>
              <option v-for="program in programList" :key="program.id" :value="program.id">
                {{ program.name }}
              </option>
            </select>
          </div>
          <div class="mt-3 col-md-4">
            <label for="order" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
              >Owner Email</label
            >
            <input
              v-model="ownerEmail"
              type="text"
              @keyup.enter="applicationsFiltersChanged()"
              @change="ownerEmail.trim().length == 0 ? applicationsFiltersChanged() : null"
              placeholder="email@shubox.ai"
              class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              aria-label="Owner Email" />
          </div>
          <div class="mt-3 col-md-4 sb-slider">
            <label for="order" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium pb-5"
              >Amount range
            </label>
            <Slider
              class="slider mb-4 px-3"
              @end="applicationsFiltersChanged()"
              v-model="value"
              :min="minValue"
              :max="maxValue"
              :merge="merge"
              :format="format" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "@vue/reactivity";
import Slider from "@vueform/slider";
import { ref } from "vue";

export default {
  props: {
    title: { type: String, default: "Filter" },
    maxAmount: { type: Number, default: 1000000 },
    tenantStatus: { type: Array, default: () => [] },
    tenantList: { type: Array },
    programList: { type: Array },
  },

  setup(props, { emit }) {
    const minValue = ref(0);
    const maxValue = ref(props.maxAmount);
    const value = ref([minValue.value, maxValue.value]);
    const name = ref("");
    const ownerEmail = ref("");
    const startDate = ref("");
    const endDate = ref("");
    const status = ref("");
    const programId = ref(0);
    const assignedUserId = ref("");
    const order = ref(2);
    const merge = 999999999;
    const showEndDate = ref(false);

    const orderByMap = {
      1: { orderBy: "date", orderByDirection: "asc" },
      2: { orderBy: "date", orderByDirection: "desc" },
      3: { orderBy: "amount", orderByDirection: "asc" },
      4: { orderBy: "amount", orderByDirection: "desc" },
      5: { orderBy: "status", orderByDirection: "asc" },
      6: { orderBy: "status", orderByDirection: "desc" },
      7: { orderBy: "score", orderByDirection: "asc" },
      8: { orderBy: "score", orderByDirection: "desc" },
    };

    const format = {
      prefix: "$",
      decimals: 0,
      thousand: ",",
    };

    const filters = computed(() => {
      const filters = {
        businessName: name.value && name.value.length ? name.value.trim() : null,
        ownerEmail: ownerEmail.value && ownerEmail.value.length ? ownerEmail.value.trim() : null,
        startSubmissionDate:
          startDate.value && startDate.value.length
            ? new Date(startDate.value).toISOString()
            : null,
        endSubmissionDate:
          endDate.value && endDate.value.length ? new Date(endDate.value).toISOString() : null,
        status: status.value && status.value.length ? status.value : null,
        orderBy: orderByMap[order.value].orderBy, // ["date", "amount", "status", "score"
        orderByDirection: orderByMap[order.value].orderByDirection, // ["asc", "desc"]
        lowAmount: value.value && value.value[0] != minValue.value ? value.value[0] : null,
        highAmount: value.value && value.value[1] != maxValue.value ? value.value[1] : null,
        assignedUserId:
          assignedUserId.value && assignedUserId.value.length ? assignedUserId.value : null,
        programId: programId.value,
      };

      return filters;
    });

    const applicationsFiltersChanged = () => {
      emit("applicationsFiltersChanged", filters.value);
    };

    const checkValidDate = (date) => {
      if (date.length == 0) {
        return true;
      }
      if (date.length != 10) return false;
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      if (year < 1000 || month < 1 || month > 12 || day < 1 || day > 31) return false;
      return true;
    };

    return {
      value,
      minValue,
      maxValue,
      merge,
      format,
      name,
      ownerEmail,
      startDate,
      endDate,
      status,
      order,
      showEndDate,
      filters,
      assignedUserId,
      programId,
      applicationsFiltersChanged,
      checkValidDate,
    };
  },

  components: {
    Slider,
  },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
