<template>
  <div class="container-fuild mb-4">
    <div class="row">
      <div class="col-xl-3 col-lg-6 p-2">
        <CurrentBalanceCard :businessId="businessId" />
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <AverageDailyBalance :businessId="businessId" />
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <CashInOutCard :businessId="businessId" flow="in" />
      </div>
      <div class="col-xl-3 col-lg-6 p-2">
        <CashInOutCard :businessId="businessId" flow="out" />
      </div>

      <div class="col-12 p-2">
        <CashCategoriesCard :businessId="businessId" />
      </div>
    </div>
  </div>
</template>

<script>
import AverageDailyBalance from "./AverageDailyBalance.vue";
import CurrentBalanceCard from "./CurrentBalanceCard.vue";
import CashInOutCard from "./CashInOutCard.vue";
import CashCategoriesCard from "./CashCategoriesCard.vue";

export default {
  props: ["google", "yelp", "business", "businessId"],

  components: {
    CurrentBalanceCard,
    AverageDailyBalance,
    CashInOutCard,
    CashCategoriesCard,
  },
};
</script>

<style>
#a {
  color: #fff;
}
</style>
