<template>
  <div class="mx-3">
    <div class="d-flex align-items-center">
      <span class="rounded-circle p-2 mirror-rounded-purple badge border-0 w-0 fs-20">
        <i class="bi bi-robot color-text-dark"></i>
      </span>
      <span class="text-white fw-semi-bold ms-2 color-text-dark fs-14">Shuey</span>
    </div>
    <div
      class="border-0 p-2 px-3 mt-1 fs-14 px-2 text-break me-5 mirror-rounded-purple"
      style="max-width: fit-content">
      <div class="snippet" data-title="dot-typing">
        <div class="stage">
          <div class="dot-typing"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style></style>
