.
<template>
  <div class="card border color-border h-100" style="min-height: 180px">
    <div class="card-title fs-20 m-3 mb-0">Average reviews stars</div>
    <div class="card-body position-relative">
      <div class="d-flex align-items-center mb-1">
        <span class="fs-32 fw-semi-bold">{{ lastStarRating }} </span>
        <span v-if="this.lastStarRating" class="ms-2">
          <i
            v-if="this.lastStarRating < 1 && this.lastStarRating >= 0.5"
            class="bi bi-star-half color-star"></i>
          <i v-else-if="this.lastStarRating < 0.5" class="bi bi-star color-star"></i>
          <i v-else class="bi bi-star-fill color-star"></i>

          <i
            v-if="this.lastStarRating < 2 && this.lastStarRating >= 1.5"
            class="bi bi-star-half color-star"></i>
          <i v-else-if="this.lastStarRating < 1.5" class="bi bi-star color-star"></i>
          <i v-else class="bi bi-star-fill color-star"></i>

          <i
            v-if="this.lastStarRating < 3 && this.lastStarRating >= 2.5"
            class="bi bi-star-half color-star"></i>
          <i v-else-if="this.lastStarRating < 1.5" class="bi bi-star color-star"></i>
          <i v-else class="bi bi-star-fill color-star"></i>

          <i
            v-if="this.lastStarRating < 4 && this.lastStarRating >= 3.5"
            class="bi bi-star-half color-star"></i>
          <i v-else-if="this.lastStarRating < 3.5" class="bi bi-star color-star"></i>
          <i v-else class="bi bi-star-fill color-star"></i>

          <i
            v-if="this.lastStarRating < 5 && this.lastStarRating >= 4.5"
            class="bi bi-star-half color-star"></i>
          <i v-else-if="this.lastStarRating < 4.5" class="bi bi-star color-star"></i>
          <i v-else class="bi bi-star-fill color-star"></i
        ></span>
      </div>
      <div>
        <br />
        <canvas id="starsChart" style="max-height: 40px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapActions } from "pinia";
import { useBusinessesStore } from "@/stores/BusinessStore";

export default {
  props: ["businessId"],
  data() {
    return {
      reviews: [],
      dates: [],
    };
  },
  computed: {
    lastStarRating() {
      if (this.reviews.length) {
        return this.reviews[this.reviews.length - 1];
      }
      return 0;
    },
  },
  methods: {
    ...mapActions(useBusinessesStore, ["getReviewsAverageOverTime"]),

    async loadData() {
      const reviewsAndDates = await this.getReviewsAverageOverTime(this.businessId);
      this.reviews = reviewsAndDates.map((x) => this.$n(x.value, "decimal"));
      this.dates = reviewsAndDates.map((x) => this.$d(x.date, "monthAndYear"));

      // this.lastStarRating = this.calculatelastStarRating();

      const ctx = document.getElementById("starsChart");

      var data = {
        labels: this.dates,
        datasets: [
          {
            data: this.reviews,
            fill: false,
            cubicInterpolationMode: "monotone",

            borderColor: ["#f3b455"],
            borderWidth: 2,
          },
        ],
      };

      var options = {
        responsive: true,
        maintainAspectRatio: false,

        pointHitRadius: 30,

        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
