<template>
  <list-result-item @click="pushRoute(false)">
    <div v-if="business.isNew">
      {{ this.$parent.setAdvancedSearch() }}
    </div>
    <div class="col-lg-3 col-8 d-flex align-items-center px-1 ps-4 my-1">
      <img
        :src="business.info.img"
        width="32"
        height="32"
        class="rounded-circle border color-border-grey" />
      <p class="m-0 ms-3 fs-16 fw-semi-bold">
        {{ business.info.name }}
      </p>
    </div>
    <div class="col-lg-1 col-4 d-flex align-items-center px-lg-1 my-1 ps-4 d-none d-lg-block">
      <p class="fs-24 fw-bold m-0 my-1">
        <score-text
          v-if="business.score"
          :score="business.score"
          :duplicate="!isAuthenticated"></score-text>
        <span class="text-muted" v-else>{{ business.isNew ? "?" : "N/A" }}</span>
      </p>
    </div>
    <div class="col-lg-2 d-flex align-items-center lh-1 px-lg-1 my-1 ps-4">
      <div>
        <p class="m-0 p-0 fs-12 me-3" v-if="this.business.address && this.page === 'search'">
          {{ splitAddress() }}
        </p>
        <p class="m-0 p-0 fs-12" v-if="this.business.address && this.page !== 'search'">
          {{ this.business.address.city }},
          {{ this.business.address.state }}
          {{ this.business.address.zipCode }},
          {{ this.business.address.country }}
        </p>
        <a
          @click.stop
          :href="'https://www.google.com/maps/place/' + business.address.address"
          v-if="business.address.address"
          target="_blank"
          class="card-link color-text-primary m-0 text-decoration-none align-middle fs-12 hover">
          View maps
        </a>
        <p class="p-0 m-0 fs-12" v-else>N/A</p>
      </div>
    </div>
    <div class="col-xl-1 col-lg-2 d-flex align-items-center px-lg-1 my-1 ps-4">
      <p class="p-0 m-0 fs-12" v-if="business.info.category">{{ business.info.category }}</p>
      <p class="p-0 m-0 fs-12" v-else>N/A</p>
    </div>
    <div class="col-xl-5 col-lg-4 align-items-center px-lg-1 my-1 overflow-hidden">
      <div class="d-flex position-relative">
        <div class="justify-content-right">
          <div v-if="business.info.description.length === 0">
            <p>&nbsp;</p>
          </div>
          <div
            v-for="item in business.info.description"
            :key="item"
            class="align-left ms-1 me-1 p-0">
            <p
              v-if="indexOfCategory(item) < 3"
              class="p-2 me-1 my-1 badge rounded-pill wrap color-background-grey p-0 m-0 fs-12 fw-normal color-text-dark">
              {{ item }}
            </p>
          </div>
        </div>
        <div
          class="d-flex align-items-center align-right justify-content-end position-absolute bottom-0 end-0 me-2 color-background-transparent">
          <button
            @click.stop="pushRoute(true)"
            class="card-link color-text-primary m-0 p-0 ps-3 py-2 color-background-transparent border-0 fs-14 pe-3 d-none d-xl-block hover">
            View more
          </button>
          <button class="icon-button" role="button">
            <track-business-button
              :businessId="business.id"
              :disabled="business.isNew"
              additionalIconClasses="fs-24 ms-2 me-2">
            </track-business-button>
          </button>
        </div>
      </div>
    </div>
  </list-result-item>

  <LoadingPopover
    :visible="showLoading"
    title="Loading Business"
    message="Please wait while we load the data.">
  </LoadingPopover>
</template>

<script>
import LoadingPopover from "./LoadingPopover.vue";
import ListResultItem from "@/components/ListResultItem.vue";
import ScoreText from "./ScoreText.vue";
import { useUserStore } from "@/stores/UserStore";
import TrackBusinessButton from "./TrackBusinessButton.vue";

export default {
  props: ["business", "page", "newResult", "businessesSearchedIds", "signature"],

  setup() {
    const userStore = useUserStore();
    return { isAuthenticated: userStore.isAuthenticated };
  },

  data() {
    return {
      windowWidth: window.outerWidth,
      showLoading: false,
    };
  },

  methods: {
    splitAddress() {
      let address = this.business?.address?.address ?? null;

      if (address == null) {
        return "";
      }
      let addressArray = address.split(",");
      let addressString = addressArray[1];
      for (let i = 2; i < addressArray.length; i++) {
        addressString += "," + addressArray[i];
      }
      return addressString;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    indexOfCategory(category) {
      let index = -1;
      let arr = this.business.info.description;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === category) {
          index = i;
        }
      }
      return index;
    },

    async pushRoute(newTab) {
      this.showLoading = true;

      let id = this.business.id;

      if (this.business.isNew) {
        this.$router.push({
          name: "BusinessNewPage",
          params: {
            id: id,
          },
          query: {
            signature: this.business.signature,
            name: this.business.info.name,
            imageUrl: this.business.info.img,
            isInternalId: this.business.isInternalId ?? false,
          },
        });
        return;
      }

      if (newTab) {
        const routeData = this.$router.resolve({ name: "Business", params: { id } });
        this.showLoading = false;
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({ name: "Business", params: { id } });
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  components: {
    LoadingPopover,
    ListResultItem,
    TrackBusinessButton,
    ScoreText,
  },
};
</script>

<style>
.col-grid {
  width: 20%;
}

.grid-16-col-3 {
  width: 18.75%;
}

.grid-16-col-1 {
  width: 6.25%;
}

.grid-16-col-2 {
  width: 12.5%;
}
</style>
