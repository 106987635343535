<template>
  <div class="card border color-border h-100 px-2">
    <div class="card-body pb-0">
      <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">{{ title }}</h4>
      <div class="table-responsive mt-3">
        <table class="table table-borderless">
          <tr v-for="n in numberOfRows" :key="n">
            <th scope="col">
              <p class="fs-12 fw-semi-bold text-dark ps-0" style="text-align: left">
                {{ columnOneRows[n - 1].label }}
              </p>
            </th>
            <th scope="col" :class="size === 'lg' ? 'pe-3 pe-xl-5' : 'pe-1 pe-xl-3'">
              <p
                class="p-2 px-3 badge rounded-pill text-dark fs-12"
                style="min-width: 100%"
                :class="
                  columnOneRows[n - 1].type === 'currency'
                    ? 'color-text-primary bg-white fw-semi-bold'
                    : 'color-background-grey fw-normal'
                "
                :style="{ 'background-color': columnOneRows[n - 1]?.color + ' !important' }">
                {{ columnOneRows[n - 1].value }}
              </p>
            </th>
            <th scope="col" :class="size === 'lg' ? 'ps-3 ps-xl-5' : 'ps-1 ps-xl-3'">
              <p
                class="fs-12 fw-semi-bold text-dark"
                :class="size === 'lg' ? 'ps-3' : 'ps-1'"
                style="text-align: left">
                {{ columnTwoRows[n - 1].label }}
              </p>
            </th>
            <th scope="col" :class="size === 'lg' ? 'pe-4' : 'pe-3'">
              <p
                class="p-2 px-3 badge rounded-pill text-dark fs-12"
                style="min-width: 100%"
                :class="
                  columnTwoRows[n - 1].type === 'currency'
                    ? 'color-text-primary bg-white fw-semi-bold'
                    : 'color-background-grey fw-normal'
                "
                :style="{ 'background-color': columnTwoRows[n - 1]?.color + ' !important' }">
                {{ columnTwoRows[n - 1].value }}
              </p>
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    columnOneRows: {
      type: Array,
      required: true,
    },
    columnTwoRows: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
  },

  setup(props) {
    const numberOfRows = ref(props.columnOneRows.length);
    return { numberOfRows };
  },
};
</script>

<style></style>
