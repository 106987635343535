<template>
  <div class="card border color-border h-100 px-2" style="min-height: 317px">
    <div class="card-body pb-0">
      <div class="container-fluid d-flex justify-content-between p-0">
        <div class="align-left">
          <h4 class="card-title m-0 p-0 mb-1 color-text-dark fs-20">Business Reviews</h4>
        </div>
      </div>
      <p class="text-muted mt-0 mb-3 fs-14">Comparing reviews count overtime</p>
      <div>
        <canvas
          class="mx-1 px-1"
          id="reviewsComparisonChart"
          style="height: 190px; min-height: 190px; max-height: 190px"></canvas>
      </div>
    </div>
    <div class="d-flex text-muted mt-3 pb-4 ms-5 align-items-center">
      <div class="fs-11 d-flex align-items-center">
        <span>
          <i class="color-text-chart-orange fs-8 bi bi-circle-fill me-1"></i>
        </span>
        Number of reviews
      </div>
      <!--       <div class="fs-11 ms-4 d-flex align-items-center">
        <span>
          <i class="color-text-cluster-mean fs-8 bi bi-circle-fill me-1"></i>
        </span>
        Average number of reviews
      </div> -->
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapActions } from "pinia";
import { useBusinessesStore } from "@/stores/BusinessStore";

export default {
  props: ["businessId"],
  methods: {
    ...mapActions(useBusinessesStore, ["getReviewsCountOverTime"]),

    async loadData() {
      const reviewsAndDates = await this.getReviewsCountOverTime(this.businessId);
      const reviews = reviewsAndDates.map((x) => x.value);
      const dates = reviewsAndDates.map((x) => this.$d(x.date, "monthAndYear"));

      const ctx = document.getElementById("reviewsComparisonChart").getContext("2d");

      var data = {
        labels: dates,
        datasets: [
          {
            label: "Number of reviews",
            data: reviews,
            fill: false,
            cubicInterpolationMode: "monotone",
            pointBackgroundColor: "#F09B1B",
            pointBorderColor: "#F09B1B",
            pointHoverBackgroundColor: "#F09B1B",
            pointHoverBorderColor: "#F09B1B",
            tension: 0.2,
            pointRadius: 2.6,
            pointHoverRadius: 6,
            borderColor: ["#F09B1B"],
            colors: ["#F09B1B"],
            borderWidth: 3,
            yAxisID: "y",
          },
        ],
      };

      var options = {
        maintainAspectRatio: false,
        responsive: true,

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 11,
                family: "Open Sans",
              },
            },
          },
          y: {
            suggestedMin: 0,
            // suggestedMax: 100,
            grid: {
              color: "#E6EAEE",
              borderDash: [5, 5],
              drawBorder: false,
              drawTicks: {
                display: false,
              },
            },
            pointLabels: {
              color: "#728095",
            },
            ticks: {
              color: "#728095",
              stepSize: 25,
              font: {
                size: 12,
                family: "Open Sans",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
