<template>
  <div
    v-if="
      benchmarkStore.loading ||
      (!benchmarkStore.errorReloading &&
        benchmarkStore.peersComparison[businessId] &&
        Object.keys(benchmarkStore.peersComparison[businessId]).length > 1)
    ">
    <SocialDataSection
      :peersComparison="benchmarkStore.peersComparison[businessId]"
      :peersScores="benchmarkStore.peersScoreComparison[businessId]"
      :peersReviewsCount="benchmarkStore.peersReviewsComparison[businessId]"
      :businessId="businessId"
      :loading="benchmarkStore.loading"
      class="mb-3"></SocialDataSection>
    <EstimatedCardTransactionSection
      v-if="
        benchmarkStore.peersExternalTransactionsComparison[businessId] || benchmarkStore.loading
      "
      :externalTransactionalInfo="externalTransactionalInfo"
      :peersExternalTransactionalInfo="
        benchmarkStore.peersExternalTransactionsComparison[businessId]
      "
      :loading="benchmarkStore.loading"
      class="mb-5"></EstimatedCardTransactionSection>
  </div>
  <div v-else>
    <p class="fs-14 text-muted text-center mt-4 mb-0">
      We are currently unable to provide a comparison with similar businesses for this business at
      this time.
    </p>
    <p class="fs-14 text-muted text-center mb-1">
      Please consider trying again later, or feel free to reach out to Shubox for assistance at
    </p>
    <a
      href="mailto: support@shubox.ai"
      class="fs-14 color-text-primary text-center"
      style="display: flex; justify-content: center"
      >support@shubox.ai</a
    >
  </div>
</template>

<script>
import SocialDataSection from "@/components/benchmark_cards/SocialDataSection.vue";
import EstimatedCardTransactionSection from "@/components/benchmark_cards/EstimatedCardTransactionSection.vue";
import { useBenchmarkStore } from "@/stores/BenchmarkStore";

export default {
  props: {
    externalTransactionalInfo: { type: Object, default: () => {} },
    businessId: {},
  },

  components: {
    SocialDataSection,
    EstimatedCardTransactionSection,
  },

  setup(props) {
    const benchmarkStore = useBenchmarkStore();

    const hasExternalTransactionalInfo =
      props.externalTransactionalInfo && Object.keys(props.externalTransactionalInfo).length > 0;

    benchmarkStore.getPeersData(props.businessId, hasExternalTransactionalInfo);

    return {
      benchmarkStore,
    };
  },
};
</script>

<style></style>
