<template>
  <MainLayout class="position-relative">
    <div v-if="business?.businessInfo" class="business">
      <BusinessNavbar
        :page="page"
        :business="business"
        :role="userStore.role"
        :hasFinancials="hasFinancials"
        :bankAccounts="bankAccounts"
        :showJourney="showJourney"
        @changePage="changePage" />
      <ShueyButton />
      <component
        :is="page"
        :businessId="businessId"
        :google="google"
        :yelp="yelp"
        :business="business"
        :hasFinancials="hasFinancials"
        :aois="aois"
        :pois="pois"
        :externalTransactionalInfo="externalTransactionalInfo"
        :externalInfo="externalInfo"
        @changePage="changePage"
        class="tab"></component>
    </div>
    <div v-else class="business">
      <div class="d-flex justify-content-center align-items-center mt-3">
        <p class="fs-14 color-text-primary">
          Loading business profile
          <span
            class="align-middle spinner-border color-text-primary ms-2 mb-1"
            style="width: 1.2rem; height: 1.2rem"
            role="status"
            aria-hidden="true"></span>
        </p>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";
import ProfileCards from "@/components/profile_cards/ProfileCards.vue";
import InsightsCards from "@/components/business_insights/InsightsCards.vue";
import FinancialCards from "@/components/profile_cards/FinancialCards.vue";
import BenchmarkCards from "@/components/benchmark_cards/BenckmarkCards.vue";
import JourneyCards from "@/components/journey_cards/JourneyCards.vue";
import { onMounted, ref, onUnmounted, computed } from "vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import { useRoute, useRouter } from "vue-router";
import BusinessNavbar from "@/components/BusinessNavbar.vue";
import { useUserStore } from "@/stores/UserStore";
import { useTourStore } from "@/stores/TourStore";
import { useBenchmarkStore } from "@/stores/BenchmarkStore";
import Analytics from "@/services/analytics.js";
import ShueyButton from "@/components/chat/ShueyButton.vue";
import { useReCaptcha } from "vue-recaptcha-v3";

import { useShepherd } from "vue-shepherd";
import { offset } from "@floating-ui/dom";
import environment from "@/services/environment";

export default {
  computed: {
    businessId() {
      return this.$route.params.id;
    },
  },

  setup() {
    const user = ref({});
    const business = ref({});
    const aois = ref({});
    const pois = ref({});
    const externalTransactionalInfo = ref({});
    const bankAccounts = ref({});
    const externalInfo = ref({});

    const route = useRoute();
    const router = useRouter();
    const hasFinancials = ref();
    const { executeRecaptcha, recaptchaLoaded, instance } = useReCaptcha();
    const userStore = useUserStore();
    const tourStore = useTourStore();
    const benchmarkStore = useBenchmarkStore();

    const isAdminOrLoanOfficer = userStore.role === "admin" || userStore.role === "loan-officer";

    const showJourney = computed(() => {
      return (
        isAdminOrLoanOfficer &&
        environment.isEnigmaDataEnabled() &&
        externalInfo?.value &&
        Object.keys(externalInfo?.value).length > 0
      );
    });

    const getRecaptchaToken = async (action) => {
      if (!userStore.isAuthenticated) {
        let retry = 0;
        while (retry < 3) {
          try {
            return await executeRecaptcha(action);
          } catch (e) {
            console.error("Could not get recaptcha token", e);
            retry++;
          }
        }
      }
    };

    const businessHasFinancials = async (id) => {
      try {
        const response = await ServiceAPI.businessHasTransactions(id);
        hasFinancials.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadBusiness = async (id) => {
      if (userStore.isAuthenticated) {
        try {
          const response = await ServiceAPI.getBusiness(id);
          business.value = response.data;
          Analytics.logViewBusiness(
            business.value.businessId,
            business.value.businessInfo?.name,
            business.value.address?.state
          );
        } catch (error) {
          console.log(error);
          router.replace({ name: "PageNotFound" });
        }
      } else {
        try {
          const businessDetailsToken = await getRecaptchaToken("business_details");
          const response = await ServiceAPI.getBusinessPublic(id, businessDetailsToken);
          business.value = response.data;
          duplicateScores();
          Analytics.logViewBusiness(
            business.value.businessId,
            business.value.businessInfo?.name,
            business.value.address?.state
          );
        } catch (error) {
          console.log(error);
          router.replace({ name: "PageNotFound" });
        }
      }
    };

    const loadExternalTransactionalInfo = async (id) => {
      if (userStore.isAuthenticated) {
        try {
          const response = await ServiceAPI.getExternalTransactionInfo(id);
          externalTransactionalInfo.value = response.data;
        } catch (error) {
          console.log(error);
        }
      }
    };

    const loadAllBankAccounts = async (id) => {
      try {
        const response = await ServiceAPI.getAllBankAccounts(id);
        bankAccounts.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadPeers = async (id) => {
      await benchmarkStore.getPeers(id);
    };

    const loadExternalInfo = async (id) => {
      if (userStore.isAuthenticated) {
        try {
          const response = await ServiceAPI.getExternalInfo(id);
          externalInfo.value = response.data;
        } catch (error) {
          console.log(error);
        }
      }
    };

    const duplicateScores = () => {
      business.value.score = business.value.score * 2;

      business.value.lastScores.forEach((score) => {
        score.score = score.score * 2;
      });
    };

    const tour = useShepherd({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "sb-tour",
      },
    });

    tour.on("complete", () => tourStore.toggleBusinessPageToured());

    tour.addStep({
      attachTo: { element: "#business-overview", on: "bottom" },
      text: "The <strong>Overview Page</strong> provides key business metrics at a glance.",
      buttons: [
        {
          text: "Next",
          action: tour.next,
        },
      ],
      floatingUIOptions: {
        middleware: [offset(20)],
      },
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 5,
      cancelIcon: {
        enabled: true,
      },
    });
    tour.addStep({
      attachTo: { element: "#shubox-score", on: "bottom" },
      text: "The <strong>Shubox score</strong> combines social and financial signals into an overall score. The score will be incomplete until the financials are connected.",
      buttons: [
        {
          text: "Next",
          action: tour.next,
        },
      ],
      floatingUIOptions: {
        middleware: [offset(15)],
        width: "max-content ",
      },
      modalOverlayOpeningRadius: 5,
      cancelIcon: {
        enabled: true,
      },
    });

    tour.addStep({
      attachTo: { element: "#business-insights", on: "bottom" },
      text: "This is the <strong>Business Insights Page</strong>. It will become visible as we gather more data points, so you can see changes over time.",
      buttons: [
        {
          text: "Next",
          action: tour.next,
        },
      ],
      floatingUIOptions: {
        middleware: [offset(20)],
        width: "max-content ",
      },
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 5,
      cancelIcon: {
        enabled: true,
      },
    });
    tour.addStep({
      attachTo: { element: ".business-connect-bank", on: "bottom" },
      text: "Complete the <strong>Shubox Score</strong> by connecting the financial data to have a better view of this business.",
      buttons: [
        {
          text: "Next",
          action: tour.next,
        },
      ],
      floatingUIOptions: {
        middleware: [offset(20)],
        width: "max-content ",
      },
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 5,
      cancelIcon: {
        enabled: true,
      },
    });

    if (userStore.role === "business-owner") {
      tour.addStep({
        attachTo: { element: ".start-loan-application", on: "right" },
        text: "Apply for a business loan with alternative lenders!",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        floatingUIOptions: {
          middleware: [offset(20)],
          width: "max-content ",
        },
        modalOverlayOpeningRadius: 5,
        cancelIcon: {
          enabled: true,
        },
      });
    }

    tour.addStep({
      attachTo: { element: ".sb-chatbot", on: "top" },
      text: "Meet <strong>Shuey</strong>, the AI personal assistant you can count on to monitor and run this business!",
      buttons: [
        {
          text: "Finish",
          action: tour.next,
        },
      ],
      floatingUIOptions: {
        middleware: [offset(20)],
        width: "max-content ",
      },
      modalOverlayOpeningRadius: 10,
      cancelIcon: {
        enabled: true,
      },
    });

    const loadAois = async (id) => {
      try {
        const response = await ServiceAPI.getBusinessAois(id);
        aois.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadPois = async (id) => {
      try {
        const response = await ServiceAPI.getBusinessPois(id);
        pois.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      const id = route.params.id;

      if (!userStore.isAuthenticated) {
        await recaptchaLoaded();
        instance.value.showBadge();
      }

      loadBusiness(id);

      if (userStore.isAuthenticated) {
        loadAois(id);
        loadPois(id);
        if (isAdminOrLoanOfficer) {
          loadExternalTransactionalInfo(id);
          loadAllBankAccounts(id);
          loadExternalInfo(id);
          await loadPeers(id);
        }

        await businessHasFinancials(id);

        if (!tourStore.isBusinessPageToured()) {
          tour.start();
        }
      }
    });

    onUnmounted(async () => {
      if (!userStore.isAuthenticated) {
        instance.value.hideBadge();
      }
    });

    return {
      user,
      business,
      hasFinancials,
      userStore,
      aois,
      pois,
      externalTransactionalInfo,
      externalInfo,
      showJourney,
      bankAccounts,
    };
  },

  components: {
    ProfileCards,
    BusinessNavbar,
    MainLayout,
    InsightsCards,
    FinancialCards,
    ShueyButton,
    BenchmarkCards,
    JourneyCards,
  },

  methods: {
    changePage(page) {
      this.page = page;
    },
  },

  data() {
    return {
      google: {
        name: "Google",
        imageUrl: "https://freesvg.org/img/1534129544.png",
      },

      yelp: {
        name: "Yelp",
        imageUrl: "https://cdn-icons-png.flaticon.com/512/174/174882.png",
      },
      pages: ["ProfileCards", "InsightsCards", "FinancialCards", "BenchmarkCards", "JourneyCards"],
      page: "ProfileCards",
    };
  },
};
</script>

<style></style>
