<template>
  <div class="card border color-border h-100" style="min-height: 162px">
    <div class="m-3 mb-0 d-flex justify-content-between">
      <span class="card-title fs-20">Cash Out Categories</span>
      <!--       <div class="dropdown">
        <p
          class="btn dropdown-toggle mb-0 pb-0 text-muted fs-12"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Last 90 days
          <i class="bi bi-chevron-down text-muted icon-bold-2 fs-10 ps-2"></i>
        </p>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button class="dropdown-item fs-10">Last 180 days</button>
          </li>
          <li>
            <button class="dropdown-item fs-10">Last 5 scores</button>
          </li>
          <li>
            <button class="dropdown-item fs-10">Last 10 scores</button>
          </li>
        </ul>
      </div> -->
    </div>
    <div class="card-body position-relative overflow-auto">
      <table class="table table-borderless overflow-auto">
        <thead>
          <tr>
            <th scope="col"><span class="fs-12 fw-normal">Name</span></th>

            <th scope="col" v-for="month in months" :key="month">
              <span class="fs-12 fw-normal">{{ monthLabel(month) }}</span>
            </th>

            <th scope="col"><span class="fs-12 fw-normal">% Total</span></th>
          </tr>
        </thead>
        <tbody>
          <tr class="align-middle" v-for="items in categorizedData" :key="items.category">
            <td>
              <span>
                <i class="bi color-text-positive me-2" :class="mapping[items.category]?.icon"></i>
                <span class="fs-12 fw-semi-bold">{{ mapping[items.category]?.text }}</span>
              </span>
            </td>
            <td v-for="item in items.values" :key="item">
              <span
                class="p-2 px-3 badge rounded-pill color-background-grey card-text fs-12 fw-semi-bold color-text-dark"
                >{{ $n(item.value, "currency") }}</span
              >
            </td>
            <td>
              <span class="fs-12 fw-semi-bold color-text-primary">{{
                $n(items.percentage, "percent")
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useBusinessesStore } from "@/stores/BusinessStore";
import { watch } from "vue";

export default {
  props: ["businessId"],

  data() {
    return {
      categorizedData: [],
      mapping: {
        GENERAL_SERVICES: {
          icon: "bi-wrench-adjustable-circle",
          text: "General services",
        },
        ENTERTAINMENT: {
          icon: "bi-joystick",
          text: "Entertainment",
        },
        GOVERNMENT_AND_NON_PROFIT: {
          icon: "bi-bank2",
          text: "Government & Non-profit",
        },
        TRANSFER_OUT: {
          icon: "bi-arrow-left-right",
          text: "Transfer out",
        },
        TRAVEL: {
          icon: "bi-airplane",
          text: "Travel",
        },
        TRANSFER_IN: {
          icon: "bi-arrow-left-right",
          text: "Transfer in",
        },
        RENT_AND_UTILITIES: {
          icon: "bi-house-gear",
          text: "Rents & Utilities",
        },
        INCOME: {
          icon: "bi-wallet2",
          text: "Income",
        },
        MEDICAL: {
          icon: "bi-prescription2",
          text: "Medical",
        },
        GENERAL_MERCHANDISE: {
          icon: "bi-bag",
          text: "General merchandise",
        },
        HOME_IMPROVEMENT: {
          icon: "bi-house-up",
          text: "Home improvement",
        },
        BANK_FEES: {
          icon: "bi-coin",
          text: "Bank fees",
        },
        FOOD_AND_DRINK: {
          icon: "bi-shop",
          text: "Food & Drinks",
        },
        LOAN_PAYMENTS: {
          icon: "bi-bank",
          text: "Loan payments",
        },
        TRANSPORTATION: {
          icon: "bi-taxi-front",
          text: "Transportation",
        },
        PERSONAL_CARE: {
          icon: "bi-person",
          text: "Personal care",
        },
      },
    };
  },
  computed: {
    months() {
      //nail to fix timezone issues
      return this.categorizedData[0]?.values?.map((x) => x.period + "T00:00:00.000-08:00") ?? [];
    },

    diffBetweenMonths() {
      if (this.months.length > 0) {
        const date1 = new Date(this.months[0]);
        const date2 = new Date(this.months[1]);
        const monthsDiff =
          (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
        return monthsDiff;
      }
      return 1;
    },
  },
  methods: {
    ...mapActions(useBusinessesStore, ["getBusinessCashOutCategories"]),
    async loadData() {
      this.categorizedData = await this.getBusinessCashOutCategories(this.businessId);
      this.categorizedData = this.categorizedData.sort((a, b) =>
        a.category.localeCompare(b.category)
      );

      this.categorizedData.forEach((category) => {
        category.values = category.values.sort((a, b) => a.period.localeCompare(b.period));
      });

      // if values length is 12 join every 2 values
      this.categorizedData.forEach((category) => {
        if (category.values.length === 12) {
          this.groupingNeededPeriod = 2;
          const newValues = [];
          for (let i = 0; i < category.values.length; i += 2) {
            newValues.push({
              period: category.values[i].period,
              value: category.values[i].value + category.values[i + 1].value,
            });
          }
          category.values = newValues;
        }
        if (category.values.length === 24) {
          this.groupingNeededPeriod = 4;
          const newValues = [];
          for (let i = 0; i < category.values.length; i += 4) {
            newValues.push({
              period: category.values[i].period,
              value:
                category.values[i].value +
                category.values[i + 1].value +
                category.values[i + 2].value +
                category.values[i + 3].value,
            });
          }
          category.values = newValues;
        }
      });
    },

    monthLabel(month) {
      if (this.diffBetweenMonths === 1) {
        const date = new Date(month);
        return date.toLocaleString("default", { month: "short", year: "numeric" });
      } else {
        const nextDate = new Date(month);
        nextDate.setMonth(nextDate.getMonth() + this.diffBetweenMonths - 1);
        return (
          new Date(month).toLocaleString("default", { month: "short" }) +
          " - " +
          nextDate.toLocaleString("default", { month: "short", year: "numeric" })
        );
      }
    },
  },
  created() {
    const businessStore = useBusinessesStore();

    watch(
      () => businessStore.period,
      () => {
        this.loadData();
      }
    );

    watch(
      () => businessStore.currentBankAccount,
      () => {
        this.loadData();
      }
    );
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style>
.table td {
  text-align: center;
}

.table td:first-child {
  text-align: left;
}

.table th {
  text-align: center;
}

.table th:first-child {
  text-align: left;
}
</style>
