<template>
  <div class="card border color-border h-100" style="min-height: 180px">
    <h4
      class="card-title m-3 mb-0"
      :class="
        titleWeight
          ? titleWeight == 'normal'
            ? 'fw-normal'
            : titleWeight == 'bold'
            ? 'fw-bold'
            : titleWeight == 'semi-bold'
            ? 'fw-semi-bold'
            : titleWeight == 'medium'
            ? 'fw-medium'
            : ''
          : ''
      "
      :style="{ fontSize: titleSize + 'px' }">
      {{ title }}
    </h4>
    <div class="card-body pt-0" v-if="loading">
      <div class="d-xl-none d-block px-2">
        <content-loader
          class="my-3"
          viewBox="0 0 500 120"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="6" ry="6" width="500" height="120" />
        </content-loader>
      </div>
      <div class="d-xl-block d-none">
        <content-loader
          class="my-3"
          viewBox="0 0 500 220"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="8" ry="8" width="500" height="220" />
        </content-loader>
      </div>
    </div>
    <div v-else class="card-body position-relative py-2">
      <div class="row align-items-center mb-1">
        <span class="col-auto fs-32 fw-semi-bold pe-0">
          {{
            !value
              ? "N/A"
              : valueType === "currencyNoCents"
              ? $n(value, "currencyNoCents")
              : valueType === "integer"
              ? $n(value, "integer")
              : valueType === "currency"
              ? $n(value, "currency")
              : valueType === "percentWithOneFractionDigit"
              ? $n(value, "percentWithOneFractionDigit")
              : value
          }}
        </span>
        <span
          v-if="variance && variance != 0.0 && isFinite(variance)"
          class="fs-18 col-auto text-start"
          :class="{
            'color-text-positive-strong': varianceColor() === 'positive',
            'color-text-neutral-strong': varianceColor() === 'neutral',
            'color-text-negative-strong': varianceColor() === 'negative',
          }">
          <div>
            <i
              v-if="variance"
              class="bi"
              :class="{
                'bi bi-plus-circle': variance >= 0 && iconStyle === 'plusMinus',
                'bi bi-dash-circle': variance < 0 && iconStyle === 'plusMinus',
                'bi-arrow-up-circle': variance >= 0,
                'bi-arrow-down-circle': variance < 0,
              }"></i>
            {{
              varianceType === "currencyNoCents"
                ? $n(varianceValue, "currencyNoCents")
                : varianceType === "integer"
                ? $n(varianceValue, "integer")
                : varianceType === "currency"
                ? $n(varianceValue, "currency")
                : varianceType === "percentWithOneFractionDigit"
                ? $n(varianceValue, "percentWithOneFractionDigit")
                : varianceValue
            }}
          </div>
          <div v-if="varianceCaption" class="fs-10 text-muted" style="margin-top: -4px">
            {{ varianceCaption }}
          </div>
        </span>
      </div>
      <div>
        <div class="d-flex justify-content-between">
          <span class="fs-10 fw-semi-bold text-muted my-0 font-spacing ms-1">{{ caption }}</span>
        </div>
        <canvas :id="graphIdValue" style="max-height: 54px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { computed, onMounted, onUpdated } from "vue";
import { ContentLoader } from "vue-content-loader";

export default {
  props: {
    title: {
      type: String,
    },
    titleSize: {
      type: Number,
      default: 16,
    },
    titleWeight: {
      type: String,
    },
    value: {
      type: Number,
    },
    variance: {
      type: Number,
    },
    valueType: {
      type: String,
    },
    varianceType: {
      type: String,
    },
    varianceCaption: {
      type: String,
    },
    caption: {
      type: String,
    },
    graphValues: {
      type: Array,
    },
    graphLabels: {
      type: Array,
    },
    graphId: {
      type: String,
    },
    graphColor: {
      type: String,
    },
    graphType: {
      type: String,
      default: "line",
    },
    iconStyle: {
      type: String,
    },
    colorInverted: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },

  components: {
    ContentLoader,
  },

  setup(props) {
    const dataValues = computed(() => {
      return props.graphValues ? props.graphValues : [];
    });
    const dataLabels = computed(() => {
      return props.graphLabels ? props.graphLabels : [];
    });
    const graphIdValue = props.graphId ? props.graphId : "myChart";

    const varianceValue = computed(() => {
      return props.variance && props.iconStyle === "plusMinus"
        ? Math.abs(props.variance)
        : props.variance;
    });

    const varianceColor = () => {
      if (props.variance === null || props.variance === undefined) return;
      if (
        (props.variance >= 0 && props.iconStyle === "plusMinus" && props.colorInverted === true) ||
        (props.variance < 0 && props.iconStyle === "plusMinus" && !props.colorInverted === true)
      ) {
        return "negative";
      } else if (
        (props.variance >= 0 && props.iconStyle != "plusMinus" && props.colorInverted === true) ||
        (props.variance < 0 && props.iconStyle != "plusMinus" && !props.colorInverted === true)
      ) {
        return "neutral";
      } else {
        return "positive";
      }
    };

    let theChart = null;

    const createGraph = () => {
      const data = {
        labels: dataLabels.value,
        datasets: [
          {
            data: dataValues.value,
            fill: false,
            cubicInterpolationMode: "monotone",

            borderColor: [props.graphColor],
            backgroundColor: [props.graphColor],
            borderRadius: 4,
            borderWidth: 2,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        pointHitRadius: 30,

        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 100,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      if (theChart != null) {
        theChart.destroy();
      }

      const ctx = document.getElementById(graphIdValue);
      theChart = new Chart(ctx, {
        type: props.graphType,
        data: data,
        options: options,
      });
    };

    onMounted(() => {
      if (props.graphId && props.graphValues && props.graphLabels && !props.loading) {
        createGraph();
      }
    });

    onUpdated(() => {
      if (props.graphId && props.graphValues && props.graphLabels && !props.loading) {
        createGraph();
      }
    });

    return {
      graphIdValue,
      varianceColor,
      varianceValue,
    };
  },
};
</script>

<style></style>
