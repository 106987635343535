<template>
  <div id="main-layout" class="main-layout" :class="isXl ? 'h-auto' : 'h-100'">
    <NavBar v-if="!userStore.isAuthenticated && !hideSidebar"></NavBar>
    <div class="container-fluid h-100">
      <div class="row justify-content-start flex-nowrap color-background h-100">
        <div class="d-flex col-auto p-0">
          <SideBar
            v-if="userStore.isAuthenticated && !hideSidebar"
            :class="open ? 'fixed-top shadow-sm' : 'd-none d-xl-block'"
            :user="userStore"
            style="z-index: 1"></SideBar>
          <span class="d-xl-none d-block">
            <nav
              v-if="userStore.isAuthenticated && !hideSidebar"
              class="navbar fixed-top d-flex align-items-center justify-content-between navbar-light bg-white border-bottom color-border py-2 shadow-sm"
              style="z-index: 2">
              <button
                class="fs-20 ms-2 me-2 border-0 hover-shadow text-decoration-none bg-white hover-2 py-1"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleOpen()">
                <i class="bi fs-28" :class="open ? 'bi-x' : 'bi-list'"></i>
              </button>
              <div class="text-center">
                <span>
                  <img src="../assets/shubox_black.svg" />
                </span>
              </div>
              <div style="padding-left: 56px"></div>
            </nav>
          </span>
        </div>
        <div
          class="col p-0 d-flex align-items-start mt-xl-0 pt-xl-0"
          :class="hideSidebar ? '' : 'mt-5  pt-3'"
          :style="open ? 'overflow-y: hidden' : ''"
          @click="open = false">
          <div
            class="container-xxl px-md-5 px-sm-4 px-3 pt-4 color-background"
            :class="open ? 'dim' : ''"
            :style="open ? 'min-height: 100vh' : ''">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  props: {
    hideSidebar: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const userStore = useUserStore();

    const open = ref(false);
    const isXl = ref(window.innerWidth >= 1200);

    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        open.value = false;
        isXl.value = true;
      } else {
        isXl.value = false;
      }
    };

    const toggleOpen = () => {
      open.value = !open.value;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      open,
      toggleOpen,
      userStore,
      isXl,
    };
  },

  components: {
    SideBar,
    NavBar,
  },
};
</script>

<style></style>
