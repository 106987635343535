<template>
  <div class="card border color-border mb-3" :class="{ 'list-item-hover': pointer }">
    <div class="card-body p-0 my-2">
      <div class="row d-flex align-items-center m-0">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pointer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
