<template>
  <p
    v-if="role === 'loan-officer' || role === 'admin'"
    class="text-muted m-0 fw-semi-bold fs-12 font-spacing"
    :class="role === 'business-owner' ? 'padding-start-1px' : 'ms-1 ps-lg-4'">
    BUSINESS PROFILE
  </p>
  <div class="row">
    <div class="col-xl-6 col-md-8 d-flex align-items-center">
      <button class="btn bg-light ps-0 border-0 d-none d-lg-block" type="button">
        <i @click="goBack()" class="bi bi-arrow-left icon-bold-2 text-dark lighter-hover"></i>
      </button>
      <h3 class="color-text-dark mb-0 fw-semi-bold fs-24 me-md-0 me-2" v-if="business.businessInfo">
        {{ business.businessInfo.name }}
      </h3>
      <img
        v-if="business.businessInfo"
        v-bind:src="business.businessInfo.img"
        width="36"
        height="36"
        class="rounded-circle border border-white border-2 mx-md-3 me-2 d-none d-sm-block" />
      <span
        v-if="business.businessInfo?.category"
        class="badge rounded-pill bg-white color-text-dark fw-normal fs-14 d-none d-sm-block">
        {{ business.businessInfo.category }}
      </span>
    </div>
    <div
      v-if="page && (role === 'loan-officer' || role === 'admin')"
      class="col-xl-6 col-md-4 d-flex align-items-center justify-content-md-end my-0 mt-3 mt-md-0">
      <button
        :disabled="hasFinancials"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-background me-3 business-connect-bank"
        :class="hasFinancials ? 'color-text-primary-disabled' : 'color-text-primary'"
        @click="!hasFinancials ? updateShowFinancialRequestModal(true) : ''">
        <div class="d-flex" :class="hasFinancials ? 'color-text-primary-disabled' : 'hover'">
          <i class="bi bi-clipboard-data align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start"> Request Financial Data </span>
        </div>
      </button>
      <button
        v-if="role === 'admin'"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-background me-3 color-text-primary"
        @click="updateShowBusinessOwnerRegistrationRequestModal(true)">
        <div class="hover d-flex">
          <i class="bi bi-person-plus align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start"> Invite owner </span>
        </div>
      </button>
      <button class="fw-semi-bold fs-14 border-0 color-text-primary color-background me-3">
        <track-business-button :businessId="$route.params.id" additionalIconClasses="fs-18">
          <span class="d-none d-xl-block text-start">Track Business</span>
        </track-business-button>
      </button>
      <!-- <button
        v-if="!isPageFinancial()"
        @click="exportCsv"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
        <div class="hover d-flex">
          <i class="bi bi-download align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start">Export CSV </span>
        </div>
      </button> -->
      <button
        v-if="!isPageFinancial() && role === 'admin'"
        @click="showBusinessEditPopover = true"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
        <div class="hover d-flex">
          <i class="bi bi-pencil align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start">Edit</span>
        </div>
      </button>
      <button
        v-if="isPageFinancial() && isAdminOrLoanOfficer && enablePlaidAssets"
        :disabled="plaidAssetsStatus === 'IN_PROGRESS'"
        @click="takeActionOnReport()"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-background">
        <div
          class="d-flex"
          :class="
            plaidAssetsStatus !== 'IN_PROGRESS'
              ? 'color-text-primary hover'
              : 'color-text-primary-disabled'
          ">
          <i class="bi bi-download align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start">{{
            plaidAssetsStatus === "DONE"
              ? "Download Report"
              : plaidAssetsStatus === "NONE"
              ? "Request Report"
              : plaidAssetsStatus === "IN_PROGRESS"
              ? "Report Available Soon"
              : "Request Report"
          }}</span>
        </div>
      </button>
    </div>
    <div
      v-else-if="page && isBusinessOwner && myBusinessesIds.includes(String(business.businessId))"
      class="col-xl-6 col-md-4 d-flex align-items-center justify-content-md-end my-0 mt-3 mt-md-0">
      <button
        v-if="isPageOverview()"
        @click="showBusinessEditPopover = true"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
        <div class="hover d-flex">
          <i class="bi bi-pencil align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start">Edit</span>
        </div>
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <div v-if="role === 'guest'" class="my-0">
      <button
        @click="shareBusiness"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
        <div class="hover d-flex">
          <i class="bi bi-share align-items-center d-flex fs-14 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start ms-2"> Share business</span>
        </div>
      </button>
    </div>
  </div>

  <div v-if="page && userStore.isAuthenticated" class="row d-flex bd-highlight">
    <div class="col-md-7 justify-content-start align-items-center d-flex flex-wrap my-3">
      <button
        id="business-overview"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 text-dark color-background me-3 pb-1"
        :class="isPageOverview() ? 'selected-tab-underlined' : ''"
        @click="!isPageOverview() ? $emit('changePage', 'ProfileCards') : ''">
        <div :class="isPageOverview() ? '' : 'dropdown-hover'">Overview</div>
      </button>

      <button
        id="business-insights"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 text-dark color-background me-3 pb-1"
        :class="isPageInsights() || isPageBenchmark() ? 'selected-tab-underlined' : ''"
        @click="
          !(isPageInsights() || isPageBenchmark()) ? $emit('changePage', 'InsightsCards') : ''
        ">
        <div :class="isPageInsights() ? '' : 'dropdown-hover'">Insights</div>
      </button>

      <button
        v-if="isAdminOrLoanOfficer && showJourney"
        id="business-journey"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 text-dark color-background me-3 pb-1"
        :class="isPageJourney() ? 'selected-tab-underlined' : ''"
        @click="!isPageJourney() ? $emit('changePage', 'JourneyCards') : ''">
        <div :class="isPageJourney() ? '' : 'dropdown-hover'">Journey</div>
      </button>

      <button
        v-if="hasFinancials || (!hasFinancials && role !== 'business-owner')"
        class="d-flex align-items-center fw-semi-bold fs-14 text-dark border-0 color-background me-3 pb-1"
        :class="[
          !hasFinancials ? 'color-text-disabled' : '',
          isPageFinancial() ? 'selected-tab-underlined' : '',
        ]"
        :disabled="!hasFinancials"
        @click="!isPageFinancial() ? $emit('changePage', 'FinancialCards') : ''">
        <div :class="isPageFinancial() || !hasFinancials ? '' : 'dropdown-hover'">Financials</div>
      </button>
      <button
        v-else-if="myBusinessesIds.includes(String(business.businessId))"
        class="d-flex align-items-center fw-semi-bold fs-14 text-dark border-0 color-background pb-1 color-text-primary business-connect-bank"
        @click="connectBankAccount()">
        <div class="d-none d-sm-block">Connect Financial Data</div>
        <div class="d-block d-sm-none d-flex align-items-bottom">
          <i class="bi bi-cash-coin fs-18 me-2 mt-1"></i>
          <span class="mt-1">Connect</span>
        </div>
      </button>
    </div>
    <div
      v-if="isPageBenchmark() || isPageInsights()"
      class="col-md-5 justify-content-md-end align-items-center d-flex flex-wrap mt-md-3 mb-3">
      <div class="d-flex fs-14 fw-semi-bold">
        Benchmarking
        <div class="form-switch form-check ms-4" style="transform: scale(1.5)">
          <input
            :disabled="disableBenchmarking()"
            @click="togglePage()"
            :checked="isPageBenchmark()"
            type="checkbox"
            class="form-check-input input-toggle"
            id="customSwitches" />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!loading && !hideLastUpdated"
    class="d-flex justify-content-between align-items-center"
    :class="isPageFinancial() ? 'mb-2' : 'mb-3'"
    style="min-height: 26px">
    <p v-if="this.business" class="fs-14 m-0 ms-2" style="color: rgba(0, 0, 0, 0.6)">
      {{ parseDateFromLastSnapshot() }}
    </p>
  </div>
  <div
    v-if="isPageFinancial() && bankAccounts"
    class="row d-flex align-items-center justify-content-between">
    <div class="col-sm-6 col d-flex align-items-center">
      <p
        class="btn dropdown-toggle px-1 mb-0 color-text-dark fs-12 fw-medium ms-1 d-flex align-items-center overflow-hidden"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <i
          v-if="filterCurrentBankAccount.isMainAccount === true"
          class="bi bi-asterisk me-1 fs-8"></i>
        {{
          filterCurrentBankAccount.isMainAccount ? "Main Account" : filterCurrentBankAccount.name
        }}
        <span class="fw-normal fs-12">
          {{
            filterCurrentBankAccount.type
              ? " - " + toLowerCaseExceptFirst(filterCurrentBankAccount.type)
              : ""
          }}
        </span>
        <i class="bi bi-chevron-down color-text-dark icon-bold-2 fs-10 ps-2"></i>
      </p>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li class="my-2" v-for="account in bankAccounts" :key="account.id">
          <button class="dropdown-item fs-12 fw-medium" @click="this.setBankAccount(account)">
            <i v-if="account.isMainAccount === true" class="bi bi-asterisk fs-8"></i>
            {{ account.isMainAccount ? "Main Account" : account.name }} <br />
            <span class="fw-normal fs-12" :class="account.isMainAccount ? 'ms-2 ps-1' : ''">
              {{ account.type ? toLowerCaseExceptFirst(account.type) : "" }}
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div class="col-sm-6 col d-flex align-items-center justify-content-sm-end">
      <p
        class="btn dropdown-toggle px-1 mb-0 color-text-dark fs-12 ms-sm-0 ms-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        {{ getFilter() }}
        <i class="bi bi-chevron-down color-text-dark icon-bold-2 fs-10 ps-2"></i>
      </p>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <button class="dropdown-item fs-10" @click="this.setFilter('30')">Last 30 days</button>
        </li>
        <li>
          <button class="dropdown-item fs-10" @click="this.setFilter('60')">Last 60 days</button>
        </li>
        <li>
          <button class="dropdown-item fs-10" @click="this.setFilter('90')">Last 90 days</button>
        </li>
        <li>
          <button class="dropdown-item fs-10" @click="this.setFilter('180')">Last 180 days</button>
        </li>
        <li>
          <button class="dropdown-item fs-10" @click="this.setFilter('360')">Last 12 months</button>
        </li>
        <li>
          <button class="dropdown-item fs-10" @click="this.setFilter('720')">Last 24 months</button>
        </li>
      </ul>
    </div>
  </div>
  <financial-invitation-popover
    :visible="showFinancialRequestModal"
    :businessId="this.$route.params.id"
    :businessName="business?.businessInfo?.name"
    @toggle="updateShowFinancialRequestModal">
  </financial-invitation-popover>

  <business-owner-registration-invite-popover
    :visible="showRegistrationInviteModal"
    :businessId="this.$route.params.id"
    @toggle="updateShowBusinessOwnerRegistrationRequestModal">
  </business-owner-registration-invite-popover>

  <change-business-details-popover
    title="Edit business details"
    :visible="showBusinessEditPopover"
    :instagram="business?.instagram?.username"
    :facebook="business?.facebook?.username"
    :website="business?.website?.website"
    :businessId="business?.businessId"
    @toggle="toggleShowBusinessEditModal">
  </change-business-details-popover>
</template>
<script>
import FinancialInvitationPopover from "./FinancialInvitationPopover.vue";
import BusinessOwnerRegistrationInvitePopover from "./BusinessOwnerRegistrationInvitePopover.vue";
import { useToast } from "vue-toastification";
import { useFollowedBusinessesStore } from "@/stores/FollowedBusinessesStore.js";
import Analytics from "@/services/analytics.js";
import ServiceAPI from "@/services/ServiceAPI.js";
import { mapWritableState, mapState } from "pinia";
import { useBusinessesStore } from "@/stores/BusinessStore.js";
import { useBusinessOwnerBusinessesStore } from "@/stores/BusinessOwnerBusinessesStore.js";
import { useUserStore } from "@/stores/UserStore.js";
import TrackBusinessButton from "./TrackBusinessButton.vue";
import environment from "@/services/environment";

import { computed, ref, onMounted } from "vue";
import { useShare, useClipboard } from "@vueuse/core";

import { useRouter } from "vue-router";
import ChangeBusinessDetailsPopover from "./popup/ChangeBusinessDetailsPopover.vue";

export default {
  setup(props, { emit }) {
    const toast = useToast();
    const router = useRouter();

    const userStore = useUserStore();
    const followedBusinessesStore = useFollowedBusinessesStore();
    const plaidAssetsStatus = ref();
    const plaidAssestsUrl = ref();
    const enablePlaidAssets = environment.isPlaidAssetsEnabled();

    const showBusinessEditPopover = ref(false);

    const toLowerCaseExceptFirst = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const isAdminOrLoanOfficer = userStore.role === "admin" || userStore.role === "loan-officer";
    const isBusinessOwner = userStore.role === "business-owner";

    if (userStore.isAuthenticated) {
      followedBusinessesStore.getFollowedBusinesses();
      followedBusinessesStore.getIds();
      if (isBusinessOwner) {
        useBusinessOwnerBusinessesStore().getMyBusinessesIds();
      }
    }

    const shareBusiness = () => {
      const url = window.location.origin + router.currentRoute.value.path;
      const { share, isSupported: isShareSupported } = useShare();

      if (isShareSupported.value) {
        share({ title: "Shubox", url, text: "Check out this business I found in Shubox." });
      } else {
        const { copy } = useClipboard();
        copy(url);
        toast.info("Link copied to the clipboard!", { toastClassName: "shubox-toast" });
      }
    };

    const hideLastUpdated = computed(() => {
      return props.page === "BenchmarkCards";
    });

    const disableBenchmarking = () => {
      return userStore.role != "admin" && userStore.role != "loan-officer";
    };

    const togglePage = () => {
      if (props.page === "BenchmarkCards") {
        emit("changePage", "InsightsCards");
      } else {
        emit("changePage", "BenchmarkCards");
      }
    };

    const openCommingSoonPopup = () => {
      toast.info("Business comparison with similar businesses comming soon!");
      // toggle input with id customSwitches to false after 1 second
      setTimeout(() => {
        document.getElementById("customSwitches").checked = false;
      }, 300);
    };

    const takeActionOnReport = () => {
      if (plaidAssetsStatus.value === "NONE") {
        const retrievePlaidAssets = confirm("Are you sure you want to generate the assets report?");
        if (!retrievePlaidAssets) {
          return;
        }
        ServiceAPI.requestPlaidAssetsReport(props.business.businessId);
        plaidAssetsStatus.value = "IN_PROGRESS";
      } else if (plaidAssetsStatus.value === "DONE") {
        ServiceAPI.downloadFile(plaidAssestsUrl.value);
      } else if (plaidAssetsStatus.value === "IN_PROGRESS") {
        // Do nothing
      }
    };

    onMounted(async () => {
      if (isAdminOrLoanOfficer) {
        const response = await ServiceAPI.getAssetsReportStatus(props.business.businessId);
        plaidAssetsStatus.value = response.data.status;
        plaidAssestsUrl.value = response.data.url;
      }
    });

    const toggleShowBusinessEditModal = (reloadPage) => {
      showBusinessEditPopover.value = !showBusinessEditPopover.value;

      if (reloadPage) {
        setTimeout(() => router.go(), 3000);
      }
    };

    return {
      toast,
      followedBusinessesStore,
      userStore,
      shareBusiness,
      hideLastUpdated,
      togglePage,
      disableBenchmarking,
      openCommingSoonPopup,
      isAdminOrLoanOfficer,
      toLowerCaseExceptFirst,
      plaidAssetsStatus,
      takeActionOnReport,
      enablePlaidAssets,
      showBusinessEditPopover,
      toggleShowBusinessEditModal,
      isBusinessOwner,
    };
  },

  props: ["page", "business", "role", "hasFinancials", "loading", "showJourney", "bankAccounts"],

  data() {
    return {
      followed: this.followedBusinessesStore.isFollowed(this.$route.params.id),
      showFinancialRequestModal: false,
      showRegistrationInviteModal: false,
      filter: 30,
      filterCurrentBankAccount: { name: "Main Account", isMainAccount: true },
    };
  },
  computed: {
    ...mapWritableState(useBusinessesStore, ["period"]),
    ...mapWritableState(useBusinessesStore, ["currentBankAccount"]),
    ...mapState(useBusinessOwnerBusinessesStore, ["myBusinessesIds"]),
  },
  methods: {
    getFilter() {
      if (this.filter === "360") {
        return "Last 12 months";
      }
      if (this.filter === "720") {
        return "Last 24 months";
      } else {
        return `Last ${this.filter} days`;
      }
    },

    setFilter(filter) {
      this.filter = filter;
      this.period = filter;
    },

    setBankAccount(account) {
      this.filterCurrentBankAccount = account;
      this.currentBankAccount = account;
    },

    updateShowFinancialRequestModal(newValue) {
      this.showFinancialRequestModal = newValue;
      if (newValue) {
        Analytics.logOpenFinancials(this.business?.businessId, this.business?.businessInfo?.name);
      }
    },

    updateShowBusinessOwnerRegistrationRequestModal(newValue) {
      this.showRegistrationInviteModal = newValue;
    },

    parseDateFromLastSnapshot() {
      if (this.business.timestamp) {
        const date = new Date(this.business.timestamp);
        const month = date.toLocaleString("en-US", { month: "long" });
        const day = date.getDate();
        let hours = date.getHours();
        const amPm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minutes = date.getMinutes();
        minutes = minutes.toString().padStart(2, "0");
        return "Last updated at " + month + " " + day + ", " + hours + ":" + minutes + " " + amPm;
      }
      return "N/A";
    },

    goBack() {
      return this.$router.go(-1);
    },

    isPageOverview() {
      return this.page === "ProfileCards";
    },

    isPageFinancial() {
      return this.page === "FinancialCards";
    },

    isPageInsights() {
      return this.page === "InsightsCards";
    },

    isPageBenchmark() {
      return this.page === "BenchmarkCards";
    },

    isPageJourney() {
      return this.page === "JourneyCards";
    },

    exportCsv() {
      Analytics.logClickExportCsv(this.$route.params.id);
      this.toast.info("Exporting CSV is not yet supported!");
    },

    async follow() {
      this.followedBusinessesStore.follow(this.$route.params.id);
    },

    async unfollow() {
      this.followedBusinessesStore.unfollow(this.$route.params.id);
    },

    connectBankAccount() {
      ServiceAPI.connectForBankConnection(this.business.businessId)
        .then((r) => {
          this.$router.push({
            name: "SelfConnectBankAccountPage",
            params: {
              type: "self",
              invitationCode: r.data.invitationCode,
            },
          });
        })
        .catch((e) => {
          console.warn("Could not initiate bank accont connection", e);
        });
    },

    toggleFollow() {
      this.followed = !this.followed;
      if (this.followed) {
        this.follow();
      } else {
        this.unfollow();
      }
    },
  },

  components: {
    FinancialInvitationPopover,
    BusinessOwnerRegistrationInvitePopover,
    TrackBusinessButton,
    ChangeBusinessDetailsPopover,
  },
};
</script>

<style></style>
