<template>
  <div class="card border color-border h-100 card-mh">
    <div class="card-body">
      <div class="position-relative">
        <h6 class="card-subtitle mb-2 text-muted fs-10 fw-semi-bold font-spacing pt-1">WEBSITE</h6>
        <div v-if="loadSkeleton">
          <content-loader
            class="d-lg-block d-none"
            viewBox="0 0 340 40"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="10" rx="3" ry="3" width="300" height="20" />
          </content-loader>
          <content-loader
            class="d-md-block d-lg-none d-none"
            viewBox="0 0 340 34"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="10" rx="3" ry="3" width="300" height="15" />
          </content-loader>
          <content-loader
            class="d-md-none d-block"
            viewBox="0 0 340 30"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="10" rx="3" ry="3" width="300" height="10" />
          </content-loader>
        </div>
        <div v-else>
          <i
            class="bi bi-display circle-icon position-absolute top-0 end-0"
            :class="[
              this.website && this.website.website ? 'color-text-primary hover-2' : 'text-muted',
            ]"
            @click="
              {
                this.website.website ? openWindow(fullwebsite) : null;
              }
            "></i>
          <div class="me-4 pe-3">
            <div class="d-flex align-items-center">
              <a
                v-if="website?.website ?? null != null"
                target="_blank"
                :href="fullwebsite"
                class="card-link text-decoration-none text-break hover fs-14 color-text-primary"
                >{{ stripUrl(website.website) }}</a
              >
              <span v-else class="text-break fs-20 fw-semi-bold color-text-dark font-spacing-sm">
                -
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  props: {
    website: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = props.loading && !props.website?.website;
    return { loadSkeleton };
  },

  computed: {
    fullwebsite() {
      if (this.website.website == null) {
        return null;
      }

      const site = this.website.website;
      if (site.toLowerCase().startsWith("http")) {
        return site;
      }

      return "http://" + site;
    },
  },

  methods: {
    openWindow(url) {
      if (this.website.website) window.open(url, "_blank");
    },

    stripUrl(url) {
      let aux = url.replace(/(^\w+:|^)\/\//, "");
      return aux.replace(/www./, "");
    },
  },
};
</script>

<style></style>
