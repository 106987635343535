<template>
  <div class="mb-sm-4 pb-1 mb-5" v-if="isLoanOfficerOrAdmin()">
    <p class="text-muted m-0 fw-semi-bold fs-12 font-spacing">APPLICATION PAGE</p>
    <div class="row">
      <div class="col-sm-6 col-12 d-flex align-items-center">
        <span class="color-text-dark mb-0 fw-semi-bold fs-24 me-md-0 me-2">
          {{ summary.details.businessDba }}
        </span>
        <span
          class="badge rounded-pill color-background-grey card-text p-2 px-3 m-0 fs-12 fw-medium color-text-dark ms-3"
          :style="[
            currentStatusColor
              ? {
                  backgroundColor: currentStatusColor + ' !important',
                }
              : '',
          ]">
          {{ currentStatusText }}
        </span>
      </div>
      <div class="col-sm-6 col-12 d-flex align-items-center justify-content-sm-end mt-sm-0 mt-3">
        <button
          @click="showAssignMePopover = true"
          class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background me-4">
          <div class="hover d-flex">
            <i
              class="bi align-items-center d-flex fs-18 pe-1 fw-light"
              :class="isAssigned ? 'bi-pin-angle-fill' : 'bi-pin-angle'"></i>
            <span class="d-none d-xl-block text-start">Assign</span>
          </div>
        </button>
        <button
          @click="showChangeLoanApplicationPopover = true"
          class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background me-4">
          <div class="hover d-flex">
            <i class="bi bi-layer-forward align-items-center d-flex fs-18 pe-1 fw-light"></i>
            <span class="d-none d-xl-block text-start">Change Status</span>
          </div>
        </button>
        <button
          class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background me-4">
          <router-link
            :to="`/business/${summary.businessId}`"
            class="color-text-primary text-decoration-none"
            target="_blank">
            <div class="hover d-flex">
              <i class="bi bi-shop-window align-items-center d-flex fs-18 pe-1"></i>
              <span class="d-none d-xl-block text-start fs-14 fw-semi-bold">Business Profile</span>
            </div>
          </router-link>
        </button>
        <button
          @click="openHistoryPopover()"
          class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
          <div class="hover d-flex">
            <i class="bi bi-clock-history align-items-center d-flex fs-18 pe-1 fw-light"></i>
            <span class="d-none d-xl-block text-start">History</span>
          </div>
        </button>
      </div>
    </div>
    <p v-if="currentAssignee" class="fs-14 color-text-dark mt-sm-1 mt-3 mb-0">
      <i class="bi bi-pin-angle-fill"></i> Assigned to
      <span class="fw-semi-bold">{{ currentAssignee.name }}</span>
    </p>
    <p class="fs-14 text-muted mt-sm-1" :class="currentAssignee ? ' mt-1' : 'mt-3'">
      Last updated at {{ d(summary.status.timestamp, "dateAndTime") }}
      {{ summary?.status?.user?.name ? "by " + summary.status.user.name : "" }}
    </p>
  </div>
  <p v-else class="fs-20 fw-bold text-center">Application Summary</p>
  <LoanApplicationFormContainer v-if="summary" :tenant="tenant" :tenantId="tenantId" :grant="grant">
    <div class="mx-sm-5 mx-2 mb-5">
      <p class="fs-18 fw-bold mt-4 pt-2">1. Main information</p>
      <div v-if="summary.details?.newRequest != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">New or follow up request: </span>
        <span class="fs-13 text-muted mb-0">
          {{
            this.summary.details?.newRequest === "new"
              ? "New"
              : this.summary.details?.newRequest === "followup"
              ? "Follow up"
              : this.summary.details?.newRequest === "na"
              ? "Not sure"
              : this.summary.details?.newRequest
          }}
        </span>
      </div>
      <div v-if="summary.details?.whereHearAboutUs != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Heard about us on: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.details?.whereHearAboutUs }}
        </span>
      </div>
      <div
        v-if="
          summary.details?.newRequest != null &&
          this.summary.details?.whereHearAboutUs === 'Referral'
        "
        class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Referral source: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.details?.referralSource ?? "N/A" }}
        </span>
      </div>
      <div v-if="summary.details?.loanAmount != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Loan amount: </span>
        <span class="fs-13 text-muted mb-0">
          ${{ addCommasToNumber(this.summary.details?.loanAmount) }}
        </span>
      </div>
      <div v-if="summary.details?.loanReason != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Funds usage: </span>
        <span class="fs-13 text-muted mb-0">
          {{ removeUnderscoreAndToLowerCase(this.summary.details?.loanReason) }}
        </span>
      </div>
      <div v-if="summary.details?.businessLegalName != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Legal business name: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.details?.businessLegalName }}
        </span>
      </div>
      <div v-if="summary.details?.businessDba != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">DBA name: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.details?.businessDba }}
        </span>
      </div>
      <div v-if="summary.details?.businessAddress != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Business address: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.details?.businessAddress }}
        </span>
      </div>
      <div v-if="summary.details?.businessIndustry != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Industry: </span>
        <span class="fs-13 text-muted mb-0">
          {{ removeUnderscoreAndToLowerCase(this.summary.details?.businessIndustry) }}
        </span>
      </div>

      <p class="fs-18 fw-bold mt-4">2. Owner profile</p>
      <div v-for="owner in this.summary.ownerDetails" v-bind:key="owner">
        <div v-if="owner.firstName != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Name: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.firstName }} {{ owner.lastName }}</span>
        </div>
        <div v-if="owner.email != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Owner email: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.email }}</span>
        </div>
        <div v-if="owner.phoneNumber != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Owner phone number: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.phoneNumber }}</span>
        </div>
        <div v-if="owner.homePhoneNumber != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Owner home phone number: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.homePhoneNumber }}</span>
        </div>
        <div v-if="owner.gender != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Gender: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.gender }}</span>
        </div>
        <div v-if="owner.isVeteran != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">US Military veteran: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.isVeteran === false ? "No" : "Yes" }}</span>
        </div>
        <div v-if="owner.ancestry != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Demographics: </span>
          <span class="fs-13 text-muted mb-0">
            {{ removeUnderscoreAndToLowerCase(owner.ancestry) }}
          </span>
        </div>
        <div v-if="owner.countryOfOrigin != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Country of Origin: </span>
          <span class="fs-13 text-muted mb-0">
            {{ removeUnderscoreAndToLowerCase(owner.countryOfOrigin) }}
          </span>
        </div>
        <div v-if="owner.role != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Type of ownership: </span>
          <span class="fs-13 text-muted mb-0">
            {{ removeUnderscoreAndToLowerCase(owner.role) }}
          </span>
        </div>
        <div v-if="owner.isFromMinority != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">From minority: </span>
          <span class="fs-13 text-muted mb-0">
            {{ owner.isFromMinority }}
          </span>
        </div>
        <div v-if="owner.title != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Owners title: </span>
          <span class="fs-13 text-muted mb-0">
            {{ owner.title }}
          </span>
        </div>
        <div v-if="owner.ownershipPercentage != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Ownership: </span>
          <span class="fs-13 text-muted mb-0"> {{ owner.ownershipPercentage }}% </span>
        </div>
        <div v-if="owner.dateOfBirth != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Date of birth: </span>
          <span class="fs-13 text-muted mb-0">
            {{ formatDate(owner.dateOfBirth) }}
          </span>
        </div>
        <div v-if="owner.address != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Owner address: </span>
          <span class="fs-13 text-muted mb-0">
            {{ owner.address }}
          </span>
        </div>
        <div v-if="owner.creditScore != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Credit score: </span>
          <span class="fs-13 text-muted mb-0">
            {{ formatCreditScore(owner.creditScore) }}
          </span>
        </div>
        <div v-if="owner.personalDebt != null" class="mb-1">
          <span class="fs-13 fw-semi-bold mb-0">Personal debt: </span>
          <span class="fs-13 text-muted mb-0"> {{ addCommasToNumber(owner.personalDebt) }} $ </span>
        </div>
      </div>

      <p class="fs-18 fw-bold mt-4">3. Business information</p>
      <div v-if="summary.businessDetails?.businessEmail != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Business email: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessEmail }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessPhone != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Business phone number: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessPhone }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.yearsOpen != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Years open: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.yearsOpen }}
          years
        </span>
      </div>
      <div v-if="summary.businessDetails?.incorporationDate != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Incorporation/Formation date: </span>
        <span class="fs-13 text-muted mb-0">
          {{ formatDate(this.summary.businessDetails?.incorporationDate) }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.numberOfEmployees != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Number of employees: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.numberOfEmployees }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.employerIdentificationNumber != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">EIN: </span>
        <span class="fs-13 text-muted mb-0">
          {{ formatEIN(this.summary.businessDetails?.employerIdentificationNumber) }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.isForProfit != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">For-Profit: </span>
        <span class="fs-13 text-muted mb-0">
          {{ summary.businessDetails?.isForProfit === false ? "No" : "Yes" }}</span
        >
      </div>
      <div v-if="summary.businessDetails?.isStartup != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Is a startup: </span>
        <span class="fs-13 text-muted mb-0">
          {{ summary.businessDetails?.isStartup === false ? "No" : "Yes" }}</span
        >
      </div>
      <div v-if="summary.businessDetails?.isOperating != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Is currently open and operating: </span>
        <span class="fs-13 text-muted mb-0">
          {{ summary.businessDetails?.isOperating === false ? "No" : "Yes" }}</span
        >
      </div>
      <div v-if="summary.businessDetails?.businessEntity != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Business entity: </span>
        <span class="fs-13 text-muted mb-0">
          {{ removeUnderscoreAndToLowerCase(this.summary.businessDetails?.businessEntity) }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessWebsite != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Business website: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessWebsite }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessFacebook != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Facebook: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessFacebook }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessInstagram != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Instagram: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessInstagram }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessTiktok != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> TikTok: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessTiktok }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessOtherSocialMedia != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Other social media: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessOtherSocialMedia }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.memberQueensChamberCommerce != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Queens Chamber of Commerce member: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.memberQueensChamberCommerce }}
        </span>
      </div>
      <div v-if="summary.businessDetails?.businessDescription != null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Business description: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.businessDetails?.businessDescription }}
        </span>
      </div>

      <p class="fs-18 fw-bold mt-4">4. Financial data</p>
      <div v-if="summary.financialDetails?.existingCreditIssues !== null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Serious credit issues: </span>
        <span class="fs-13 text-muted mb-0">
          {{ booleanToYesNo(this.summary.financialDetails?.existingCreditIssues) }}
        </span>
      </div>
      <div v-if="summary.financialDetails?.inAnInstallmentPlan !== null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> In an installment plan: </span>
        <span class="fs-13 text-muted mb-0">
          {{ booleanToYesNo(this.summary.financialDetails?.inAnInstallmentPlan) }}
        </span>
      </div>
      <div v-if="summary.financialDetails?.everFilledForBankruptcy !== null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0"> Ever filled bankruptcy: </span>
        <span class="fs-13 text-muted mb-0">
          {{ booleanToYesNo(this.summary.financialDetails?.everFilledForBankruptcy) }}
        </span>
      </div>
      <div v-if="summary.financialDetails?.bankruptcyStillActive !== null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Active bankruptcy: </span>
        <span class="fs-13 text-muted mb-0">
          {{ booleanToYesNo(this.summary.financialDetails?.bankruptcyStillActive) }}
        </span>
      </div>
      <div v-if="summary.financialDetails?.planDescription !== null" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Funding description: </span>
        <span class="fs-13 text-muted mb-0">
          {{ this.summary.financialDetails?.planDescription }}
        </span>
      </div>
      <div v-if="summary.financialDetails?.grossReceiptsPastYear" class="mb-1">
        <span class="fs-13 fw-semi-bold mb-0">Gross receipts past year: </span>
        <span class="fs-13 text-muted mb-0">
          {{
            this.summary?.financialDetails?.grossReceiptsPastYear
              ? $n(this.summary.financialDetails.grossReceiptsPastYear, "currencyNoCents")
              : "N/A"
          }}
        </span>
      </div>
      <div v-if="isAuthenticated()">
        <span class="fs-13 fw-semi-bold mb-0">Financials connected: </span>
        <span class="fs-13 text-muted mb-0">
          {{ booleanToYesNo(this.summary.financialDetails?.isPlaidConnected) }}
        </span>
      </div>
      <div v-if="isLoanOfficerOrAdmin()">
        <span class="fs-13 fw-semi-bold mb-0">Main account last 12 months cash in: </span>
        <span class="fs-13 text-muted mb-0">
          {{
            last12MonthsCashIn?.total
              ? $n(Math.abs(last12MonthsCashIn.total), "currencyNoCents")
              : "N/A"
          }}
        </span>
      </div>
      <p class="fs-18 fw-bold mt-4">Files uploaded</p>
      <div v-if="businessBankStatements.length > 0">
        <p class="fs-14 fw-semi-bold mb-1">Business bank statements files:</p>
        <ul>
          <li class="fs-13 text-muted" v-for="file in businessBankStatements" v-bind:key="file">
            {{ file.fileName }}
            <i
              v-if="isAuthenticated()"
              class="bi bi-download pointer hover color-text-primary ms-1"
              @click="dowloadFile(file.downloadUrl)"></i>
          </li>
        </ul>
      </div>

      <div v-if="personalBankStatements.length > 0">
        <p class="fs-14 fw-semi-bold mt-3 mb-1">Personal bank statements files:</p>
        <ul>
          <li class="fs-13 text-muted" v-for="file in personalBankStatements" v-bind:key="file">
            {{ file.fileName }}
            <i
              v-if="isAuthenticated()"
              class="bi bi-download pointer hover color-text-primary ms-1"
              @click="dowloadFile(file.downloadUrl)"></i>
          </li>
        </ul>
      </div>
      <div v-if="this.lastTwoYearsPersonalTaxFiles.length > 0">
        <p class="fs-14 fw-semi-bold mt-3 mb-1">Last 2 years of personal tax returns files:</p>
        <ul>
          <li
            class="fs-13 text-muted"
            v-for="file in this.lastTwoYearsPersonalTaxFiles"
            v-bind:key="file">
            {{ file.fileName }}
            <i
              v-if="isAuthenticated()"
              class="bi bi-download pointer hover color-text-primary ms-1"
              @click="dowloadFile(file.downloadUrl)"></i>
          </li>
        </ul>
      </div>
      <div v-if="this.lastTwoYearsBusinessTaxFiles.length > 0">
        <p class="fs-14 fw-semi-bold mt-3 mb-1">Last 2 years of business tax returns files:</p>
        <ul>
          <li
            class="fs-13 text-muted"
            v-for="file in this.lastTwoYearsBusinessTaxFiles"
            v-bind:key="file">
            {{ file.fileName }}
            <i
              v-if="isAuthenticated()"
              class="bi bi-download pointer hover color-text-primary ms-1"
              @click="dowloadFile(file.downloadUrl)"></i>
          </li>
        </ul>
      </div>
      <div v-if="this.otherFiles.length > 0">
        <p class="fs-14 fw-semi-bold mt-3 mb-1">Supporting documents:</p>
        <ul>
          <li class="fs-13 text-muted" v-for="file in this.otherFiles" v-bind:key="file">
            {{ file.fileName }}
            <i
              v-if="isAuthenticated()"
              class="bi bi-download pointer hover color-text-primary ms-1"
              @click="dowloadFile(file.downloadUrl)"></i>
          </li>
        </ul>
      </div>

      <div class="mt-5 fs-12">
        <slot></slot>
      </div>
    </div>
  </LoanApplicationFormContainer>
  <ChangeLoanApplicationPopover
    v-if="isLoanOfficerOrAdmin()"
    @toggle="toggleChangeStatusPopover"
    @changeStatus="changeApplicationStatus"
    :visible="showChangeLoanApplicationPopover"
    :status="summary.status.status"
    :listOfObjects="loanStatusSettings"
    :loading="loadingStatusPopover"
    :tenantStatus="tenantStatus"
    :statusColors="statusColors"
    title="Change Application Status"></ChangeLoanApplicationPopover>
  <AssignPopover
    v-if="isLoanOfficerOrAdmin()"
    @toggle="toggleAssignMePopover"
    @assign="assignApplication"
    :visible="showAssignMePopover"
    :loading="loadingAssignMePopover"
    :tenantList="tenantList"
    :assignee="currentAssignee"
    :currentUserId="currentUserId"
    title="Assign"></AssignPopover>
  <HistoryPopover
    v-if="isLoanOfficerOrAdmin()"
    @toggle="toggleHistoryPopover"
    :visible="showHistoryPopover"
    :statusColors="statusColors"
    :loanApplicationId="loanApplicationId"
    title="Status History"></HistoryPopover>
</template>

<script>
import ServiceAPI from "@/services/ServiceAPI";
import { useUserStore } from "@/stores/UserStore";
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import AssignPopover from "../loans_dashboard/AssignPopover.vue";
import ChangeLoanApplicationPopover from "../loans_dashboard/ChangeLoanApplicationPopover.vue";
import HistoryPopover from "../loans_dashboard/HistoryPopover.vue";
import LoanApplicationFormContainer from "./LoanApplicationFormContainer.vue";

export default {
  props: [
    "summary",
    "loanApplicationId",
    "tenant",
    "tenantId",
    "grant",
    "tenantStatus",
    "tenantList",
  ],

  setup(props) {
    const businessBankStatements = ref([]);
    const personalBankStatements = ref([]);
    const lastTwoYearsPersonalTaxFiles = ref([]);
    const lastTwoYearsBusinessTaxFiles = ref([]);
    const otherFiles = ref([]);
    const userStore = useUserStore();
    const showChangeLoanApplicationPopover = ref(false);
    const showAssignMePopover = ref(false);
    const showHistoryPopover = ref(false);
    const loadingStatusPopover = ref(false);
    const loadingAssignMePopover = ref(false);
    const currentAssignee = ref(props.summary.assignee);
    const currentUserId = userStore.id;
    const toast = useToast();
    const last12MonthsCashIn = ref();

    const isAssigned = computed(() => {
      return currentAssignee.value !== null;
    });

    const { d } = useI18n();
    const currentStatus = ref(props.summary.status.status);

    const statusColors = {
      START: "#BFE1FF",
      IN_PROGRESS: "#FFE7C2",
      UNSUCCESS: "#FFB4B4",
      SUCCESS: "#E1FFEB",
    };

    const currentStatusText = computed(() => {
      return currentStatus?.value?.status ?? "N/A";
    });

    const currentStatusColor = computed(() => {
      return statusColors[currentStatus?.value?.type] ?? "#EDEDED";
    });

    const isLoanOfficerOrAdmin = () => {
      return (
        userStore.isAuthenticated &&
        (userStore.role === "loan-officer" || userStore.role === "admin")
      );
    };

    const isAuthenticated = () => {
      return userStore.isAuthenticated;
    };

    const openHistoryPopover = async () => {
      showHistoryPopover.value = true;
    };

    businessBankStatements.value = props.summary.documents.filter(
      (file) => file.documentType === "LAST_6_MONTHS_BUSINESS_BANK_STATEMENTS"
    );

    personalBankStatements.value = props.summary.documents.filter(
      (file) => file.documentType === "LAST_6_MONTHS_PERSONAL_BANK_STATEMENTS"
    );

    lastTwoYearsPersonalTaxFiles.value = props.summary.documents.filter(
      (file) => file.documentType === "LAST_2_YEARS_PERSONAL_TAX"
    );

    lastTwoYearsBusinessTaxFiles.value = props.summary.documents.filter(
      (file) => file.documentType === "LAST_2_YEARS_BUSINESS_TAX"
    );

    otherFiles.value = props.summary.documents.filter((file) => file.documentType === "OTHER");

    const assignLoanToMe = async () => {
      loadingAssignMePopover.value = true;
      try {
        await ServiceAPI.assignLoanToMe(props.loanApplicationId);
        currentAssignee.value = {
          id: currentUserId,
          name: "you",
        };
        loadingAssignMePopover.value = false;
        toggleAssignMePopover();
      } catch (error) {
        console.log(error);
        toast.error("Could not assign loan to you.");
        loadingAssignMePopover.value = false;
        toggleAssignMePopover();
      }
    };

    const assignApplication = async (assigneeId) => {
      loadingAssignMePopover.value = true;
      try {
        await ServiceAPI.assignLoanApplication(props.loanApplicationId, assigneeId);
        currentAssignee.value = props.tenantList.find((tenant) => tenant.id === assigneeId);
        loadingAssignMePopover.value = false;
        toggleAssignMePopover();
      } catch (error) {
        console.log(error);
        toast.error("Could not assign application to the selected user.");
        loadingAssignMePopover.value = false;
        toggleAssignMePopover();
      }
    };

    const unassignLoanFromMe = async () => {
      loadingAssignMePopover.value = true;
      try {
        await ServiceAPI.unassignLoanFromMe(props.loanApplicationId);
        currentAssignee.value = null;
        loadingAssignMePopover.value = false;
        toggleAssignMePopover();
      } catch (error) {
        console.log(error);
        toast.error("Could not unassign loan from you.");
        loadingAssignMePopover.value = false;
        toggleAssignMePopover();
      }
    };

    const toggleChangeStatusPopover = () => {
      showChangeLoanApplicationPopover.value = !showChangeLoanApplicationPopover.value;
    };

    const toggleAssignMePopover = () => {
      showAssignMePopover.value = !showAssignMePopover.value;
    };

    const toggleHistoryPopover = () => {
      showHistoryPopover.value = !showHistoryPopover.value;
    };

    const changeApplicationStatus = async (statusId, note, sendEmail) => {
      loadingStatusPopover.value = true;
      try {
        await ServiceAPI.updateLoanApplicationStatus(
          props.loanApplicationId,
          statusId,
          note,
          sendEmail
        );
        currentStatus.value = props.tenantStatus.find((s) => s.statusId === statusId);
        loadingStatusPopover.value = false;
        toggleChangeStatusPopover();
      } catch (error) {
        console.log(error);
        if (sendEmail) {
          toast.error("Error changing status or sending email. Please try again later.");
        } else {
          toast.error("Error changing status. Please try again later.");
        }
        loadingStatusPopover.value = false;
      }
    };

    const showWorkingOnHistoryPopup = () => {
      toast.info("This feature is coming soon.");
    };

    const loadLast12MonthsCashIn = async () => {
      try {
        const response = await ServiceAPI.getBusinessCashIn(props.summary.businessId, 360);
        last12MonthsCashIn.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      if (isLoanOfficerOrAdmin()) {
        try {
          await loadLast12MonthsCashIn();
        } catch (error) {
          console.log(error);
        }
      }
    });

    return {
      businessBankStatements,
      personalBankStatements,
      lastTwoYearsPersonalTaxFiles,
      lastTwoYearsBusinessTaxFiles,
      otherFiles,
      showChangeLoanApplicationPopover,
      showAssignMePopover,
      toggleChangeStatusPopover,
      toggleAssignMePopover,
      changeApplicationStatus,
      isLoanOfficerOrAdmin,
      loadingStatusPopover,
      loadingAssignMePopover,
      currentStatus,
      isAuthenticated,
      statusColors,
      currentStatusText,
      currentStatusColor,
      currentUserId,
      d,
      assignLoanToMe,
      isAssigned,
      unassignLoanFromMe,
      currentAssignee,
      showWorkingOnHistoryPopup,
      assignApplication,
      showHistoryPopover,
      toggleHistoryPopover,
      openHistoryPopover,
      last12MonthsCashIn,
    };
  },

  methods: {
    dowloadFile(url) {
      ServiceAPI.downloadFile(url);
    },

    booleanToYesNo(value) {
      if (value === null || value === undefined) {
        return "N/A";
      }
      return value === true ? "Yes" : "No";
    },

    removeEverytingButNumbersOrDots(x) {
      if (x == null) {
        return "";
      }
      return x.toString().replace(/[^0-9.]/g, "");
    },

    addCommasToNumber(x) {
      let input = this.removeEverytingButNumbersOrDots(x);
      let decimal = input.split(".")[1];
      let integer = input.split(".")[0];
      let res = "";

      for (let i = integer.length - 1; i >= 0; i--) {
        res = integer[i] + res;
        if ((integer.length - i) % 3 == 0 && i != 0) {
          res = "," + res;
        }
      }

      if (input.includes(".")) {
        res += "." + decimal;
      }

      return res;
    },

    removeUnderscoreAndToLowerCase(value) {
      if (value === null || value === undefined) {
        return "N/A";
      }
      return value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, " ").toLowerCase();
    },

    formatDate(date) {
      if (date === null || date === undefined) {
        return "N/A";
      }
      return new Date(date).toLocaleDateString();
    },

    formatCreditScore(value) {
      if (value === null || value === undefined) {
        return "N/A";
      }
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      return value.replace("_", " ").replace("_", "-");
    },

    addDashToIndex(index, x) {
      if (x.length <= index) {
        return x;
      }
      return x.slice(0, index) + "-" + x.slice(index);
    },

    removeEverythingButNumbers(x) {
      return x.replace(/[^0-9]/g, "");
    },

    formatEIN(value) {
      if (value === null || value === undefined) {
        return "N/A";
      }
      return this.addDashToIndex(2, this.removeEverythingButNumbers(value));
    },

    validateAndNextTab() {
      const submitApplication = confirm("Submit application?");
      this.$emit(
        "changeTab",
        submitApplication ? "FinishedPage" : "SummaryPage",
        null,
        submitApplication
      );
    },

    async submitForm() {},
  },

  components: {
    LoanApplicationFormContainer,
    ChangeLoanApplicationPopover,
    AssignPopover,
    HistoryPopover,
  },
};
</script>

<style></style>
