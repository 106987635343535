<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="financial-invitation"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog" style="max-width: 600px">
        <div v-if="googleSearch" class="modal-content">
          <div class="modal-body fs-14 px-0 mx-4">
            <p class="fs-18 fw-semi-bold mt-2 mb-3 ms-1">Select your business</p>
            <div class="row d-flex align-items-center overflow-hidden">
              <div
                class="col-md-4 d-flex align-items-center col-6 overflow-hidden fs-14 fs-semi-bold text-muted">
                <span class="ms-2">Business</span>
              </div>
              <div class="col-md-5 col-6 overflow-hidden fs-14 fs-semi-bold text-muted">
                Address
              </div>
              <div class="col-md-3 d-flex d-md-block d-none fs-14 fs-semi-bold text-muted">
                Category
              </div>
            </div>
            <div v-for="(item, index) in businesses" :key="item">
              <div
                class="card border-1 my-2"
                :class="index === businessSelected ? 'sb-select-enabled' : 'sb-select-disabled'"
                @click="businessSelected = index">
                <div class="row d-flex align-items-center overflow-hidden">
                  <div
                    class="col-md-4 d-flex align-items-center col-6 overflow-hidden"
                    style="min-height: 50px">
                    <span class="ms-2">
                      <img
                        :src="item.info.img"
                        width="30"
                        height="30"
                        class="rounded-circle border color-border-grey" />
                    </span>
                    <span class="fs-12 fw-semi-bold ms-2">{{ item.info.name }}</span>
                  </div>
                  <div class="col-md-5 col-6 overflow-hidden text-break">
                    <div class="me-md-0 me-2">
                      <span class="fs-12">{{ item?.address?.address }} </span>
                    </div>
                  </div>
                  <div class="col-md-3 d-flex d-md-block d-none">
                    <span class="fs-12">{{ item.info.category }}</span>
                  </div>
                </div>
              </div>
            </div>
            <span class="fs-12 ms-2 mt-2">
              Can’t find your business? Add it
              <a
                @click="googleSearch = false"
                class="card-link text-decoration-none text-break hover fs-12 fw-semi-bold color-text-primary"
                >manually here</a
              >
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-center mb-5">
            <button
              type="button"
              class="btn shadow-sm sb-btn-secondary rounded mt-4 mx-2"
              style="height: 42px; width: 90px"
              @click="$emit('closeBusinessesResultsPopover')">
              <span class="fs-14 fw-bold"> Cancel </span></button
            ><button
              class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2"
              style="height: 42px; width: 90px"
              :disabled="!businessSelectedInfo"
              @click="
                $emit('selectBusiness', this.businessSelectedInfo);
                businessSelected = null;
              ">
              <span class="fs-14 fw-bold"> Select </span>
            </button>
          </div>
        </div>
        <div v-else class="modal-content">
          <div class="modal-body fs-14 px-0 mx-4">
            <p class="fs-18 fw-semi-bold mt-2 mb-3 ms-1">Insert your business details</p>
            <form class="fs-12 mx-3 mb-4 pb-2" @submit.prevent="$emit('addManualBusiness')">
              <div class="fw-normal">
                <div class="form-group">
                  <label class="fw-bold fs-14 ms-1 mb-2" for="businessName">Business name</label>
                  <input
                    tabindex="1"
                    id="businessName"
                    class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                    name="businessName"
                    type="text"
                    aria-invalid=""
                    v-model="
                      loanApplicationStore.userLoanApplications[
                        loanApplicationStore.defaultBusinessId
                      ].tab1GetStarted.businessDbaName
                    "
                    placeholder="Insert your business name"
                    autofocus="autofocus"
                    required />
                </div>
              </div>

              <div class="mt-4">
                <label for="industry" class="fw-bold fs-14 ms-1 mb-2">Industry</label>
                <select
                  class="form-select fs-12 color-background-form border-2 color-border py-2"
                  placeholder="Select industry type"
                  required
                  v-model="
                    loanApplicationStore.userLoanApplications[
                      loanApplicationStore.defaultBusinessId
                    ].tab1GetStarted.industry
                  "
                  aria-label="Select industry"
                  id="industry"
                  :class="{ 'text-muted': industry === '' }">
                  <option value="" disabled selected hidden muted>Select industry</option>
                  <option value="Accommodation and Food Services">
                    Accommodation and Food Services
                  </option>
                  <option value="Administrative and Support Services">
                    Administrative and Support Services
                  </option>
                  <option value="Agriculture, Forestry, Hunting and Fishing">
                    Agriculture, Forestry, Hunting and Fishing
                  </option>
                  <option value="Arts, Entertainment and Recreation">
                    Arts, Entertainment and Recreation
                  </option>
                  <option value="Construction">Construction</option>
                  <option value="Data Processing Services">Data Processing Services</option>
                  <option value="Educational Services">Educational Services</option>
                  <option value="Finance and Insurance">Finance and Insurance</option>
                  <option value="Healthcare and Social Assistance">
                    Healthcare and Social Assistance
                  </option>
                  <option value="Information/Technology">Information/Technology</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Personal Care Business">Personal Care Business</option>
                  <option value="Professional, Scientific, and Technical Services">
                    Professional, Scientific, and Technical Services
                  </option>
                  <option value="Retail Trade">Retail Trade</option>
                  <option value="Transportation and Warehousing">
                    Transportation and Warehousing
                  </option>
                  <option value="Waste Management and Remediation Services">
                    Waste Management and Remediation Services
                  </option>
                  <option value="Wholesale Trade">Wholesale Trade</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <p class="mt-4 fs-12 fw-semi-bold text-muted">Address Details</p>

              <div class="fw-normal">
                <div class="mt-3 mb-1 form-group">
                  <label class="fw-bold fs-14 ms-1 mb-2" for="address">Business address</label>
                  <input
                    tabindex="1"
                    id="address"
                    class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                    name="address"
                    type="text"
                    aria-invalid=""
                    v-model="
                      loanApplicationStore.userLoanApplications[
                        loanApplicationStore.defaultBusinessId
                      ].tab1GetStarted.businessAddress
                    "
                    placeholder="Insert your business address"
                    required />
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="inputCity" class="fw-bold fs-14 ms-1 mb-2">City</label>
                  <input
                    required
                    type="text"
                    v-model="
                      loanApplicationStore.userLoanApplications[
                        loanApplicationStore.defaultBusinessId
                      ].tab1GetStarted.businessCity
                    "
                    class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                    placeholder="Insert business city"
                    aria-label="Insert city"
                    id="inputCity" />
                </div>
                <div class="col-md-4 col-7 mt-md-0 mt-3">
                  <label for="inputState" class="fw-bold fs-14 ms-1 mb-2">State</label>
                  <select
                    required
                    id="inputState"
                    aria-label="Select state"
                    v-model="
                      loanApplicationStore.userLoanApplications[
                        loanApplicationStore.defaultBusinessId
                      ].tab1GetStarted.businessState
                    "
                    class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0 form-select"
                    :class="{ 'text-muted': state === '' }">
                    <option class="" value="" hidden>Select state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div class="col-md-2 col-5 mt-md-0 mt-3">
                  <label for="zip" class="fw-bold fs-14 ms-1 mb-2">Zip</label>
                  <input
                    id="zip"
                    name="zip"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]{5}"
                    title="Insert a valid 5 digits zip code"
                    v-model="
                      loanApplicationStore.userLoanApplications[
                        loanApplicationStore.defaultBusinessId
                      ].tab1GetStarted.businessZip
                    "
                    placeholder="Zip"
                    aria-label="Insert zip"
                    class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0" />
                </div>
              </div>

              <p class="mt-4 fs-12 fw-semi-bold text-muted">
                Social Details <i class="fw-normal"> - Optional</i>
              </p>

              <div class="fw-normal">
                <div class="mb-1 mt-3 form-group">
                  <label class="fw-bold fs-14 ms-1 mb-2" for="website">Website</label>
                  <input
                    tabindex="1"
                    id="website"
                    class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                    type="text"
                    aria-invalid=""
                    v-model="
                      loanApplicationStore.userLoanApplications[
                        loanApplicationStore.defaultBusinessId
                      ].social.website
                    "
                    placeholder="https://business-website.com"
                    @focus="handleWebsite()"
                    @blur="handleWebsite(true)"
                    @change="handleWebsite(true)" />
                </div>
              </div>

              <div class="row">
                <div class="mt-4 fw-normal col">
                  <div class="mb-1">
                    <label class="fw-bold fs-14 ms-1 mb-2" for="instagram">Instagram</label>
                    <input
                      tabindex="1"
                      id="instagram"
                      class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                      name="instagram"
                      type="text"
                      aria-invalid=""
                      v-model="
                        loanApplicationStore.userLoanApplications[
                          loanApplicationStore.defaultBusinessId
                        ].social.instagram
                      "
                      placeholder="Insert your instagram username" />
                  </div>
                </div>
                <div class="mt-4 fw-normal col">
                  <div class="mb-1">
                    <label class="fw-bold fs-14 ms-1 mb-2" for="facebook">Facebook</label>
                    <input
                      tabindex="1"
                      id="facebook"
                      class="mt-2 form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
                      name="facebook"
                      type="text"
                      aria-invalid=""
                      v-model="
                        loanApplicationStore.userLoanApplications[
                          loanApplicationStore.defaultBusinessId
                        ].social.facebook
                      "
                      placeholder="Insert your facebook username" />
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center justify-content-center mt-3">
                <button
                  class="btn shadow-sm sb-btn-secondary mt-4 mx-2"
                  @click="googleSearch = true"
                  style="height: 42px; width: 90px">
                  <span class="fs-14"> Back </span>
                </button>
                <button
                  class="btn shadow-sm sb-btn-primary mt-4 mx-2"
                  type="submit"
                  style="height: 42px; width: 90px">
                  <span class="fs-14"> Register</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
export default {
  props: {
    visible: Boolean,
    title: String,
    message: String,
    businesses: Array,
  },

  setup() {
    const loanApplicationStore = useLoanApplicationStore();

    const handleWebsite = (clear) => {
      const id = loanApplicationStore.defaultBusinessId;
      const website = loanApplicationStore.userLoanApplications[id].social.website;

      if (website === null || website === undefined) {
        loanApplicationStore.userLoanApplications[id].social.website = "https://";
        return;
      }

      if (clear && website === "https://") {
        loanApplicationStore.userLoanApplications[id].social.website = "";
        return;
      }

      if (!website.startsWith("http")) {
        loanApplicationStore.userLoanApplications[id].social.website = "https://" + website;
      }
    };

    return {
      loanApplicationStore,
      handleWebsite,
    };
  },

  data() {
    return {
      openClose: this.visible,
      businessSelected: null,
      googleSearch: true,
    };
  },

  computed: {
    businessSelectedInfo() {
      return this.businesses[this.businessSelected];
    },
  },

  watch: {
    visible: function (newVal) {
      this.openClose = newVal;
    },
  },
  emits: ["selectBusiness", "closeBusinessesResultsPopover", "addManualBusiness"],
};
</script>
<style></style>
