<template>
  <LoanApplicationFormContainer :tenant="tenant" :tenantId="tenantId" :grant="grant">
    <div class="row mx-md-5 mx-sm-4 mx-2">
      <p class="fs-18 fw-bold text-center mt-4 pt-2">
        Congratulations, your Application was submitted!
      </p>
      <p class="fs-16 text-center px-sm-5" style="opacity: 0.8">
        {{
          tenant.name !== "Shubox"
            ? "Thank you for submitting your application. Our team will review your information and get back to you as soon as possible."
            : "Shubox connects you with alternative lenders that are 2X more likely to approve small business loans than the big banks."
        }}
      </p>

      <div class="d-flex justify-content-center mt-2 pe-4">
        <img src="../../assets/loan_application_finish.svg" style="max-width: 280px; width: 90%" />
      </div>

      <p class="fs-16 text-center px-sm-5 fw-semi-bold mt-4 mb-sm-4 mb-0">
        {{
          tenant.name !== "Shubox" ? "" : "Here's the list of banks we sent your application to:"
        }}
      </p>
      <div class="container-fluid" :class="{ 'mb-3': authenticated, 'mb-5': !authenticated }">
        <div class="row px-sm-5 px-3">
          <div v-if="tenant.name !== 'Shubox'" class="d-flex justify-content-center">
            <LoanApplicationBankCard :bank="convertTenantToBank(tenant)" :isTenant="true" />
          </div>
          <div v-else v-for="(bank, index) in banks" :key="index" class="col-sm-6 mt-sm-0 mt-4">
            <LoanApplicationBankCard :bank="bank" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="authenticated" class="d-flex align-items-center justify-content-center mb-5">
      <router-link
        :to="{ name: 'Business', params: { id: businessId } }"
        class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2 fw-400"
        replace>
        <span class="fs-14">Go back to business page</span>
      </router-link>
    </div>
  </LoanApplicationFormContainer>
</template>

<script>
import LoanApplicationFormContainer from "@/components/loan_application/LoanApplicationFormContainer.vue";
import LoanApplicationBankCard from "./LoanApplicationBankCard.vue";

export default {
  props: {
    businessId: {
      type: String,
    },
    authenticated: {
      type: Boolean,
    },
    tenant: {
      type: Object,
    },
    tenantId: {
      type: String,
    },
    grant: {
      type: Boolean,
    },
    programDetails: {
      type: Object,
    },
  },

  setup() {
    const convertTenantToBank = (tenant) => {
      return {
        imagePath: tenant.imageUrl,
        name: tenant.name,
        link: tenant.website,
        email: tenant.email,
        phone: tenant.phoneNumber,
      };
    };

    return {
      convertTenantToBank,
    };
  },

  components: {
    LoanApplicationFormContainer,
    LoanApplicationBankCard,
  },
  data() {
    return {
      banks: [
        {
          imagePath: "https://i.imgur.com/ydCq2T4.png",
          imageName: "spring_bank",
          name: "Spring Bank",
          link: "https://www.spring.bank",
          email: "erosero@spring.bank",
          phone: "(718) 879 -5193",
        },
        {
          imagePath: "https://i.imgur.com/WAAsPxZ.png",
          imageName: "ponce_bank",
          name: "Ponce Bank",
          link: "https://www.poncebank.com/",
          email: "contact.center@poncebank.net ",
          phone: "718-931-9000",
        },
      ],
    };
  },
};
</script>

<style></style>
