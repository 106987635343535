<template>
  <div v-if="isAuthenticated" class="container-fuild">
    <div class="wrapper">
      <div class="fw-semi-bold fs-14 me-2 color-text-dark">Social Data</div>
      <hr class="divider" />
      <div v-if="!loading" class="dropdown">
        <p
          class="btn dropdown-toggle mb-0 py-0 text-muted fs-12 color-text-dark"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {{ currentSelection }}
          <i class="bi bi-chevron-down text-muted icon-bold-2 fs-10 ps-2"></i>
        </p>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button class="dropdown-item fs-10" @click="changeCurrentSelection('Last 3 months')">
              Last 3 months
            </button>
          </li>
          <li>
            <button class="dropdown-item fs-10" @click="changeCurrentSelection('Last 4 months')">
              Last 4 months
            </button>
          </li>
          <li>
            <button class="dropdown-item fs-10" @click="changeCurrentSelection('Last 6 months')">
              Last 6 months
            </button>
          </li>
        </ul>
      </div>
    </div>
    <p v-if="lastDate && !loading" class="fs-14 m-0" style="color: rgba(0, 0, 0, 0.6)">
      Last updated at {{ lastDate }}
    </p>
    <div v-if="!loading">
      <span v-for="business in businessPills" :key="business.id">
        <span
          @click="toggleBusinessPill(business.id)"
          class="p-2 me-3 mt-3 badge rounded-pill color-background-grey card-text p-0 m-0 fs-12 fw-normal color-text-dark pill-hover"
          :style="business.enabled ? { backgroundColor: business.color + '99 !important' } : ''">
          {{ business.businessName }} <i v-if="business.enabled" class="bi bi-pin-angle ms-1"></i>
        </span>
      </span>
    </div>
    <div class="col mt-3">
      <div class="row" v-if="filteredScoreGraphLabels || filteredReviewsGraphLabels">
        <div class="col-lg-6 p-2">
          <GenericChartCard
            title="Shubox Score"
            tooltipLabel="Your score explained"
            yAxisLabel="Score"
            yAxisLabelColor="#7201E4"
            tooltipContent="Shubox score characterizes a business based on intagram followers, google rating, web traffic, yelp reviews and financials."
            graphId="ShuboxScorePeerComparison"
            graphType="bar"
            :loading="loading"
            :graphLabels="filteredScoreGraphLabels"
            :graphDatasets="filteredScoreGraphDataset"></GenericChartCard>
        </div>
        <div class="col-lg-6 p-2">
          <GenericChartCard
            title="Business Reviews"
            subtitle="Number of reviews compared with peer group"
            graphId="BusinessReviewsPeerComparison"
            graphType="line"
            :loading="loading"
            :graphLabels="filteredReviewsGraphLabels"
            :graphDatasets="filteredReviewsGraphDataset"></GenericChartCard>
        </div>
      </div>
      <div class="row p-2">
        <GenericComparisonTable
          title="Business Online Presence Comparison"
          :tableData="tableData"
          :loading="loading"></GenericComparisonTable>
      </div>
    </div>
  </div>
</template>

<script>
import GenericChartCard from "./GenericChartCard.vue";
import GenericComparisonTable from "./GenericComparisonTable.vue";
import { useUserStore } from "@/stores/UserStore";
import { useRouter } from "vue-router";

import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";

export default {
  props: {
    peersComparison: {
      type: Object,
    },
    businessId: {},
    peersScores: {
      type: Object,
    },
    peersReviewsCount: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const userStore = useUserStore();
    const isAuthenticated = userStore.isAuthenticated;
    const currentSelection = ref("Last 4 months");
    const peerColors = ["#6082FB", "#FFB61D", "#16C79A", "#B3B3B3"];
    const mainBusinessColor = "#B16BF8";
    const colorPositive = "#E1FFEB";
    const colorNegative = "#FFE3E1";
    const businessPills = ref([]);
    const router = useRouter();

    const { d, n } = useI18n();

    const changeCurrentSelection = (selection) => {
      currentSelection.value = selection;
    };

    const populateBusinessPills = () => {
      businessPills.value = [];
      for (const [key, value] of Object.entries(props.peersComparison)) {
        if (key !== props.businessId) {
          businessPills.value.push({
            id: key,
            businessName: value.businessName,
            enabled: true,
            color: businessColor.value[key],
          });
        }
      }
    };

    const mainBusiness = computed(() => {
      if (!props.peersComparison) {
        return null;
      }
      populateBusinessPills();
      return {
        ...props.peersComparison[props.businessId],
        color: mainBusinessColor,
      };
    });

    const businessColor = computed(() => {
      const binding = {};
      binding[props.businessId] = mainBusinessColor;

      let i = 0;
      if (!props.peersComparison) {
        return binding;
      }

      for (const [key] of Object.entries(props.peersComparison)) {
        if (key !== props.businessId) {
          binding[key] = peerColors[i];
          i++;
        }
      }
      return binding;
    });

    const toggleBusinessPill = (id) => {
      const index = businessPills.value.findIndex((business) => business.id === id);
      businessPills.value[index].enabled = !businessPills.value[index].enabled;
    };

    const lastDate = computed(() => {
      if (!props.peersReviewsCount) {
        return null;
      }
      const mainBusinessData = props.peersReviewsCount[props.businessId];
      return mainBusinessData
        ? d(mainBusinessData[mainBusinessData?.length - 1].date, "fullMonthAndYear")
        : null;
    });

    const scoreGraphLabels = computed(() => {
      if (!props.peersScores) {
        return null;
      }
      const mainBusinessData = props.peersScores[props.businessId];

      return mainBusinessData ? mainBusinessData.map((data) => data.date) : null;
    });

    const scoreGraphDataset = computed(() => {
      if (!props.peersScores) {
        return null;
      }
      const dataset = [];

      const backgroundColor =
        props.businessId && businessColor?.value[props.businessId]
          ? businessColor?.value[props.businessId]
          : "#6082FB";

      dataset.push({
        label: mainBusiness?.value?.businessName ?? "Main Business",
        data: props.peersScores[props.businessId]?.map((data) => Math.round(data.value)),
        borderColor: businessColor?.value[props.businessId],
        backgroundColor: backgroundColor,
      });

      for (const [key, value] of Object.entries(props.peersScores)) {
        if (
          key !== props.businessId &&
          businessPills.value.find((business) => business.id === key)?.enabled
        ) {
          dataset.push({
            label: props.peersComparison[key]?.businessName ?? "Peer Business",
            data: value.map((data) => Math.round(data.value ?? 0)),
            borderColor: businessColor?.value[key],
            backgroundColor: businessColor?.value[key],
          });
        }
      }

      return dataset;
    });

    const reviewsGraphLabels = computed(() => {
      if (!props.peersReviewsCount) {
        return null;
      }
      const mainBusinessData = props.peersReviewsCount[props.businessId];

      return mainBusinessData ? mainBusinessData.map((data) => data.date) : null;
    });

    const reviewsGraphDataset = computed(() => {
      if (!props.peersReviewsCount) {
        return null;
      }
      const dataset = [];

      dataset.push({
        label: mainBusiness?.value?.businessName ?? "Main Business",
        data: props.peersReviewsCount[props.businessId]?.map((data) => data.value),
        borderColor: businessColor?.value[props.businessId],
        backgroundColor: businessColor?.value[props.businessId],
      });

      for (const [key, value] of Object.entries(props.peersReviewsCount)) {
        if (
          key !== props.businessId &&
          businessPills.value.find((business) => business.id === key)?.enabled
        ) {
          dataset.push({
            label: props.peersComparison[key]?.businessName ?? "Peer Business",
            data: value.map((data) => data.value ?? 0),
            borderColor: businessColor?.value[key],
            backgroundColor: businessColor?.value[key],
          });
        }
      }

      return dataset;
    });

    const tableHeader = ref({
      header: [
        { value: "" },
        { value: "Name", align: "left" },
        { value: "Avg Rating" },
        { value: "Website Users" },
        { value: "IG Followers" },
        { value: "Customer Sentiment" },
        { value: "FB Followers" },
        { value: "FB Likes" },
      ],
    });

    const tableBody = computed(() => {
      const body = [];
      body.push({
        data: [
          { valueType: "icon", color: mainBusinessColor },
          {
            value: mainBusiness?.value?.businessName ?? "Main Business",
            valueType: "basic",
            align: "left",
            url: router.resolve({ name: "Business", params: { id: props.businessId } }).href,
          },
          { value: mainBusiness?.value?.rating?.value ?? null, valueType: "rating" },
          {
            value: mainBusiness?.value?.websiteUsers?.value
              ? n(mainBusiness.value.websiteUsers.value, "integer")
              : "N/A",
            valueType: "pill",
          },
          {
            value: mainBusiness?.value?.instagramFollowers?.value
              ? n(mainBusiness.value.instagramFollowers.value, "integer")
              : "N/A",
            valueType: "pill",
          },
          {
            value: mainBusiness?.value?.positiveReviews?.value
              ? n(mainBusiness.value.positiveReviews.value, "percent") + " Positive"
              : "N/A",
            valueType: "pill",
            color:
              mainBusiness?.value?.positiveReviews?.value >= 0.5 ? colorPositive : colorNegative,
          },
          {
            value: mainBusiness?.value?.facebookFollowers?.value
              ? n(mainBusiness.value.facebookFollowers.value, "integer")
              : "N/A",
            valueType: "pill",
          },
          {
            value: mainBusiness?.value?.facebookLikes?.value
              ? n(mainBusiness.value.facebookLikes.value, "integer")
              : "N/A",
            valueType: "pill",
          },
        ],
      });

      if (!props.peersComparison) {
        return body;
      }

      for (const [key, value] of Object.entries(props.peersComparison)) {
        if (
          key !== props.businessId &&
          businessPills.value.find((business) => business.id === key)?.enabled
        ) {
          body.push({
            data: [
              { valueType: "icon", color: businessColor?.value[key] },
              {
                value: value.businessName ?? "Peer Business",
                valueType: "basic",
                align: "left",
                url: router.resolve({ name: "Business", params: { id: key } }).href,
              },
              { value: value.rating?.value ?? null, valueType: "rating" },
              {
                value: value.websiteUsers?.value ? n(value.websiteUsers.value) : "N/A",
                valueType: "pill",
              },
              {
                value: value.instagramFollowers?.value
                  ? n(value.instagramFollowers.value, "integer")
                  : "N/A",
                valueType: "pill",
              },
              {
                value: value.positiveReviews?.value
                  ? n(value.positiveReviews.value, "percent") + " Positive"
                  : "N/A",
                valueType: "pill",
                color: value.positiveReviews?.value >= 0.5 ? colorPositive : colorNegative,
              },
              {
                value: value.facebookFollowers?.value
                  ? n(value.facebookFollowers.value, "integer")
                  : "N/A",
                valueType: "pill",
              },
              {
                value: value.facebookLikes?.value ? n(value.facebookLikes.value, "integer") : "N/A",
                valueType: "pill",
              },
            ],
          });
        }
      }

      return body;
    });

    const currentCutDate = computed(() => {
      const middleCurrentMonth = new Date();
      middleCurrentMonth.setDate(15);

      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(middleCurrentMonth.getMonth() - 2);
      threeMonthsAgo.setDate(0);

      const fourMonthsAgo = new Date();
      fourMonthsAgo.setMonth(middleCurrentMonth.getMonth() - 3);
      fourMonthsAgo.setDate(0);

      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(middleCurrentMonth.getMonth() - 5);
      sixMonthsAgo.setDate(0);

      const currentCut =
        currentSelection?.value === "Last 3 months"
          ? threeMonthsAgo
          : currentSelection?.value === "Last 4 months"
          ? fourMonthsAgo
          : currentSelection?.value === "Last 6 months"
          ? sixMonthsAgo
          : new Date();

      return currentCut;
    });

    const filterDateLabels = (labels) => {
      if (!labels) {
        return null;
      }

      const filteredLabels = labels.filter((label) => {
        const labelDate = new Date(label);

        return labelDate > currentCutDate.value;
      });

      if (!filteredLabels || filteredLabels.length === 0) {
        return null;
      }

      return filteredLabels.map((label) => d(label, "fullMonthAndYear"));
    };

    const filteredScoreGraphLabels = computed(() => {
      if (!scoreGraphLabels.value) {
        return null;
      }

      return filterDateLabels(scoreGraphLabels.value);
    });

    const filteredScoreGraphDataset = computed(() => {
      if (!scoreGraphDataset.value) {
        return null;
      }

      const filteredDataset = scoreGraphDataset.value.map((dataset) => {
        if (!dataset.data) {
          return dataset;
        }
        const filteredData = dataset.data.filter((data, index) => {
          const labelDate = new Date(scoreGraphLabels.value[index]);

          return labelDate > currentCutDate.value;
        });

        return {
          ...dataset,
          data: filteredData,
        };
      });

      return filteredDataset;
    });

    const filteredReviewsGraphLabels = computed(() => {
      if (!reviewsGraphLabels.value) {
        return null;
      }

      return filterDateLabels(reviewsGraphLabels.value);
    });

    const filteredReviewsGraphDataset = computed(() => {
      if (!reviewsGraphDataset.value) {
        return null;
      }

      const filteredDataset = reviewsGraphDataset.value.map((dataset) => {
        if (!dataset.data) {
          return dataset;
        }

        const filteredData = dataset.data.filter((data, index) => {
          if (!reviewsGraphLabels.value || !reviewsGraphLabels.value[index]) {
            return false;
          }

          const labelDate = new Date(reviewsGraphLabels.value[index]);

          return labelDate > currentCutDate.value;
        });

        return {
          ...dataset,
          data: filteredData,
        };
      });

      return filteredDataset;
    });

    const tableData = computed(() => {
      return [tableHeader?.value, ...tableBody?.value];
    });

    return {
      currentSelection,
      changeCurrentSelection,
      isAuthenticated,
      tableData,
      mainBusiness,
      lastDate,
      businessColor,
      businessPills,
      toggleBusinessPill,
      filteredScoreGraphLabels,
      filteredScoreGraphDataset,
      filteredReviewsGraphLabels,
      filteredReviewsGraphDataset,
    };
  },

  components: {
    GenericChartCard,
    GenericComparisonTable,
  },
};
</script>

<style></style>
