<template>
  <div class="card border color-border h-100 px-2 parent">
    <div class="card-body pb-3">
      <div class="container m-0 p-0">
        <p class="card-title m-0 p-0 mb-1 color-text-dark fs-20">{{ title }}</p>
        <div class="mt-4">
          <label for="name" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
            >Business name</label
          >
          <div class="input-group p-0">
            <span
              class="input-group-text fs-14 color-background-filter color-border-filter px-1 border-end-0 color-border"
              id="addon-wrapping-start">
              <i class="bi bi-search text-muted ms-2 fs-14"></i>
            </span>
            <input
              v-model="name"
              type="text"
              @keyup.enter="interestFormFiltersChanged()"
              @change="name.trim().length == 0 ? interestFormFiltersChanged() : null"
              placeholder="Search"
              class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              aria-label="Name" />
          </div>
        </div>
        <div class="mt-3">
          <label for="startDate" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
            >Start date</label
          >
          <div class="input-group p-0">
            <input
              v-model="startDate"
              @change="checkValidDate(startDate) ? interestFormFiltersChanged() : null"
              type="date"
              class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              aria-label="startDate" />
          </div>
        </div>

        <div class="mt-3">
          <label for="endDate" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
            >End date</label
          >
          <div class="input-group p-0">
            <input
              v-model="endDate"
              type="date"
              @change="checkValidDate(endDate) ? interestFormFiltersChanged() : null"
              placeholder=""
              class="form-control color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              aria-label="endDate" />
          </div>
        </div>
        <div class="mt-3">
          <label for="status" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium">
            Application status
          </label>
          <div class="input-group p-0">
            <select
              class="form-select color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
              @change="interestFormFiltersChanged()"
              v-model="status"
              aria-label="Default select example">
              <option value="" selected>All status</option>
              <option value="SUBMITTED">Submitted</option>
              <option value="CONTACTED">Contacted</option>
              <option value="LOAN_APPLICATION_CREATED">Loan application created</option>
              <option value="NOT_INTERESTED">Not interested</option>
              <option value="CLOSED">Closed</option>
            </select>
          </div>
        </div>
        <div class="mt-3 mb-4">
          <label for="order" class="form-label fs-12 color-text-dark-grey mb-0 fw-medium"
            >Order by</label
          >
          <select
            class="form-select color-background-filter color-border-filter ps-2 fs-12 color-text-dark input-height"
            v-model="order"
            @change="interestFormFiltersChanged()"
            aria-label="Default select example">
            <option class="fs-12 color-background-filter" value="1">Submission old to new</option>
            <option class="fs-12 color-background-filter" value="2">Submission new to old</option>
            <option class="fs-12 color-background-filter" value="3">Status A to Z</option>
            <option class="fs-12 color-background-filter" value="4">Status Z to A</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "@vue/reactivity";
import { ref } from "vue";

export default {
  props: {
    title: { type: String, default: "Filter" },
  },

  setup(props, { emit }) {
    const name = ref("");
    const startDate = ref("");
    const endDate = ref("");
    const status = ref("");
    const order = ref(2);
    const showEndDate = ref(false);

    const orderByMap = {
      1: { orderBy: "date", orderByDirection: "asc" },
      2: { orderBy: "date", orderByDirection: "desc" },
      3: { orderBy: "status", orderByDirection: "asc" },
      4: { orderBy: "status", orderByDirection: "desc" },
    };

    const filters = computed(() => {
      const filters = {
        businessName: name.value && name.value.length ? name.value.trim() : null,
        startSubmissionDate:
          startDate.value && startDate.value.length
            ? new Date(startDate.value).toISOString()
            : null,
        endSubmissionDate:
          endDate.value && endDate.value.length ? new Date(endDate.value).toISOString() : null,
        status: status.value && status.value.length ? status.value : null,
        orderBy: orderByMap[order.value].orderBy, // ["date",  "status"]
        orderByDirection: orderByMap[order.value].orderByDirection, // ["asc", "desc"]
      };

      return filters;
    });

    const interestFormFiltersChanged = () => {
      emit("interestFormFiltersChanged", filters.value);
    };

    const checkValidDate = (date) => {
      if (date.length == 0) {
        return true;
      }
      if (date.length != 10) return false;
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      if (year < 1000 || month < 1 || month > 12 || day < 1 || day > 31) return false;
      return true;
    };

    return {
      name,
      startDate,
      endDate,
      status,
      order,
      showEndDate,
      filters,
      interestFormFiltersChanged,
      checkValidDate,
    };
  },
};
</script>
<style></style>
