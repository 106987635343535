<template>
  <Transition>
    <div
      v-if="openClose"
      class="modal fade show"
      id="financial-invitation"
      tabindex="-1"
      role="dialog"
      style="display: block; background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog" style="width: 366px">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-16 fw-semi-bold" id="exampleModalLabel">
              Invite Business Owner
            </h1>
            <button
              type="button"
              class="btn-close fs-12"
              style="width: 20px; height: 20px"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeDialog()"></button>
          </div>
          <div class="modal-body fs-12 mx-2">
            <p>Enter business owner details to send invitation to create an account.</p>
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control fs-12 color-background-grey color-border-light"
                  id="recipient-name"
                  placeholder="Name"
                  v-model="businessOwnerName" />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control fs-12 color-background-grey color-border-light"
                  id="email-text"
                  placeholder="Email"
                  v-model="businessOwnerEmail" />
              </div>

              <div v-if="previousInvitation">
                <br />
                <p class="fw-bold">A previous invitation exists!</p>

                <div>
                  <span class="fw-bold">Name:</span> {{ previousInvitation.businessOwnerName }}
                </div>
                <div>
                  <span class="fw-bold">Email:</span> {{ previousInvitation.businessOwnerEmail }}
                </div>
                <div>
                  <span class="fw-bold">Url: </span>
                  <a class="color-text-primary" :href="previousInvitation.invitationUrl">
                    Invitation</a
                  >
                </div>
                <div>
                  <span class="fw-bold">Sent on:</span>
                  {{ $d(previousInvitation.requestDate) }}
                </div>
                <div>
                  <span class="fw-bold">Expires on:</span>
                  {{ $d(previousInvitation.expirationDate) }}
                </div>
              </div>

              <div class="modal-footer border-0 pe-0">
                <button
                  type="button"
                  @click="closeDialog()"
                  class="btn btn-secondary d-flex align-items-center bg-white text-dark rounded fs-14">
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary d-flex align-items-center sb-btn-dark rounded fs-14"
                  :disabled="!readyToSendMessage"
                  @click.prevent="sendInvitation()">
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import ServiceAPI from "../services/ServiceAPI";
import { useToast } from "vue-toastification";

export default {
  name: "BusinessOwnereRegistrationInvite",
  props: {
    visible: Boolean,
    businessId: String,
  },

  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },

  data() {
    return {
      openClose: this.visible,
      businessOwnerName: null,
      businessOwnerEmail: null,
      previousInvitation: null,
    };
  },
  methods: {
    closeDialog() {
      this.openClose = false;
      this.$emit("toggle", this.openClose);
    },
    sendInvitation() {
      ServiceAPI.sendBusinessOwnerAccountRegistrationInvite(
        this.businessId,
        this.businessOwnerName,
        this.businessOwnerEmail
      )
        .then(() => {
          this.closeDialog();
          this.toast.success("Invitation sent successfully!");
        })
        .catch((e) => {
          console.log("error: " + e);
          this.closeDialog();
          this.toast.error("Error sending invitation. Try again later.");
        });
    },
  },
  computed: {
    readyToSendMessage() {
      return this.businessOwnerName != null && this.businessOwnerEmail != null;
    },
    website() {
      return window.location.origin;
    },
  },
  watch: {
    visible: function (newVal) {
      // watch it
      this.openClose = newVal;

      if (newVal && !this.previousInvitation) {
        ServiceAPI.getPendingBusinessOwnerRegistrationInvite(this.businessId)
          .then((r) => {
            this.previousInvitation = r.data;
          })
          .catch((e) => {
            console.log("No invitation: " + e);
          });
      }
    },
  },
  emits: ["toggle"],
};
</script>

<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
