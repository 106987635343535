<template>
  <div class="loanApplicationPage px-sm-5 px-4 color-background" style="overflow: hidden">
    <div class="d-flex justify-content-center my-4 pe-4 pb-2">
      <img src="../assets/shubox_black.svg" style="max-width: 145px; width: 90%" />
    </div>
    <LoanApplicationNoAccount />
  </div>
</template>

<script>
import LoanApplicationNoAccount from "@/components/loan_application/LoanApplicationNoAccount.vue";

export default {
  components: {
    LoanApplicationNoAccount,
  },
};
</script>

<style></style>
