<template>
  <div>
    <p class="fs-14 text-center m-0 mt-3">
      Check out our
      <a
        href="https://shubox.ai/faq/"
        target="_blank"
        rel="noreferrer"
        class="hover color-text-primary text-decoration-none"
        style="color: #7200e4">
        FAQ
      </a>
      and
      <a
        href="https://shubox.ai/privacy/"
        target="_blank"
        rel="noreferrer"
        class="hover color-text-primary text-decoration-none">
        Privacy Policy
      </a>
      for more information.
    </p>

    <p class="fs-14 text-center m-0 mt-2 pb-4">
      The Team at
      <a
        href="https://shubox.ai/"
        target="_blank"
        rel="noreferrer"
        class="hover color-text-primary text-decoration-none">
        Shubox
      </a>
    </p>
  </div>
  <div class="d-flex justify-content-center mb-4 pe-4 pb-2">
    <img src="../../assets/bottom_icons_1_no_bg.png" style="max-height: 40px" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
