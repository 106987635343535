<template>
  <div class="container-fluid mb-4 p-0">
    <div class="row">
      <div class="col-lg-8 col-12 p-2 d-none d-md-block">
        <TwoColumnTableCard
          title="Business Registration"
          :columnOneRows="columnOneBusinessRegistrationRows"
          :columnTwoRows="columnTwoBusinessRegistrationRows"
          size="lg"></TwoColumnTableCard>
      </div>
      <div class="col-lg-8 col-12 p-2 d-block d-md-none">
        <OneColumnTableCard
          title="Business Registration"
          :rows="businessRegistrationRows"></OneColumnTableCard>
      </div>
      <div class="col-lg-4 col-12 p-2">
        <OneColumnTableCard
          title="Business Industry"
          :rows="businessIndustryRows"></OneColumnTableCard>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12 p-2 d-none d-md-block">
        <TwoColumnTableCard
          title="SBA Loans Overview"
          :columnOneRows="columnOneSbaLoans"
          :columnTwoRows="columnTwoSbaLoans"></TwoColumnTableCard>
      </div>
      <div class="col-lg-6 col-12 p-2 d-block d-md-none">
        <OneColumnTableCard title="SBA Loans Overview" :rows="sbaLoansRows"></OneColumnTableCard>
      </div>
      <div class="col-lg-6 col-12 p-2">
        <GenericComparisonTable
          v-if="sbaLoansHistory?.length > 0"
          title="SBA Loans History"
          :tableData="tableData"></GenericComparisonTable>
        <EmptyCard v-else title="SBA Loans History" :message="sbaCardEmptyCardMessage"></EmptyCard>
      </div>
    </div>
  </div>
</template>

<script>
import TwoColumnTableCard from "@/components/journey_cards/TwoColumnTableCard.vue";
import OneColumnTableCard from "@/components/journey_cards/OneColumnTableCard.vue";
import GenericComparisonTable from "../benchmark_cards/GenericComparisonTable.vue";
import EmptyCard from "@/components/journey_cards/EmptyCard.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  props: {
    business: {
      type: Object,
    },

    externalInfo: {
      type: Object,
    },
  },

  components: {
    TwoColumnTableCard,
    OneColumnTableCard,
    GenericComparisonTable,
    EmptyCard,
  },

  setup(props) {
    const { n, d } = useI18n();

    const corporationRegistration = ref(props.externalInfo?.corporationRegistration);
    const industry = ref(props.externalInfo?.industry);
    const sbaLoans = ref(props.externalInfo?.sbaLoans);
    const sbaLoansHistory = ref(props.externalInfo?.sbaLoans?.sbaLoansHistory);

    const isDomesticColor =
      corporationRegistration?.value?.isDomestic === "domestic" ? "#b9eee1" : "#f0ddbf";

    const businessIndustryRows = [
      {
        label: "Description",
        value: industry?.value?.classificationDescription ?? "N/A",
      },
      {
        label: "Code",
        value: industry?.value?.classificationCode ?? "N/A",
      },
      {
        label: "Type",
        value: industry?.value?.classificationType ?? "N/A",
      },
    ];

    if (corporationRegistration?.value) {
      Object.keys(corporationRegistration?.value).forEach((key) => {
        if (corporationRegistration?.value[key] === "") {
          corporationRegistration.value[key] = null;
        }
      });
    }

    const columnOneBusinessRegistrationRows = [
      {
        label: "Legal name",
        value: corporationRegistration?.value?.businessName ?? "N/A",
      },
      {
        label: "State",
        value: corporationRegistration?.value?.state ?? "N/A",
      },
      {
        label: "File number",
        value: corporationRegistration?.value?.fileNumber ?? "N/A",
      },
    ];

    const columnTwoBusinessRegistrationRows = [
      {
        label: "Corporation structure",
        value: corporationRegistration?.value?.corporateStructure ?? "N/A",
      },
      {
        label: "Is domestic",
        value: corporationRegistration?.value?.isDomestic
          ? corporationRegistration?.value?.isDomestic === "domestic"
            ? "Yes"
            : "No"
          : "N/A",
        color: isDomesticColor,
      },
      {
        label: "Incorporation date",
        value: corporationRegistration?.value?.issuedDate ?? "N/A",
      },
    ];

    const columnOneSbaLoans = [
      {
        label: "Total loans number",
        value: sbaLoans?.value?.totalNumber ?? "N/A",
      },
      {
        label: "Total loans amount",
        value:
          typeof sbaLoans?.value?.totalValue === "number"
            ? n(sbaLoans.value.totalValue, "currency")
            : "N/A",
        type: "currency",
      },
      {
        label: "Total number of charged off",
        value: sbaLoans?.value?.totalNumberChargedOff ?? "N/A",
      },
    ];

    const columnTwoSbaLoans = [
      {
        label: "Total loans active",
        value: sbaLoans?.value?.totalNumberActive ?? "N/A",
      },
      {
        label: "Total amount active",
        value:
          typeof sbaLoans?.value?.totalValueActive === "number"
            ? n(sbaLoans?.value?.totalValueActive, "currency")
            : "N/A",
        type: "currency",
      },
      {
        label: "Total amount charged off",
        value:
          typeof sbaLoans?.value?.totalValueChargedOff === "number"
            ? n(sbaLoans?.value?.totalValueChargedOff, "currency")
            : "N/A",
        type: "currency",
      },
    ];

    const businessRegistrationRows = columnOneBusinessRegistrationRows.concat(
      columnTwoBusinessRegistrationRows
    );

    const sbaLoansRows = columnOneSbaLoans.concat(columnTwoSbaLoans);

    const sbaCardEmptyCardMessage = computed(() => {
      if (!sbaLoans?.value) {
        return "We regret to inform you that we couldn't find SBA loan information for this business.";
      }

      if (props.business?.businessInfo?.name) {
        return (
          "There are no records indicating that " +
          props.business?.businessInfo?.name +
          " has previously applied for or received an SBA loan."
        );
      } else {
        return "There are no records indicating that the business has previously applied for or received an SBA loan.";
      }
    });

    const tableHeader = ref({
      header: [
        { value: "Date", align: "center" },
        { value: "Amount" },
        { value: "Status" },
        { value: "Type" },
        { value: "Term" },
      ],
    });

    const tableBody = computed(() => {
      const body = [];
      for (let i = 0; i < sbaLoansHistory?.value?.length; i++) {
        body.push({
          data: [
            {
              value: sbaLoansHistory.value[i].date
                ? d(sbaLoansHistory.value[i].date, "dateNumeric")
                : "-",
              valueType: "pill",
              align: "center",
            },
            {
              value:
                typeof sbaLoansHistory.value[i]?.value === "number"
                  ? n(sbaLoansHistory.value[i].value, "currency")
                  : "N/A",
              valueType: "currency",
            },
            {
              value: sbaLoansHistory.value[i].status ?? "-",
              valueType: "pill",
              color:
                sbaLoansHistory.value[i].status === "PIF"
                  ? "#b9eee1"
                  : sbaLoansHistory.value[i].status
                  ? "#fcd9d9"
                  : null,
            },
            {
              value: sbaLoansHistory.value[i].type ?? "-",
              valueType: "pill",
            },
            {
              value: sbaLoansHistory.value[i].term + " months",
              valueType: "pill",
            },
          ],
        });
      }

      return body;
    });

    const tableData = computed(() => {
      return [tableHeader?.value, ...tableBody?.value];
    });

    return {
      businessIndustryRows,
      columnOneBusinessRegistrationRows,
      columnTwoBusinessRegistrationRows,
      columnOneSbaLoans,
      columnTwoSbaLoans,
      businessRegistrationRows,
      sbaLoansRows,
      sbaCardEmptyCardMessage,
      corporationRegistration,
      industry,
      sbaLoans,
      sbaLoansHistory,
      tableData,
    };
  },
};
</script>

<style></style>
