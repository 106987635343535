<template>
  <div class="col d-flex justify-content-center" v-if="hasData">
    <h3 class="coltext-center mt-5 mb-2 ms-3 color-text-dark fs-20 fw-semi-bold">
      Latest searches
    </h3>
  </div>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center pb-xl-0 pb-5" v-if="hasData">
      <div class="col-auto mt-4" v-for="business in businesses.businesses" :key="business.id">
        <SingleResultCompact :business="business"></SingleResultCompact>
      </div>
    </div>
  </div>
</template>

<script>
import SingleResultCompact from "./SingleResultCompact.vue";
export default {
  props: ["businesses"],
  components: { SingleResultCompact },

  data() {
    return {};
  },
  computed: {
    hasData() {
      return this.businesses?.businesses?.length > 0 ?? false;
    },
  },
};
</script>

<style></style>
