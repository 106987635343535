<template>
  <div class="card color-background-form h-100 border-0">
    <div class="container-fluid my-2">
      <img v-if="bank?.imagePath" :src="bank.imagePath" style="max-width: 105px; width: 90%" />
      <p class="fs-10 fw-bold mt-2 mb-1 ms-1">
        {{ bank.name }}
      </p>
      <p class="fs-10 p-0 mb-1 ms-1">{{ link }}</p>
      <p class="fs-10 p-0 mb-1 ms-1">Email: {{ bank.email }}</p>
      <!-- <p class="fs-10 p-0 mb-2 ms-1">Phone: {{ bank.phone }}</p> -->
      <p class="fs-10 p-0 mb-2 ms-1">
        Website:
        <a
          :href="bank.link"
          class="hover color-text-primary text-decoration-none"
          target="_blank"
          >{{ bank.link }}</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bank: {
      type: Object,
    },
    isTenant: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
