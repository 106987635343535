<template>
  <div class="card border color-border h-100 card-mh-2">
    <div class="card-body">
      <div class="position-relative">
        <i
          v-if="!loadSkeleton"
          class="bi bi-clock circle-icon position-absolute color-text-primary top-0 end-0"
          :class="[this.googleWebsite ? 'color-text-primary hover-2' : 'text-muted']"
          @click="
            {
              this.googleWebsite ? openWindow(googleWebsite) : null;
            }
          "></i>
        <div class="me-4 pe-3">
          <h6 class="card-subtitle mb-2 text-muted fs-10 fw-semi-bold font-spacing pt-1">
            SCHEDULE
          </h6>
          <div v-if="loadSkeleton">
            <content-loader
              class="d-sm-block d-none pt-2"
              viewBox="0 0 170 60"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="3" ry="3" width="160" height="10" />
              <rect x="0" y="15" rx="3" ry="3" width="170" height="10" />
              <rect x="0" y="35" rx="3" ry="3" width="150" height="10" />
              <rect x="0" y="50" rx="3" ry="3" width="140" height="10" />
            </content-loader>
            <content-loader
              class="d-sm-none d-block pt-2"
              viewBox="0 0 340 60"
              :speed="2"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="3" ry="3" width="320" height="10" />
              <rect x="0" y="15" rx="3" ry="3" width="340" height="10" />
              <rect x="0" y="35" rx="3" ry="3" width="300" height="10" />
              <rect x="0" y="50" rx="3" ry="3" width="280" height="10" />
            </content-loader>
          </div>
          <div v-else>
            <div class="p-0" v-if="schedule">
              <div v-for="item in schedule" v-bind:key="item.day">
                <p class="card-text m-0 fs-12 fw-semi-bold color-text-dark">{{ item.day }}</p>
                <p class="card-text m-0 fs-12 color-text-dark">{{ item.schedule }}</p>
              </div>
            </div>
            <span v-else class="text-break fs-20 fw-semi-bold color-text-dark font-spacing-sm">
              -
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  props: ["schedule", "googleWebsite", "loading"],
  components: { ContentLoader },

  setup(props) {
    const loadSkeleton = props.loading && !props.schedule?.length;
    return { loadSkeleton };
  },

  methods: {
    openWindow(url) {
      if (this.googleWebsite) window.open(url, "_blank");
    },
  },
};
</script>

<style></style>
