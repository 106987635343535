<template>
  <div class="plaid-link-wrapper">
    <slot name="button" v-bind:onClick="handleOnClick">
      <button
        type="button"
        class="btn rounded fs-13 border-0 px-3"
        :class="this.buttonType === 'dark' ? 'sb-btn-dark' : 'sb-btn-primary'"
        @click="handleOnClick"
        :disabled="!token">
        <slot />
      </button>
    </slot>
  </div>
</template>

<script>
export default {
  name: "PlaidLink",
  props: {
    plaidUrl: {
      type: String,
      default: "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
    },
    institution: String,
    selectAccount: Boolean,
    token: String,
    product: {
      type: [String, Array],
      default: function () {
        return ["transactions"];
      },
    },
    language: String,
    countryCodes: Array,
    isWebView: Boolean,
    clientName: String,
    publicKey: String,
    webhook: String,
    onLoad: Function,
    onSuccess: Function,
    onExit: Function,
    onEvent: Function,
    buttonType: String,
  },
  created() {
    this.loadScript(this.plaidUrl).then(this.onScriptLoaded).catch(this.onScriptError);
  },
  beforeUnmount() {
    if (window.linkHandler) {
      window.linkHandler.exit();
    }
  },
  methods: {
    onScriptError(error) {
      console.error("There was an issue loading the link-initialize.js script", error);
    },
    async onScriptLoaded() {
      while (!window.Plaid) {
        await new Promise((resolve) => setTimeout(resolve, 200));
      }
      window.linkHandler = window.Plaid.create({
        clientName: this.clientName,
        key: this.publicKey,
        onExit: this.onExit,
        onEvent: this.onEvent,
        onSuccess: this.onSuccess,
        product: this.product,
        selectAccount: this.selectAccount,
        token: this.token,
        webhook: this.webhook,
      });
      this.$emit("plaidLoaded");
      if (this.onLoad) {
        this.onLoad();
      }
    },
    handleOnClick() {
      const institution = this.institution || null;
      if (window.linkHandler) {
        window.linkHandler.open(institution);
      }
    },
    loadScript(src) {
      return new Promise(function (resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
          resolve();
          return;
        }
        const el = document.createElement("script");
        el.type = "text/javascript";
        el.async = true;
        el.src = src;
        el.addEventListener("load", resolve);
        el.addEventListener("error", reject);
        el.addEventListener("abort", reject);
        document.head.appendChild(el);
      });
    },
  },
  watch: {
    $props: {
      handler(newVal) {
        if (newVal.token) {
          this.onScriptLoaded();
        }
      },
      deep: true,
    },
  },
};
</script>
