<template>
  <MainLayout>
    <LoansDashboardNavbar
      :page="page"
      :periodFilter="periodFilter"
      :valueTypeFilter="valueTypeFilter"
      :programs="tenantPrograms"
      :programFilter="tenantProgramFilter"
      @changePage="changePage"
      @changePeriodFilter="changePeriodFilter"
      @changeValueTypeFilter="changeValueTypeFilter"
      @changeProgramFilter="changeProgramFilter"
      @exportLoansCsv="exportLoansCsv" />
    <component
      :is="currentTab"
      :overviewSmallCards="overviewSmallCards"
      :portfolioPerformance="portfolioPerformance"
      :applicationsInEachStage="applicationsInEachStage"
      :periodFilter="periodFilter"
      :valueTypeFilter="valueTypeFilter"
      :loading="loading"
      :itemsPerPage="itemsPerPaginationPage"
      :dashboardLoanApplications="dashboardLoanApplications"
      :applicationsCurrentPage="applicationsPaginationCurrentPage"
      :applicationsTotalPages="applicationsTotalPaginationPages"
      :dashboardInterestForms="dashboardInterestForms"
      :interestFormsCurrentPage="interestFormsPaginationCurrentPage"
      :interestFormsTotalPages="interestFormsTotalPaginationPages"
      :tenantStatus="tenantStatus"
      :programs="tenantPrograms"
      :tenantList="tenantList"
      @goToPage="goToPaginationPage"
      @previousPage="previousPaginationPage"
      @nextPage="nextPaginationPage"
      @applicationsFiltersChanged="applicationsFiltersChanged"
      @interestFormFiltersChanged="interestFormFiltersChanged"
      class="tab"></component>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";
import InterestFormsTab from "@/components/loans_dashboard/InterestFormsTab.vue";
import LoansDashboardNavbar from "@/components/loans_dashboard/LoansDashboardNavbar.vue";
import LoansPortfolioTab from "@/components/loans_dashboard/LoansPortfolioTab.vue";
import OverviewTab from "@/components/loans_dashboard/OverviewTab.vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import { useUserStore } from "@/stores/UserStore.js";
import { computed } from "@vue/reactivity";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    MainLayout,
    LoansDashboardNavbar,
    OverviewTab,
    LoansPortfolioTab,
    InterestFormsTab,
  },

  props: {
    tab: {
      type: String,
      default: "overview-page",
    },
  },

  setup(props) {
    const userStore = useUserStore();
    const pages = ["overview-page", "loans-portfolio-page", "interest-forms-page"];
    const page = ref(pages.includes(props.tab) ? props.tab : "overview-page");
    const router = useRouter();
    const applicationsFilters = ref({
      businessName: null,
      startSubmissionDate: null,
      endSubmissionDate: null,
      status: null,
      orderBy: null, // ["date", "amount", "status", "score"
      orderByDirection: null, // ["asc", "desc"]
      lowAmount: null,
      highAmount: null,
      assignedUserId: null,
      programId: null,
    });

    const interestFormsFilters = ref({
      businessName: null,
      startSubmissionDate: null,
      endSubmissionDate: null,
      status: null,
      orderBy: null, // ["date", "status"
      orderByDirection: null, // ["asc", "desc"]
      lowAmount: null,
      highAmount: null,
    });

    const itemsPerPaginationPage = 25;
    const applicationsPaginationCurrentPage = ref(1);
    const applicationsTotalPaginationPages = ref(1);
    const interestFormsPaginationCurrentPage = ref(1);
    const interestFormsTotalPaginationPages = ref(1);

    const periodFilter = ref("MONTHLY");
    const valueTypeFilter = ref("Total count");
    const tenantProgramFilter = ref(0);
    const overviewSmallCards = ref({});
    const loading = ref({
      simpleCardsLoading: false,
      portfolioPerformanceLoading: false,
      applicationsInEachStageLoading: false,
      applicationsTableLoading: false,
    });
    const portfolioPerformance = ref({});
    const applicationsInEachStage = ref({});
    const dashboardLoanApplications = ref({});
    const dashboardInterestForms = ref({});
    const tenantStatus = ref([]);
    const tenantList = ref([]);
    const tenantPrograms = ref({});

    const currentTab = computed(() => {
      if (page.value === "loans-portfolio-page") {
        return LoansPortfolioTab;
      } else if (page.value === "interest-forms-page") {
        return InterestFormsTab;
      } else {
        return OverviewTab;
      }
    });

    const changePeriodFilter = async (newPeriod) => {
      periodFilter.value = newPeriod;
      Promise.all([loadOverviewSmallCards(), loadApplicationsInEachStage()]);
      router.push({
        params: { tab: page.value },
        query: {
          period: newPeriod,
          valueType: valueTypeFilter.value,
          program: tenantProgramFilter.value,
        },
      });
    };

    const changeValueTypeFilter = (newValueType) => {
      valueTypeFilter.value = newValueType;
      router.push({
        params: { tab: page.value },
        query: {
          period: periodFilter.value,
          valueType: newValueType,
          program: tenantProgramFilter.value,
        },
      });
    };

    const changeProgramFilter = (newProgram) => {
      tenantProgramFilter.value = newProgram;
      Promise.all([loadTenantStatus(), loadOverviewSmallCards(), loadApplicationsInEachStage()]);
      router.push({
        params: { tab: page.value },
        query: {
          period: periodFilter.value,
          valueType: valueTypeFilter.value,
          program: newProgram,
        },
      });
    };

    const changePage = (newPage) => {
      page.value = newPage;
      router.push({
        params: { tab: newPage },
        query: {
          period: periodFilter.value,
          valueType: valueTypeFilter.value,
          program: tenantProgramFilter.value,
        },
      });
    };

    const goToPaginationPage = async (newPage) => {
      if (page.value === "loans-portfolio-page") {
        applicationsPaginationCurrentPage.value = newPage;
        await loadDashboardLoanApplications();
      }
    };

    const previousPaginationPage = async () => {
      if (page.value === "loans-portfolio-page") {
        if (applicationsPaginationCurrentPage.value > 1) {
          applicationsPaginationCurrentPage.value -= 1;
          await loadDashboardLoanApplications();
        }
      }
    };

    const nextPaginationPage = async () => {
      if (page.value === "loans-portfolio-page") {
        if (applicationsPaginationCurrentPage.value < applicationsTotalPaginationPages.value) {
          applicationsPaginationCurrentPage.value += 1;
          await loadDashboardLoanApplications();
        }
      }
    };

    const loadOverviewSmallCards = async () => {
      loading.value.simpleCardsLoading = true;
      try {
        const response = await ServiceAPI.getLoansDashboardSmallCards(
          periodFilter.value,
          tenantProgramFilter.value
        );
        overviewSmallCards.value = response.data;
        loading.value.simpleCardsLoading = false;
      } catch (error) {
        console.log(error);
        loading.value.simpleCardsLoading = false;
      }
    };

    const loadApplicationsInEachStage = async () => {
      loading.value.applicationsInEachStageLoading = true;
      try {
        const response = await ServiceAPI.getApplicationsInEachStage(
          periodFilter.value,
          tenantProgramFilter.value
        );
        applicationsInEachStage.value = response.data;
        loading.value.applicationsInEachStageLoading = false;
      } catch (error) {
        console.log(error);
        loading.value.applicationsInEachStageLoading = false;
      }
    };

    const loadPortfolioPerformance = async () => {
      loading.value.portfolioPerformanceLoading = true;

      try {
        const response = await ServiceAPI.getLoansDashboardPortfolioPerformance();
        portfolioPerformance.value = response.data;
        loading.value.portfolioPerformanceLoading = false;
      } catch (error) {
        console.log(error);
        loading.value.portfolioPerformanceLoading = false;
      }
    };

    const loadDashboardLoanApplications = async () => {
      loading.value.applicationsTableLoading = true;
      try {
        const response = await ServiceAPI.getDashboardLoanApplications(
          applicationsFilters.value.businessName,
          applicationsFilters.value.ownerEmail,
          applicationsFilters.value.startSubmissionDate,
          applicationsFilters.value.endSubmissionDate,
          applicationsFilters.value.status,
          applicationsFilters.value.orderBy,
          applicationsFilters.value.orderByDirection,
          applicationsFilters.value.lowAmount,
          applicationsFilters.value.highAmount,
          applicationsFilters.value.assignedUserId,
          applicationsFilters.value.programId,
          applicationsPaginationCurrentPage.value,
          itemsPerPaginationPage
        );
        dashboardLoanApplications.value = response.data;
        applicationsTotalPaginationPages.value = Math.ceil(
          response.data.total / itemsPerPaginationPage
        );
        loading.value.applicationsTableLoading = false;
      } catch (error) {
        console.log(error);
        loading.value.applicationsTableLoading = false;
      }
    };

    const loadTenantPrograms = async () => {
      const response = await ServiceAPI.getTenantPrograms();
      tenantPrograms.value = response.data;
    };

    const exportLoansCsv = async () => {
      try {
        ServiceAPI.exportLoansCsv(
          applicationsFilters.value.businessName,
          applicationsFilters.value.startSubmissionDate,
          applicationsFilters.value.endSubmissionDate,
          applicationsFilters.value.status,
          applicationsFilters.value.orderBy,
          applicationsFilters.value.orderByDirection,
          applicationsFilters.value.lowAmount,
          applicationsFilters.value.highAmount,
          applicationsFilters.value.assignedUserId
        );
      } catch (error) {
        console.log(error);
      }
    };

    const loadDashboardInterestForms = async () => {
      loading.value.applicationsTableLoading = true;
      try {
        const response = await ServiceAPI.getDashboardInterestForms(
          interestFormsFilters.value.businessName,
          interestFormsFilters.value.startSubmissionDate,
          interestFormsFilters.value.endSubmissionDate,
          interestFormsFilters.value.status,
          interestFormsFilters.value.orderBy,
          interestFormsFilters.value.orderByDirection,
          interestFormsPaginationCurrentPage.value,
          itemsPerPaginationPage
        );
        dashboardInterestForms.value = response.data;
        interestFormsTotalPaginationPages.value = Math.ceil(
          response.data.total / itemsPerPaginationPage
        );
        loading.value.applicationsTableLoading = false;
      } catch (error) {
        console.log(error);
        loading.value.applicationsTableLoading = false;
      }
    };

    const loadTenantStatus = async () => {
      try {
        const response = await ServiceAPI.getTenantAvailableStatuses(tenantProgramFilter.value);
        tenantStatus.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadTenantList = async () => {
      try {
        const response = await ServiceAPI.getTenantList();
        tenantList.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const applicationsFiltersChanged = async (newFilters) => {
      applicationsPaginationCurrentPage.value = 1;
      applicationsFilters.value = newFilters;
      await loadDashboardLoanApplications();
    };

    const interestFormFiltersChanged = async (newFilters) => {
      interestFormsFilters.value = newFilters;
      await loadDashboardInterestForms();
    };

    onMounted(async () => {
      if (!(userStore.role === "loan-officer" || userStore.role === "admin")) {
        router.push({ name: "PageNotFound" });
      }

      if (router.currentRoute.value.query.period) {
        periodFilter.value = router.currentRoute.value.query.period;
      }
      if (router.currentRoute.value.query.valueType) {
        valueTypeFilter.value = router.currentRoute.value.query.valueType;
      }
      if (router.currentRoute.value.query.program) {
        tenantProgramFilter.value = router.currentRoute.value.query.program;
      }

      Promise.all([
        loadTenantPrograms(),
        loadOverviewSmallCards(),
        loadApplicationsInEachStage(),
        loadDashboardLoanApplications(),
        loadTenantStatus(),
        loadTenantList(),
      ]);
    });

    return {
      page,
      pages,
      changePage,
      periodFilter,
      valueTypeFilter,
      changePeriodFilter,
      changeValueTypeFilter,
      currentTab,
      overviewSmallCards,
      loading,
      portfolioPerformance,
      applicationsInEachStage,
      dashboardLoanApplications,
      goToPaginationPage,
      previousPaginationPage,
      nextPaginationPage,
      applicationsPaginationCurrentPage,
      applicationsTotalPaginationPages,
      applicationsFiltersChanged,
      dashboardInterestForms,
      interestFormsPaginationCurrentPage,
      interestFormsTotalPaginationPages,
      interestFormFiltersChanged,
      tenantStatus,
      exportLoansCsv,
      tenantList,
      tenantPrograms,
      tenantProgramFilter,
      changeProgramFilter,
      applicationsFilters,
      loadPortfolioPerformance,
    };
  },
};
</script>

<style></style>
