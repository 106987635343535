<template>
  <list-result-item :pointer="false">
    <div class="col-lg-3 col-8 d-flex align-items-center px-1 ps-4 my-1">
      <img
        :src="loanApplication.business.info.img"
        width="32"
        height="32"
        class="rounded-circle border color-border-grey" />
      <p class="m-0 ms-3 fs-16 fw-semi-bold">
        <router-link
          class="hover color-text-dark text-decoration-none"
          :to="{
            name: 'Business',
            params: { id: loanApplication.business.id },
          }"
          target="_blank">
          {{ loanApplication.business.info.name }}
        </router-link>
      </p>
    </div>
    <div class="col-lg-1 col-4 d-flex align-items-center px-lg-1 my-1 ps-4 d-none d-lg-block">
      <p
        class="fs-24 fw-bold m-0 my-1"
        :class="
          loanApplication.business.isNew
            ? 'text-muted'
            : loanApplication.business.score <= 24
            ? 'color-text-negative'
            : loanApplication.business.score <= 37
            ? 'color-text-neutral'
            : 'color-text-positive'
        ">
        {{ loanApplication.business.score ?? (loanApplication.business.isNew ? "?" : "N/A") }}
      </p>
    </div>
    <div class="col-lg-2 d-flex align-items-center lh-1 px-lg-1 my-1 ps-4">
      <div>
        <p class="m-0 p-0 fs-12 me-3">
          {{ this.loanApplication.details.businessAddress }}
        </p>
        <a
          @click.stop
          :href="'https://www.google.com/maps/place/' + loanApplication.business.address.address"
          v-if="loanApplication.business.address.address"
          target="_blank"
          class="card-link color-text-primary m-0 text-decoration-none align-middle fs-12 hover">
          View maps
        </a>
        <p class="p-0 m-0 fs-12" v-else>N/A</p>
      </div>
    </div>
    <div class="col-lg-2 d-flex align-items-center px-lg-1 my-1 ps-4">
      <p class="p-0 m-0 fs-12">
        {{ $d(loanApplication.submissionTimestamp, "dateOnly") }}
      </p>
    </div>
    <div class="col-lg-1 d-flex align-items-center px-lg-1 my-1 ps-4">
      <p class="p-0 m-0 fs-12">
        {{ $n(loanApplication.details.loanAmount, "currency") }}
      </p>
    </div>
    <div class="col-lg-3 align-items-center px-lg-1 my-1 overflow-hidden">
      <div class="d-flex position-relative">
        <div class="justify-content-right">
          <div class="align-left ms-1 me-1 p-0">
            <p
              class="p-2 me-1 my-1 badge rounded-pill wrap p-0 m-0 fs-12 fw-normal color-background-success">
              {{ loanApplication.status.toLowerCase() }}
            </p>
          </div>
        </div>
        <div
          class="d-flex align-items-center align-right justify-content-end position-absolute bottom-0 end-0 me-2 color-background-transparent">
          <router-link
            v-if="loanApplication.business.info"
            class="card-link color-text-primary m-0 p-0 ps-3 py-2 color-background-transparent border-0 fs-14 pe-3 d-none d-xl-block hover text-decoration-none"
            :to="{
              name: 'LoanApplicationSummary',
              params: { loanApplicationId: loanApplication.loanApplicationId },
            }"
            target="_blank">
            View Application
          </router-link>
        </div>
      </div>
    </div>
  </list-result-item>
</template>
<script>
import ListResultItem from "@/components/ListResultItem.vue";

export default {
  props: ["loanApplication"],
  setup() {},
  components: {
    ListResultItem,
  },
};
</script>
