<script setup>
import { useUserStore } from "@/stores/UserStore";
import { useRouter } from "vue-router";

const userStore = useUserStore();
const router = useRouter();

if (userStore.role === "business-owner") {
  router.replace({ name: "MyBusinesses" });
} else if (userStore.role === "loan-officer") {
  router.replace({ name: "LoansDashboardPage", params: { tab: "overview-page" } });
} else {
  router.replace({ name: "Search" });
}
</script>
