<template>
  <NavBar v-if="openPage"></NavBar>
  <div class="search" :class="isXl ? 'h-auto' : 'h-100'">
    <div class="container-fluid h-100">
      <div class="row flex-nowrap h-100">
        <div class="d-flex col-auto p-0">
          <SideBar
            v-if="!openPage"
            :class="open ? 'fixed-top shadow-sm' : 'd-none d-xl-block'"
            :user="userStore"
            style="z-index: 1"></SideBar>
          <span class="d-xl-none d-block">
            <nav
              v-if="!openPage"
              class="navbar fixed-top d-flex align-items-center justify-content-between navbar-light bg-white border-bottom color-border py-2 shadow-sm"
              style="z-index: 2">
              <button
                class="fs-20 ms-2 me-2 border-0 hover-shadow text-decoration-none bg-white hover-2 py-1"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleOpen()">
                <i class="bi fs-28" :class="open ? 'bi-x' : 'bi-list'"></i>
              </button>
              <div class="text-center">
                <span>
                  <img src="../assets/shubox_black.svg" />
                </span>
              </div>
              <div style="padding-left: 56px"></div>
            </nav>
          </span>
        </div>
        <div
          class="p-0 col bg-white"
          @click="open = false"
          :style="open ? 'overflow: hidden; filter: brightness(70%);' : ''">
          <div class="p-0" :style="open ? 'overflow: hidden;   pointer-events: none;' : ''">
            <div
              class="container-fluid color-background"
              style="padding-top: 25vh; padding-bottom: 25vh; min-height: 50%; max-height: 60%">
              <SearchSection :credits="this.credits"></SearchSection>
            </div>
            <div class="container-fluid bg-white mb-5">
              <LatestSearches
                v-if="highlightedBusinesses"
                :businesses="highlightedBusinesses"></LatestSearches>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";
import ServiceAPI from "@/services/ServiceAPI.js";
import SearchSection from "@/components/SearchSection.vue";
import LatestSearches from "@/components/LatestSearches.vue";
import { useUserStore } from "@/stores/UserStore";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    openPage: Boolean,
  },

  setup(props) {
    const highlightedBusinesses = ref({});
    const credits = ref(11);
    const isXl = ref(window.innerWidth >= 1200);

    const userStore = useUserStore();

    const loadHighlightedBusinesses = async (registeredUser) => {
      try {
        const response = registeredUser
          ? await ServiceAPI.getLatestSearches()
          : await ServiceAPI.getSampleBusinesses();
        highlightedBusinesses.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const loadCredits = async () => {
      try {
        const response = await ServiceAPI.getUserCredits();
        credits.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      if (!props.openPage) {
        loadHighlightedBusinesses(true);
        loadCredits();
      } else {
        if (userStore.isAuthenticated) {
          useRouter().replace({ name: "Search" });
        } else {
          loadHighlightedBusinesses(false);
        }
      }
    });

    const open = ref(false);

    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        open.value = false;
        isXl.value = true;
      } else {
        isXl.value = false;
      }
    };

    const toggleOpen = () => {
      open.value = !open.value;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      highlightedBusinesses,
      credits,
      userStore,
      open,
      toggleOpen,
      isXl,
    };
  },

  components: {
    SideBar,
    NavBar,
    SearchSection,
    LatestSearches,
  },

  data() {
    return {
      page: "search",
      google: {
        name: "Google",
        imageUrl: "https://freesvg.org/img/1534129544.png",
      },

      yelp: {
        name: "Yelp",
        imageUrl: "https://cdn-icons-png.flaticon.com/512/174/174882.png",
      },
    };
  },
};
</script>

<style></style>
