import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const useTourStore = defineStore("tourStore", {
  state: () => ({
    businessPage: useStorage("tour-businessPage", false),
  }),

  actions: {
    isBusinessPageToured() {
      return this.businessPage;
    },
    toggleBusinessPageToured() {
      this.businessPage = true;
    },
  },
});
