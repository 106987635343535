<template>
  <MainLayout :hideSidebar="true">
    <LoanInterestForm :tenant="tenant" :tenantExternalId="tenantId"> </LoanInterestForm>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";
import LoanInterestForm from "@/components/loan_application/loan_interest_form/LoanInterestForm.vue";
import { useRouter } from "vue-router";
import ServiceAPI from "@/services/ServiceAPI";
import { onMounted, ref } from "vue";

export default {
  components: {
    MainLayout,
    LoanInterestForm,
  },

  props: {
    tenantId: String,
  },

  setup(props) {
    const router = useRouter();

    const tenant = ref({});

    const loadTenant = async (tenantId) => {
      try {
        const response = await ServiceAPI.getInterestFormTenant(tenantId);
        tenant.value = response.data;
      } catch (error) {
        console.log(error);
        router.replace({ name: "PageNotFound" });
      }
    };

    onMounted(async () => {
      await loadTenant(props.tenantId);
    });

    return {
      tenant,
    };
  },
};
</script>

<style></style>
