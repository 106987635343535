<template>
  <div class="card border color-border big-card-mh h-100 px-2">
    <div class="card-body pb-0">
      <div class="container mb-3 m-0 p-0 align-left d-flex justify-content-between align-items-end">
        <h4 class="card-title m-0 p-0 fs-20">Business Area</h4>
        <div class="card-title m-0 p-0 fs-12 d-flex align-items-center">
          {{ communityDistrictAoi?.description }}
          {{ communityDistrictAoi?.description ? "-" : "" }} {{ business.address?.city }},
          {{ business.address?.state }}
        </div>
      </div>
      <div v-if="loadSkeleton">
        <div class="d-none d-sm-block">
          <content-loader
            class="mb-4"
            viewBox="0 0 500 274"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="500" height="274" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none">
          <content-loader
            class="mb-4"
            viewBox="0 0 320 274"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="8" ry="8" width="320" height="274" />
          </content-loader>
        </div>
      </div>
      <div v-else class="card border color-border container-xxl m-0 p-0 mb-4">
        <GMapMap
          class="p-0"
          :center="center"
          :zoom="15"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,

            styles: styles,
          }"
          map-type-id="roadmap"
          style="min-height: 328px">
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="businessIcon"
            :z-index="1000000"
            :label="{
              text: m.title,
              color: '#7200E4',
              fontSize: '14px',
              className: 'map-label',
            }" />

          <GMapMarker
            :key="index"
            v-for="(m, index) in metroStationPois"
            :position="{ lat: m.latitude, lng: m.longitude }"
            :icon="subwayIcon" />

          <GMapMarker
            :key="index"
            v-for="(m, index) in commercialCorridorsPois"
            :position="{ lat: m.latitude, lng: m.longitude }"
            :icon="commercialCorridorsIcon" />

          <GMapMarker
            :key="index"
            v-for="(m, index) in busStopsPois"
            :position="{ lat: m.latitude, lng: m.longitude }"
            :icon="busIcon" />
        </GMapMap>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { computed, ref } from "vue";

export default {
  props: {
    aois: { type: Array, default: () => [] },
    pois: { type: Array, default: () => [] },
    business: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
  },

  components: {
    ContentLoader,
  },

  setup(props) {
    const loadSkeleton = props.loading && !(props.aois?.length || props.pois?.length);

    const center = ref({
      lat: props.business.address.latitude,
      lng: props.business.address.longitude,
    });

    const markers = ref([
      {
        position: {
          lat: props.business.address.latitude,
          lng: props.business.address.longitude,
        },
        title: props.business.businessInfo.name,
      },
    ]);

    const communityDistrictAoi = computed(() => {
      return props.aois?.length
        ? props.aois.filter((aoi) => aoi.type === "COMMUNITY_DISTRICT")[0]
        : null;
    });

    const busStopsPois = computed(() => {
      return props.pois?.length ? props.pois.filter((poi) => poi.type === "BUS_STOP") : null;
    });
    const metroStationPois = computed(() => {
      return props.pois?.length ? props.pois.filter((poi) => poi.type === "METRO_STATION") : null;
    });
    const commercialCorridorsPois = computed(() => {
      return props.pois?.length
        ? props.pois.filter((poi) => poi.type === "COMMERCIAL_CORRIDOR")
        : null;
    });

    const businessIcon = {
      url: "https://cdn.discordapp.com/attachments/990253498283204649/1151563196562419865/shubox_marker.png",
      scaledSize: { width: 42, height: 56 },
      labelOrigin: { x: 16, y: -10 },
    };

    const busIcon = {
      url: "https://cdn.discordapp.com/attachments/990253498283204649/1151525601631543316/bus_marker.png",
      scaledSize: { width: 32, height: 43 },
      labelOrigin: { x: 16, y: -10 },
    };

    const subwayIcon = {
      url: "https://cdn.discordapp.com/attachments/990253498283204649/1151525633441153105/subway_marker.png",
      scaledSize: { width: 32, height: 43 },
      labelOrigin: { x: 16, y: -10 },
    };

    const commercialCorridorsIcon = {
      url: "https://cdn.discordapp.com/attachments/990253498283204649/1151525331207987211/commercial_corridor_marker.png",
      scaledSize: { width: 32, height: 43 },
      labelOrigin: { x: 16, y: -10 },
    };

    const styles = [
      {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },

      {
        featureType: "poi.business",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ];

    return {
      center,
      markers,
      businessIcon,
      busIcon,
      subwayIcon,
      commercialCorridorsIcon,
      busStopsPois,
      metroStationPois,
      commercialCorridorsPois,
      styles,
      communityDistrictAoi,
      loadSkeleton,
    };
  },
};
</script>
<style>
.map-label {
  font-weight: 500;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
</style>
