import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import ServiceAPI from "@/services/ServiceAPI.js";

const UNDEFINED_BUSINESS_ID_KEY = "new";

export const useLoanApplicationStore = defineStore("formStore", {
  state: () => ({
    userLoanApplications: useStorage("userLoanApplications", {}),
    applicationBusiness: useStorage("applicationBusiness", {}),
    autenticatedUser: true,
    wantsLoanApplication: useStorage("wantsLoanApplication", {}),
  }),

  getters: {
    defaultBusinessId() {
      return UNDEFINED_BUSINESS_ID_KEY;
    },

    getWantsLoanApplication() {
      if (!this.wantsLoanApplication) {
        return false;
      }

      return (
        (this.wantsLoanApplication?.value &&
          new Date().getTime() - this.wantsLoanApplication?.date < 3_600_000 * 24 * 15) ??
        false
      );
    },
  },

  actions: {
    isLoanApplicationExpired(businessId) {
      return !(
        this.userLoanApplications[businessId]?.startDate &&
        new Date().getTime() - this.userLoanApplications[businessId].startDate <=
          3_600_000 * 24 * 15
      );
    },

    async createOrLoadApplication(businessId, token, tenantExternalId, grant) {
      businessId = businessId ?? UNDEFINED_BUSINESS_ID_KEY;

      if (!!this.userLoanApplications[businessId] && this.isLoanApplicationExpired(businessId)) {
        delete this.userLoanApplications[businessId];
      }

      this.userLoanApplications[businessId] = this.userLoanApplications[businessId] ?? {
        ownerInfo: false,
        businessInfo: false,
        loading: true,
        tab1GetStarted: {},
        tab2OwnerInformation: {},
        tab3BusinessInformation: { businessDescription: "" },
        tab4FinancialData: { fundingUseDescription: "" },
        social: {},
        startDate: new Date().getTime(),
      };

      if (!this.userLoanApplications[businessId].loanApplicationId) {
        return await this.startApplication(businessId, token, tenantExternalId, grant);
      }

      return this.userLoanApplications[businessId].loanApplicationId;
    },

    changeBusinessId(businessId) {
      this.applicationBusiness.businessId = businessId;
    },

    getBusinessId() {
      return this.applicationBusiness.businessId;
    },

    isFormComplete(businessId, toTab) {
      if (toTab === 1) {
        return true;
      }

      let forms = 0;

      for (const key in this.userLoanApplications[businessId]?.tab1GetStarted) {
        forms++;
        if (
          this.userLoanApplications[businessId].tab1GetStarted[key] === null ||
          this.userLoanApplications[businessId].tab1GetStarted[key] === ""
        ) {
          return false;
        }
      }

      if (toTab === 2) {
        return forms >= 9;
      }

      forms = 0;

      for (const key in this.userLoanApplications[businessId]?.tab2OwnerInformation) {
        forms++;
        if (
          this.userLoanApplications[businessId].tab2OwnerInformation[key] === null ||
          this.userLoanApplications[businessId].tab2OwnerInformation[key] === ""
        ) {
          return false;
        }
      }

      if (toTab === 3) {
        return forms >= 13;
      }

      forms = 0;

      for (const key in this.userLoanApplications[businessId]?.tab3BusinessInformation) {
        forms++;
        if (
          this.userLoanApplications[businessId].tab3BusinessInformation[key] === null ||
          this.userLoanApplications[businessId].tab3BusinessInformation[key] === ""
        ) {
          return false;
        }
      }

      if (toTab === 4) {
        return forms >= 5;
      }

      forms = 0;

      for (const key in this.userLoanApplications[businessId]?.tab4FinancialData) {
        if (
          this.userLoanApplications[businessId].tab4FinancialData[key] === null ||
          this.userLoanApplications[businessId].tab4FinancialData[key] === ""
        ) {
          return false;
        }
      }
      return forms >= 5;
    },

    isFormStarted(businessId) {
      return !!this.userLoanApplications[businessId] && !this.isLoanApplicationExpired(businessId);
    },

    answerToFormQuestion(data, question, type, businessId) {
      this.userLoanApplications[businessId].loading = true;
      let userMessage =
        'Answer to this question as if you were me. "' + question + '" Taking into account that ';

      for (const [key, value] of Object.entries(data)) {
        userMessage += key + " is " + value + ", ";
      }

      userMessage += ". Make the text around 100 words.";

      this.returnAssitantResponse(userMessage, businessId, type);
    },

    async returnAssitantResponse(message, businessId, type) {
      try {
        const response = await ServiceAPI.sendChatMessage(businessId, message, null);

        this.userLoanApplications[businessId].loading = false;
        if (type === "businessDescription") {
          this.userLoanApplications[businessId].tab3BusinessInformation.businessDescription =
            response.data?.message;
        } else {
          this.userLoanApplications[businessId].tab4FinancialData.fundingUseDescription =
            response.data?.message;
        }
      } catch (error) {
        this.userLoanApplications[businessId].loading = false;
        console.log(error);
      }
    },

    async startApplication(businessId, token, tenantExternalId, grant) {
      try {
        const response = await ServiceAPI.startApplication(
          businessId,
          token,
          tenantExternalId,
          grant
        );
        console.debug("Start application");
        console.debug("response data: ", response.data);
        this.userLoanApplications[businessId].loanApplicationId = response.data;
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    clearApplication(businessId) {
      this.userLoanApplications[businessId] ? delete this.userLoanApplications[businessId] : null;
      delete this.applicationBusiness.business;
    },

    setWantsLoanApplication() {
      this.wantsLoanApplication = {
        date: new Date().getTime(),
        value: true,
      };
    },

    clearWantsLoanApplication() {
      delete this.wantsLoanApplication.date;
      delete this.wantsLoanApplication.value;
    },

    async createOrLoadApplicationV2(token, programId, businessId) {
      console.debug("CREATE OR LOAD APPLICATION V2");
      businessId = businessId ?? UNDEFINED_BUSINESS_ID_KEY;

      if (!!this.userLoanApplications[businessId] && this.isLoanApplicationExpired(businessId)) {
        delete this.userLoanApplications[businessId];
      }

      this.userLoanApplications[businessId] = this.userLoanApplications[businessId] ?? {
        ownerInfo: false,
        businessInfo: false,
        loading: true,
        tab1GetStarted: {},
        tab2OwnerInformation: {},
        tab3BusinessInformation: { businessDescription: "" },
        tab4FinancialData: { fundingUseDescription: "" },
        social: {},
        startDate: new Date().getTime(),
      };

      if (!this.userLoanApplications[businessId].loanApplicationId) {
        return await this.startApplicationV2(token, programId, businessId);
      }

      return this.userLoanApplications[businessId].loanApplicationId;
    },

    async startApplicationV2(token, programId, businessId) {
      try {
        const response = await ServiceAPI.startApplicationForTenantV2(programId, token);
        console.debug("Start application");
        console.debug("response data: ", response.data);
        this.userLoanApplications[businessId].loanApplicationId = response.data;
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
