import { defineStore } from "pinia";
import ServiceAPI from "@/services/ServiceAPI.js";

const MAIN_ACCOUNT = "main";

export const useBusinessesStore = defineStore("businessesStore", {
  state: () => ({
    period: "30",
    currentBankAccount: { name: "Main Account", isMainAccount: true, id: MAIN_ACCOUNT },
    businessCashIn: {},
    businessCashOut: {},
    businessCategoriesOut: {},
    businessAverageDailyBalance: {},
    businessScoreOverTime: {},
    businessReviewsOverTime: {},
    businessReviewsAverageOverTime: {},
    businessWebsiteVisitsOverTime: {},
    businessInstagramFollowersOverTime: {},
    businessFacebookLikesOverTime: {},
  }),

  getters: {},

  actions: {
    async getCashIn(businessId) {
      const accountId = this.currentBankAccount.isMainAccount
        ? MAIN_ACCOUNT
        : this.currentBankAccount.id;
      this.businessCashIn[businessId] = this.businessCashIn[businessId] ?? {};
      this.businessCashIn[businessId][accountId] = this.businessCashIn[businessId][accountId] ?? {};

      if (this.businessCashIn[businessId][accountId]?.[this.period]) {
        return this.businessCashIn[businessId][accountId][this.period];
      }

      let data = null;
      if (accountId === MAIN_ACCOUNT) {
        data = (await ServiceAPI.getBusinessCashIn(businessId, this.period))?.data;
      } else {
        data = (await ServiceAPI.getBusinessCashInByAccount(businessId, this.period, accountId))
          ?.data;
      }

      if (data) {
        this.businessCashIn[businessId][accountId][this.period] = data;
      }
      return data;
    },
    async getCashOut(businessId) {
      const accountId = this.currentBankAccount.isMainAccount
        ? MAIN_ACCOUNT
        : this.currentBankAccount.id;
      this.businessCashOut[businessId] = this.businessCashOut[businessId] ?? {};
      this.businessCashOut[businessId][accountId] =
        this.businessCashOut[businessId][accountId] ?? {};

      if (this.businessCashOut[businessId][accountId]?.[this.period]) {
        return this.businessCashOut[businessId][accountId][this.period];
      }

      let data = null;
      if (accountId === MAIN_ACCOUNT) {
        data = (await ServiceAPI.getBusinessCashOut(businessId, this.period))?.data;
      } else {
        data = (await ServiceAPI.getBusinessCashOutByAccount(businessId, this.period, accountId))
          ?.data;
      }

      if (data) {
        this.businessCashOut[businessId][accountId][this.period] = data;
      }

      return data;
    },
    async getBusinessCashOutCategories(businessId) {
      const cashOutCatPeriod = Math.max(this.period, 90);
      const accountId = this.currentBankAccount.isMainAccount
        ? MAIN_ACCOUNT
        : this.currentBankAccount.id;

      this.businessCategoriesOut[businessId] = this.businessCategoriesOut[businessId] ?? {};
      this.businessCategoriesOut[businessId][accountId] =
        this.businessCategoriesOut[businessId][accountId] ?? {};

      if (this.businessCategoriesOut[businessId][accountId][cashOutCatPeriod]) {
        return this.businessCategoriesOut[businessId][accountId][cashOutCatPeriod];
      }

      let data = null;
      if (accountId === MAIN_ACCOUNT) {
        data = (await ServiceAPI.getBusinessCashCategories(businessId, cashOutCatPeriod))?.data;
      } else {
        data = (
          await ServiceAPI.getBusinessCashCategoriesByAccount(
            businessId,
            cashOutCatPeriod,
            accountId
          )
        )?.data;
      }

      if (data) {
        this.businessCategoriesOut[businessId][accountId][cashOutCatPeriod] = data;
      }
      return data;
    },
    async getBusinessCashDailyBalanceAverage(businessId) {
      const accountId = this.currentBankAccount.isMainAccount
        ? MAIN_ACCOUNT
        : this.currentBankAccount.id;

      this.businessAverageDailyBalance[businessId] =
        this.businessAverageDailyBalance[businessId] ?? {};

      this.businessAverageDailyBalance[businessId][accountId] =
        this.businessAverageDailyBalance[businessId][accountId] ?? {};

      if (this.businessAverageDailyBalance[businessId][accountId][this.period]) {
        return this.businessAverageDailyBalance[businessId][accountId][this.period];
      }

      let data = null;
      if (accountId === MAIN_ACCOUNT) {
        data = (await ServiceAPI.getBusinessCashDailyBalanceAverage(businessId, this.period))?.data;
      } else {
        data = (
          await ServiceAPI.getBusinessCashDailyBalanceAverageByAccount(
            businessId,
            this.period,
            accountId
          )
        )?.data;
      }

      if (data) {
        this.businessAverageDailyBalance[businessId][accountId][this.period] = data;
      }
      return data;
    },

    async getBusinessScoreOverTime(businessId) {
      this.businessScoreOverTime[businessId] = this.businessScoreOverTime[businessId] ?? [];

      if (this.businessScoreOverTime[businessId].length > 0) {
        return this.businessScoreOverTime[businessId];
      }

      const data = (await ServiceAPI.getBusinessScoreOverTime(businessId))?.data;

      if (data) {
        this.businessScoreOverTime[businessId] = data;
      }
      return data;
    },

    async getReviewsCountOverTime(businessId) {
      this.businessReviewsOverTime[businessId] = this.businessReviewsOverTime[businessId] ?? [];

      if (this.businessReviewsOverTime[businessId].length > 0) {
        return this.businessReviewsOverTime[businessId];
      }

      const data = (await ServiceAPI.getReviewsCountOverTime(businessId))?.data;

      if (data) {
        this.businessReviewsOverTime[businessId] = data;
      }
      return data;
    },

    async getReviewsAverageOverTime(businessId) {
      this.businessReviewsAverageOverTime[businessId] =
        this.businessReviewsAverageOverTime[businessId] ?? [];

      if (this.businessReviewsAverageOverTime[businessId].length > 0) {
        return this.businessReviewsAverageOverTime[businessId];
      }

      const data = (await ServiceAPI.getReviewsAverageOverTime(businessId))?.data;

      if (data) {
        this.businessReviewsAverageOverTime[businessId] = data;
      }
      return data;
    },
    async getWebsiteVisitsOverTime(businessId) {
      this.businessWebsiteVisitsOverTime[businessId] =
        this.businessWebsiteVisitsOverTime[businessId] ?? [];

      if (this.businessWebsiteVisitsOverTime[businessId].length > 0) {
        return this.businessWebsiteVisitsOverTime[businessId];
      }

      const data = (await ServiceAPI.getWebsiteVisitsOverTime(businessId))?.data;

      if (data) {
        this.businessWebsiteVisitsOverTime[businessId] = data;
      }
      return data;
    },
    async getInstagramFollowersOverTime(businessId) {
      this.businessInstagramFollowersOverTime[businessId] =
        this.businessInstagramFollowersOverTime[businessId] ?? [];

      if (this.businessInstagramFollowersOverTime[businessId].length > 0) {
        return this.businessInstagramFollowersOverTime[businessId];
      }

      const data = (await ServiceAPI.getInstagramFollowersOverTime(businessId))?.data;

      if (data) {
        this.businessInstagramFollowersOverTime[businessId] = data;
      }
      return data;
    },
    async getFacebookLikesOverTime(businessId) {
      this.businessFacebookLikesOverTime[businessId] =
        this.businessFacebookLikesOverTime[businessId] ?? [];

      if (this.businessFacebookLikesOverTime[businessId].length > 0) {
        return this.businessFacebookLikesOverTime[businessId];
      }

      const data = (await ServiceAPI.getFacebookLikesOverTime(businessId))?.data;

      if (data) {
        this.businessFacebookLikesOverTime[businessId] = data;
      }
      return data;
    },
  },
});
