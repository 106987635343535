<template>
  <div class="row">
    <div class="col-xl-3 col-12 p-2">
      <div class="d-block d-xl-none mb-2">
        <button
          @click="toggleFilters()"
          class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
          <div class="hover d-flex">
            <i class="bi bi-filter align-items-center d-flex fs-14 pe-1 fw-light"></i>
            <span class="text-start">{{ showFilters ? "Hide filters" : "Show filters" }}</span>
          </div>
        </button>
      </div>
      <div class="d-xl-block" :class="showFilters ? 'd-block' : 'd-none'">
        <FormsFilterCard
          title="Interest Forms Filter"
          :maxAmount="1000000"
          :listOfObjects="dashboardInterestForms?.items"
          @interestFormFiltersChanged="interestFormFiltersChanged"></FormsFilterCard>
      </div>
    </div>
    <div class="col-xl-9 col-12 p-2">
      <GenericComparisonTable
        title="Interest forms"
        titleWeight="normal"
        height="75dvh"
        maxHeight="75dvh"
        minHeight="75dvh"
        :tableData="tableData"
        :loading="loading.applicationsTableLoading"
        :pagination="true"
        :currentPage="interestFormsCurrentPage"
        :totalPages="interestFormsTotalPages"
        @goToPage="goToPage"
        @previousPage="previousPage"
        @nextPage="nextPage"></GenericComparisonTable>
    </div>
  </div>
</template>

<script>
import GenericComparisonTable from "@/components/benchmark_cards/GenericComparisonTable.vue";
import FormsFilterCard from "@/components/loans_dashboard/FormsFilterCard.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  props: {
    dashboardInterestForms: { type: Object, default: () => {} },
    itemsPerPage: { type: Number, default: 50 },
    interestFormsCurrentPage: { type: Number, default: 1 },
    interestFormsTotalPages: { type: Number, default: 1 },
    loading: { type: Object, default: () => {} },
  },

  setup(props, { emit }) {
    const { d } = useI18n();

    const maxAmount = computed(() => {
      if (!props.dashboardInterestForms?.items?.length) {
        return 1000000;
      }

      return props.dashboardInterestForms?.items.forEach((loan) => {
        return loan.loanAmount > maxAmount.value ? loan.loanAmount : maxAmount.value;
      });
    });

    const goToPage = (page) => {
      emit("goToPage", page);
    };

    const previousPage = () => {
      emit("previousPage");
    };

    const nextPage = () => {
      emit("nextPage");
    };

    const showFilters = ref(false);

    const toggleFilters = () => {
      showFilters.value = !showFilters.value;
    };

    const formStatusSettings = {
      SUBMITTED: {
        color: "#BFE1FF",
        text: "Submitted",
      },
      CONTACTED: {
        color: "#FEFFC2",
        text: "Contacted",
      },
      LOAN_APPLICATION_CREATED: {
        color: "#DAC8E9",
        text: "Loan application created",
      },
      NOT_INTERESTED: {
        color: "#FFB4B4",
        text: "Not interested",
      },
      CLOSED: {
        color: "#FFE7C2",
        text: "Closed",
      },
    };

    const forms = computed(() => {
      return props.dashboardInterestForms?.items ? props.dashboardInterestForms.items : [];
    });

    const tableData = computed(() => {
      const tableData = [];

      if (!forms.value.length) {
        return tableData;
      }

      const header = {
        header: [
          { value: "Name", align: "left" },
          { value: "Address" },
          { value: "Submission" },
          { value: "Status" },
        ],
      };
      tableData.push(header);
      forms.value.forEach((form) => {
        const name = form.businessName ? form.businessName : "N/A";
        const address = form.businessAddress ? form.businessAddress : "N/A";
        const date = form.submissionDate ? d(form.submissionDate, "dateOnly") : "N/A";
        const status = form.status ? form.status : null;
        const statusValue =
          status && formStatusSettings[status] ? formStatusSettings[status].text : "N/A";
        const statusColor =
          status && formStatusSettings[status] ? formStatusSettings[status].color : "#EDEDED";
        const data = {
          data: [
            {
              value: name,
              valueType: "basic",
              fontWeight: "medium",
              rowsMargin: 1,
            },
            {
              value: address,
              valueType: "basic",
              align: "left",
            },
            { value: date, valueType: "pill", xPadding: 2 },
            {
              value: statusValue,
              valueType: "pill",
              color: statusColor,
              fontWeight: "semi-bold",
              width: "172px",
              xPadding: 3,
            },
          ],
        };
        tableData.push(data);
      });
      return tableData;
    });

    const interestFormFiltersChanged = (filters) => {
      emit("interestFormFiltersChanged", filters);
    };

    return {
      tableData,
      goToPage,
      previousPage,
      nextPage,
      toggleFilters,
      showFilters,
      maxAmount,
      interestFormFiltersChanged,
    };
  },

  components: { GenericComparisonTable, FormsFilterCard },
};
</script>

<style></style>
