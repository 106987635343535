<template>
  <LoanApplicationFormContainer :tenant="tenant" :tenantId="tenantId" :grant="grant">
    <div class="row mx-sm-5 mx-2 d-flex align-items-center mt-5 mb-2">
      <div class="col-md-8">
        <div class="card-title fw-bold fs-18 mb-0">2. Owner information</div>
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button @click="resetApplication()" class="btn sb-btn-secondary fs-12 fw-semi-bold">
          Restart application
        </button>
      </div>
    </div>
    <div class="card-body pb-0 mx-sm-5 mx-2 pt-0">
      <form @submit.prevent="$emit('changeTab', 'FormTab3')">
        <div class="row">
          <div v-if="(question = getQuestionObject('ownerFirstName'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              First name
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .firstName
              "
              autocomplete="given-name"
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert your first name"
              aria-label="Insert your first name"
              autofocus />
          </div>
          <div v-if="(question = getQuestionObject('ownerLastName'))" class="col-md-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Last name
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .lastName
              "
              autocomplete="family-name"
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert your last name"
              aria-label="Insert your last name" />
          </div>
        </div>
        <div class="mt-4" v-if="(question = getQuestionObject('ownerEmail')) && !authenticated">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Email
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            type="email"
            autocomplete="email"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation.email
            "
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            placeholder="Insert your email"
            aria-label="Insert your email" />
        </div>
        <div v-if="(question = getQuestionObject('ownerPhoneNumber'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Phone number
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="input-box p-0">
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .phoneNumber
              "
              v-on:input="formatPhoneNumber()"
              type="text"
              :required="question.required"
              placeholder="Insert phone number (000) 000-0000"
              autocomplete="tel"
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              aria-label="Phone number" />
          </div>
        </div>
        <div v-if="(question = getQuestionObject('ownerHomePhoneNumber'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Home phone number
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="input-box p-0">
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .ownerHomePhoneNumber
              "
              v-on:input="formatHomePhoneNumber()"
              type="text"
              :required="question.required"
              id="ownerHomePhoneNumber"
              placeholder="Insert home phone number (000) 000-0000"
              autocomplete="tel"
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              aria-label="Home phone number" />
          </div>
        </div>
        <div v-if="(question = getQuestionObject('ownerGender'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Gender
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation.gender
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Select funds use">
            <option class="text-muted" value="female">Female</option>
            <option class="text-muted" value="male">Male</option>
            <option class="text-muted" value="nonbinary">Non-binary</option>
            <option class="text-muted" value="na">I do not want to disclose</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('ownerIsVeteran'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Are you a US Military veteran?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                .isVeteran
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Select funds use">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div class="row">
          <div v-if="(question = getQuestionObject('ownerEthnicity'))" class="col-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Demographics
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <select
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .ethnicity
              "
              class="form-select fs-12 color-background-form border-2 color-border py-2"
              aria-label="Select funds use">
              <option class="text-muted" value="Black">Black</option>
              <option class="text-muted" value="Hispanic">Hispanic</option>
              <option class="text-muted" value="Asian-Pacific">Asian-Pacific</option>
              <option class="text-muted" value="Asian-Indian Subcontinent">
                Asian-Indian Subcontinent
              </option>
              <option class="text-muted" value="Native American">Native American</option>
              <option class="text-muted" value="White">White</option>
            </select>
          </div>
          <div v-if="(question = getQuestionObject('ownerCountryOfOrigin'))" class="col-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Country of Origin
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <select
              :required="question.required"
              id="countryList"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .countryOfOrigin
              "
              class="form-select fs-12 color-background-form border-2 color-border py-2"
              aria-label="Select funds use">
              <option
                v-for="country in countries"
                :key="country"
                class="text-muted"
                :value="country">
                {{ country }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('ownerIsFromMinority'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Are you a member of any minority groups?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                .ownerIsFromMinority
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Are you a member of any minority groups?">
            <option class="text-muted" :value="true">Yes</option>
            <option class="text-muted" :value="false">No</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('ownerRole'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Are you the business owner, property owner, or both?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                .ownership
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Select funds use">
            <option class="text-muted" value="BUSINESS_OWNER">Business owner</option>
            <option class="text-muted" value="PROPERTY_OWNER">Property owner</option>
            <option class="text-muted" value="BOTH">Both</option>
          </select>
        </div>
        <div class="row">
          <div v-if="(question = getQuestionObject('ownerTitle'))" class="col-sm-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Owners title
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .ownersTitle
              "
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert title"
              aria-label="Insert title" />
          </div>
          <div v-if="(question = getQuestionObject('ownerPercentage'))" class="col-sm-6 mt-4">
            <p class="fw-bold fs-14 ms-1 mb-2">
              Ownership %
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <input
              :required="question.required"
              type="number"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .ownershipPercentage
              "
              min="0"
              max="100"
              class="form-control fs-12 color-background-form border-2 color-border py-2"
              placeholder="Insert your ownership %"
              aria-label="Insert your ownership %" />
          </div>
        </div>
        <div v-if="(question = getQuestionObject('ownerDateOfBirth'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Date of birth
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <input
            :required="question.required"
            type="date"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                .dateOfBirth
            "
            class="form-control fs-12 color-background-form border-2 color-border py-2"
            aria-label="Insert your date of birth (mm/dd/yyyy)" />
        </div>
        <div v-if="(question = getQuestionObject('ownerAddress'))">
          <div class="mt-4">
            <div class="row">
              <div class="fw-bold fs-14 ms-1 mb-2 pe-0 col-auto">Home address line</div>
              <a
                class="hover color-text-primary fs-14 col-auto fw-semi-bold text-decoration-none"
                style="width: fit-content"
                @click="this.toggleAddressLine2">
                <p v-if="!this.addressLine2" class="mb-0">
                  <i class="bi bi-plus-circle me-1"></i>apt/suite/floor
                </p>
              </a>
            </div>
            <input
              required
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .ownerAddressLine1
              "
              :class="addressLine2 ? 'mt-0' : 'mt-2'"
              class="form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
              placeholder="Insert home address line 1"
              aria-label="Insert home address line 1" />
          </div>
          <div class="mt-4" v-if="addressLine2">
            <div class="row">
              <div class="fw-bold fs-14 ms-1 mb-2 pe-0 col-auto">Home address line 2</div>
              <a
                class="hover color-text-primary fs-14 col-auto fw-semi-bold text-decoration-none"
                style="width: fit-content"
                @click="this.toggleAddressLine2">
                <p v-if="addressLine2" class="mb-0">
                  <i class="bi bi-x-circle me-1"></i>apt/suite/floor
                </p>
              </a>
            </div>
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .ownerAddressLine2
              "
              :class="addressLine2 ? 'mt-2' : 'mt-0'"
              class="form-control fs-12 color-background-form border-2 color-border py-2 mt-sm-0"
              :required="addressLine2"
              placeholder="Insert home address line 2"
              aria-label="Insert home address line 2" />
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <label for="inputCity" class="fw-bold fs-14 ms-1 mb-2">City</label>
              <input
                required
                type="text"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                    .ownerCity
                "
                class="form-control fs-12 color-background-form border-2 color-border py-2"
                placeholder="Insert home city"
                aria-label="Insert home city"
                id="inputCity" />
            </div>
            <div class="col-md-4 col-7 mt-md-0 mt-3">
              <label for="inputState" class="fw-bold fs-14 ms-1 mb-2">State</label>
              <select
                required
                id="inputState"
                aria-label="Select state"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                    .ownerState
                "
                class="form-select fs-12 color-background-form border-2 color-border py-2">
                <option value="">Select state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="col-md-2 col-5 mt-md-0 mt-3">
              <label for="inputZip" class="fw-bold fs-14 ms-1 mb-2">Zip</label>
              <input
                id="zip"
                name="zip"
                type="text"
                inputmode="numeric"
                required
                pattern="[0-9]{5}"
                title="Insert a valid 5 digits zip code"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                    .ownerZip
                "
                placeholder="Zip"
                aria-label="Insert zip"
                class="form-control fs-12 color-background-form border-2 color-border py-2 text-deccoration-none" />
            </div>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('ownerCreditScore'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Your credit score
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <select
            :required="question.required"
            v-model="
              loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                .creditScore
            "
            class="form-select fs-12 color-background-form border-2 color-border py-2"
            aria-label="Select funds use">
            <option value="OVER_720" class="text-muted">720+</option>
            <option value="INTERVAL_700_719" class="text-muted">700-719</option>
            <option value="INTERVAL_660_699" class="text-muted">660-699</option>
            <option value="INTERVAL_640_659" class="text-muted">640-659</option>
            <option value="INTERVAL_620_629" class="text-muted">620-629</option>
            <option value="INTERVAL_580_619" class="text-muted">580-619</option>
            <option value="INTERVAL_550_579" class="text-muted">550-579</option>
            <option value="BELOW_550" class="text-muted">Below 550</option>
            <option value="UNKNOWN" class="text-muted">I don't know</option>
          </select>
        </div>
        <div v-if="(question = getQuestionObject('personalDebt'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            How much personal debt do you have?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="input-box">
            <span
              class="prefix fs-12"
              :class="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .personalDebt != '' &&
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .personalDebt != null
                  ? 'fw-bold'
                  : 'text-muted'
              "
              >USD</span
            >
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
                  .personalDebt
              "
              v-on:input="this.addCommasToPersonalDebt()"
              type="text"
              :required="question.required"
              id="personalDebt"
              style="height: 36px"
              min="0"
              class="form-control fs-12 color-background-form border-2 color-border py-2 ps-1"
              aria-label="Amount (to the nearest dollar)" />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-5 mb-5">
          <button
            type="button"
            class="btn shadow-sm sb-btn-secondary rounded mt-4 mx-2"
            @click="$emit('changeTab', 'FormTab1')"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-semi-bold"> Previous </span></button
          ><button
            class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-semi-bold"> Next </span>
          </button>
        </div>
      </form>
    </div>
  </LoanApplicationFormContainer>
</template>

<script>
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import { getEmail, getFirstName, getLastName } from "@/services/auth";
import LoanApplicationFormContainer from "./LoanApplicationFormContainer.vue";

export default {
  props: ["businessId", "authenticated", "tenant", "tenantId", "grant", "questions"],

  setup(props, { emit }) {
    const loanApplicationStore = useLoanApplicationStore();
    const countries = [
      "United States of America (the)",
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas (the)",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia (Plurinational State of)",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory (the)",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cayman Islands (the)",
      "Central African Republic (the)",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands (the)",
      "Colombia",
      "Comoros (the)",
      "Congo (the Democratic Republic of the)",
      "Congo (the)",
      "Cook Islands (the)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czechia",
      "Côte d'Ivoire",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic (the)",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Falkland Islands (the) [Malvinas]",
      "Faroe Islands (the)",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories (the)",
      "Gabon",
      "Gambia (the)",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (the)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran (Islamic Republic of)",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea (the Democratic People's Republic of)",
      "Korea (the Republic of)",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic (the)",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands (the)",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia (Federated States of)",
      "Moldova (the Republic of)",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands (the)",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger (the)",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands (the)",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine, State of",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines (the)",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Republic of North Macedonia",
      "Romania",
      "Russian Federation (the)",
      "Rwanda",
      "Réunion",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan (the)",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands (the)",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates (the)",
      "United Kingdom of Great Britain and Northern Ireland (the)",
      "United States Minor Outlying Islands (the)",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela (Bolivarian Republic of)",
      "Viet Nam",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
      "Åland Islands",
    ];

    const getQuestionObject = (name) => {
      const loanApplicationDetails = props.questions.LoanApplicationDetails;
      const loanApplicationOwnerDetails = props.questions.LoanApplicationOwnerDetails;
      const loanApplicationBusinessDetails = props.questions.LoanApplicationBusinessDetails;
      const loanApplicationFinancialDetails = props.questions.LoanApplicationFinancialDetails;

      return (
        loanApplicationDetails.find((item) => item.name === name) ||
        loanApplicationOwnerDetails.find((item) => item.name === name) ||
        loanApplicationBusinessDetails.find((item) => item.name === name) ||
        loanApplicationFinancialDetails.find((item) => item.name === name)
      );
    };

    const formatPhoneNumber = () => {
      var cleaned = (
        "" +
        loanApplicationStore.userLoanApplications[props.businessId].tab2OwnerInformation.phoneNumber
      ).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        loanApplicationStore.userLoanApplications[
          props.businessId
        ].tab2OwnerInformation.phoneNumber = "(" + match[1] + ") " + match[2] + "-" + match[3];
      } else {
        loanApplicationStore.userLoanApplications[
          props.businessId
        ].tab2OwnerInformation.phoneNumber = cleaned;
      }
    };

    const formatHomePhoneNumber = () => {
      var cleaned = (
        "" +
        loanApplicationStore.userLoanApplications[props.businessId].tab2OwnerInformation
          .ownerHomePhoneNumber
      ).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        loanApplicationStore.userLoanApplications[
          props.businessId
        ].tab2OwnerInformation.ownerHomePhoneNumber =
          "(" + match[1] + ") " + match[2] + "-" + match[3];
      } else {
        loanApplicationStore.userLoanApplications[
          props.businessId
        ].tab2OwnerInformation.ownerHomePhoneNumber = cleaned;
      }
    };

    const resetApplication = () => {
      emit("resetApplication");
    };

    return {
      loanApplicationStore,
      countries,
      formatPhoneNumber,
      formatHomePhoneNumber,
      resetApplication,
      getQuestionObject,
    };
  },

  mounted() {
    if (this.authenticated) {
      if (!this.loanApplicationStore.userLoanApplications[this.businessId].ownerInfo) {
        try {
          this.loanApplicationStore.userLoanApplications[
            this.businessId
          ].tab2OwnerInformation.firstName = getFirstName();
          this.loanApplicationStore.userLoanApplications[
            this.businessId
          ].tab2OwnerInformation.lastName = getLastName();
          this.loanApplicationStore.userLoanApplications[
            this.businessId
          ].tab2OwnerInformation.email = getEmail();
        } catch (error) {
          console.log(error);
        }

        this.loanApplicationStore.userLoanApplications[this.businessId].ownerInfo = true;
      }

      this.loanApplicationStore.userLoanApplications[
        this.businessId
      ].tab2OwnerInformation.ownershipPercentage = this.transformNumberLowerThan100(
        this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
          .ownershipPercentage
      );

      this.loanApplicationStore.userLoanApplications[
        this.businessId
      ].tab2OwnerInformation.personalDebt = this.addCommasToNumber(
        this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
          .personalDebt
      );
    }

    if (
      this.grant &&
      !this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
        .ownerState
    ) {
      this.loanApplicationStore.userLoanApplications[
        this.businessId
      ].tab2OwnerInformation.ownerState = "NY";
    }
  },

  methods: {
    transformNumberLowerThan100(value) {
      if (value > 100) {
        return 100;
      }
      return value;
    },

    removeEverytingButNumbersOrDots(x) {
      if (x == null) {
        return "";
      }
      return x.toString().replace(/[^0-9.]/g, "");
    },

    addCommasToNumber(x) {
      let input = this.removeEverytingButNumbersOrDots(x);
      let decimal = input.split(".")[1];
      let integer = input.split(".")[0];
      let res = "";

      for (let i = integer.length - 1; i >= 0; i--) {
        res = integer[i] + res;
        if ((integer.length - i) % 3 == 0 && i != 0) {
          res = "," + res;
        }
      }

      if (input.includes(".")) {
        res += "." + decimal;
      }

      return res;
    },

    toggleAddressLine2() {
      this.addressLine2 = !this.addressLine2;
      if (this.addressLine2 == false) {
        this.loanApplicationStore.userLoanApplications[
          this.businessId
        ].tab2OwnerInformation.ownerAddressLine2 = "";
      }
    },

    addCommasToPersonalDebt() {
      this.loanApplicationStore.userLoanApplications[
        this.businessId
      ].tab2OwnerInformation.personalDebt = this.addCommasToNumber(
        this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
          .personalDebt
      );
    },
  },

  data() {
    return {
      addressLine2:
        this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
          .ownerAddressLine2 != null &&
        this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
          .ownerAddressLine2 != "",
    };
  },

  updated() {
    this.loanApplicationStore.userLoanApplications[
      this.businessId
    ].tab2OwnerInformation.ownershipPercentage = this.transformNumberLowerThan100(
      this.loanApplicationStore.userLoanApplications[this.businessId].tab2OwnerInformation
        .ownershipPercentage
    );
  },

  components: {
    LoanApplicationFormContainer,
  },
  emits: ["changeTab"],
};
</script>

<style></style>
