<template>
  <LoanApplicationFormContainer :tenant="tenant" :tenantId="tenantId" :grant="grant">
    <div class="row mx-sm-5 mx-2 d-flex align-items-center mt-5 mb-2">
      <div class="col-md-8">
        <div class="card-title fw-bold fs-18 mb-0">4. Financial Data</div>
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button @click="resetApplication()" class="btn sb-btn-secondary fs-12 fw-semi-bold">
          Restart application
        </button>
      </div>
    </div>
    <div class="card-body pb-0 mx-sm-5 mx-2 pt-2">
      <form @submit.prevent="validateAndNextTab">
        <div class="mt-0">
          <p v-if="!hasFinancials && !plaidSuccess" class="fs-14 ms-1 mb-2">
            Connect your <span class="fw-bold">business bank account</span> to automatically provide
            your bank statements and account balance(s).
          </p>
          <div class="card-body color-background-plaid rounded mx-sm-1 px-sm-4">
            <div v-if="!hasFinancials">
              <div v-if="!plaidSuccess" class="row justify-content-between align-items-center">
                <div
                  class="col-md-6 d-flex justify-content-md-start justify-content-center mb-md-0 mb-2">
                  <img
                    src="../../assets/shubox_plaid.png"
                    style="max-height: 26px; max-width: 100%" />
                </div>
                <div class="col-md-6 d-flex justify-content-md-end justify-content-center">
                  <plaid-button
                    :businessId="
                      authenticated
                        ? businessId
                        : loanApplicationStore.userLoanApplications['new'].businessId
                    "
                    :tenant-id="tenantId"
                    :businessIdSignature="
                      authenticated
                        ? null
                        : loanApplicationStore.userLoanApplications['new'].businessIdSignature
                    "
                    :onResultListener="onPlaidResponse">
                    Connect your bank account
                  </plaid-button>
                </div>
              </div>
              <div v-else>
                <span
                  class="fs-14 fw-semi-bold d-flex align-items-center justify-content-md-start justify-content-center">
                  <i class="bi bi-check-circle color-text-positive fs-16 me-2"></i>
                  Connection successful
                </span>
              </div>
              <div
                v-if="
                  showBankStatements &&
                  (question = getDocumentObject('last6monthsBusinessBankStatements'))
                "
                class="mt-4">
                <p class="fw-bold fs-14 ms-1 mb-2">
                  Upload your business bank statements
                  <span v-if="!question.required"
                    ><i class="text-muted fw-normal"> - Optional</i></span
                  >
                </p>

                <div class="mb-3" style="position: relative; padding-right: 100px">
                  <input
                    class="fs-12"
                    required
                    type="file"
                    ref="businessBankStatements"
                    accept=".pdf, .xls, .xlsx, .csv, .doc, .docx, .jpeg, .jpg, .png"
                    @change="handleFileUpload($event, 'businessBankStatements')"
                    multiple />
                  <p class="fs-12 text-muted mt-1">
                    Select up to 4 files to upload. Max file size: 5MB
                  </p>
                </div>
              </div>
              <div
                v-if="
                  showBankStatements &&
                  (question = getDocumentObject('last6monthsPersonalBankStatements'))
                "
                class="mt-4">
                <p class="fw-bold fs-14 ms-1 mb-2">
                  Upload your personal bank statements
                  <span v-if="!question.required"
                    ><i class="text-muted fw-normal"> - Optional</i></span
                  >
                </p>
                <div class="mb-3" style="position: relative; padding-right: 100px">
                  <input
                    class="fs-12"
                    type="file"
                    ref="personalBankStatements"
                    accept=".pdf, .xls, .xlsx, .csv, .doc, .docx, .jpeg, .jpg, .png"
                    @change="handleFileUpload($event, 'personalBankStatements')"
                    multiple />
                  <p class="fs-12 text-muted mt-1">
                    Select up to 4 files to upload. Max file size: 5MB
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              <span
                class="fs-14 fw-semi-bold d-flex align-items-center justify-content-md-start justify-content-center">
                <i class="bi bi-check-circle color-text-positive fs-16 me-2"></i>
                We already have your financials.
              </span>
            </div>
          </div>
          <span v-if="!hasFinancials && !plaidSuccess" class="fs-12 ms-2 mt-2">
            To know more about this integration
            <a
              target="_blank"
              href="https://shubox.ai/faq/"
              class="card-link text-decoration-none text-break hover fs-12 fw-semi-bold color-text-primary"
              >click here</a
            >
          </span>
          <div
            v-if="
              (question = getDocumentObject('last6monthsBusinessBankStatements')) &&
              !hasFinancials &&
              !plaidSuccess
            "
            class="fs-12 ms-2 mt-2">
            Plaid isn't working for you? You can also upload your bank statements by
            <a
              :disabled="hasFinancials || plaidSuccess"
              @click="
                {
                  showBankStatements = !showBankStatements;
                }
              "
              class="card-link text-decoration-none text-break hover fs-12 fw-semi-bold color-text-primary"
              >clicking here</a
            >
          </div>
          <div
            v-if="(question = getQuestionObject('seriousCreditIssuesOrOweBackTaxes'))"
            class="mt-4">
            <p class="fw-bold fs-14 ms-1 mt-4 mb-2">
              Do you have any serious credit issues including accounts in collection, or do you owe
              any back taxes to the IRS?
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <div class="form-check form-check-inline ms-2">
              <input
                class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
                type="radio"
                :required="question.required"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                    .seriousCreditIssuesOrOweBackTaxes
                "
                @click="
                  loanApplicationStore.userLoanApplications[
                    this.businessId
                  ].tab4FinancialData.approvedInstallmentPlan = ''
                "
                name="seriousCreditIssuesOrOweBackTaxes"
                value="true" />
              <label class="form-check-label fs-12">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
                type="radio"
                name="seriousCreditIssuesOrOweBackTaxes"
                required
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                    .seriousCreditIssuesOrOweBackTaxes
                "
                @click="
                  loanApplicationStore.userLoanApplications[
                    this.businessId
                  ].tab4FinancialData.approvedInstallmentPlan = 'false'
                "
                value="false" />
              <label class="form-check-label fs-12">No</label>
            </div>
          </div>
        </div>
        <div
          class="mt-4"
          v-if="
            (question = getQuestionObject('seriousCreditIssuesOrOweBackTaxes')) &&
            loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
              .seriousCreditIssuesOrOweBackTaxes === 'true'
          ">
          <p
            class="fw-bold fs-14 ms-1 mb-2"
            :class="
              loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                .seriousCreditIssuesOrOweBackTaxes === 'false'
                ? 'text-muted'
                : ''
            ">
            Are you on an approved installment plan?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="form-check form-check-inline ms-2">
            <input
              class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
              type="radio"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .approvedInstallmentPlan
              "
              name="approvedInstallmentPlan"
              value="true" />
            <label class="form-check-label fs-12">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
              type="radio"
              name="approvedInstallmentPlan"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .approvedInstallmentPlan
              "
              value="false" />
            <label class="form-check-label fs-12">No</label>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('filledForBankruptcy'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Have you ever filled for bankruptcy?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="form-check form-check-inline ms-2">
            <input
              class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
              type="radio"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .filledForBankruptcy
              "
              @click="
                loanApplicationStore.userLoanApplications[
                  this.businessId
                ].tab4FinancialData.activeBankruptcy = ''
              "
              name="filledForBankruptcy"
              value="true" />
            <label class="form-check-label fs-12">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
              type="radio"
              name="filledForBankruptcy"
              required
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .filledForBankruptcy
              "
              @click="
                loanApplicationStore.userLoanApplications[
                  this.businessId
                ].tab4FinancialData.activeBankruptcy = 'false'
              "
              value="false" />
            <label class="form-check-label fs-12">No</label>
          </div>
        </div>
        <div
          class="mt-4"
          v-if="
            (question = getQuestionObject('filledForBankruptcy')) &&
            loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
              .filledForBankruptcy === 'true'
          ">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Is it still active?
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="form-check form-check-inline ms-2">
            <input
              class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
              type="radio"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .activeBankruptcy
              "
              name="activeBankruptcy"
              value="true" />
            <label class="form-check-label fs-12">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input color-background-grey-darker border-0 shadow-none dropdown-hover"
              type="radio"
              name="activeBankruptcy"
              :required="question.required"
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .activeBankruptcy
              "
              value="false" />
            <label class="form-check-label fs-12">No</label>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('fundingUseDescription'))" class="mt-4">
          <div class="shueyTextArea">
            <p class="fw-bold fs-14 ms-1 mb-2">
              {{ this.title }}
              <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
            </p>
            <div class="textarea-container">
              <textarea
                :required="question.required"
                v-model="
                  loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                    .fundingUseDescription
                "
                class="form-control fs-12 color-background-form border-2 color-border py-3"
                :disabled="loanApplicationStore.userLoanApplications[this.businessId].loading"
                rows="10"
                :placeholder="
                  loanApplicationStore.userLoanApplications[this.businessId].loading
                    ? ''
                    : `Example:\n$1000 to buy equipment\n$2000 for instagram ads`
                "
                aria-label="Example:\n$1000 to buy equipment\n$2000 for instagram ads" />
              <a
                id="shuey-button"
                v-if="
                  this.authenticated &&
                  loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                    .fundingUseDescription === ''
                "
                @click="this.postUserMessage"
                class="d-flex align-items-center bg-transparent border-0 fs-14 color-text-primary fw-semi-bold mb-2">
                <div class="hover d-flex align-items-center mt-2 me-3">
                  <i class="bi bi-stars fs-20 pe-1 fw-light"></i>
                  <p class="d-none d-sm-block m-0 p-0">Fill with Shuey</p>
                </div>
              </a>
              <a
                class="mx-3 mt-2"
                id="wait-message"
                v-if="loanApplicationStore.userLoanApplications[this.businessId].loading">
                <div class="d-flex align-items-center">
                  <span class="rounded-circle p-2 mirror-rounded-purple badge border-0 w-0 fs-20">
                    <i class="bi bi-robot color-text-dark"></i>
                  </span>
                  <span class="text-white fw-semi-bold ms-2 color-text-dark fs-14">Shuey</span>
                </div>
                <div
                  class="border-0 p-2 px-3 mt-1 fs-14 px-2 text-break me-5 mirror-rounded-purple"
                  style="max-width: fit-content">
                  <div class="snippet" data-title="dot-typing">
                    <div class="stage">
                      <div class="dot-typing"></div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div v-if="(question = getQuestionObject('grossReceiptsPastYear'))" class="mt-4">
          <p
            v-if="
              programDetails.name === 'Marketing Loan Program' &&
              programDetails.tenant.name === 'Greater Jamaica Development Corporation'
            "
            class="fw-bold fs-14 ms-1 mb-2">
            Online sales past year ({{ new Date().getFullYear() - 1 }})
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <p v-else class="fw-bold fs-14 ms-1 mb-2">
            Gross receipts past year ({{ new Date().getFullYear() - 1 }})
            <span v-if="!question.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="input-box">
            <span
              class="prefix fs-12"
              :class="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .grossReceiptsPastYear != '' &&
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .grossReceiptsPastYear != null
                  ? 'fw-bold'
                  : 'text-muted'
              "
              >USD</span
            >
            <input
              v-model="
                loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
                  .grossReceiptsPastYear
              "
              v-on:input="this.addCommasToAmount()"
              type="text"
              :required="question.required"
              id="amount"
              style="height: 36px"
              min="0"
              class="form-control fs-12 color-background-form border-2 color-border py-2 ps-1"
              aria-label="Past year gross receipts" />
          </div>
        </div>
        <div v-if="(document = getDocumentObject('last2yearsPersonalTax'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Upload the last 2 years of personal tax returns
            <span v-if="!document.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="mb-3" style="position: relative; padding-right: 100px">
            <input
              class="fs-12"
              :required="document.required"
              type="file"
              ref="last2yearsPersonalTax"
              accept=".pdf, .xls, .xlsx, .csv, .doc, .docx, .jpeg, .jpg, .png"
              @change="handleFileUpload($event, 'last2yearsPersonalTax')"
              multiple />
            <p class="fs-12 text-muted mt-1">Select up to 4 files to upload. Max file size: 5MB</p>
          </div>
        </div>
        <div v-if="(document = getDocumentObject('last2yearsBusinessTax'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Upload the last 2 years of business tax returns
            <span v-if="!document.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="mb-3" style="position: relative; padding-right: 100px">
            <input
              class="fs-12"
              :required="document.required"
              type="file"
              ref="last2yearsBusinessTax"
              accept=".pdf, .xls, .xlsx, .csv, .doc, .docx, .jpeg, .jpg, .png"
              @change="handleFileUpload($event, 'last2yearsBusinessTax')"
              multiple />
            <p class="fs-12 text-muted mt-1">Select up to 4 files to upload. Max file size: 5MB</p>
          </div>
        </div>
        <div v-if="(document = getDocumentObject('idDocument'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Upload a valid identification document
            <span v-if="!document.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="mb-3" style="position: relative; padding-right: 100px">
            <input
              class="fs-12"
              :required="document.required"
              type="file"
              ref="idDocument"
              accept=".pdf, .xls, .xlsx, .csv, .doc, .docx, .jpeg, .jpg, .png"
              @change="handleFileUpload($event, 'idDocument', 2)"
              multiple />
            <p class="fs-12 text-muted mt-1">Select up to 2 files to upload. Max file size: 5MB</p>
          </div>
        </div>
        <div v-if="(document = getDocumentObject('other'))" class="mt-4">
          <p class="fw-bold fs-14 ms-1 mb-2">
            Any additional supporting documents
            <span v-if="!document.required"><i class="text-muted fw-normal"> - Optional</i></span>
          </p>
          <div class="mb-3" style="position: relative; padding-right: 100px">
            <input
              class="fs-12"
              type="file"
              :required="document.required"
              ref="otherFiles"
              accept=".pdf, .xls, .xlsx, .csv, .doc, .docx, .jpeg, .jpg, .png"
              @change="handleFileUpload($event, 'otherFiles')"
              multiple />
            <p class="fs-12 text-muted mt-1">Select up to 4 files to upload. Max file size: 5MB</p>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-5 mb-5">
          <button
            type="button"
            class="btn shadow-sm sb-btn-secondary rounded mt-4 mx-2"
            @click="$emit('changeTab', 'FormTab3')"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-semi-bold">Previous</span></button
          ><button
            type="submit"
            class="btn shadow-sm sb-btn-primary rounded mt-4 mx-2"
            style="height: 42px; width: 90px">
            <span class="fs-14 fw-semi-bold">Next</span>
          </button>
        </div>
      </form>
    </div>
  </LoanApplicationFormContainer>
</template>

<script>
import { onMounted, ref } from "vue";
import { useLoanApplicationStore } from "@/stores/LoanApplicationStore";
import { useToast } from "vue-toastification";
import LoanApplicationFormContainer from "./LoanApplicationFormContainer.vue";
import PlaidButton from "@/components/plaid/PlaidButton.vue";
import ServiceAPI from "@/services/ServiceAPI";

export default {
  props: [
    "businessId",
    "authenticated",
    "tenant",
    "tenantId",
    "grant",
    "questions",
    "documents",
    "programDetails",
  ],

  setup(props, { emit }) {
    const loanApplicationStore = useLoanApplicationStore();
    const toast = useToast();
    const hasFinancials = ref();
    const showBankStatements = ref(false);

    const businessHasFinancials = async (id) => {
      try {
        const response = await ServiceAPI.businessHasTransactions(id);
        hasFinancials.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getQuestionObject = (name) => {
      const loanApplicationDetails = props.questions.LoanApplicationDetails;
      const loanApplicationOwnerDetails = props.questions.LoanApplicationOwnerDetails;
      const loanApplicationBusinessDetails = props.questions.LoanApplicationBusinessDetails;
      const loanApplicationFinancialDetails = props.questions.LoanApplicationFinancialDetails;

      return (
        loanApplicationDetails.find((item) => item.name === name) ||
        loanApplicationOwnerDetails.find((item) => item.name === name) ||
        loanApplicationBusinessDetails.find((item) => item.name === name) ||
        loanApplicationFinancialDetails.find((item) => item.name === name)
      );
    };

    const getDocumentObject = (name) => {
      return props.documents.find((item) => item.name === name);
    };

    const resetApplication = () => {
      emit("resetApplication");
    };

    onMounted(async () => {
      if (props.authenticated) {
        const id = props.authenticated
          ? props.businessId
          : loanApplicationStore.userLoanApplications[loanApplicationStore.defaultBusinessId]
              .businessId;
        await businessHasFinancials(id);
      } else {
        hasFinancials.value = false;
      }
    });

    return {
      loanApplicationStore,
      hasFinancials,
      toast,
      resetApplication,
      showBankStatements,
      getQuestionObject,
      getDocumentObject,
    };
  },

  data() {
    return {
      title: "Please provide a brief overview of the project and its associated cost",
      type: "fundingUseDescription",
      files: {},
      plaidSuccess: false,
    };
  },

  mounted() {
    this.loanApplicationStore.userLoanApplications[this.businessId].loading = false;
  },
  methods: {
    removeEverytingButNumbersOrDots(x) {
      if (x == null) {
        return "";
      }
      return x.toString().replace(/[^0-9.]/g, "");
    },

    addCommasToNumber(x) {
      let input = this.removeEverytingButNumbersOrDots(x);
      let decimal = input.split(".")[1];
      let integer = input.split(".")[0];
      let res = "";

      for (let i = integer.length - 1; i >= 0; i--) {
        res = integer[i] + res;
        if ((integer.length - i) % 3 == 0 && i != 0) {
          res = "," + res;
        }
      }

      if (input.includes(".")) {
        res += "." + decimal;
      }

      return res;
    },

    addCommasToAmount() {
      this.loanApplicationStore.userLoanApplications[
        this.businessId
      ].tab4FinancialData.grossReceiptsPastYear = this.addCommasToNumber(
        this.loanApplicationStore.userLoanApplications[this.businessId].tab4FinancialData
          .grossReceiptsPastYear
      );
    },

    postUserMessage() {
      const data = {
        industry:
          this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.industry,
        amoountOfMoneyNeeded:
          this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted.amount,
        purposeOfLoan:
          this.loanApplicationStore.userLoanApplications[this.businessId].tab1GetStarted
            .fundsPurpose,
      };
      return this.loanApplicationStore.answerToFormQuestion(
        data,
        this.title,
        this.type,
        this.businessId
      );
    },
    handleFileUpload(e, key, maxFiles) {
      if (e.target.files.length > (maxFiles ?? 4)) {
        this.toast.error("Too many files!");
        this.$refs[key].value = null;
      }

      if (key === "businessBankStatements") {
        this.plaidSuccess = true;
      }

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];

        if (file.size > 5_000_000) {
          this.toast.error("File too large! Max file size: 5MB");
          this.$refs[key].value = null;
          return;
        }

        if (!this.files[key]) {
          this.files[key] = [];
        }

        this.files[key].push(file);
      }
    },

    onPlaidResponse(success) {
      this.showBankStatements = false;
      this.plaidSuccess = success;
    },

    validateAndNextTab() {
      if (!this.hasFinancials && !this.plaidSuccess) {
        this.toast.error("Bank account must be connected to submit application!");
        return;
      }

      this.$emit("changeTab", "SummaryPage", this.files);
    },
  },
  components: {
    LoanApplicationFormContainer,
    PlaidButton,
  },
  emits: ["changeTab", "resetApplication"],
};
</script>

<style>
input[type="file"] {
  height: 40px;
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  color: #5b5b5b;
  font-size: 13px;
  border: #f0f0f0 1px solid;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fbfbfb;
}

input[type="file"]::-webkit-file-upload-button {
  background-color: #ffffff;
  color: #6b00d5;
  font-weight: 600;
  height: 40px;
  border: 1px solid #6b00d5;
  border-radius: 4px;
  position: absolute;
  right: 0;
  bottom: 22px;
  cursor: pointer;
  margin: 0px;
  padding: 0px 10px;
  transition: all 0.2s ease-in-out;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: #6b00d5;
  font-weight: 600;
  height: 40px;
  border: 1px solid #6b00d5;
  border-radius: 4px;
  position: absolute;
  right: 0;
  bottom: 22px;
  cursor: pointer;
  margin: 0px;
  padding: 0px 10px;
  transition: all 0.2s ease-in-out;
}
</style>
