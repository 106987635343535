<template>
  <plaid-link
    v-bind="{
      onSuccess: onSuccess,
      token: linkToken,
      clientName: 'Shubox',
      onExit: onExit,
    }">
    <slot />
  </plaid-link>
</template>

<script>
import ServiceAPI from "@/services/ServiceAPI.js";
import PlaidLink from "@/components/plaid/PlaidLink.vue";
import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  name: "PlaidButton",
  props: ["businessId", "tenantId", "onResultListener", "businessIdSignature"],
  components: {
    PlaidLink,
  },
  data() {
    return {
      linkToken: "",
      invitationCode: "",
    };
  },
  methods: {
    async onSuccess(token, metadata) {
      console.debug("token", token, metadata);
      ServiceAPI.setPlaidAccessToken(token, this.invitationCode)
        .then(() => {
          this.onResultListener(true);
        })
        .catch((err) => {
          console.log(err);
          this.onResultListener(false);
        });
    },
    onExit() {
      console.debug("onExit");
      this.onResultListener(false);
    },
    setLinkToken(token) {
      this.linkToken = token;
      localStorage.setItem(
        "linkToken",
        JSON.stringify({ token, invitationCode: this.invitationCode })
      );
    },
    async getRecaptchaToken() {
      const { executeRecaptcha } = useReCaptcha();
      let retry = 0;
      while (retry < 3) {
        try {
          return await executeRecaptcha("plaid_connect");
        } catch (e) {
          console.error("Could not get recaptcha token", e);
          retry++;
        }
      }
    },
  },
  async mounted() {
    if (this.businessIdSignature !== undefined && this.businessIdSignature !== null) {
      const captchaToken = await this.getRecaptchaToken();
      const r = await ServiceAPI.connectForBankConnection(
        this.businessId,
        captchaToken,
        this.businessIdSignature,
        this.tenantId
      );
      this.invitationCode = r.data.invitationCode;
    } else {
      const r = await ServiceAPI.connectForBankConnection(this.businessId);
      this.invitationCode = r.data.invitationCode;
    }
    ServiceAPI.getPlaidLinkToken(this.invitationCode).then((response) => {
      this.setLinkToken(response.data.linkToken);
    });
  },
};
</script>
