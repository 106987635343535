<template>
  <div>
    <div
      class="container-xl card border color-border h-100 round-border border-2 color-text-dark"
      style="max-width: 760px">
      <slot></slot>
    </div>
  </div>
  <LoanInterestFooter
    v-if="type === 'interest-form' || tenant"
    :tenantPhoneNumber="tenant.phoneNumber"
    :tenantEmail="tenant.email"
    :tenantName="tenant.name"
    :tenantWebsite="tenant.website"
    :grant="grant" />
  <LoanApplicationFooter v-else-if="!tenantId" />
</template>

<script>
import LoanApplicationFooter from "@/components/loan_application/LoanApplicationFooter.vue";
import LoanInterestFooter from "@/components/loan_application/loan_interest_form/LoanInterestFooter.vue";

export default {
  props: {
    type: {
      type: String,
    },
    tenant: {
      type: Object,
    },
    tenantId: {
      type: String,
    },
    grant: {
      type: Boolean,
    },
  },
  components: {
    LoanApplicationFooter,
    LoanInterestFooter,
  },
};
</script>

<style></style>
