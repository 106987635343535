import Api from "./API";

export default {
  getAddress() {
    return Api().get("/getAddress");
  },

  getUser() {
    return Api().get("/getUser");
  },

  getCostumerInflux() {
    return Api().get("/getCostumerInflux");
  },

  getCostumerSentiment() {
    return Api().get("/getCostumerSentiment");
  },

  getGoogleReviews() {
    return Api().get("/getGoogleReviews");
  },

  getYelpReviews() {
    return Api().get("/getYelpReviews");
  },

  getInstagram() {
    return Api().get("/getInstagram");
  },

  getWebsite() {
    return Api().get("/getWebsite");
  },

  getSchedule() {
    return Api().get("/getSchedule");
  },

  getBusinesses() {
    return Api().get("/getBusinesses");
  },

  getBusiness(id) {
    return Api().get("/business/details/" + id);
  },

  getBusinessPublic(id, recaptchaToken) {
    return Api().get("/business/details/" + id, {
      headers: { "X-Recaptcha-Token": recaptchaToken },
    });
  },

  getBusinessAois(id) {
    return Api().get("/business/" + id + "/aois");
  },

  getBusinessPois(id) {
    return Api().get("/business/" + id + "/pois");
  },

  getLatestSearches() {
    return Api().get("/business/lastViewed");
  },

  getSampleBusinesses() {
    return Api().get("/business/sample");
  },

  getSearchedBusinesses(region, name) {
    return Api().get("/business/search?region=" + region + "&name=" + name);
  },

  getFavorites() {
    return Api().get("/business/followed");
  },

  addFavorite(id) {
    return Api().post("/business/follow/" + id);
  },

  removeFavorite(id) {
    return Api().post("/business/unfollow/" + id);
  },

  getMyBusinesses() {
    return Api().get("/user/business-owner/businesses");
  },

  registerBusiness(id, signature, isInternalId) {
    if (isInternalId && JSON.parse(isInternalId)) {
      return Api().post(`/business/register-existing-business/${id}/${signature}`);
    }
    return Api().post(`/business/register-business/${id}/${signature}`);
  },

  checkBusinessRegistration(signature) {
    return Api().get(`/business/registration-status/${signature}`);
  },

  getAdvancedSearchedBusinesses(region, name) {
    return Api().get("/business/advancedSearch?region=" + region + "&name=" + name);
  },

  getPlaidLinkToken(invitationCode) {
    return Api().post("/bankConnection/create_link_token", {
      invitationCode,
    });
  },

  setPlaidAccessToken(publicToken, invitationCode) {
    return Api().post("/bankConnection/set_access_token", {
      publicToken,
      invitationCode,
    });
  },

  sendInvitationForBankConnection(businessId, businessOwnerName, businessOwnerEmail) {
    return Api().post("/bankConnection/createInvitation", {
      businessId,
      businessOwnerName,
      businessOwnerEmail,
    });
  },

  sendChatMessage(businessId, message, sessionId) {
    return Api().post("/chatbot/session/message", {
      businessId,
      message,
      sessionId,
    });
  },

  connectForBankConnection(businessId, captchaToken, businessIdSignature, tenantExternalId) {
    return Api().post(
      `/bankConnection/connect-account/${businessId}` +
        (captchaToken
          ? `?captchaToken=${captchaToken}&businessIdSignature=${businessIdSignature}` +
            (tenantExternalId ? `&tenantId=${tenantExternalId}` : "")
          : "")
    );
  },

  getInvitationDetails(invitationCode) {
    return Api().get(`/bankConnection/getInvitation/${invitationCode}`);
  },

  businessHasTransactions(businessId) {
    return Api().get("/business/hasTransactions/" + businessId);
  },

  getBusinessOwnerBusiness() {
    return Api().get("/user/business-owner/business");
  },

  getRegistrationInvitationDetails(invitationCode) {
    return Api().get(`/user/business-owner/invitation/${invitationCode}`);
  },

  getTenantUserRegistrationInvitationDetails(invitationCode) {
    return Api().get(`/tenants/invitation/${invitationCode}`);
  },

  registerBusinessOwnerUser(invitationCode, password, phoneNumber) {
    return Api().post("/user/business-owner", {
      invitationCode,
      password,
      phoneNumber,
    });
  },

  registerTenantUser(invitationCode, email, firstName, lastName, password, phoneNumber) {
    return Api().post(`/tenants/register/${invitationCode}`, {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
    });
  },

  async getUserCredits() {
    return Api().get("/credits");
  },

  // financials

  getBusinessCashIn(businessId, period) {
    return Api().get(`/financials/${businessId}/in?months=${period / 30}`);
  },
  getBusinessCashInByAccount(businessId, period, accountId) {
    return Api().get(`/financials/${businessId}/in/${accountId}?months=${period / 30}`);
  },
  getBusinessCashOut(businessId, period) {
    return Api().get(`/financials/${businessId}/out?months=${period / 30}`);
  },
  getBusinessCashOutByAccount(businessId, period, accountId) {
    return Api().get(`/financials/${businessId}/out/${accountId}?months=${period / 30}`);
  },
  getBusinessCashCategories(businessId, period) {
    return Api().get(`/financials/${businessId}/categories?months=${period / 30}`);
  },
  getBusinessCashCategoriesByAccount(businessId, period, accountId) {
    return Api().get(`/financials/${businessId}/categories/${accountId}?months=${period / 30}`);
  },
  getBusinessCashBalance(businessId) {
    return Api().get(`/financials/${businessId}/balance`);
  },
  getBusinessCashBalanceByAccount(businessId, accountId) {
    return Api().get(`/financials/${businessId}/balance/${accountId}`);
  },
  getBusinessCashDailyBalanceAverage(businessId, period) {
    return Api().get(`/financials/${businessId}/averagedailybalance?months=${period / 30}`);
  },
  getBusinessCashDailyBalanceAverageByAccount(businessId, period, accountId) {
    return Api().get(
      `/financials/${businessId}/averagedailybalance/${accountId}?months=${period / 30}`
    );
  },
  getBusinessScoreOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/score`);
  },
  getBusinessPeersScoreOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/score/peers`);
  },
  getInstagramFollowersOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/instagram`);
  },
  getFacebookLikesOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/facebook`);
  },
  getWebsiteVisitsOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/website`);
  },
  getReviewsCountOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/reviews-count`);
  },
  getReviewsAverageOverTime(businessId) {
    return Api().get(`/business/stats/${businessId}/reviews-stars`);
  },

  // business owner
  sendBusinessOwnerAccountRegistrationInvite(businessId, businessOwnerName, businessOwnerEmail) {
    return Api().post("/user/business-owner/send-registration-invitation", {
      businessId,
      businessOwnerName,
      businessOwnerEmail,
    });
  },
  getPendingBusinessOwnerRegistrationInvite(businessId) {
    return Api().get(`/user/business-owner/pending-invitation/${businessId}`);
  },
  async searchBusinessWithoutSession(query, region, token) {
    return Api().get(
      `/business/public-search?captchaToken=${token}&region=${region}&name=${query}`
    );
  },
  async advancedSearchBusinessWithoutSession(query, region, token) {
    return Api().get(
      `/business/public-advanced-search?captchaToken=${token}&region=${region}&name=${query}`
    );
  },
  async registerBusinessWithoutSession(googleId, signature, token, isInternalId) {
    if (isInternalId && JSON.parse(isInternalId)) {
      return Api().post(
        `/business/public-register-existing-business/${googleId}/${signature}?captchaToken=${token}`
      );
    }

    return Api().post(
      `/business/public-register-business/${googleId}/${signature}?captchaToken=${token}`
    );
  },
  registerBusinessOwnerWithoutInvite(businessId, signature, user, token) {
    return Api().post(`/user/business-owner/claim?captchaToken=${token}`, {
      businessId,
      signature,
      user,
    });
  },
  registerBusinessManual(
    name,
    address,
    city,
    state,
    zipCode,
    industry,
    facebook,
    instagram,
    website,
    token
  ) {
    return Api().post(`/business/public-register-business-manual?captchaToken=${token}`, {
      name,
      address,
      city,
      state,
      zipCode,
      industry,
      facebook,
      instagram,
      website,
    });
  },

  // start application
  async startApplication(businessId, token, tenantExternalId, grant) {
    if (!token) {
      const response = await this.startApplicationAuthenticated(businessId);
      return {
        data: {
          id: response.data,
        },
      };
    } else {
      return this.startApplicationPublic(token, tenantExternalId, grant);
    }
  },

  startApplicationAuthenticated(businessId) {
    return Api().post(`/loan-applications/business/${businessId}`);
  },

  async startApplicationPublic(token, tenantExternalId, grant) {
    if (tenantExternalId) {
      return Api().post(
        `/public-loan-applications/tenant/${tenantExternalId}?captchaToken=${token}&isGrant=${!!grant}`
      );
    }
    return Api().post(`/public-loan-applications?captchaToken=${token}&isGrant=${!!grant}`);
  },

  // save basic details
  saveBasicDetails(
    authenticated,
    loanApplicationId,
    newRequest,
    whereHearAboutUs,
    referralSource,
    loanAmount,
    loanReason,
    businessLegalName,
    businessDba,
    businessAddress,
    businessIndustry,
    signature,
    token
  ) {
    if (authenticated) {
      return this.saveBasicDetailsAuthenticated(
        loanApplicationId,
        newRequest,
        whereHearAboutUs,
        referralSource,
        loanAmount,
        loanReason,
        businessLegalName,
        businessDba,
        businessAddress,
        businessIndustry
      );
    } else {
      return this.saveBasicDetailsPublic(
        token,
        signature,
        loanApplicationId,
        newRequest,
        whereHearAboutUs,
        referralSource,
        loanAmount,
        loanReason,
        businessLegalName,
        businessDba,
        businessAddress,
        businessIndustry
      );
    }
  },

  saveBasicDetailsAuthenticated(
    loanApplicationId,
    newRequest,
    whereHearAboutUs,
    referralSource,
    loanAmount,
    loanReason,
    businessLegalName,
    businessDba,
    businessAddress,
    businessIndustry
  ) {
    return Api().post(`/loan-applications/${loanApplicationId}/basic-details`, {
      newRequest,
      whereHearAboutUs,
      referralSource,
      loanAmount,
      loanReason,
      businessLegalName,
      businessDba,
      businessAddress,
      businessIndustry,
    });
  },
  async saveBasicDetailsPublic(
    token,
    signature,
    loanApplicationId,
    newRequest,
    whereHearAboutUs,
    referralSource,
    loanAmount,
    loanReason,
    businessLegalName,
    businessDba,
    businessAddress,
    businessIndustry
  ) {
    return Api().post(
      `/public-loan-applications/${loanApplicationId}/basic-details?captchaToken=${token}&loanApplicationIdSignature=${signature}`,
      {
        newRequest,
        whereHearAboutUs,
        referralSource,
        loanAmount,
        loanReason,
        businessLegalName,
        businessDba,
        businessAddress,
        businessIndustry,
      }
    );
  },

  // save owner details
  saveOwnerDetails(
    authenticated,
    loanApplicationId,
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    isVeteran,
    title,
    ancestry,
    countryOfOrigin,
    ownershipPercentage,
    role,
    dateOfBirth,
    address,
    creditScore,
    personalDebt,
    filledApplication,
    signature,
    token
  ) {
    if (authenticated) {
      return this.saveOwnerDetailsAuthenticated(
        loanApplicationId,
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        isVeteran,
        title,
        ancestry,
        countryOfOrigin,
        ownershipPercentage,
        role,
        dateOfBirth,
        address,
        creditScore,
        personalDebt,
        filledApplication
      );
    } else {
      return this.saveOwnerDetailsPublic(
        token,
        signature,
        loanApplicationId,
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        isVeteran,
        title,
        ancestry,
        countryOfOrigin,
        ownershipPercentage,
        role,
        dateOfBirth,
        address,
        creditScore,
        personalDebt,
        filledApplication
      );
    }
  },
  saveOwnerDetailsAuthenticated(
    loanApplicationId,
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    isVeteran,
    title,
    ancestry,
    countryOfOrigin,
    ownershipPercentage,
    role,
    dateOfBirth,
    address,
    creditScore,
    personalDebt,
    filledApplication
  ) {
    return Api().post(`/loan-applications/${loanApplicationId}/owner-details`, {
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      isVeteran,
      title,
      ancestry,
      countryOfOrigin,
      ownershipPercentage,
      role,
      dateOfBirth,
      address,
      creditScore,
      personalDebt,
      filledApplication,
    });
  },
  async saveOwnerDetailsPublic(
    token,
    signature,
    loanApplicationId,
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    isVeteran,
    title,
    ancestry,
    countryOfOrigin,
    ownershipPercentage,
    role,
    dateOfBirth,
    address,
    creditScore,
    personalDebt,
    filledApplication
  ) {
    return Api().post(
      `/public-loan-applications/${loanApplicationId}/owner-details?captchaToken=${token}&loanApplicationIdSignature=${signature}`,
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        isVeteran,
        title,
        ancestry,
        countryOfOrigin,
        ownershipPercentage,
        role,
        dateOfBirth,
        address,
        creditScore,
        personalDebt,
        filledApplication,
      }
    );
  },

  // save business details
  saveBusinessDetails(
    authenticated,
    loanApplicationId,
    businessEmail,
    businessPhone,
    yearsOpen,
    numberOfEmployees,
    incorporationDate,
    businessEntity,
    employerIdentificationNumber,
    isForProfit,
    isStartup,
    isOperating,
    businessDescription,
    signature,
    token
  ) {
    if (authenticated) {
      return this.saveBusinessDetailsAuthenticated(
        loanApplicationId,
        businessEmail,
        businessPhone,
        yearsOpen,
        numberOfEmployees,
        incorporationDate,
        businessEntity,
        employerIdentificationNumber,
        isForProfit,
        isStartup,
        isOperating,
        businessDescription
      );
    } else {
      return this.saveBusinessDetailsPublic(
        token,
        signature,
        loanApplicationId,
        businessEmail,
        businessPhone,
        yearsOpen,
        numberOfEmployees,
        incorporationDate,
        businessEntity,
        employerIdentificationNumber,
        isForProfit,
        isStartup,
        isOperating,
        businessDescription
      );
    }
  },
  saveBusinessDetailsAuthenticated(
    loanApplicationId,
    businessEmail,
    businessPhone,
    yearsOpen,
    numberOfEmployees,
    incorporationDate,
    businessEntity,
    employerIdentificationNumber,
    isForProfit,
    isStartup,
    isOperating,
    businessDescription
  ) {
    return Api().post(`/loan-applications/${loanApplicationId}/business-details`, {
      businessEmail,
      businessPhone,
      yearsOpen,
      numberOfEmployees,
      incorporationDate,
      businessEntity,
      employerIdentificationNumber,
      isForProfit,
      isStartup,
      isOperating,
      businessDescription,
    });
  },
  async saveBusinessDetailsPublic(
    token,
    signature,
    loanApplicationId,
    businessEmail,
    businessPhone,
    yearsOpen,
    numberOfEmployees,
    incorporationDate,
    businessEntity,
    employerIdentificationNumber,
    isForProfit,
    isStartup,
    isOperating,
    businessDescription
  ) {
    return Api().post(
      `/public-loan-applications/${loanApplicationId}/business-details?captchaToken=${token}&loanApplicationIdSignature=${signature}`,
      {
        businessEmail,
        businessPhone,
        yearsOpen,
        numberOfEmployees,
        incorporationDate,
        businessEntity,
        employerIdentificationNumber,
        isForProfit,
        isStartup,
        isOperating,
        businessDescription,
      }
    );
  },

  // save business financials
  saveFinancialDetails(
    authenticated,
    loanApplicationId,
    averageRevenueLast3Months,
    existingDebtAmount,
    existingCreditIssues,
    inAnInstallmentPlan,
    everFilledForBankruptcy,
    bankruptcyStillActive,
    planDescription,
    grossReceiptsPastYear,
    files,
    signature,
    token
  ) {
    if (authenticated) {
      return this.saveFinancialDetailsAuthenticated(
        loanApplicationId,
        averageRevenueLast3Months,
        existingDebtAmount,
        existingCreditIssues,
        inAnInstallmentPlan,
        everFilledForBankruptcy,
        bankruptcyStillActive,
        planDescription,
        grossReceiptsPastYear,
        files
      );
    } else {
      return this.saveFinancialDetailsPublic(
        token,
        signature,
        loanApplicationId,
        averageRevenueLast3Months,
        existingDebtAmount,
        existingCreditIssues,
        inAnInstallmentPlan,
        everFilledForBankruptcy,
        bankruptcyStillActive,
        planDescription,
        grossReceiptsPastYear,
        files
      );
    }
  },
  saveFinancialDetailsAuthenticated(
    loanApplicationId,
    averageRevenueLast3Months,
    existingDebtAmount,
    existingCreditIssues,
    inAnInstallmentPlan,
    everFilledForBankruptcy,
    bankruptcyStillActive,
    planDescription,
    grossReceiptsPastYear,
    files
  ) {
    const formData = new FormData();

    const blob = new Blob(
      [
        JSON.stringify({
          averageRevenueLast3Months,
          existingDebtAmount,
          existingCreditIssues,
          inAnInstallmentPlan,
          everFilledForBankruptcy,
          bankruptcyStillActive,
          planDescription,
          grossReceiptsPastYear,
        }),
      ],
      { type: "application/json" }
    );

    formData.append("financialDetails", blob);

    if (files?.last2yearsPersonalTax) {
      for (let i = 0; i < files.last2yearsPersonalTax.length; i++) {
        const f = files.last2yearsPersonalTax[i];
        formData.append("last2yearsPersonalTax", f);
      }
    }

    if (files?.last2yearsBusinessTax) {
      for (let i = 0; i < files.last2yearsBusinessTax.length; i++) {
        const f = files.last2yearsBusinessTax[i];
        formData.append("last2yearsBusinessTax", f);
      }
    }

    if (files?.otherFiles) {
      for (let i = 0; i < files.otherFiles.length; i++) {
        const f = files.otherFiles[i];
        formData.append("otherFiles", f);
      }
    }

    if (files?.businessBankStatements) {
      for (let i = 0; i < files.businessBankStatements.length; i++) {
        const f = files.businessBankStatements[i];
        formData.append("businessBankStatements", f);
      }
    }

    if (files?.personalBankStatements) {
      for (let i = 0; i < files.personalBankStatements.length; i++) {
        const f = files.personalBankStatements[i];
        formData.append("personalBankStatements", f);
      }
    }

    return Api().post(`/loan-applications/${loanApplicationId}/financial-details`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async saveFinancialDetailsPublic(
    token,
    signature,
    loanApplicationId,
    averageRevenueLast3Months,
    existingDebtAmount,
    existingCreditIssues,
    inAnInstallmentPlan,
    everFilledForBankruptcy,
    bankruptcyStillActive,
    planDescription,
    grossReceiptsPastYear,
    files
  ) {
    const formData = new FormData();

    const blob = new Blob(
      [
        JSON.stringify({
          averageRevenueLast3Months,
          existingDebtAmount,
          existingCreditIssues,
          inAnInstallmentPlan,
          everFilledForBankruptcy,
          bankruptcyStillActive,
          planDescription,
          grossReceiptsPastYear,
        }),
      ],
      { type: "application/json" }
    );

    formData.append("financialDetails", blob);

    if (files?.last2yearsPersonalTax) {
      for (let i = 0; i < files.last2yearsPersonalTax.length; i++) {
        const f = files.last2yearsPersonalTax[i];
        formData.append("last2yearsPersonalTax", f);
      }
    }

    if (files?.last2yearsBusinessTax) {
      for (let i = 0; i < files.last2yearsBusinessTax.length; i++) {
        const f = files.last2yearsBusinessTax[i];
        formData.append("last2yearsBusinessTax", f);
      }
    }

    if (files?.otherFiles) {
      for (let i = 0; i < files.otherFiles.length; i++) {
        const f = files.otherFiles[i];
        formData.append("otherFiles", f);
      }
    }

    if (files?.idDocument) {
      for (let i = 0; i < files.idDocument.length; i++) {
        const f = files.idDocument[i];
        formData.append("otherFiles", f);
      }
    }

    if (files?.businessBankStatements) {
      for (let i = 0; i < files.businessBankStatements.length; i++) {
        const f = files.businessBankStatements[i];
        formData.append("businessBankStatements", f);
      }
    }

    if (files?.personalBankStatements) {
      for (let i = 0; i < files.personalBankStatements.length; i++) {
        const f = files.personalBankStatements[i];
        formData.append("personalBankStatements", f);
      }
    }

    return Api().post(
      `/public-loan-applications/${loanApplicationId}/financial-details?captchaToken=${token}&loanApplicationIdSignature=${signature}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  // submit loan application
  submitLoanApplication(authenticated, loanApplicationId, signature, token, invitationCode) {
    if (authenticated) {
      return this.submitLoanApplicationAuthenticated(loanApplicationId);
    } else {
      return this.submitLoanApplicationPublic(signature, loanApplicationId, token, invitationCode);
    }
  },
  submitLoanApplicationAuthenticated(loanApplicationId) {
    return Api().post(`/loan-applications/${loanApplicationId}/submit`);
  },
  async submitLoanApplicationPublic(signature, loanApplicationId, token, invitationCode) {
    return Api().post(
      `/public-loan-applications/${loanApplicationId}/submit?captchaToken=${token}&loanApplicationIdSignature=${signature}&invitationCode=${invitationCode}`
    );
  },

  // get loan application summary
  getLoanApplicationSummary(authenticated, loanApplicationId, signature, token) {
    if (authenticated) {
      return this.getLoanApplicationSummaryAuthenticated(loanApplicationId);
    } else {
      return this.getLoanApplicationSummaryPublic(loanApplicationId, signature, token);
    }
  },

  getLoanApplicationSummaryAuthenticated(loanApplicationId) {
    return Api().get(`/loan-applications/${loanApplicationId}/summary`);
  },
  async getLoanApplicationSummaryPublic(loanApplicationId, signature, token) {
    return Api().get(
      `/public-loan-applications/${loanApplicationId}/summary?captchaToken=${token}&loanApplicationIdSignature=${signature}`
    );
  },

  //set user
  async setUser(
    token,
    signature,
    loanApplicationId,
    email,
    phoneNumber,
    firstName,
    lastName,
    password
  ) {
    return Api().post(
      `/public-loan-applications/${loanApplicationId}/set-user?captchaToken=${token}&loanApplicationIdSignature=${signature}`,
      {
        email,
        phoneNumber,
        firstName,
        lastName,
        password,
      }
    );
  },

  //set business
  async setBusiness(loanApplicationIdSignature, loanApplicationId, id, signature, token) {
    return Api().post(
      `/public-loan-applications/${loanApplicationId}/set-business?captchaToken=${token}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      {
        id,
        signature,
      }
    );
  },

  getLoanApplications() {
    return Api().get("/loan-applications");
  },

  getLoanApplication(loanApplicationId) {
    return Api().get(`v2/loan-applications/${loanApplicationId}`);
  },

  // get external info
  getExternalInfo(id) {
    return Api().get(`/business/${id}/external-info`);
  },

  getExternalTransactionInfo(id) {
    return Api().get(`/business/${id}/external-info-transactional`);
  },

  getExternalTransactionInfoByEndDate(id, endDate) {
    return Api().get(`/business/${id}/external-info-transactional?endDate=${endDate}`);
  },

  getPeerGroupTransactionalDetails(id) {
    return Api().get(`/business/${id}/peer-external-info-transactional`);
  },

  getPeerComparison(id) {
    return Api().get(`/business/${id}/compare/peers`);
  },

  getPeerReviewsCountComparison(id) {
    return Api().get(`/business/stats/${id}/reviews-count/peers`);
  },

  getPeers(id) {
    return Api().get(`/business/${id}/peers`);
  },

  registerPeers(id) {
    return Api().post(`/business/${id}/peers/register`);
  },

  test() {
    return Api().get("/business/test");
  },

  testError() {
    return Api().get("/business/test-error");
  },

  getInterestFormTenant(tenantExternalId) {
    return Api().get(`/tenants/${tenantExternalId}`);
  },

  submitLoanInterestForm(tenantExternalId, shortForm, captchaToken) {
    return Api().post(`/short-form/${tenantExternalId}?captchaToken=${captchaToken}`, shortForm);
  },

  async getLoansDashboardSmallCards(timespan, programId) {
    if (programId && programId != 0) {
      return Api().get(
        `/loan-officer-dashboard/v2/count-totals?timespan=${timespan}&programId=${programId}`
      );
    } else {
      return Api().get(`/loan-officer-dashboard/v2/count-totals?timespan=${timespan}`);
    }
  },

  async getLoansDashboardPortfolioPerformance() {
    return Api().get("/loan-officer-dashboard/portfolio-performance");
  },

  async getApplicationsInEachStage(timespan, programId) {
    if (programId && programId != 0) {
      return Api().get(
        `loan-officer-dashboard/v2/applications-stats?timespan=${timespan}&programId=${programId}`
      );
    } else {
      return Api().get(`loan-officer-dashboard/v2/applications-stats?timespan=${timespan}`);
    }
  },

  async getDashboardLoanApplications(
    businessName,
    ownerEmail,
    startSubmissionDate,
    endSubmissionDate,
    status,
    orderBy,
    orderByDirection,
    lowAmount,
    highAmount,
    assignedUserId,
    programId,
    page,
    pageSize
  ) {
    let queryParameters = "";
    if (businessName) {
      queryParameters += `&businessName=${businessName}`;
    }
    if (ownerEmail) {
      queryParameters += `&ownerEmail=${ownerEmail}`;
    }
    if (startSubmissionDate) {
      queryParameters += `&startSubmissionDate=${startSubmissionDate}`;
    }
    if (endSubmissionDate) {
      queryParameters += `&endSubmissionDate=${endSubmissionDate}`;
    }
    if (status) {
      queryParameters += `&status=${status}`;
    }
    if (orderBy) {
      queryParameters += `&orderBy=${orderBy}`;
    }
    if (orderByDirection) {
      queryParameters += `&orderByDirection=${orderByDirection}`;
    }
    if (lowAmount) {
      queryParameters += `&lowAmount=${lowAmount}`;
    }
    if (highAmount) {
      queryParameters += `&highAmount=${highAmount}`;
    }
    if (assignedUserId) {
      queryParameters += `&assignedUserId=${assignedUserId}`;
    }
    if (programId && programId != 0) {
      queryParameters += `&programId=${programId}`;
    }
    return Api().get(
      `loan-officer-dashboard/v2/loan-applications?pageSize=${pageSize}&page=${page}` +
        queryParameters
    );
  },
  downloadFile(url) {
    Api()
      .get(url, {
        responseType: "arraybuffer",
      })
      .then(function (response) {
        const fileName = response.headers["content-disposition"].match(/filename="([^"]+)"/)[1];

        let blob = new Blob([response.data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
  },

  async getDashboardInterestForms(
    businessName,
    startSubmissionDate,
    endSubmissionDate,
    status,
    orderBy,
    orderByDirection,
    page,
    pageSize
  ) {
    let queryParameters = "";
    if (businessName) {
      queryParameters += `&businessName=${businessName}`;
    }
    if (startSubmissionDate) {
      queryParameters += `&startSubmissionDate=${startSubmissionDate}`;
    }
    if (endSubmissionDate) {
      queryParameters += `&endSubmissionDate=${endSubmissionDate}`;
    }
    if (status) {
      queryParameters += `&status=${status}`;
    }
    if (orderBy) {
      queryParameters += `&orderBy=${orderBy}`;
    }
    if (orderByDirection) {
      queryParameters += `&orderByDirection=${orderByDirection}`;
    }
    return Api().get(
      `loan-officer-dashboard/interest-forms?pageSize=${pageSize}&page=${page}` + queryParameters
    );
  },

  async updateLoanApplicationStatus(loanApplicationId, statusId, note, sendEmail) {
    if (sendEmail != null) {
      return Api().put(`v2/loan-applications/${loanApplicationId}/status`, {
        statusId,
        note,
        sendEmail,
      });
    } else {
      return Api().put(`v2/loan-applications/${loanApplicationId}/status`, {
        statusId,
        note,
      });
    }
  },

  async getApplicationsStatusHistory(loanApplicationId) {
    return Api().get(`v2/loan-applications/${loanApplicationId}/status/history`);
  },

  async getTenantAvailableStatuses(programId) {
    if (!programId || programId == 0) {
      return Api().get(`/loan-program/available-status`);
    } else {
      return Api().get(`/loan-program/${programId}/available-status`);
    }
  },

  async assignLoanToMe(loanApplicationId) {
    return Api().post(`/loan-applications/${loanApplicationId}/assign`);
  },

  async unassignLoanFromMe(loanApplicationId) {
    return Api().post(`/loan-applications/${loanApplicationId}/unassign`);
  },

  async assignLoanApplication(loanApplicationId, userId) {
    return Api().post(`/loan-applications/${loanApplicationId}/assign/${userId}`);
  },

  async getTenantList() {
    return Api().get(`/loan-officer-dashboard/users-from-my-tenant`);
  },

  async getAllBankAccounts(businessId) {
    return Api().get(`/financials/${businessId}/accounts`);
  },

  async getLoanApplicationHistory(loanApplicationId) {
    return Api().get(`/loan-applications/${loanApplicationId}/status/history`);
  },

  async getAssetsReportStatus(businessId) {
    return Api().get(`/financials/${businessId}/assets-report/status`);
  },

  async requestPlaidAssetsReport(businessId) {
    return Api().post(`/financials/${businessId}/assets-report`);
  },

  async exportLoansCsv(
    businessName,
    startSubmissionDate,
    endSubmissionDate,
    status,
    orderBy,
    orderByDirection,
    lowAmount,
    highAmount,
    assignedUserId
  ) {
    let queryParameters = "";
    if (businessName) {
      queryParameters += `&businessName=${businessName}`;
    }
    if (startSubmissionDate) {
      queryParameters += `&startSubmissionDate=${startSubmissionDate}`;
    }
    if (endSubmissionDate) {
      queryParameters += `&endSubmissionDate=${endSubmissionDate}`;
    }
    if (status) {
      queryParameters += `&status=${status}`;
    }
    if (orderBy) {
      queryParameters += `&orderBy=${orderBy}`;
    }
    if (orderByDirection) {
      queryParameters += `&orderByDirection=${orderByDirection}`;
    }
    if (lowAmount) {
      queryParameters += `&lowAmount=${lowAmount}`;
    }
    if (highAmount) {
      queryParameters += `&highAmount=${highAmount}`;
    }
    if (assignedUserId) {
      queryParameters += `&assignedUserId=${assignedUserId}`;
    }
    this.downloadFile(`loan-officer-dashboard/loan-applications/csv?csv=csv` + queryParameters);
  },

  async validateInvitationCode(tenantId, invitationCode, captchaToken) {
    return Api().get(
      `/loan-application-invitations/${tenantId}/${invitationCode}?captchaToken=${captchaToken}`
    );
  },

  async updateBusiness(businessId, newFacebook, newInstagram, newWebsite) {
    const data = {};

    if (newFacebook) {
      data.facebook = newFacebook;
    }

    if (newInstagram) {
      data.instagram = newInstagram;
    }

    if (newWebsite) {
      data.website = newWebsite;
    }

    return Api().patch(`/business/${businessId}`, data);
  },

  //||//////////////////////////////||
  //|| LOAN APPLICATION V2
  //||//////////////////////////////||

  async startApplicationForTenantV2(programId, captchaToken) {
    return Api().post(`/v2/loan-applications/program/${programId}?captchaToken=${captchaToken}`);
  },

  async saveBasicDetailsV2(captchaToken, loanApplicationIdSignature, loanApplicationId, bean) {
    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/basic-details?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      bean
    );
  },

  async setUserV2(captchaToken, loanApplicationIdSignature, loanApplicationId, bean) {
    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/set-user?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      bean
    );
  },

  async setBusinessV2(loanApplicationIdSignature, loanApplicationId, id, signature, token) {
    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/set-business?captchaToken=${token}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      {
        id,
        signature,
      }
    );
  },

  async saveOwnerDetailsV2(captchaToken, loanApplicationIdSignature, loanApplicationId, bean) {
    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/owner-details?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      bean
    );
  },

  async saveBusinessDetailsV2(captchaToken, loanApplicationIdSignature, loanApplicationId, bean) {
    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/business-details?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      bean
    );
  },

  async saveFinancialDetailsV2(
    captchaToken,
    loanApplicationIdSignature,
    loanApplicationId,
    files,
    bean
  ) {
    const formData = new FormData();
    formData.append("financialDetails", JSON.stringify(bean));

    if (files?.last2yearsPersonalTax) {
      for (let i = 0; i < files.last2yearsPersonalTax.length; i++) {
        const f = files.last2yearsPersonalTax[i];
        formData.append("last2yearsPersonalTax", f);
      }
    }

    if (files?.last2yearsBusinessTax) {
      for (let i = 0; i < files.last2yearsBusinessTax.length; i++) {
        const f = files.last2yearsBusinessTax[i];
        formData.append("last2yearsBusinessTax", f);
      }
    }

    if (files?.otherFiles) {
      for (let i = 0; i < files.otherFiles.length; i++) {
        const f = files.otherFiles[i];
        formData.append("other", f);
      }
    }

    if (files?.idDocument) {
      for (let i = 0; i < files.idDocument.length; i++) {
        const f = files.idDocument[i];
        formData.append("idDocument", f);
      }
    }

    if (files?.businessBankStatements) {
      for (let i = 0; i < files.businessBankStatements.length; i++) {
        const f = files.businessBankStatements[i];
        formData.append("last6monthsBusinessBankStatements", f);
      }
    }

    if (files?.personalBankStatements) {
      for (let i = 0; i < files.personalBankStatements.length; i++) {
        const f = files.personalBankStatements[i];
        formData.append("last6monthsPersonalBankStatements", f);
      }
    }

    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/financial-details?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  async getLoanApplicationSummaryV2(captchaToken, loanApplicationIdSignature, loanApplicationId) {
    return Api().get(
      `/v2/loan-applications/${loanApplicationId}/summary?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`
    );
  },

  async submitApplicationV2(captchaToken, loanApplicationIdSignature, loanApplicationId) {
    return Api().post(
      `/v2/loan-applications/${loanApplicationId}/submit?captchaToken=${captchaToken}&loanApplicationIdSignature=${loanApplicationIdSignature}`
    );
  },

  //||//////////////////////////////||
  //|| LOAN PROGRAM
  //||//////////////////////////////||

  async getProgramDetails(programId, captchaToken) {
    return Api().get(`/loan-program/${programId}?captchaToken=${captchaToken}`);
  },

  async getProgramQuestions(programId, captchaToken) {
    return Api().get(`/loan-program/${programId}/questions?captchaToken=${captchaToken}`);
  },

  async getTenantPrograms() {
    return Api().get(`/loan-program`);
  },
};
