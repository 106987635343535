<template>
  <div class="card border color-border h-100" style="min-height: 180px">
    <div class="card-title fs-20 m-3 mb-0">Average Daily Balance</div>
    <div class="card-body position-relative">
      <span class="fs-32 fw-semi-bold">
        {{ $n(avgDailyBalance, "currency", { notation: "compact" }) }}
      </span>

      <div>
        <p class="fs-10 fw-semi-bold text-muted my-0 font-spacing mt-1">LAST {{ period }} DAYS</p>

        <canvas id="financialChart" style="max-height: 40px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

import { watch } from "vue";
import { mapActions, mapState } from "pinia";
import { useBusinessesStore } from "@/stores/BusinessStore";

export default {
  props: ["businessId"],
  data() {
    return {
      avgDailyBalance: 0,
      cashInVariance: 0,
      theChart: null,
    };
  },
  computed: {
    ...mapState(useBusinessesStore, ["period", "currentBankAccount"]),
  },
  methods: {
    ...mapActions(useBusinessesStore, ["getBusinessCashDailyBalanceAverage"]),
    async loadData() {
      const dailyBalance = await this.getBusinessCashDailyBalanceAverage(this.businessId);

      this.avgDailyBalance = dailyBalance.average;

      const labels = dailyBalance.values.map((x) => x.date);
      const values = dailyBalance.values.map((x) => x.balance);

      const data = {
        labels: labels,
        datasets: [
          {
            data: values,
            fill: false,
            cubicInterpolationMode: "monotone",

            borderColor: ["rgba(177, 107, 248, 1)"],
            borderWidth: 2,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        pointHitRadius: 40,

        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            suggestedMin: 0,
            suggestedMax: 100,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      if (this.theChart != null) {
        this.theChart.destroy();
      }

      const ctx = document.getElementById("financialChart");
      this.theChart = new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },
  created() {
    const businessStore = useBusinessesStore();
    watch(
      () => businessStore.period,
      () => {
        this.loadData();
      }
    );
    watch(
      () => businessStore.currentBankAccount,
      () => {
        this.loadData();
      }
    );
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
