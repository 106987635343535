<template>
  <div class="row">
    <div class="col d-flex align-items-center justify-content-center">
      <div class="row justify-content-center ms-sm-0 ms-4 mb-lg-0 mb-4">
        <h3 class="color-text-dark fs-20 col-lg-7 pe-0">Search for a business in &nbsp;</h3>
        <h3 class="color-text-dark fw-bold fs-20 col-lg-5 ps-2 ps-lg-1 ms-lg-0 ms-1">
          <div class="scroller">
            <span>
              California<br />
              Florida<br />
              Illinois<br />
              Maryland<br />
              Massachusetts<br />
              New York<br />
              Ohio<br />
              Pennsylvania<br />
              Texas<br />
              any state<br />
            </span>
          </div>
        </h3>
      </div>
    </div>
  </div>
  <div class="container-fluid h-100 px-lg-5">
    <div
      class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1 justify-content-start flex-nowrap color-background h-100">
      <SearchBusinessBar></SearchBusinessBar>
    </div>
  </div>
  <div v-if="credits < 11" class="row">
    <div v-if="credits > 0" class="col d-flex align-items-center justify-content-center">
      <span class="text-danger fs-16 mt-4">You only have&nbsp;</span>
      <span class="text-danger fs-16 mt-4"> {{ credits }}</span>
      <span class="text-danger fs-16 mt-4">&nbsp;credit</span>
      <span v-if="credits > 1" class="text-danger fs-16 mt-4">s</span>
      <span class="text-danger fs-16 mt-4">&nbsp;remaining! Use them wisely!</span>
    </div>
    <div v-else class="col d-flex align-items-center justify-content-center">
      <span class="text-danger fs-16 mt-4">You ran out of credits!</span>
    </div>
  </div>
</template>

<script>
import Analytics from "@/services/analytics.js";
import SearchBusinessBar from "@/components/SearchBusinessBar.vue";

export default {
  props: ["credits"],
  data() {
    return {
      searchQuery: "",
      currentState: "New York",
    };
  },

  methods: {
    isQueryEmpty() {
      return this.searchQuery.trim().length < 3;
    },
    setCurrentState(state) {
      this.currentState = state;
    },
    searchBusiness() {
      if (this.isQueryEmpty()) {
        return;
      }
      Analytics.logSearch(this.searchQuery, this.currentState, false);

      this.$router.push({
        name: "ResultsSearch",
        params: {
          region: this.currentState,
          name: this.searchQuery,
        },
      });
    },
  },

  components: {
    SearchBusinessBar,
  },
};
</script>

<style>
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  width: 7.5em;
}
.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 20s infinite;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
  }
  5% {
    top: 0em;
  }
  10% {
    top: -1.2em;
  }
  15% {
    top: -1.2em;
  }
  20% {
    top: -2.4em;
  }
  25% {
    top: -2.4em;
  }
  30% {
    top: -3.6em;
  }
  35% {
    top: -3.6em;
  }
  40% {
    top: -4.8em;
  }
  45% {
    top: -4.8em;
  }
  50% {
    top: -6em;
  }
  55% {
    top: -6em;
  }
  60% {
    top: -7.2em;
  }
  65% {
    top: -7.2em;
  }
  70% {
    top: -8.4em;
  }
  75% {
    top: -8.4em;
  }
  80% {
    top: -9.6em;
  }
  85% {
    top: -9.6em;
  }
  90% {
    top: -10.8em;
  }
  95% {
    top: -10.8em;
  }
}
</style>
