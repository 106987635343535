.
<template>
  <div class="card border color-border h-100" style="min-height: 180px">
    <div class="card-title fs-20 m-3 mb-0">{{ this.title }}</div>
    <div class="card-body position-relative">
      <div class="d-flex align-items-center">
        <span class="fs-32 m-0 fw-semi-bold">{{ $n(this.lastValue) }}</span>
        <span
          v-if="growth"
          class="fs-18 ms-3"
          :class="this.growth > 0 ? 'color-text-positive' : 'color-text-negative'">
          <i
            v-if="this.growth"
            class="bi"
            :class="this.growth > 0 ? 'bi-arrow-up-circle' : 'bi-arrow-down-circle'"></i>
          {{ $n(Math.abs(this.growth), "percent", { minimumFractionDigits: 1 }) }}
        </span>
      </div>
      <div>
        <p class="fs-10 fw-semi-bold text-muted my-0 font-spacing mb-3">LAST 30 DAYS</p>
        <canvas v-if="this.id" :id="this.id" style="max-height: 40px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapActions } from "pinia";
import { useBusinessesStore } from "@/stores/BusinessStore";

export default {
  props: [
    "businessId",
    "id",
    "title",
    "color",
    "cardType", // possible values: ["website", "facebook", "instagram"]
  ],
  data() {
    return {
      values: [],
    };
  },
  computed: {
    lastValue() {
      if (this.values.length) {
        return this.values[this.values.length - 1];
      }
      return 0;
    },
    growth() {
      if (this.values.length >= 2) {
        const n1 = this.values[this.values.length - 1];
        const n2 = this.values[this.values.length - 2];

        if (n2 == 0) {
          return null;
        }

        return (n1 - n2) / n2;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(useBusinessesStore, [
      "getWebsiteVisitsOverTime",
      "getInstagramFollowersOverTime",
      "getFacebookLikesOverTime",
    ]),
    getData() {
      if (this.cardType === "website") {
        return this.getWebsiteVisitsOverTime;
      }
      if (this.cardType === "instagram") {
        return this.getInstagramFollowersOverTime;
      }
      if (this.cardType === "facebook") {
        return this.getFacebookLikesOverTime;
      }
    },
    async loadData() {
      const d = await this.getData()(this.businessId);

      this.values = d.map((x) => x.value);
      const dates = d.map((x) => this.$d(x.date, "monthAndYear"));

      const ctx = document.getElementById(this.id);

      const data = {
        labels: dates,
        datasets: [
          {
            data: this.values,
            fill: false,
            cubicInterpolationMode: "monotone",

            borderColor: [this.color],
            borderWidth: 2,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        pointHitRadius: 30,

        elements: {
          point: {
            radius: 0,
          },
        },

        scales: {
          x: {
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawTicks: {
                display: false,
              },
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
          },
        },
        scale: {
          ticks: {
            beginAtZero: true,
          },
        },
      };

      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
