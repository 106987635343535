<template>
  <div class="card border color-border big-card-mh h-100 px-2">
    <div class="card-body pb-0">
      <div class="container mb-3 m-0 p-0 align-left d-flex justify-content-between align-items-end">
        <h4 class="card-title m-0 p-0 fs-20">Business Area</h4>
        <div class="card-title m-0 p-0 fs-12 d-flex align-items-center">
          {{ business.address.city }},
          {{ business.address.state }}
        </div>
      </div>
      <div v-if="loadSkeleton">
        <div class="d-none d-sm-block">
          <content-loader
            class="mb-4"
            viewBox="0 0 500 274"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="6" ry="6" width="500" height="274" />
          </content-loader>
        </div>
        <div class="d-block d-sm-none">
          <content-loader
            class="mb-4"
            viewBox="0 0 320 274"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb">
            <rect x="0" y="0" rx="6" ry="6" width="320" height="274" />
          </content-loader>
        </div>
      </div>
      <div
        v-else
        class="card border color-border container-xxl m-0 p-0 mb-4 position-relative"
        style="height: 274px"
        @mouseenter="setShowLoginBanner(true)"
        @mouseleave="setShowLoginBanner(false)">
        <img class="image-blur" src="@/assets/map_placeholder.png" style="height: 274px" />
        <div
          v-if="showLoginBanner"
          class="card position-absolute top-50 start-50 translate-middle"
          style="max-width: 400px; margin: auto">
          <div class="card-body">
            <div class="card-title fs-16 fw-semi-bold">Login to Shubox</div>
            <div class="card-text fs-12">
              To access the complete business area overview, please log in or create a Shubox
              account.
            </div>
            <router-link :to="{ name: 'Home' }" class="text-decoration-none color-text-dark">
              <button
                class="btn sb-btn-dark rounded fs-14 mt-2 mx-auto d-flex align-items-center px-3">
                Login
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { ContentLoader } from "vue-content-loader";

export default {
  props: ["business", "loading"],
  components: {
    ContentLoader,
  },

  setup(props) {
    const loadSkeleton = props.loading && !props.business?.address?.city;
    const showLoginBanner = ref(false);

    const setShowLoginBanner = (value) => {
      showLoginBanner.value = value;
    };

    return {
      showLoginBanner,
      setShowLoginBanner,
      loadSkeleton,
    };
  },
};
</script>
<style></style>
