<template>
  <p class="text-muted m-0 fw-semi-bold fs-12 font-spacing">{{ pageTitle }}</p>
  <h3 class="color-text-dark mb-0 fw-semi-bold fs-24 me-md-0 me-2">
    {{ tenant }}
  </h3>
  <div v-if="page" class="row d-flex justify-content-between my-3">
    <div class="col-md-6 d-flex">
      <button
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 text-dark color-background me-3 pb-1"
        :class="isPageOverview() ? 'selected-tab-underlined' : ''"
        @click="!isPageOverview() ? $emit('changePage', 'overview-page') : ''">
        <div :class="isPageOverview() ? '' : 'dropdown-hover'">Overview</div>
      </button>
      <button
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 text-dark color-background me-3 pb-1"
        :class="isPageLoansPortfolio() ? 'selected-tab-underlined' : ''"
        @click="!isPageLoansPortfolio() ? $emit('changePage', 'loans-portfolio-page') : ''">
        <div :class="isPageLoansPortfolio() ? '' : 'dropdown-hover'">Loans Portfolio</div>
      </button>
    </div>
    <div v-if="isPageOverview()" class="col-md-6 col mt-3 mt-md-0 d-flex justify-content-end">
      <div class="row">
        <div class="dropdown col-auto d-flex align-items-center">
          <p
            class="btn dropdown-toggle m-0 p-0 color-text-dark fs-12 py-1 px-2"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ programName }}
            <i class="bi bi-chevron-down color-text-dark icon-bold-2 fs-10 ps-2"></i>
          </p>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li class="dropdown-item fs-12" @click="$emit('changeProgramFilter', 0)">
              All programs
            </li>
            <li
              v-for="program in programs"
              :key="program?.id"
              class="dropdown-item fs-12"
              @click="$emit('changeProgramFilter', program.id)">
              {{ program?.name }}
            </li>
          </ul>
        </div>
        <div class="dropdown col-auto d-flex align-items-center">
          <p
            class="btn dropdown-toggle m-0 p-0 color-text-dark fs-12 py-1 px-2"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ currentTimeFilterLabel() }}
            <i class="bi bi-chevron-down color-text-dark icon-bold-2 fs-10 ps-2"></i>
          </p>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li class="dropdown-item fs-12" @click="$emit('changePeriodFilter', 'MONTHLY')">
              Current month
            </li>
            <li class="dropdown-item fs-12" @click="$emit('changePeriodFilter', 'QUARTERLY')">
              Current quarter
            </li>
            <li class="dropdown-item fs-12" @click="$emit('changePeriodFilter', 'YEARLY')">
              Current year
            </li>
            <li class="dropdown-item fs-12" @click="$emit('changePeriodFilter', 'ALL')">
              All time
            </li>
          </ul>
        </div>
        <div class="dropdown col-auto d-flex align-items-center">
          <p
            class="btn dropdown-toggle m-0 p-0 color-text-dark fs-12 py-1 px-2"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ valueTypeFilter }}
            <i class="bi bi-chevron-down color-text-dark icon-bold-2 fs-10 ps-2"></i>
          </p>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style="max-width: 50px; width: 50px">
            <li class="dropdown-item fs-12" @click="$emit('changeValueTypeFilter', 'Total count')">
              Total count
            </li>
            <li class="dropdown-item fs-12" @click="$emit('changeValueTypeFilter', 'Total amount')">
              Total amount
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="isPageLoansPortfolio()" class="col-md-6 col mt-3 mt-md-0 d-flex justify-content-end">
      <button
        @click="exportLoansCsv()"
        class="d-flex align-items-center fw-semi-bold fs-14 border-0 color-text-primary color-background">
        <div class="hover d-flex">
          <i class="bi bi-download align-items-center d-flex fs-18 pe-1 fw-light"></i>
          <span class="d-none d-xl-block text-start">Export CSV </span>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { getTenant } from "@/services/auth.js";
import { computed } from "vue";

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
    valueTypeFilter: {
      type: String,
      default: "Total count",
    },
    periodFilter: {
      type: String,
      default: "MONTHLY",
    },
    programs: {
      type: Array,
    },
    programFilter: {
      type: Number,
    },
  },

  setup(props, { emit }) {
    const tenant = getTenant();
    const pageTitle = "LOAN OFFICER DASHBOARD";

    const isPageOverview = () => {
      return props.page === "overview-page";
    };

    const isPageLoansPortfolio = () => {
      return props.page === "loans-portfolio-page";
    };

    const isPageInterestForms = () => {
      return props.page === "interest-forms-page";
    };

    const exportLoansCsv = () => {
      emit("exportLoansCsv");
    };

    const programNameMap = computed(() => {
      const map = { 0: "All programs" };
      if (props?.programs && props.programs.length > 0) {
        props.programs.forEach((program) => {
          map[program.id] = program.name;
        });
        return map;
      }
      return map;
    });

    const programName = computed(() => {
      return programNameMap.value[props.programFilter] || "All programs";
    });

    const getProgramName = computed(() => {
      const id = props.programFilter;
      if (Array.isArray(props.programs) && props.programs.length > 0) {
        const firstProgram = props.programs.find((program) => program.id === id);
        return firstProgram ? firstProgram.name : "All programs";
      } else {
        return "All programs";
      }
    });

    const currentTimeFilterLabel = () => {
      if (props.periodFilter === "MONTHLY") {
        return "Current month";
      } else if (props.periodFilter === "QUARTERLY") {
        return "Current quarter";
      } else if (props.periodFilter === "YEARLY") {
        return "Current year";
      } else if (props.periodFilter === "ALL") {
        return "All time";
      }
    };

    const currentProgramFilter = () => {
      if (props.programFilter === 0) return "All programs";
      else return getProgramName(props.programFilter);
    };

    return {
      pageTitle,
      tenant,
      isPageOverview,
      isPageLoansPortfolio,
      isPageInterestForms,
      exportLoansCsv,
      currentTimeFilterLabel,
      currentProgramFilter,
      getProgramName,
      programNameMap,
      programName,
    };
  },
};
</script>
<style></style>
